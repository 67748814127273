import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getProgramService } from "features/programs/programApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  ProgramVariantItem,
  ProgramVariantOptions,
  getProgramVariantService,
} from "./programVariantApi";

export interface UseProgramVariantFormProps extends GridModalFormProps {}

type RouteParams = {
  program_variant_id?: string;
};

export interface UseProgramVariantFormReturnType
  extends UseFormReturnType<ProgramVariantItem, ProgramVariantOptions> {
  params?: Readonly<
    Params<
      "program_variant_id" | "program_id" | "booking_id" | "booking_option_id"
    >
  >;
}

export default function useProgramVariantForm({
  id,
  afterSave,
  defaultValues,
}: UseProgramVariantFormProps): UseProgramVariantFormReturnType {
  const params = useParams<
    "program_variant_id" | "program_id" | "booking_id" | "booking_option_id"
  >();
  const scope = { programs: params.program_id };
  const service = getProgramVariantService(scope);

  const form = useForm<ProgramVariantItem, ProgramVariantOptions>({
    id: params.program_variant_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "ProgramVariant",
    rules: {
      name: Validator.rule.any().required(),
      program_id: params.program_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      code: Validator.rule.any().required(),
      sku: Validator.rule.any().required(),
      min_unit: Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      program_id: () => getProgramService(undefined).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}

