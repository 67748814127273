import Check from "@mui/icons-material/Check";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  ButtonProps as MuiButtonProps,
  ToggleButton,
} from "@mui/material";
import { FakeEvent } from "common/types";
import { ColorIndicatorProps } from "../types";

export interface CheckboxButtonProps
  extends ColorIndicatorProps,
    Omit<
      MuiButtonProps,
      "error" | "onChange" | "onBlur" | "value" | "checked"
    > {
  error?: string;
  helperText?: string;
  mask?: string;
  onChange?: (evt: FakeEvent) => void;
  onBlur?: Function;
  onChangeByKeyPress?: Function;
  useDelayedKeystrokes?: boolean;
  unmask?: boolean;
  editable?: boolean;
  isDirty?: boolean;
  value?: boolean | number;
  label?: string;
  numeric?: boolean;
  nullOnFalse?: boolean;
  required?: boolean;
  formControlProps?: FormControlProps;
}

export default function CheckboxButton({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  onChangeByKeyPress,
  editable,
  useDelayedKeystrokes = true,
  isDirty,
  label: _label,
  required,
  numeric,
  indicatorColor,
  indicatorOpacity,
  formControlProps,
  nullOnFalse,
  ...props
}: CheckboxButtonProps) {
  const label =
    required && _label ? (
      <span>
        {_label} <span style={{ color: "red" }}>*</span>
      </span>
    ) : (
      _label
    );
  return (
    <FormControl
      //style={{ minHeight: 50 }}
      {...formControlProps}
      error={error ? true : false}
    >
      <FormLabel htmlFor={props.id}>{label ? label : ""}</FormLabel>
      <ToggleButton
        id={props.id}
        sx={{
          p: 1,
          minWidth: 40,
          minHeight: 40,
        }}
        value="check"
        selected={!!value}
        onChange={event => {
          //event.stopPropagation();
          console.log("LOL");
          const checked = !value;
          console.log({ checked });
          const numericChecked: number = checked ? 1 : 0;
          let v: any = numeric ? (numericChecked as never) : (checked as never);

          if (!checked && nullOnFalse) {
            v = null;
          }
          onChange &&
            onChange({
              target: {
                value: v,
              },
            });
        }}
      >
        {<Check sx={{ opacity: !!value ? 1 : 0.2 }} />}
      </ToggleButton>
      {(error || helperText) && (
        <FormHelperText error={error ? true : false}>
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

