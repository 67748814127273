import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import {
  alpha,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  StaticDatePicker,
  StaticDatePickerProps,
} from "@mui/x-date-pickers/StaticDatePicker";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
//@ts-ignore
import tgrx from "@tgrx/getcontrasttext";
import I18n from "i18n";
import moment, { Moment } from "moment-timezone";
import stc from "string-to-color";
import { ScrollPicker } from "../TimePicker";
import { BaseInputProps } from "../types";
import Toolbar from "./../../Toolbar";

export type Option = {
  label: string;
  value: any;
};

const hours12: Option[] = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
];
const hours24: Option[] = [
  { label: "00", value: "00" },
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
];

function getMinutes(increment: number): Option[] {
  let minute = 0;
  let options: Option[] = [];
  if (!increment) {
    return options;
  }
  while (minute <= 59) {
    let value = String(minute).padStart(2, "0");
    options.push({
      label: value,
      value,
    });
    minute += increment;
  }
  return options;
}
export interface ColorIndicatorProps {
  /**
   * Show vertical line at left of the field inside. color string or "auto" generate unique color by props.id
   */
  indicatorColor?: "auto" | string;
  /**
   * It works only with indicatorColor="auto"
   */
  indicatorOpacity?: number;
}

export function getContrastColor(
  color?: string,
  opacity: number = 1,
  contrastThreshold: number = 3
): string {
  if (!color) {
    return "#000000";
  }

  return tgrx.default({
    background: color,
    contrastThreshold: contrastThreshold / opacity,
    warnings: false,
  });
}

export function getColorsFromString(
  str?: string,
  opacity?: number
): {
  color?: string;
  backgroundColor?: string;
} {
  if (!str) {
    return {};
  }
  let backgroundColor = opacity ? alpha(stc(str), opacity) : stc(str);
  const color = getContrastColor(backgroundColor, opacity);
  return { color, backgroundColor };
}

export function getTextFieldColorIndicatorSx(
  id: any,
  { indicatorColor, indicatorOpacity }: ColorIndicatorProps
) {
  let sx = {};
  if (indicatorColor === "auto") {
    sx = {
      "& .MuiFilledInput-root": {
        boxShadow: `inset 5px 0 0 0 ${
          getColorsFromString(id, indicatorOpacity || 1).backgroundColor
        }`,
      },
    };
  } else if (indicatorColor) {
    sx = {
      "& .MuiFilledInput-root": {
        boxShadow: `inset 5px 0 0 0 ${indicatorColor}`,
      },
    };
  }
  return sx;
}

export type DateTimePickerState = {
  hour: string;
  minute: string;
  ampm: string;
  date: string;
  open: boolean;
  value: string;
};

export type DateTimePickerProps = {
  is24Hours?: boolean;
  minuteIncrement?: number;
  shorcutIncrement?: number;
  changeOnAccept?: boolean;
  changeOnClickAway?: boolean;
  extraAdornment?: ReactNode;
  dates?: string[];
  dateFormat?: string;
  renderBottomContent?: (state: DateTimePickerState) => ReactNode;
  renderInput?: Function;
  ignoreChangesWhenOpen?: boolean;
  //Depricated
  minutesStep?: boolean;
  openOnInputClick?: boolean;
  pickerProps?: StaticDatePickerProps<any>;
} & TextFieldProps &
  BaseInputProps;

export default function DateTimePicker({
  id,
  error,
  helperText,
  clearable = true,
  indicatorColor,
  indicatorOpacity,
  value,
  onChange,
  isDirty,
  onBlur,
  label,
  minuteIncrement = 1,
  shorcutIncrement = 15,
  changeOnAccept = true,
  changeOnClickAway = true,
  required,
  is24Hours = true,
  ignoreChangesWhenOpen,
  //Depricated
  minutesStep,
  extraAdornment,
  dates,
  dateFormat = "L",
  renderInput,
  openOnInputClick = true,
  renderBottomContent,
  pickerProps,
  ...textFieldProps
}: DateTimePickerProps) {
  const amorpm = useRef<Option[]>([
    {
      label: moment("08:00", "HH:mm").format("A"),
      value: moment("08:00", "HH:mm").format("A"),
    },
    {
      label: moment("16:00", "HH:mm").format("A"),
      value: moment("16:00", "HH:mm").format("A"),
    },
  ]);

  const dateOptions: Option[] = dates
    ? dates.map(d => ({
        value: d,
        label: moment(d).format(dateFormat),
      }))
    : [];

  const defaultDateTimePickerState = useRef<DateTimePickerState>({
    hour: "01",
    minute: "00",
    ampm: moment("08:00", "HH:mm").format("A"),
    open: false,
    ...parseValues(),
    value: parseValue(value as any) || "",
  });

  const lastState = useRef<DateTimePickerState>(
    defaultDateTimePickerState.current
  );
  const [open, setOpen] = useState<boolean>(false);
  const [state, _setState] = useState<DateTimePickerState>(
    defaultDateTimePickerState.current
  );

  const setState = useCallback(
    (_state: Partial<DateTimePickerState>) => {
      let newState = Object.assign({}, lastState.current, _state);
      lastState.current = newState;
      return _setState({ ...newState });
    },
    [state]
  );

  //const [open, setOpen] = useState<boolean>(false);
  const minutes = getMinutes(minuteIncrement);
  const shortcuts = getMinutes(shorcutIncrement);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isInternalChange = useRef<boolean>(false);
  const textFieldRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();

  const wrapperHeight = 160;
  const showModal = () => {
    setState({ open: true });
  };

  const hideModal = () => {
    setState({ ...defaultDateTimePickerState.current });
  };

  function getValue() {
    if (dates || true) {
      const datestr = is24Hours
        ? `${state.date} ${state.hour}:${state.minute}`
        : `${state.date} ${state.hour}:${state.minute} ${state.ampm}`;
      const d = is24Hours
        ? moment(datestr, "YYYY-MM-DD HH:mm")
        : moment(datestr, "YYYY-MM-DD hh:mm A");
      if (d.isValid()) {
        return d.format("YYYY-MM-DD HH:mm");
      }
    } else {
      const datestr = `${state.hour}:${state.minute} ${state.ampm}`;
      const d = is24Hours
        ? moment(datestr, "HH:mm")
        : moment(datestr, "hh:mm A");

      if (d.isValid()) {
        return d.format("HH:mm");
      }
    }
    return null;
  }

  function parseValue(datestr?: string) {
    if (!value) {
      return "";
    }
    let d: any = null;
    if (dates || true) {
      d = moment(value);
    } else {
      d = moment(value, "HH:mm");
    }

    if (d.isValid()) {
      if (is24Hours) {
        return d.format(`${dateFormat} HH:mm`);
      } else {
        return d.format(`${dateFormat} hh:mm A`);
      }
    }
    return "";
  }

  function parseValues() {
    let d: any = null;
    if (dates || true) {
      d = moment(value);
    } else {
      d = moment(value, "HH:mm");
    }

    if (value && d.isValid()) {
      return {
        date: d.format("YYYY-MM-DD"),
        hour: is24Hours ? d.format("HH") : d.format("hh"),
        minute: d.format("mm"),
        ampm: d.format("A"),
      } as any;
    } else {
      const now = moment();
      return {
        date: now.format("YYYY-MM-DD"),
        hour: is24Hours ? now.format("HH") : now.format("hh"),
        minute: now.format("mm"),
        ampm: now.format("A"),
      } as any;
    }
    return {} as any;

    /*const d = moment(value as any, "HH:mm");
    if (d.isValid()) {
      return {
        hour: is24Hours ? d.format("HH") : d.format("hh"),
        minute: d.format("mm"),
        ampm: d.format("A"),
      };
    }
    return {};*/
  }

  const handleAccept = () => {
    onChange && onChange({ target: { value: getValue() } } as any);
    hideModal();
  };

  const handleInputValueChange = () => {
    console.log("handleInputValueChange");
    let parsedValue = null;
    if (is24Hours) {
      parsedValue = state.value ? moment(state.value /*, "HH:mm"*/) : null;
    } else {
      parsedValue = state.value ? moment(state.value /*, "hh:mm A"*/) : null;
    }
    if (state.value === "") {
      onChange && onChange({ target: { value: null } } as any);
    } else if (parsedValue && parsedValue.isValid()) {
      let exportedValue = parsedValue.format("YYYY-MM-DD HH:mm");
      if (exportedValue === state.value) {
        onChange && onChange({ target: { value: exportedValue } } as any);
      }
    }
  };

  function handleOnChange(evt: any) {
    if (state.value !== evt.target.value) {
      isInternalChange.current = true;
      setState({ value: evt.target.value });
    }
  }
  /*const inputRef = useRef();*/

  const _label = required ? (
    <>
      {label} <b style={{ color: "red" }}> *</b>
    </>
  ) : (
    label
  );
  function getDateValue(): Moment | null {
    return !state.date || state.date === "" ? null : moment(state.date);
  }
  useEffect(() => {
    if (isInternalChange.current) {
      isInternalChange.current = false;
      handleInputValueChange();
    }
  }, [state.value]);

  useEffect(() => {
    if (state.open) {
      setTimeout(() => {
        setOpen(true);
      }, 400);
    } else {
      setOpen(false);
    }
  }, [state.open]);

  useEffect(() => {
    isInternalChange.current = false;
    if (!ignoreChangesWhenOpen || !open) {
      setState({ value: parseValue(value as any), ...parseValues() });
    }
  }, [value, state.open]);

  useEffect(() => {
    if (!changeOnAccept) {
      isInternalChange.current = false;
      onChange && onChange({ target: { value: getValue() } } as any);
    }
  }, [state.hour, state.minute, state.ampm]);

  return (
    <Box>
      {!!renderInput ? (
        <Box
          ref={textFieldRef}
          onClick={
            textFieldProps.disabled
              ? undefined
              : evt => {
                  evt.stopPropagation();
                  //handleClick()
                  //onChange && onChange({ target: { value: null } } as any);
                  if (state.open) {
                    if (changeOnClickAway) {
                      handleAccept();
                    } else {
                      hideModal();
                    }
                  } else {
                    showModal();
                  }
                }
          }
        >
          {renderInput({ value, ...textFieldProps })}
        </Box>
      ) : (
        <TextField
          ref={textFieldRef}
          label={_label}
          sx={{
            ...getTextFieldColorIndicatorSx(id, {
              indicatorColor,
              indicatorOpacity,
            }),
            ...textFieldProps.sx,
          }}
          id={id}
          value={state.value}
          helperText={error || helperText}
          error={error ? true : false}
          onChange={handleOnChange}
          onBlur={evt => {
            //hideModal();
            onBlur && onBlur(evt);
          }}
          onClick={openOnInputClick ? () => showModal() : undefined}
          /*onFocus={evt => {
          evt.stopPropagation();
          showModal();
          //onBlur && onBlur(evt);
        }}*/
          InputProps={{
            readOnly: true,
            //inputComponent: TextMaskCustom as any,
            //name: is24Hours ? "is24Hours" : undefined,
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: -6 }}>
                {!!state.value && state.value !== "" && clearable && (
                  <IconButton
                    disabled={textFieldProps.disabled}
                    onClick={() => {
                      isInternalChange.current = true;

                      onChange && onChange({ target: { value: null } } as any);
                      //setState({ ...defaultDateTimePickerState.current });
                    }}
                    sx={{ color: theme.palette.text.secondary }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  disabled={textFieldProps.disabled}
                  //onClick={() => setOpen(true)}
                  onClick={evt => {
                    evt.stopPropagation();
                    //handleClick()
                    //onChange && onChange({ target: { value: null } } as any);
                    if (state.open) {
                      if (changeOnClickAway) {
                        handleAccept();
                      } else {
                        hideModal();
                      }
                    } else {
                      showModal();
                    }
                  }}
                  size="small"
                >
                  <AccessTimeIcon fontSize="small" />
                </IconButton>
                {extraAdornment}
              </InputAdornment>
            ),
          }}
          {...textFieldProps}
        />
      )}
      <Popper
        id={id}
        open={state.open}
        anchorEl={textFieldRef.current}
        transition
        placement="bottom-start"
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper
              elevation={5}
              sx={{
                p: 1,
              }}
            >
              <ClickAwayListener
                onClickAway={() => {
                  if (changeOnClickAway) {
                    handleAccept();
                  } else {
                    state.open && hideModal();
                  }
                }}
              >
                <Box>
                  <Toolbar>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        //openTo="year"
                        value={getDateValue()}
                        onChange={newValue => {
                          setState({
                            date: newValue
                              ? moment(newValue).format("YYYY-MM-DD")
                              : undefined,
                          });
                        }}
                        //renderInput={params => <TextField />}
                        {...pickerProps}
                      />
                    </LocalizationProvider>
                    <Box sx={{ flex: 1, alignSelf: "stretch", marginTop: 3 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          alignItems: "stretch",
                          paddingBottom: 3,
                          //backgroundColor: "red",
                        }}
                      >
                        <Typography
                          flex={1}
                          textAlign="center"
                          variant="caption"
                        >
                          {I18n.t("App.hours")}
                        </Typography>
                        <Typography
                          flex={1}
                          textAlign="center"
                          variant="caption"
                        >
                          {I18n.t("App.minutes")}
                        </Typography>
                        {!is24Hours && (
                          <Typography
                            flex={1}
                            textAlign="center"
                            variant="caption"
                          >
                            AM/PM
                          </Typography>
                        )}
                        {!!dates && (
                          <Typography
                            flex={1}
                            textAlign="center"
                            variant="caption"
                          >
                            {I18n.t("App.dates")}
                          </Typography>
                        )}
                      </Stack>
                      <Box
                        sx={{
                          p: 2,
                          paddingBottom: 0.5,
                          paddingTop: 0.5,
                          position: "relative",
                          border: theme => `solid 1px ${theme.palette.divider}`,
                          borderRadius: "4px",
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          divider={<Divider orientation="vertical" flexItem />}
                          sx={{
                            position: "relative",
                            height: wrapperHeight,
                            alignItems: "stretch",
                            //backgroundColor: "red",
                          }}
                        >
                          <ScrollPicker
                            useAnimation={open}
                            options={is24Hours ? hours24 : hours12}
                            value={state.hour}
                            onValueChange={value => {
                              setState({ hour: value as string });
                            }}
                            wrapperHeight={wrapperHeight}
                          />
                          <ScrollPicker
                            useAnimation={open}
                            options={minutes}
                            value={state.minute}
                            onValueChange={value => {
                              setState({ minute: value as string });
                            }}
                            wrapperHeight={wrapperHeight}
                          />
                          {!is24Hours && (
                            <ScrollPicker
                              useAnimation={open}
                              options={amorpm.current}
                              value={state.ampm}
                              onValueChange={value => {
                                setState({ ampm: value as string });
                              }}
                              wrapperHeight={wrapperHeight}
                            />
                          )}
                          {!!dates && (
                            <ScrollPicker
                              itemWidth={44}
                              options={dateOptions}
                              value={state.date}
                              onValueChange={value => {
                                setState({ date: value as string });
                              }}
                              wrapperHeight={wrapperHeight}
                            />
                          )}
                        </Stack>
                        <Box
                          sx={{
                            position: "absolute",
                            left: theme => theme.spacing(1),
                            right: theme => theme.spacing(1),
                            top: "50%",
                            borderRadius: "40px",
                            marginTop: `${-4 - 30 / 2}px`,
                            height: `${30}px`,
                            borderBottom: theme =>
                              `dashed 1px ${alpha(
                                theme.palette.primary.main,
                                0.2
                              )}`,
                            borderTop: theme =>
                              `dashed 1px ${alpha(
                                theme.palette.primary.main,
                                0.2
                              )}`,
                            backgroundColor: theme =>
                              alpha(theme.palette.text.primary, 0.1),
                            pointerEvents: "none",
                          }}
                        />
                      </Box>
                      {!!renderBottomContent
                        ? renderBottomContent(state)
                        : null}
                      {shortcuts.length > 0 && (
                        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                          <ButtonGroup
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            {shortcuts.map(option => (
                              <Button
                                key={option.value}
                                onClick={() =>
                                  setState({ minute: option.value })
                                }
                                variant={
                                  option.value === state.minute
                                    ? "contained"
                                    : undefined
                                }
                              >
                                {state.hour}: {option.label}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="outlined"
                            onClick={() => hideModal()}
                          >
                            {I18n.t("App.cancel")}
                          </Button>
                          <Button
                            onClick={() => handleAccept()}
                            variant="contained"
                          >
                            {I18n.t("App.ok")}
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Toolbar>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

