import SkeletonAnimation, {
  SkeletonAnimationTypes,
} from "common/components/SkeletonAnimation/SkeletonAnimation";
import I18n from "i18n";
import { get } from "lodash";
import { ReactElement, ReactNode, cloneElement, useRef } from "react";
import { deepMap } from "react-children-utilities";

export interface IDictionary<TValue> {
  [id: string]: TValue;
}

export interface FormProps {
  children: ReactNode;
  item?: IDictionary<any>;
  errors?: IDictionary<any>;
  options?: IDictionary<any>;
  defaultItem?: IDictionary<any>;
  onChange: Function;
  onValidate?: Function;
  translationCategory?: string;
  translationPlaceholdersCategory?: string;
  translationHelpersCategory?: string;
  idParam?: string;
  disabled?: boolean;
  isDirty?: (id?: string) => boolean;
  disabledFields?: Array<string>;
  loading?: boolean;
  isView?: boolean;
  requiredFields?: string[];
  skeletonAnimationType?: SkeletonAnimationTypes;
  getFieldProps?: (item: any, field: string) => any;
} /* use `interface` if exporting so that consumers can extend */

export default function Form({
  children,
  item = {},
  errors = {},
  options = {},
  onChange,
  translationCategory,
  translationPlaceholdersCategory,
  translationHelpersCategory,
  idParam = "id",
  disabled,
  disabledFields = [],
  loading,
  isDirty: _isDirty,
  isView,
  requiredFields,
  skeletonAnimationType = "shimmer",
  getFieldProps,
}: FormProps) {
  const originalValues = useRef(item || ({} as IDictionary<any>));

  function reset(id: string) {
    let _item = Object.assign({}, item);
    delete _item[id];
    onChange && onChange(_item);
  }

  const handleChange =
    (id: string, originalEventHandler: Function) =>
    (evt: any, option: object) => {
      const value = evt.target.value;
      let _item = Object.assign({}, item);
      if (JSON.stringify(_item[id]) === JSON.stringify(value)) {
        return;
      }
      _item[id] = value;
      originalEventHandler && originalEventHandler(value);
      onChange && onChange(_item, id, option);
    };

  function isDisabled(id: string) {
    return disabled || disabledFields.includes(id);
  }

  function isDirty(id: string) {
    return (
      JSON.stringify(originalValues.current[id]) !== JSON.stringify(item[id])
    );
  }

  return (
    <SkeletonAnimation loading={loading} animationType={skeletonAnimationType}>
      {/*@ts-ignore*/}
      {deepMap(children, (child: ReactNode) => {
        const id = get(child, `props.${idParam}`);
        const roleProp = get(child, "props.role");
        const resetProp = get(child, "props.reset");
        const onUndefined = get(child, "props.onUndefined");
        const customOnChange = get(child, "props.customOnChange");
        const isFirstLoading = !item.id && loading;
        if (id) {
          const el = cloneElement(child as ReactElement, {
            label:
              translationCategory &&
              I18n.t(`${translationCategory}.${id}`, {
                defaultValue: `${translationCategory}.${id}`,
              }),
            placeholder:
              translationPlaceholdersCategory && id !== "sort"
                ? I18n.t(`${translationPlaceholdersCategory}.${id}`, {
                    defaultValue: "",
                  })
                : undefined,
            helperText:
              translationHelpersCategory && id !== "sort"
                ? I18n.t(`${translationHelpersCategory}.${id}`, {
                    defaultValue: "",
                  })
                : undefined,
            value: item[id] === undefined ? onUndefined : item[id],
            error: errors ? errors[id] : undefined,
            options: options ? options[id] : undefined,
            isDirty: isDirty(id),
            disabled: isDisabled(id),
            required: requiredFields ? requiredFields.includes(id) : undefined,
            ...(getFieldProps ? getFieldProps(item, id) || {} : {}),
            ...(child as ReactElement).props,
            onChange:
              customOnChange ||
              handleChange(id, (child as ReactElement).props.onChange),
          });
          return el;
        } else if (roleProp) {
          return cloneElement(child as ReactElement, {
            ...(child as ReactElement).props,
            ...(getFieldProps ? getFieldProps(item, roleProp) || {} : {}),
          });
        } else if (resetProp) {
          return cloneElement(child as ReactElement, {
            ...(child as ReactElement).props,
            reset: undefined,
            onReset: reset(resetProp),
          });
        }
        return child;
      })}
    </SkeletonAnimation>
  );
}

