import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { Box, LinearProgress } from "@mui/material";
import { PageHeader, Spacer } from "common";
import I18n from "i18n";
import { useState } from "react";
import KitchenFilter from "./KitchenFilter";
import KitchenList from "./KitchenList";
import { getKitchenData, KitchenItem } from "./kitchenApi";

export default function KitchenPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [kitchenData, setKitchenData] = useState<KitchenItem[]>([]);

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => {
    setLoading(true);
    const { success, data } = await getKitchenData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    if (success) {
      setKitchenData(data || []);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        //position: "relative",
      }}
    >
      <PageHeader
        avatar={<RestaurantMenuIcon />}
        title={I18n.t("Kitchen.title")}
      />
      <KitchenFilter onFilter={handleFilter} />
      <Spacer h={1} />
      <KitchenList kitchenData={kitchenData} />
      {loading && (
        <LinearProgress sx={{ position: "fixed", left: 0, top: 0, right: 0 }} />
      )}
    </Box>
  );
}
