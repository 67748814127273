//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  ExchangeRateItem,
  getExchangeRateService,
} from "./exchangeRateApi";

export default function useExchangeRateList() {
  const params = useParams();
  const scope = undefined;
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "exchange-rates/"
      : ""
  }`
  const service = getExchangeRateService();

  return { ...service, params, scope, editUrl };
}

