import { FakeEvent } from "common/types";
import { useRef, useState } from "react";

const useList = <T extends object>(
  initialValue: T[],
  onChange?: (value: FakeEvent<T[] | null | undefined>) => void,
  onRowCahnge?: (item: T, index: number, field: keyof T, value: any) => T
) => {
  //const [items, setItems] = useState<T[]>(initialValue);
  const items = initialValue || [];
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const timer = useRef<any>();
  const touched = useRef(false);
  //const [debouncedItems] = useDebounce(items, 300);

  const handleOnchange = (newItems: T[]) => {
    onChange?.({ target: { value: newItems.length > 0 ? newItems : null } });
  };

  /* useEffect(() => {
    handleOnchange(debouncedItems);
  }, [debouncedItems, handleOnchange]);*/

  const handleAddItem = () => {
    touched.current = true;
    const newItem = {} as T;
    let prevItems = [...(initialValue || [])];
    handleOnchange([...(prevItems || []), newItem]);
  };

  const handleDeleteItem = (index: number) => {
    touched.current = true;
    let prevItems = [...(initialValue || [])];
    handleOnchange(prevItems.filter((_, i) => i !== index));
  };

  const handleChangeItem = (index: number, field: keyof T, value: any) => {
    touched.current = true;
    let prevItems = [...(initialValue || [])];
    if (onRowCahnge) {
      let newItems = prevItems.map((item, i) =>
        i === index
          ? onRowCahnge({ ...item, [field]: value }, index, field, value)
          : item
      );
      handleOnchange(newItems);
      return;
    }
    let newItems = prevItems.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    handleOnchange(newItems);
  };

  const handleOpenDialog = (index: number) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleDelete = () => {
    if (deleteIndex !== null) {
      handleDeleteItem(deleteIndex);
      handleCloseDialog();
    }
  };

  return {
    items,
    handleAddItem,
    handleDeleteItem,
    handleChangeItem,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleDelete,
  };
};

export default useList;

