import {
  Button,
  Field,
  FieldsContainer,
  Form,
  FormBlock,
  Spacer,
  TextField,
  Toolbar,
} from "common";
import I18n from "i18n";
import { useOutletContext } from "react-router-dom";
import { UseRoleFormProps, UseRoleFormReturnType } from "./useRoleForm";

export interface RoleFormProps extends UseRoleFormProps {}

export default function RoleForm() {
  const {
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    getIsDirty,
    requiredFields,
    onChange,
    save,
    saving,
    params,
    //} = useRoleForm({});
  } = useOutletContext<UseRoleFormReturnType>();

  const title = isCreate ? I18n.t("Role.addTitle") : I18n.t("Role.editTitle");
  return (
    <FormBlock>
      <Form
        loading={loading}
        item={item}
        options={options}
        errors={errors}
        onChange={onChange}
        translationCategory="Role.attributes"
        isDirty={getIsDirty}
        requiredFields={requiredFields}
      >
        <FieldsContainer>
          <Field>
            <TextField id="name" />
          </Field>
        </FieldsContainer>
      </Form>
      <Spacer h={2} />
      <Toolbar>
        <Spacer right />
        <Button
          title={I18n.t("App.save")}
          disabled={!isDirty}
          onClick={() => save()}
          loading={saving}
        />
      </Toolbar>
    </FormBlock>
  );
}

