import { useAppSelector } from "app/hooks";
import {
  selectProfile,
  selectProfilePermissions,
  selectProfileRoles,
} from "./profileSlice";

export default function useAccess() {
  const profile = useAppSelector(selectProfile);
  const roles = useAppSelector(selectProfileRoles);
  const permissions = useAppSelector(selectProfilePermissions);

  function canEditItem(table: string, item: any) {
    const onlyOwn =
      hasPermissions(`${table}.editown`) && !hasPermissions(`${table}.editany`);
    if (onlyOwn) {
      return !(item.created_by === profile.id);
    }
    return true;
  }
  function hasRoles(role: string | string[], roleList?: string[]) {
    const _roles = roleList || roles;
    if (!_roles) {
      return false;
    }
    if (typeof role === "string") {
      return _roles.includes(role);
    } else {
      return !!role.find(function (value) {
        return _roles.includes(value);
      });
    }
  }
  function hasPermissions(permission: string | string[]) {
    if (!permissions) {
      return false;
    }
    if (typeof permission === "string") {
      return permissions.includes(permission);
    } else {
      return permission.some(function (value) {
        return permissions.indexOf(value) >= 0;
      });
    }
  }
  return {
    hasRoles,
    hasPermissions,
    canEditItem,
  };
}

