import { Box, BoxProps, styled } from "@mui/material";

const View = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface ToolbarProps extends BoxProps {}

export default function Toolbar({ children, ...props }: ToolbarProps) {
  return <View {...props}>{children}</View>;
}

