import { Box, BoxProps, styled } from "@mui/material";

import { BoatItem } from "features/boats/boatApi";
import { CalendarCellItem, CalendarEventItem, VIEWS } from "./calendarApi";

//export const CALENDAR_MONTH_CELL_WIDTH = "14.2857143%";
export const CALENDAR_MONTH_CELL_WIDTH = "14.25%";

interface ContainerProps extends BoxProps {
  view?: VIEWS;
}
const Container = styled(Box, {
  shouldForwardProp: prop => prop !== "view",
})<ContainerProps>`
  width: ${CALENDAR_MONTH_CELL_WIDTH};
  //max-width: ${props => (props.view === VIEWS.WEEK ? "160px" : "")};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.spacing(0.5)};
`;

export interface CalendarCellProps<
  EventType extends CalendarEventItem = CalendarEventItem
> extends BoxProps,
    CalendarCellItem<EventType> {
  view?: VIEWS;
  allBoats?: BoatItem[];
  onPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  onAddPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  isSelectedFunc?: (item: EventType) => boolean;
}
export default function CalendarCell<
  EventType extends CalendarEventItem = CalendarEventItem
>({}: CalendarCellProps<EventType>) {
  return null;
}

