import api from "app/api";
import { Options } from "common";
import { PartnerGroupItem } from "features/partner-groups/partnerGroupApi";
export interface PartnerItem {
  id: number;
  name: string;
  email: string;
  phone: string;
  partner_group_id: number;
  partner_group?: PartnerGroupItem;
  commission: number;
  online: boolean;
  active: boolean;
}

export type PartnerOptions = Options<"role" | "status">;

export function getPartnerService(scope?: any) {
  return api.getService<
    PartnerItem,
    PartnerItem,
    PartnerOptions
  >("partners", {
    scope,
    //params goes here
  });
}

