import { List as ListIcon } from "@mui/icons-material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import InterestsIcon from "@mui/icons-material/Interests";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import Save from "@mui/icons-material/Save";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { CircularProgress, InputBase, Typography } from "@mui/material";
import {
  Autocomplete,
  Button,
  CreatedUpdatedBy,
  DatePicker,
  Field,
  FieldsContainer,
  Form,
  FormBlock,
  PageHeaderBreadcrumbs,
  Spacer,
  TextField,
  Toolbar,
} from "common";
import List from "common/components/Inputs/List/List";
import FabSaveButton from "components/FabSaveButton/FabSaveButton";
import PriceInputBase from "components/PriceInputBase";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import GenerateButton from "features/schedules/actions/GenerateButton";
import {
  ScheduleEvent,
  ScheduleLimit,
  ScheduleOption,
  ScheduleVariant,
} from "features/schedules/scheduleApi";
import I18n from "i18n";
import { createContext } from "react";
import CloneButton from "./actions/CloneButton";
import { LIMIT_TYPES } from "./scheduleConstants";
import useScheduleForm, {
  UseScheduleFormProps,
  UseScheduleFormReturnType,
} from "./useScheduleForm";

export const ScheduleContext = createContext<
  UseScheduleFormReturnType | undefined
>(undefined);
export interface ScheduleFormProps extends UseScheduleFormProps {}

export default function ScheduleForm() {
  const { hasPermissions, canEditItem } = useAccess();

  const form = useScheduleForm({});
  const {
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
    params,
    times,
    hasOptions,
    clone,
    days,
    type,
    programs,
    disabledFields,
  } = form;
  const title = isCreate
    ? I18n.t("Schedule.addTitle")
    : `${item?.name} ${item?.date_from}-${item?.date_to}`;
  const subtitle = isCreate ? undefined : `${item?.boat?.name}`;

  return (
    <ScheduleContext.Provider value={form as any}>
      <PageHeaderBreadcrumbs
        avatar={<EditCalendarIcon />}
        loading={loading}
        title={title}
        subtitle={subtitle}
        backUrl=".."
      >
        <GenerateButton />
        <CloneButton />
      </PageHeaderBreadcrumbs>
      <FormBlock>
        <Toolbar>
          <Typography variant="button">
            {I18n.t("Schedule.titleSingular")} {I18n.t("App.mainData")}
          </Typography>
          <Spacer right />
          <FabSaveButton
            color="primary"
            sx={{ position: "fixed", right: 50, bottom: 50 }}
            title={I18n.t("App.save")}
            visible={isDirty}
            disabled={!isDirty}
            onClick={() => save()}
            //loading={saving}
          >
            {saving ? <CircularProgress size={24} /> : <Save />}
          </FabSaveButton>
        </Toolbar>
        <Spacer h={3} />
        <Form
          loading={loading}
          item={item}
          options={options}
          errors={errors}
          onChange={onChange}
          translationCategory="Schedule.attributes"
          translationPlaceholdersCategory="Schedule.placeholders"
          translationHelpersCategory="Schedule.helpers"
          isDirty={getIsDirty}
          requiredFields={getRequiredFields()}
          disabled={!canEditItem("schedule", item)}
          disabledFields={disabledFields}
        >
          <FieldsContainer>
            <Field>
              <TextField id="name" />
            </Field>
            {!params?.boat_id && (
              <Field>
                <Autocomplete id="boat_id" />
              </Field>
            )}
            <Field>
              <TextField id="duration" />
            </Field>
            <Field>
              <DatePicker id="date_from" />
            </Field>
            <Field>
              <DatePicker id="date_to" />
            </Field>
            <Field>
              <Autocomplete id="program_ids" multiple colorAttribute="color" />
            </Field>
            <Field fullWidth>
              <List<ScheduleEvent>
                id="events"
                icon={<ListIcon />}
                columns={[
                  {
                    id: "start_times",
                    title: "Időpontok",
                    inputComponent: Autocomplete,
                    headerProps: { sx: { width: "45%" } },
                    inputProps: {
                      multiple: true,
                      options: times,
                      freeSolo: true,
                    },
                  },
                  {
                    id: "days",
                    title: "Napok",
                    inputComponent: Autocomplete,
                    inputProps: {
                      multiple: true,
                      options: days,
                    },
                  },
                ]}
              />
            </Field>
            <Field fullWidth>
              <List<ScheduleVariant>
                id="variants"
                icon={<InterestsIcon />}
                columns={[
                  {
                    id: "variant_id",
                    title: I18n.t("Price.attributes.variant_id"),
                    inputComponent: Autocomplete,
                    inputProps: {
                      options: options.variants,
                      colorAttribute: "program.color",
                    },
                    headerProps: { width: "50%" },
                  },
                  {
                    id: "adult_price",
                    title: I18n.t("Price.attributes.adult_price"),
                    inputComponent: PriceInputBase,
                    inputProps: { type: "number", switchDisabled: true },
                  },
                  {
                    id: "kid_price",
                    title: I18n.t("Price.attributes.kid_price"),
                    inputComponent: PriceInputBase,
                    inputProps: { type: "number" },
                  },
                ]}
              />
            </Field>
            <Field fullWidth>
              <List<ScheduleLimit>
                id="limits"
                icon={<ProductionQuantityLimitsIcon />}
                onRowCahnge={(item, index, field, value) => {
                  if (field === "type") {
                    if (value === LIMIT_TYPES.PROGRAM) {
                      const variant_ids = options.variants
                        .filter(v => v.program_id === item.program_id)
                        .map(v => v.id);
                      return { ...item, variant_ids, name: undefined };
                    }
                    if (value === LIMIT_TYPES.BOAT) {
                      const variant_ids = options.variants.map(v => v.id);
                      return {
                        ...item,
                        variant_ids,
                        name: "Hajó",
                        program_id: undefined,
                      };
                    }
                    return { ...item, program_id: undefined };
                  }
                  if (field === "program_id") {
                    const variant_ids = options.variants
                      .filter(v => v.program_id === value)
                      .map(v => v.id);
                    return { ...item, variant_ids, name: undefined };
                  }
                  return item;
                }}
                columns={[
                  {
                    id: "type",
                    title: I18n.t("Limit.attributes.type"),
                    inputComponent: Autocomplete,
                    inputProps: {
                      options: type,
                    },
                    headerProps: { width: "140px" },
                  },
                  (item, index) => {
                    if (item?.type === LIMIT_TYPES.PROGRAM) {
                      return {
                        id: "program_id",
                        title: I18n.t("Limit.attributes.name"),
                        inputComponent: Autocomplete,
                        inputProps: {
                          options: programs,
                          colorAttribute: "color",
                        },
                        headerProps: { width: "50%" },
                      };
                    }
                    return {
                      id: "name",
                      title: I18n.t("Limit.attributes.name"),
                      inputComponent: InputBase,
                      inputProps: {
                        disabled: item?.type === LIMIT_TYPES.BOAT,
                      },
                      headerProps: { width: "140px" },
                    };
                  },
                  {
                    id: "variant_ids",
                    title: I18n.t("Limit.attributes.variant_ids"),
                    inputComponent: Autocomplete,
                    inputProps: (item?: ScheduleLimit) => ({
                      disabled:
                        item?.type === LIMIT_TYPES.BOAT ||
                        item?.type === LIMIT_TYPES.PROGRAM,
                      multiple: true,
                      options: options.variants,
                      colorAttribute: "program.color",
                    }),
                    headerProps: { width: "50%" },
                  },
                  {
                    id: "number_of_places",
                    title: I18n.t("Limit.attributes.number_of_places"),
                    inputComponent: InputBase,
                    inputProps: { type: "number" },
                  },
                  {
                    id: "number_of_online_places",
                    title: I18n.t("Limit.attributes.number_of_online_places"),
                    inputComponent: InputBase,
                    inputProps: { type: "number" },
                  },
                  {
                    id: "places_tolerance",
                    title: I18n.t("Limit.attributes.places_tolerance"),
                    inputComponent: InputBase,
                    inputProps: { type: "number" },
                  },
                ]}
              />
            </Field>
            {hasOptions && (
              <Field fullWidth>
                <List<ScheduleOption>
                  id="options"
                  icon={<WorkspacePremiumIcon />}
                  columns={[
                    {
                      id: "option_id",
                      title: "Option ID",
                      inputComponent: Autocomplete,
                      inputProps: {
                        options: options.options,
                        colorAttribute: "program.color",
                      },
                      headerProps: { width: "50%" },
                    },
                    {
                      id: "price",
                      title: I18n.t("Price.attributes.price"),
                      inputComponent: PriceInputBase,
                      inputProps: { type: "number", switchDisabled: true },
                    },
                    {
                      id: "limit",
                      title: I18n.t("Price.attributes.limit"),
                      inputComponent: InputBase,
                      inputProps: { type: "number" },
                    },
                  ]}
                />
              </Field>
            )}
          </FieldsContainer>
        </Form>
        <Spacer h={2} />
        <Toolbar>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Spacer right />
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </Toolbar>
      </FormBlock>
    </ScheduleContext.Provider>
  );
}

