import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  ExchangeRateItem,
  ExchangeRateOptions,
  getExchangeRateService,
} from "./exchangeRateApi";
import { EXCHANGERATE_TYPES } from "./exchangeRateConstants";

export interface UseExchangeRateFormProps extends GridModalFormProps {}

type RouteParams = {
  exchange_rate_id?: string;
};

export interface UseExchangeRateFormReturnType
  extends UseFormReturnType<ExchangeRateItem, ExchangeRateOptions> {
  params?: Readonly<Params<"exchange_rate_id">>;
}

export default function useExchangeRateForm({
  id,
  afterSave,
  defaultValues,
}: UseExchangeRateFormProps): UseExchangeRateFormReturnType {
  const params = useParams<"exchange_rate_id">();
  const scope = undefined;
  const service = getExchangeRateService(scope);

  const form = useForm<ExchangeRateItem, ExchangeRateOptions>({
    id: params.exchange_rate_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "ExchangeRate",
    rules: {
      type: Validator.rule.string().required(),
      rate: Validator.rule.any().required(),
      date_from: Validator.rule.any().required(),
      date_to: Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      type: () =>
        enumToOptions(EXCHANGERATE_TYPES, "ExchangeRate.options.type"),
    }),
  });

  return { ...form, params };
}

