import I18n from "i18n";
import Joi, { CustomHelpers, LanguageMessages } from "joi";
import { get } from "lodash";

interface ExtendedAnySchema extends Joi.AnySchema {
  optionalArray(): this;
}

interface ExtendedJoi extends Joi.Root {
  optionalArray(): ExtendedAnySchema;
}

// @ts-ignore
const customJoi: ExtendedJoi = Joi.extend((joi: any) => {
  return {
    type: "optionalArray",
    base: joi.any(),
    coerce(value: any, helpers: CustomHelpers) {
      return { value: value === undefined || value === null ? [] : value };
    },
  };
});

export interface ValidationErrors {
  [id: string]: string;
}

export interface Rules {
  [id: string]: Joi.AnySchema | [Rules];
}

export interface Values {
  [id: string]: any;
}
const flattenObj = (ob: any) => {
  // The object which contains the
  // final result
  let result: any = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + "." + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};
function validate(
  rules: Rules,
  values: Values | undefined,
  translationCategory?: string
) {
  const messages = flattenObj(I18n.get("validation")) as LanguageMessages;

  let errors: ValidationErrors = {};

  Object.keys(rules).forEach(key => {
    const validator = rules[key];
    if (Array.isArray(validator)) {
      const items: any[] = values && values[key] ? values[key] : [];
      let _errors: any[] = [];
      let errorCount = 0;

      items.forEach(item => {
        const _result = validate(validator[0], item, translationCategory);
        if (_result.success) {
          _errors.push(null);
        } else {
          errorCount++;
          _errors.push(_result.errors);
        }
      });
      if (errorCount > 0) {
        //@ts-ignore
        errors[key] = _errors;
      }
    } else {
      const result = validator
        .messages(messages)
        .empty(null)
        .validate(get(values, key, null) /*, { allowUnknown: true }*/);
      if (result.error) {
        if (translationCategory) {
          errors[key] = result.error.message.replace(
            '"value"',
            I18n.t(`${translationCategory}.attributes.${key}`)
          );
        } else {
          errors[key] = result.error.message;
        }
      }
    }
  });

  return {
    success: Object.keys(errors).length === 0,
    errors,
  };
}

const Validator = {
  rule: customJoi,
  validate,
};

export default Validator;

