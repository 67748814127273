import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Dictionary, loadDictionary } from "./dictionaryApi";

export interface DictionaryState {
  data: Dictionary;
  status: "idle" | "loading" | "failed";
}

const initialState: DictionaryState = {
  data: {} as Dictionary,
  status: "idle",
};

export const loadDictionaryAsync = createAsyncThunk(
  "dictionaries/load",
  async (_, { rejectWithValue }) => {
    const data = await loadDictionary();

    if (data) {
      return data;
    }
    return rejectWithValue({ message: "No dicionaries loaded" });
  }
);

export const dictionarySlice = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {
    clearDictionary: state => {
      state.data = {} as Dictionary;
    },
    resetStatus: state => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    //Load dictionary
    builder
      .addCase(loadDictionaryAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(loadDictionaryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          console.log("loadDictionaryAsync different");
          state.data = action.payload;
        }
      });
  },
});

export const { clearDictionary, resetStatus } = dictionarySlice.actions;

export const selectDictionary = (state: RootState) => state.dictionaries.data;
export const selectDictionaryStatus = (state: RootState) =>
  state.dictionaries.status;

export default dictionarySlice.reducer;

