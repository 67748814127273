import { Dialog, DialogProps } from "@mui/material";
import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import {
  GridModalFormProps as GridModalFormPropsBase,
  ModalApi,
  ModalState,
} from "./types";

export interface GridModalFormProps extends GridModalFormPropsBase {
  apiRef?: MutableRefObject<ModalApi | undefined>;
  renderModalForm?: (props: GridModalFormPropsBase) => ReactNode;
  modalProps?: Partial<DialogProps>;
}

export default function DataGridModalForm({
  apiRef,
  renderModalForm,
  afterSave,
  items,
  modalProps,
}: GridModalFormProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState<ModalState>({});

  function close(afterClose?: Function) {
    setOpen(false);
    setTimeout(() => {
      setState({});
      afterClose && afterClose();
    }, 500);
  }

  useEffect(() => {
    if (apiRef) {
      apiRef.current = {
        open: setState,
        close,
      };
    }
  }, []);

  useEffect(() => {
    if (state.item) {
      setOpen(true);
    }
  }, [state.item]);

  return (
    <Dialog open={open} onClose={() => close()} fullWidth {...modalProps}>
      {!!renderModalForm &&
        renderModalForm({
          onClose: close,
          listItem: state.item,
          id: state.id,
          afterSave,
          setItem: (item: any) => setState({ id: item.id || state.id, item }),
          items,
        })}
    </Dialog>
  );
}

