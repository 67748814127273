import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  IconButtonProps,
  InputAdornment,
  Link,
  Tooltip,
} from "@mui/material";
import I18n from "i18n";
import TextField, { TextFieldProps } from "../TextField";
import { FilePreviewType, previewFile } from "./../../FilePreview";
import Spacer from "./../../Spacer";
import { UploadFileTypes, UploadIconButton } from "./../../UploadButton";
import useFilePicker, { UseFilePickerProps } from "./useFilePicker";

export type FileProps = {
  buttonSize?: IconButtonProps["size"];
  previewType?: FilePreviewType;
  accept?: string;
  useObjectValue?: boolean;
} & TextFieldProps &
  Omit<UseFilePickerProps, "useObjectValue">;
export default function File({
  upload,
  uri,
  value,
  onChange,
  buttonSize = "small",
  onClear,
  alwaysShowClearButton,
  previewType: _previewType,
  file: _file,
  accept,
  validate,
  useObjectValue,
  ...textFieldProps
}: FileProps) {
  const { file, downloadUrl, clearFile, onUploadButtonChange } = useFilePicker({
    value,
    uri,
    upload,
    onChange,
    file: _file,
    validate,
    useObjectValue: (useObjectValue ? true : false) as any,
  });
  let previewType: FilePreviewType = "none";
  if (
    ["jpg", "jpeg", "png", "gif", "heic"].includes(
      _file?.extension.toLowerCase() || ""
    )
  ) {
    previewType = "img";
  }
  if (["pdf"].includes(_file?.extension.toLowerCase() || "")) {
    previewType = "pdf";
  }
  let _url = uri?.toLowerCase() || "";
  if (_url.includes(".heic")) {
    previewType = "img";
  }
  if (_url.includes(".jpeg")) {
    previewType = "img";
  }
  if (_url.includes(".jpg")) {
    previewType = "img";
  }
  if (_url.includes(".png")) {
    previewType = "img";
  }
  if (_url.includes(".pdf")) {
    previewType = "pdf";
  }

  if (_previewType) {
    previewType = _previewType;
  }
  return (
    <TextField
      value={file?.name}
      InputProps={{
        readOnly: true,
        placeholder: I18n.t("App.uploadPlaceholder"),
        endAdornment: (
          <InputAdornment position="end">
            <UploadIconButton
              disabled={textFieldProps.disabled}
              size={buttonSize}
              title={I18n.t("App.upload")}
              accept={accept || UploadFileTypes.DocumentsAndImages}
              onChange={onUploadButtonChange}
              //onValidate={validateType}
            >
              <CloudUploadIcon fontSize={buttonSize} />
            </UploadIconButton>
            <Spacer w={1} />
            {!!file?.name && (
              <>
                {!!file?.uri && (
                  <>
                    <Tooltip title={I18n.t("App.preview")}>
                      <IconButton
                        size={buttonSize}
                        onClick={() =>
                          !!file?.uri &&
                          previewFile({
                            url: file?.uri,
                            title: file?.name,
                            fileName: file?.name,
                            type: previewType,
                          })
                        }
                      >
                        <VisibilityIcon fontSize={buttonSize} />
                      </IconButton>
                    </Tooltip>
                    <Spacer w={0.5} />
                    <Tooltip title={I18n.t("App.download")}>
                      <IconButton
                        size={buttonSize}
                        href={downloadUrl()}
                        component={Link}
                        download
                      >
                        <CloudDownloadIcon fontSize={buttonSize} />
                      </IconButton>
                    </Tooltip>
                    <Spacer w={0.5} />
                  </>
                )}
              </>
            )}
            {(!!file?.name || alwaysShowClearButton) && (
              <Tooltip title={I18n.t("App.clear")}>
                <IconButton
                  disabled={textFieldProps.disabled}
                  size={buttonSize}
                  onClick={() => {
                    clearFile();
                    onClear && onClear();
                  }}
                >
                  <CloseIcon fontSize={buttonSize} />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
}

