import api from "app/api";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export type PermissionAction = {
  id: number;
  name: string;
  permission: string;
  value: boolean;
  disabled: boolean;
};
export interface PermissionFormItem {
  model: string;
  actions: PermissionAction[];
}

export default function usePermissionForm() {
  const params = useParams<"user_id" | "role_id">();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const originalPermission = useRef<PermissionFormItem[]>();
  const [permissions, setPermissions] = useState<PermissionFormItem[]>([]);

  const url = params.user_id
    ? `/users/${params.user_id}/permissions`
    : `/roles/${params.role_id}/permissions`;

  async function loadPermissions() {
    setLoading(true);
    const response = await api.get(url);
    setLoading(false);
    if (response.success) {
      originalPermission.current = response.data;
      setPermissions(response.data);
    }
  }

  function changePermission(
    index: number,
    actionIndex: number,
    value: boolean
  ) {
    let newPerms = cloneDeep(permissions);
    newPerms[index].actions[actionIndex].value = value;
    setPermissions(newPerms);
  }

  function isDirty() {
    return (
      JSON.stringify(permissions) !== JSON.stringify(originalPermission.current)
    );
  }

  function getPermissions(): string[] {
    let result: string[] = [];
    permissions.forEach(permission => {
      permission.actions.forEach(action => {
        if (!action.disabled && action.value) {
          result.push(action.permission);
        }
      });
    });
    return result;
  }

  async function save() {
    setSaving(true);
    const response = await api.post(url, { permissions: getPermissions() });
    setSaving(false);
    if (response.success) {
      originalPermission.current = response.data;
      setPermissions(response.data);
    }
  }

  useEffect(() => {
    loadPermissions();
  }, []);

  return {
    permissions,
    loading,
    saving,
    changePermission,
    isDirty: isDirty(),
    save,
  };
}

