import { useTheme } from "@mui/material";

export interface SpacerProps {
  w?: number;
  h?: number;
  right?: boolean;
  left?: boolean;
  minWidth?: number;
  maxWidth?: number;
}

export default function Spacer({
  w,
  h,
  right,
  left,
  minWidth,
  maxWidth,
}: SpacerProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        width: w ? theme.spacing(w) : undefined,
        height: h ? theme.spacing(h) : undefined,
        marginLeft: right ? "auto" : undefined,
        marginRight: left ? "auto" : undefined,
        minWidth: minWidth ? theme.spacing(minWidth) : undefined,
        maxWidth: maxWidth ? theme.spacing(maxWidth) : undefined,
      }}
    />
  );
}

