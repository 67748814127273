import { SxProps, Theme, alpha, lighten } from "@mui/material";

function getBadge(sx: SxProps<Theme>): any {
  return {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
      top: "-4px",
      right: "-4px",
      width: "10px",
      height: "10px",
      backgroundColor: theme => theme.palette.info.main,
      borderRadius: "50%",
      animation: "pulse 1.2s infinite ease-in-out",
      zIndex: 10,
      ...sx,
    },
  } as SxProps<Theme>;
}

const yellowBadge = getBadge({
  backgroundColor: theme => theme.palette.warning.main,
});
const redBadge = getBadge({
  backgroundColor: theme => theme.palette.error.main,
});
const yellowBadgeLeft = getBadge({
  backgroundColor: theme => theme.palette.warning.main,
  right: 0,
  left: "-4px",
});
const redBadgeLeft = getBadge({
  backgroundColor: theme => theme.palette.error.main,
  right: 0,
  left: "-4px",
});
const blueBadge = getBadge({
  backgroundColor: theme => theme.palette.info.main,
});

const yellowDot = getBadge({
  backgroundColor: theme => theme.palette.warning.main,
  width: "6px",
  height: "6px",
  top: "-4px",
  right: "calc(50% - 3px)",
});
const redDot = getBadge({
  backgroundColor: theme => theme.palette.error.main,
  width: "6px",
  height: "6px",
  top: "-4px",
  right: "calc(50% - 3px)",
});

export const calendarStyle: SxProps<Theme> = {
  backgroundColor: theme => theme.palette.background.paper,
  maxHeight: {
    xs: "calc(100vh - 128px - 56px - 56px)",
    sm: "calc(100vh - 128px - 56px - 64px)",
    md: "calc(100vh - 128px - 56px)",
  },
  "& table": {},
  "& th, td": {
    padding: "3px",
  },
  "& th": {
    fontSize: {
      xs: "0.7rem",
      sm: "0.8rem",
      md: "0.9rem",
      lg: "0.9rem",
    },
    backgroundColor: theme => theme.palette.background.paper,
    color: theme =>
      theme.palette.getContrastText(lighten(theme.palette.info.main, 0.8)),
    textAlign: "center",
    verticalAlign: "middle",
    "& span": {
      display: "inline-block",
      textAlign: "center",
    },
  },
  "& td, th": {
    "&.cal-TimeCell": {
      fontWeight: 500,
      width: 50,
      fontSize: "0.9rem",
      pl: 0.5,
      pr: 0.5,
    },
    "&.isHoliday": {
      backgroundColor: theme => alpha(theme.palette.warning.main, 0.15),
    },
    "&.isCurrentDay": {
      backgroundColor: theme => alpha(theme.palette.info.main, 0.15),
    },
  },
  "& tr:hover td": {
    "&.cal-TimeCell": {
      backgroundColor: theme => theme.palette.info.main,
      color: theme => theme.palette.getContrastText(theme.palette.info.main),
    },
  },
  //Event font sizes
  "& td": {
    fontSize: "0.7rem",
    lineHeight: "0.7rem",
    "& .calFontSizeSmall": {
      fontSize: "0.7rem",
      lineHeight: "0.7rem",
    },
    "& .calFontSizeNormal": {
      fontSize: "0.85rem",
      lineHeight: "0.85rem",
    },
    "& .calFontSizeLarge": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  },
  // Boat
  "& td .calBoat": {
    border: theme => `solid 1px ${theme.palette.divider}`,
    backgroundColor: "background.paper",
    "&.boatClosed": {
      backgroundColor: theme => theme.palette.action.disabledBackground,
    },
    "&:not(.boatClosed)": {
      "&.boatOverLimit": redBadgeLeft,
      "&.boatOverOnlineLimit": yellowBadgeLeft,
    },
  },

  "& td .calContent:not(.showClosedEvents) .boatClosed": {
    display: "none",
  },
  "& td .calContent:not(.showClosedEvents) .eventClosed": {
    display: "none",
  },
  "& td .calContent:not(.showFullEvents) .boatOverLimit": {
    display: "none",
  },
  "& td .calContent:not(.showFullEvents) .eventOverLimit": {
    display: "none",
  },
  // Event when boat is closed or event is closed
  "& td .boatClosed .calEvent, td .calEvent.eventClosed": {
    backgroundColor: theme => theme.palette.action.disabledBackground,
  },
  "& td div.calEvent.selected": {
    backgroundColor: theme => theme.palette.primary.main,
  },
  // Event when boat is not closed or not over limit
  "& td .showIndicatiors div.calBoat:not(.boatClosed):not(.boatOverLimit) .calEvent:not(.eventClosed)":
    {
      // Yellow badge indicates online limit over
      "&.eventOverOnlineLimit:not(.eventOverLimit)": yellowBadge,
      // Red badge indicates event limit over
      "&.eventOverLimit": redBadge,
      // Limits if event is not closed
      "& .calLimit": {
        // Closed limits have disabled background and strikethrough text
        "&.limitClosed": {
          backgroundColor: theme => theme.palette.action.disabled,
          textDecoration: "line-through",
          opacity: 0.7,
          color: "inherit",
        },
        // Red dot for limits over limit
        "&:not(.limitClosed)": {
          "&.limitOverLimit": redDot,
          // Yellow dot for online limits over limit
          "&.limitOverOnlineLimit:not(.limitOverLimit)": yellowDot,
        },
      },
    },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.2)",
      opacity: 0.4,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
};
