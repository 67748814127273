import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import OptionForm from "./OptionForm";
import { OptionItem } from "./optionApi";
import useOptionList from "./useOptionList";
export interface OptionListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function OptionList({
  showHeader = true,
  ...props
}: OptionListProps) {
  const { params, scope, editUrl, ...service } = useOptionList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<OptionItem> = [
    {
      field: "name",
      headerName: I18n.t("Option.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      hide: !!params.program_id,
      hideable: !params.program_id,
      field: "program.name",
      headerName: I18n.t("Option.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_id}`}
          params={params}
        />
      ),
    },
    { field: "code", headerName: I18n.t("Option.attributes.code"), flex: 1 },
    {
      hide: !!params.option_group_id,
      hideable: !params.option_group_id,
      field: "option_group.name",
      headerName: I18n.t("Option.attributes.option_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/option-groups/${params.row?.option_group_id}`}
          params={params}
        />
      ),
    },
    {
      field: "limit",
      headerName: I18n.t("Option.attributes.limit"),
      type: "number",
    },
    {
      field: "active",
      headerName: I18n.t("Option.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<WorkspacePremiumIcon />}
          title={I18n.t("Option.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="options"
        renderModalForm={props => <OptionForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`Option.attributes.${field.replaceAll(".", "_")}`)}
        canCreate={hasPermissions("program_option.create")}
        canDelete={hasPermissions([
          "program_option.editany",
          "program_option.editown",
        ])}
        canEdit={hasPermissions([
          "program_option.editany",
          "program_option.editown",
        ])}
        canDeleteRow={params => canEditItem("option", params.row)}
        canEditRow={params => canEditItem("option", params.row)}
        canBatchActions={hasPermissions([
          "program_option.editany",
          "program_option.editown",
        ])}
        canHandleTrash={hasPermissions([
          "program_option.editany",
          "program_option.editown",
        ])}
      />
    </>
  );
}

