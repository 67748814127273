import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode } from "react";
import Spacer from "../Spacer";
import Toolbar from "../Toolbar";
import { getOptionLabel } from "./../../modules/helpers";
import Field, { FieldProps } from "./Field";

interface ContainerProps extends Omit<FieldProps, "children"> {
  multiline?: boolean;
}

const Container = styled(Field, {
  shouldForwardProp: prop => prop !== "multiline",
})<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.multiline ? "column" : "row")};
  align-items: ${props => (props.multiline ? "flex-start" : "center")};
  justify-content: ${props => (props.multiline ? "" : "space-between")};
`;

export interface LabelValueProps extends Omit<FieldProps, "children"> {
  id?: string;
  multiline?: boolean;
  inline?: boolean;
  label?: string;
  labelButtons?: any;
  valueSeparator?: string;
  value?: any;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  pressableProps?: BoxProps;
  onValuePress?: Function;
  subValue?: any;
  subValueProps?: TypographyProps;
  valueSuffix?: any;
  valueSuffixProps?: TypographyProps;
  formatValue?: (value: any) => any;
  formatSubValue?: (value: any) => any;
  options?: any;
  valueComponent?: ReactNode;
  helperText?: string;
  required?: boolean;
  isDirty?: boolean;
  placeholder?: string;
}

export default function LabelValue({
  label,
  value,
  labelProps,
  valueProps,
  multiline,
  onValuePress,
  subValue,
  subValueProps,
  formatValue,
  formatSubValue,
  valueComponent,
  pressableProps,
  valueSuffix,
  valueSuffixProps,
  options,
  inline,
  valueSeparator,
  labelButtons,
  required,
  helperText,
  placeholder,
  isDirty,
  ...props
}: LabelValueProps) {
  return (
    <Container multiline={multiline} {...props}>
      <Toolbar>
        <Typography
          variant="subtitle2"
          //color="ButtonText"
          {...labelProps}
          //style={{ flex: inline || multiline ? 0 : 1 }}
        >
          {label}
          {valueSeparator}
        </Typography>
        {!!labelButtons && <Spacer w={1} />}
        {labelButtons}
      </Toolbar>
      {!multiline && <Spacer w={inline ? 0.5 : 2} />}
      {valueComponent}
      {!valueComponent &&
        (!!value || value === false || value === 0) &&
        !onValuePress && (
          <Typography
            variant="subtitle1"
            //color="GrayText"
            {...valueProps}
            //style={{ flex: inline ? 0 : 1 }}
          >
            {!!options
              ? getOptionLabel(options, value)
              : !!formatValue
              ? formatValue(value)
              : value}
            {!!valueSuffix && (
              <Typography variant="subtitle1" {...valueSuffixProps}>
                {valueSuffix}
              </Typography>
            )}
          </Typography>
        )}
      {(!!subValue || subValue === false || subValue === 0) &&
        !onValuePress && (
          <Typography
            variant="body2"
            //color="GrayText"
            style={{ flex: inline ? 0 : 1 }}
            {...subValueProps}
          >
            {!!options
              ? getOptionLabel(options, subValue)
              : !!formatSubValue
              ? formatSubValue(subValue)
              : subValue}
          </Typography>
        )}
      {!valueComponent &&
        (!!value || value === false || value === 0) &&
        onValuePress && (
          <Box
            onClick={() => onValuePress(value)}
            style={{ flex: inline ? 0 : 1, display: "flex" }}
            {...pressableProps}
          >
            <Typography
              variant="subtitle1"
              color="info"
              style={{
                flex: inline ? 0 : 1 /*textDecorationLine: "underline"*/,
              }}
              {...valueProps}
            >
              {!!options
                ? getOptionLabel(options, value)
                : !!formatValue
                ? formatValue(value)
                : value}
              {!!valueSuffix && (
                <Typography
                  variant="subtitle1"
                  color="inputText"
                  {...valueSuffixProps}
                >
                  {valueSuffix}
                </Typography>
              )}
            </Typography>
            {(!!subValue || subValue === false || subValue === 0) && (
              <Typography
                variant="body2"
                color="inputText"
                style={{ flex: inline ? 0 : 1 }}
                {...subValueProps}
              >
                {!!options
                  ? getOptionLabel(options, subValue)
                  : !!formatValue
                  ? formatValue(subValue)
                  : subValue}
              </Typography>
            )}
          </Box>
        )}
    </Container>
  );
}

