import { Box, Chip } from "@mui/material";
import { Option } from "common";
import React from "react";

interface OptionPrice {
  variant_id: number;
  option_name: string;
  option_price: number;
}

interface OptionPricesCellProps {
  params?: any;
  variants?: Option[];
  options?: Option[];
}

const OptionPricesCell: React.FC<OptionPricesCellProps> = ({
  params,
  variants = [],
  options = [],
}) => {
  const optionPrices: OptionPrice[] = params?.row?.options || [];

  return (
    <Box display="flex" flexDirection="column" padding="2px" fontSize="0.75rem">
      {optionPrices
        ?.filter(o => !!o.option_price)
        ?.map((optionPrice, index) => {
          const variant = variants.find(
            v => v.value === optionPrice.variant_id
          );
          const option = options.find(o => o.value === optionPrice.option_name);
          return (
            <Box
              key={index}
              marginBottom="4px"
              display="flex"
              flexDirection="column"
              padding="4px"
              sx={{
                border: "1px solid transparent",
                borderRadius: "4px",
                transition: "border-color 0.3s, background-color 0.3s",
                "&:hover": {
                  borderColor: "primary.light",
                  backgroundColor: "rgba(144, 202, 249, 0.1)",
                },
              }}
            >
              <Box display="flex" flexWrap="wrap" marginBottom="2px">
                <Chip
                  label={variant ? variant.label : optionPrice.variant_id}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: "secondary.light",
                    color: "secondary.contrastText",
                  }}
                />
                <Chip
                  label={` ${option?.label}`}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: "secondary.light",
                    color: "secondary.contrastText",
                  }}
                />
                <Chip
                  label={optionPrice.option_price}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: "secondary.light",
                    color: "secondary.contrastText",
                  }}
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default OptionPricesCell;

