import { Box, BoxProps, Divider, Typography, styled } from "@mui/material";

import { Toolbar, VIEWS } from "common";
import { getSettingsClassName } from "features/events/useEventCalculator";
import useSettings from "features/settings/useSettings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import StartBoatGroupWeek from "../views/StartBoatGroupWeek";
import { StartBoatCellProps } from "./StartBoatCell";
import { BoatGroup } from "./useStartBoatCell";

interface ContainerProps extends BoxProps {
  view?: VIEWS;
}
const Container = styled(Box, {
  shouldForwardProp: prop => prop !== "view",
})<ContainerProps>`
  //flex: 1;
  display: flex;
  //justify-content: center;
  //align-items: flex-start;
  flex-direction: column;
  //background-color: ${props => props.theme.palette.background.sheet};
  gap: 8px;
  //padding: 8px;
  //min-width: 300px;
`;
const ITEM_HEADER_HEIGHT = 28;
const ITEM_PADDING = 4;
/** Min height of the program boxes */
const ITEM_MIN_HEIGHT = 111;

const PIXEL_PER_MINUTES =
  (ITEM_MIN_HEIGHT + ITEM_HEADER_HEIGHT + 2 * ITEM_PADDING) / 60;

function getHeight(duration: number) {
  return duration * PIXEL_PER_MINUTES;
}

function getCurrentMinutes(start_time: string) {
  return moment().diff(moment(start_time, "HH:mm"), "minutes");
}
function getCurrentTop(start_time: string) {
  return getCurrentMinutes(start_time) * PIXEL_PER_MINUTES;
}
function CurrentTimeIndicator({
  start_time,
  end_time,
}: {
  start_time: string;
  end_time: string;
}) {
  const [top, setTop] = useState(getCurrentTop(start_time));

  const timer = useRef<any>();
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setTop(getCurrentTop(start_time));
    }, 1000);
  }, [start_time]);

  if (top < 0) {
    return null;
  }
  return (
    <Toolbar
      sx={{
        left: 0,
        right: 0,
        position: "absolute",
        top: `${top}px`,
        //zIndex: 1000,
        height: 2,
        gap: 1,
        pl: 1,
        pr: 1,
      }}
    >
      <Typography
        variant="caption"
        color="info.main"
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "2px",
          padding: "1px",
        }}
      >
        {moment().format("LT")}
      </Typography>
      <Divider
        className="CURRENT_COUR"
        sx={{
          borderColor: "info.main",
          borderTopWidth: 1,
          borderBottomWidth: 1,
          flex: 1,
        }}
      />
    </Toolbar>
  );
}

export interface StartBoatCellViewWeekProps extends StartBoatCellProps {
  startTimes: BoatGroup[];
}
export default function StartBoatCellViewWeek({
  startTimes,
  ...props
}: StartBoatCellViewWeekProps) {
  const [settings] = useSettings({ key: "calendar" });
  let className = getSettingsClassName(settings);
  return (
    <Container className="DAY">
      <Box
        className={className}
        sx={{
          gap: 0.5,
          display: "flex",
          flexDirection: "column",
          //alignItems: "center",
          //justifyContent: "space-between",
        }}
      >
        {startTimes.map((boat, boatIndex) => {
          return (
            <StartBoatGroupWeek
              key={boat.boat?.id || boatIndex}
              item={boat}
              isSelectedFunc={props.isSelectedFunc}
              onClick={
                props.onPress
                  ? event =>
                      props.view &&
                      props.onPress &&
                      props.onPress(event as any, props, props.view)
                  : undefined
              }
              onAddClick={
                props.onAddPress
                  ? event =>
                      props.view &&
                      props.onAddPress &&
                      props.onAddPress(event as any, props, props.view)
                  : undefined
              }
            />
          );
        })}
      </Box>
    </Container>
  );
}

