import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Option } from "./../../../modules/helpers";

function isNumeric(str: string | unknown): boolean {
  if (typeof str === "number") return true;
  if (typeof str !== "string") return false;
  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export type ScrollPickerProps = {
  style?: any;
  options?: Option[];
  value?: number | string | null;
  onValueChange?: (value: number | string | null) => void;
  renderItem?: (
    item: Option,
    index: number,
    isSelected: boolean
  ) => JSX.Element;
  highlightColor?: string;
  useAnimation?: boolean;
  itemHeight?: number;
  itemWidth?: number;
  wrapperHeight?: number;
  wrapperColor?: string;
};

export default function ScrollPicker({
  itemHeight = 30,
  itemWidth, // = 36,
  style,
  value,
  options = [],
  useAnimation,
  ...props
}: ScrollPickerProps): JSX.Element {
  const theme = useTheme();
  const [innerValue, setInnerValue] = useState(value);
  const innerValueRef = useRef(value);
  const sView = useRef<null | HTMLDivElement>(null);
  const isScrolling = useRef<any>();
  const isInternalChange = useRef<boolean>(false);

  //const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const wrapperHeight = props.wrapperHeight || itemHeight * 5;

  function getSelectedIndex() {
    if (options) {
      return options.findIndex(o => o.value === innerValue);
    }
    return 0;
  }

  const selectedIndex = getSelectedIndex();

  useEffect(() => {
    isInternalChange.current = false;
    innerValueRef.current = value;
    setInnerValue(value);
    //const y = itemHeight * selectedIndex;
    //sView?.current?.scrollTo({ top: y });
  }, [value]);

  useEffect(() => {
    if (isInternalChange.current) {
      return;
    }
    timer.current = setTimeout(() => {
      const y = itemHeight * selectedIndex;
      sView?.current?.scrollTo({
        top: y,
        behavior: useAnimation ? "smooth" : "auto",
      });
    }, 0);

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [itemHeight, innerValue, sView]);

  const renderPlaceHolder = () => {
    const h = (wrapperHeight - itemHeight) / 2;
    const header = <Box style={{ height: h, flex: 1 }} />;
    const footer = <Box style={{ height: h, flex: 1 }} />;
    return { header, footer };
  };

  const renderItem = (data: Option, index: number) => {
    const isSelected = index === selectedIndex;
    const item = props.renderItem ? (
      props.renderItem(data, index, isSelected)
    ) : (
      <Typography
        sx={{
          fontWeight: isSelected ? "bold" : "normal",
          //width: `${itemWidth}px`,
          textAlign: "center",
          flex: 1,
          color: theme =>
            isSelected
              ? theme.palette.text.primary
              : theme.palette.text.disabled,
        }}
        //color={isSelected ? "text" : "disabled"}
      >
        {data.label}
      </Typography>
    );

    return (
      <Box
        key={index}
        onClick={() => {
          if (!isSelected) {
            innerValueRef.current = data.value;
            setInnerValue(data.value);
            scrollToIndex(index);
          }
        }}
        sx={{
          cursor: "pointer",
          "&:hover p": {
            color: theme => theme.palette.text.primary,
          },
        }}
      >
        <Box
          style={{
            height: itemHeight,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {item}
        </Box>
      </Box>
    );
  };

  const { header, footer } = renderPlaceHolder();
  //const highlightWidth = (isViewStyle(style) ? style.width : 0) || deviceWidth;
  const highlightColor = props.highlightColor || theme.palette.secondary.main;

  const wrapperStyle: SxProps<Theme> = {
    height: wrapperHeight,
    msOverflowStyle: "none" /* for Internet Explorer, Edge */,
    scrollbarWidth: "none" /* for Firefox */,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none" /* for Chrome, Safari, and Opera */,
    },
    //position: "relative",
    webkitUserSelect: "none" /* Safari */,
    msUserSelect: "none" /* IE 10 and IE 11 */,
    userSelect: "none" /* Standard syntax */,
    flex: 1,
    //minWidth: itemWidth,
    //overflow: "auto",
  };
  if (props.wrapperColor) {
    wrapperStyle.backgroundColor = props.wrapperColor;
  }
  if (itemWidth) {
    wrapperStyle.minWidth = itemWidth;
  }

  const highlightStyle: SxProps<Theme> = {
    position: "absolute",
    top: (wrapperHeight - itemHeight) / 2,
    height: itemHeight,
    //width: highlightWidth,
    borderTopColor: highlightColor,
    borderBottomColor: highlightColor,
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
  };

  const scrollToIndex = useCallback(
    (_selectedIndex: number) => {
      let y = 0;
      const h = itemHeight;

      const _y = _selectedIndex * h;
      if (_y !== y || _y === 0) {
        // using scrollTo in ios, onMomentumScrollEnd will be invoked
        /*if (Platform.OS === "ios") {
          setIsScrollTo(true);
        }*/

        sView?.current?.scrollTo({ top: _y, behavior: "smooth" });
      }
      if (selectedIndex === _selectedIndex) {
        return;
      }
      // onValueChange
      if (props.onValueChange) {
        const selectedValue = options ? options[_selectedIndex].value : null;
        if (innerValue !== selectedValue) {
          //setInnerValue(selectedValue);
          if (value !== selectedValue) {
            props.onValueChange(selectedValue);
          }
        }
      }
    },
    [itemHeight, props, innerValue]
  );

  useEffect(() => {
    if (sView.current) {
      sView.current.addEventListener(
        "scroll",
        function (e) {
          // Clear our timeout throughout the scroll
          if (isScrolling.current) {
            clearTimeout(isScrolling.current);
          }

          // Set a timeout to run after scrolling ends
          isScrolling.current = setTimeout(function () {
            // Run the callback

            let y = 0;
            const h = itemHeight;
            if (sView.current) {
              y = sView.current.scrollTop;
            }
            const _selectedIndex = Math.round(y / h);

            const _y = _selectedIndex * h;
            const selectedValue = options
              ? options[_selectedIndex].value
              : null;
            if (selectedValue !== innerValueRef.current) {
              isInternalChange.current = true;
              innerValueRef.current = selectedValue;
              setInnerValue(selectedValue);

              if (value !== selectedValue) {
                props.onValueChange && props.onValueChange(selectedValue);
              }
            }

            sView?.current?.scrollTo({ top: _y, behavior: "smooth" });
          }, 66);
        },
        false
      );
    }
  }, []);

  return (
    <Box sx={wrapperStyle} ref={sView}>
      <Box sx={highlightStyle} />
      <Box>
        {header}
        {options.map(renderItem)}
        {footer}
      </Box>
      {/*<Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "50%",
          borderRadius: "40px",
          marginTop: `${-4 - itemHeight / 2}px`,
          height: `${itemHeight}px`,
          borderBottom: theme =>
            `dashed 1px ${alpha(theme.palette.primary.main, 0.2)}`,
          borderTop: theme =>
            `dashed 1px ${alpha(theme.palette.primary.main, 0.2)}`,
          backgroundColor: theme => alpha(theme.palette.text.primary, 0.1),
          pointerEvents: "none",
        }}
      />*/}
    </Box>
  );
}

