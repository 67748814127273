import WorkspacesIcon from "@mui/icons-material/Workspaces";
import I18n from "i18n";
import { PageHeader, Spacer, DataGridBaseBaseProps, DataGridV2 as DataGrid, GridColumns } from "common";
import useAccess from "features/profile/useAccess";
import PartnerGroupForm from "./PartnerGroupForm";
import usePartnerGroupList from "./usePartnerGroupList";
import { PartnerGroupItem } from "./partnerGroupApi";
import { DataGridModalEditColumn } from "common";
export interface PartnerGroupListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function PartnerGroupList({ showHeader = true, ...props }: PartnerGroupListProps) {
  const { params, scope, editUrl, ...service } = usePartnerGroupList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<PartnerGroupItem> = [
    { field: "name", headerName: I18n.t("PartnerGroup.attributes.name"), renderCell: params => (
          <DataGridModalEditColumn href={`${editUrl}${params.row?.id}`} params={params} />
        ), flex: 1 },
      ];

  return (
    <>
      {showHeader && (
          <PageHeader
            avatar={<WorkspacesIcon />}
            title={I18n.t("PartnerGroup.title")}
          />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="partner-groups"
        renderModalForm={props => <PartnerGroupForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`PartnerGroup.attributes.${field.replaceAll(".", "_")}`)}
                canCreate={hasPermissions("partner_group.create")}
        canDelete={hasPermissions(["partner_group.editany", "partner_group.editown"])}
        canEdit={hasPermissions(["partner_group.editany", "partner_group.editown"])}
        canDeleteRow={params => canEditItem("partner_group", params.row)}
        canEditRow={params => canEditItem("partner_group", params.row)}
        canBatchActions={hasPermissions(["partner_group.editany", "partner_group.editown"])}
        canHandleTrash={hasPermissions(["partner_group.editany", "partner_group.editown"])}      />
    </>
  );
}

