import { Grid, GridProps } from "@mui/material";
import { ReactNode } from "react";

export interface FieldProps extends GridProps {
  children: ReactNode;
  fullWidth?: boolean;
  half?: boolean;
  third?: boolean;
  fourth?: boolean;
}

export default function Field({
  children,
  fullWidth,
  half,
  third,
  fourth,
  ...props
}: FieldProps) {
  if (fullWidth) {
    return (
      <Grid className="field" item xs={12} {...props}>
        {children}
      </Grid>
    );
  }
  if (half) {
    return (
      <Grid className="field" item xs={6} {...props}>
        {children}
      </Grid>
    );
  }
  if (third) {
    return (
      <Grid className="field" item xs={4} {...props}>
        {children}
      </Grid>
    );
  }
  if (fourth) {
    return (
      <Grid className="field" item xs={3} {...props}>
        {children}
      </Grid>
    );
  }
  if (fullWidth) {
    return (
      <Grid className="field" item xs={12} {...props}>
        {children}
      </Grid>
    );
  }
  if (props.xs || props.sm || props.md || props.lg || props.xl) {
    return (
      <Grid className="field" item {...props}>
        {children}
      </Grid>
    );
  }
  return (
    <Grid className="field" item xs={12} md={6} lg={4} xl={3} {...props}>
      {children}
    </Grid>
  );
}

