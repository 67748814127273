import moment from "moment-timezone";

export interface ApiResponse<D = any, E = any> {
  data?: D;
  error?: E;
}

/**
 * Determine if the token nedds to refresh
 *
 * @param expires_at token expiration time unix
 * @param delta_time seconds before should refresh token
 * @returns true if token needs refres
 */
export function tokenWillExpire(
  expires_at: number,
  delta_time: number
): boolean {
  return expires_at - delta_time < moment().unix();
}

/**
 * Check if token is expired or not
 * @param expires_at
 * @returns true if expired
 */
export function tokenIsExpired(expires_at: number): boolean {
  return expires_at < moment().unix();
}
/**
 * Check if token is expired or not
 * @param expires_at
 * @returns true if expired
 */

export const loadMultipleOptions =
  (optionGetters: any) => async (): Promise<any> => {
    //let promises: Promise<any>[] = Object.values(optionGetters());
    let keys = Object.keys(optionGetters);

    let keysFiltered: string[] = [];

    let promises: Promise<any>[] = keys
      .filter(k => typeof optionGetters[k] === "function")
      .map(key => {
        keysFiltered.push(key);
        return optionGetters[key]();
      });

    const responses = await Promise.all(promises);
    const options: any = {};
    responses.map((data, index) => {
      if (data) {
        options[keysFiltered[index]] = data;
      }
    });

    return options;
  };

