import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import dictionaryReducer from "features/dictionaries/dictionarySlice";
import identityMiddleware from "features/identity/identityMiddleware";
import identityReducer from "features/identity/identitySlice";
import languageReducer from "features/language/languageSlice";
import notificationMiddleware from "features/notifications/notificationMiddleware";
import notificationReducer from "features/notifications/notificationsSlice";
import profileMiddleware from "features/profile/profileMiddleware";
import profileReducer from "features/profile/profileSlice";
import settingsReducer from "features/settings/settingsSlice";
import themeReducer from "features/theme/themeSlice";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

/*
import { EncryptStorage } from "encrypt-storage";
const encryptStorage = new EncryptStorage("lkasjhlkjf74lDFGDFl48dfD4");
const storage: any = {
  ...encryptStorage,
  getItem: (key: string, doNotDecrypt?: boolean | undefined) =>
    new Promise(resolve => resolve(encryptStorage.getItem(key, doNotDecrypt))),
  setItem: (key: string, value: any, doNotDecrypt?: boolean | undefined) =>
    new Promise(resolve =>
      resolve(encryptStorage.setItem(key, value, doNotDecrypt))
    ),
};
*/

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  //blacklist: ["navigation"], // navigation will not be persisted
  whitelist: [
    "theme",
    "identity",
    "profile",
    "notifications",
    "language",
    "dictionaries",
    "settings",
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    identity: identityReducer,
    profile: profileReducer,
    theme: themeReducer,
    notifications: notificationReducer,
    language: languageReducer,
    dictionaries: dictionaryReducer,
    settings: settingsReducer,
  })
);

let middlewares = [
  identityMiddleware,
  profileMiddleware,
  notificationMiddleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

