import {
  alpha,
  Box,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import { get } from "lodash";
import { GuestSummary } from "./cateringApi";

export interface CateringBookingListSummaryProps {
  program_ids?: number[];
  summary?: { [key: string | number]: GuestSummary };
}
function CateringBookingListSummary({
  program_ids,
  summary,
}: CateringBookingListSummaryProps) {
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: 1,
        flexWrap: "wrap",
        flex: 1,
      }}
    >
      {program_ids?.map(program_id => {
        const program = resolveOption<ProgramItem>("programs", program_id);
        const summaries: GuestSummary = get(
          summary,
          `programs.${program_id}`,
          {} as GuestSummary
        );

        return (
          <Box
            key={program_id}
            sx={{
              //flex: 1,
              gap: 1,
              flex: 1,
              flexWrap: "wrap",
              display: "flex",
              //justifyContent: "center",
              flexDirection: "column",
              // p: 1,
              borderRadius: "6px",
              border: theme =>
                `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
              backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
              overflow: "hidden",
            }}
          >
            <Toolbar
              sx={{
                gap: 1,
                pt: 1,
                pl: 1,
                pr: 1,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  minWidth: 10,
                  minHeight: 10,
                  borderRadius: "5px",
                  backgroundColor: program?.color,
                }}
              />
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: 1,
                }}
              >
                {program?.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
              >
                {`${summaries.bookings_arrived}/${summaries.number_of_bookings}`}
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
              >
                {`(${summaries.guests_arrived} / ${summaries.number_of_guests})`}
              </Typography>
            </Toolbar>
            <LinearProgress
              sx={{ flex: 1, height: 8, minHeight: 8 }}
              variant="determinate"
              color="info"
              value={
                (summaries.bookings_arrived / summaries.number_of_bookings) *
                100
              }
            />
          </Box>
        );
      })}
      <Box
        sx={{
          //flex: 1,
          gap: 1,
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          //justifyContent: "center",
          flexDirection: "column",
          // p: 1,
          borderRadius: "6px",
          border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
          backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
          overflow: "hidden",
        }}
      >
        <Toolbar
          sx={{
            gap: 1,
            pt: 1,
            pl: 1,
            pr: 1,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: 1,
            }}
          >
            Összesen
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
          >
            {`${summary?.total.bookings_arrived}/${summary?.total.number_of_bookings}`}
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
          >
            {`(${summary?.total.guests_arrived} / ${summary?.total.number_of_guests})`}
          </Typography>
        </Toolbar>
        <LinearProgress
          sx={{ flex: 1, height: 8, minHeight: 8 }}
          variant="determinate"
          color="info"
          value={
            ((summary?.total.bookings_arrived || 0) /
              (summary?.total.number_of_bookings || 1)) *
            100
          }
        />
      </Box>
    </Box>
  );
}

export default CateringBookingListSummary;
