import { Box, Chip } from "@mui/material";
import useDictionaries from "features/dictionaries/useDictionaries";
import React from "react";

interface Price {
  variant_id: number;
  adult_price: number;
  kid_price: number;
}

interface PricesCellProps {
  params?: any;
}

const PricesCell: React.FC<PricesCellProps> = ({ params }) => {
  const variants: Price[] = params?.row?.variants || [];
  const { resolveOption } = useDictionaries();
  return (
    <Box display="flex" flexDirection="column" padding="2px" fontSize="0.75rem">
      {variants?.map((price, index) => {
        const variant = resolveOption("variants", price.variant_id);
        return (
          <Box
            key={index}
            marginBottom="4px"
            display="flex"
            flexDirection="column"
            padding="4px"
            sx={{
              border: "1px solid transparent",
              borderRadius: "4px",
              transition: "border-color 0.3s, background-color 0.3s",
              "&:hover": {
                borderColor: "primary.light",
                backgroundColor: "rgba(144, 202, 249, 0.1)",
              },
            }}
          >
            <Box display="flex" flexWrap="wrap" marginBottom="2px">
              <Chip
                label={variant ? variant.label : price.variant_id}
                size="small"
                sx={{
                  margin: "1px",
                  fontSize: "0.75rem",
                  backgroundColor: variant
                    ? "primary.light"
                    : "secondary.light",
                  color: variant
                    ? "primary.contrastText"
                    : "secondary.contrastText",
                }}
              />
              <Chip
                label={`Adult: $${price.adult_price}`}
                size="small"
                sx={{
                  margin: "1px",
                  fontSize: "0.75rem",
                  backgroundColor: "secondary.light",
                  color: "secondary.contrastText",
                }}
              />
              <Chip
                label={`Kid: $${price.kid_price}`}
                size="small"
                sx={{
                  margin: "1px",
                  fontSize: "0.75rem",
                  backgroundColor: "secondary.light",
                  color: "secondary.contrastText",
                }}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PricesCell;

