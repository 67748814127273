import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
  Theme,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import I18n from "i18n";
import { EventItem, EventLimit } from "../eventApi";
import LimitCloseButton from "./LimitCloseButton";
import summaryTableSx from "./summaryTableStyle";

interface LimitSummaryProps extends TableRowProps {
  items: EventLimit[];
  event: EventItem;
}

function rederRow(
  item: EventLimit | undefined,
  event: EventItem,
  sx?: SxProps<Theme>
) {
  return (
    <TableRow
      key={JSON.stringify(item?.variant_ids)}
      sx={{
        ...sx,
        backgroundColor: item?.is_shared
          ? theme => alpha(theme.palette.warning.main, 0.15)
          : undefined,
        "& td": {
          backgroundColor: item?.closed
            ? "action.disabledBackground"
            : undefined,
        },
      }}
    >
      <TableCell sx={{ textAlign: "left!important" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          {item?.name}
          {item?.is_shared && (
            <Tooltip title={I18n.t("Event.sharedLimit")}>
              <InfoOutlined fontSize="small" color="info" />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 700,
          color: item?.over_limit
            ? "error.main"
            : item?.over_tolerance
            ? "warning.main"
            : "success.main",
          fontSize: "1.1rem",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "flex-end",
            gap: 1,
            "&>div": {
              fontSize: "0.6rem",
            },
          }}
        >
          {item?.over_limit && (
            <Box>
              +{Math.abs(item?.number_of_places - item?.reserved_places)}
            </Box>
          )}
          {item?.remaining_places}
        </Toolbar>
      </TableCell>
      <TableCell>{item?.reserved_places}</TableCell>
      <TableCell
        sx={{
          backgroundColor: item?.online_closed
            ? "action.disabledBackground"
            : undefined,
        }}
      >
        <LimitCloseButton item={item} event={event} isOnline />
      </TableCell>
      <TableCell>
        <LimitCloseButton item={item} event={event} />
      </TableCell>
    </TableRow>
  );
}

export default function LimitSummary({
  items,
  event,
  ...tableRowProps
}: LimitSummaryProps) {
  const theme = useTheme();

  return (
    <Table padding="none" sx={summaryTableSx}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: "left!important" }}>
            {I18n.t("Limit.titleSingular")}
          </TableCell>
          <TableCell sx={{ width: "120px" }}>
            {I18n.t("Event.available")}
          </TableCell>
          <TableCell>{I18n.t("Event.reserved")}</TableCell>
          <TableCell>{I18n.t("Event.onlineSeats")}</TableCell>
          <TableCell>{I18n.t("Event.seats")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items
          ?.filter(i => i.type !== LIMIT_TYPES.BOAT)
          .map(item => {
            return rederRow(item, event, {});
          })}
        {rederRow(items.find(i => i.type === LIMIT_TYPES.BOAT) as any, event, {
          borderTop: theme => `solid 2px ${theme.palette.info.main}`,
          borderBottom: "none",
          "& td": {
            borderBottom: "none",
            fontWeight: 700,
            fontStyle: "italic",
          },
        })}
      </TableBody>
    </Table>
  );
}
