import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  List,
  ListItemText,
  ListProps,
  MenuItem,
} from "@mui/material";
import { get } from "lodash";
import { cloneElement, isValidElement, useMemo, useState } from "react";
import useSelectedTab, { UseSelectedTabProps } from "./useSelectedTab";
export interface ListNavigationProps extends ListProps, UseSelectedTabProps {
  pattern?: string;
  tabParam?: string;
  replace?: boolean;
  absolute?: boolean;
  minimized?: boolean;
  grouped?: boolean;
  groupTitle?: string;
}

export default function ListNavigation({
  pattern,
  tabParam = "tab",
  children,
  absolute,
  minimized,
  groupTitle,
  grouped,
  ...listProps
}: ListNavigationProps) {
  let tab = useSelectedTab({ pattern, tabParam });
  let avaibleTabs: any[] = [];
  if (Array.isArray(children)) {
    children.forEach(child => {
      if (child.props) {
        avaibleTabs.push(child.props.value);
      }
    });
  } else if (isValidElement(children)) {
    if (children.props) {
      avaibleTabs.push(children.props.value);
    }
  }
  let _tab: any = tab || "";
  if (!avaibleTabs.includes(tab || "")) {
    _tab = false;
  }

  const hasSelected = useMemo(() => {
    if (Array.isArray(children)) {
      return !!children.find(
        (child: any) => _tab === get(child, "props.value")
      );
    } else if (children) {
      return _tab === get(children, "props.value");
    }
    return false;
  }, [children, _tab]);
  const [open, setOpen] = useState(hasSelected);

  const handleClick = () => {
    setOpen(!open);
  };

  function renderItems(className?: string) {
    return (
      <List {...listProps} className={className}>
        {Array.isArray(children)
          ? children.map((child: any, index: number) => {
              if (!child.props) {
                return null;
              }
              return cloneElement(child, {
                minimized,
                absolute,
                key: index,
                selected: _tab === child.props.value,
              });
            })
          : (children as any)?.props
          ? cloneElement(children as any, {
              minimized,
              absolute,
              selected: _tab === get(children, "props.value"),
            })
          : null}
      </List>
    );
  }
  if (grouped) {
    return (
      <Box>
        <MenuItem
          onClick={handleClick}
          className={
            open || hasSelected
              ? "NavigationGroupTitle opened"
              : "NavigationGroupTitle"
          }
        >
          <ListItemText primary={groupTitle} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </MenuItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {renderItems("ListNavigationGroup")}
        </Collapse>
      </Box>
    );
  } else {
    return renderItems();
  }
}

