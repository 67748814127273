import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import { ColorIndicatorProps } from "../types";

export interface CheckboxProps
  extends ColorIndicatorProps,
    Omit<
      MuiCheckboxProps,
      "error" | "onChange" | "onBlur" | "value" | "checked"
    > {
  error?: string;
  helperText?: string;
  mask?: string;
  onChange?: Function;
  onBlur?: Function;
  onChangeByKeyPress?: Function;
  useDelayedKeystrokes?: boolean;
  unmask?: boolean;
  editable?: boolean;
  isDirty?: boolean;
  value?: boolean;
  label?: string;
  numeric?: boolean;
  nullOnFalse?: boolean;
  formControlProps?: FormControlProps;
}

export default function Checkbox({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  onChangeByKeyPress,
  editable,
  useDelayedKeystrokes = true,
  isDirty,
  label: _label,
  required,
  numeric,
  indicatorColor,
  indicatorOpacity,
  formControlProps,
  nullOnFalse,
  ...props
}: CheckboxProps) {
  const label =
    required && _label ? (
      <span>
        {_label} <span style={{ color: "red" }}>*</span>
      </span>
    ) : (
      _label
    );
  return (
    <FormControl
      //style={{ minHeight: 50 }}
      {...formControlProps}
      error={error ? true : false}
    >
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <MuiCheckbox
            //sx={{ padding: "5px" }}
            checked={value ? true : false}
            onChange={(event, checked) => {
              const numericChecked: any = checked ? 1 : 0;
              let v: any = numeric
                ? (numericChecked as never)
                : (checked as never);

              if (!checked && nullOnFalse) {
                v = null;
              }
              onChange &&
                onChange({
                  target: {
                    value: v,
                  },
                });
            }}
            {...props}
          />
        }
        label={label ? label : ""}
      />
      {(error || helperText) && (
        <FormHelperText error={error ? true : false}>
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

