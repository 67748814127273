import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { PageHeaderProps } from "./PageHeader";

type PageHeaderContextProps = {
  setValue: (value: PageHeaderProps) => void;
  value: PageHeaderProps;
};
const PageHeaderContext = createContext<PageHeaderContextProps>(
  {} as PageHeaderContextProps
);

export default function PageHeaderProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [value, setValue] = useState<PageHeaderProps>({});

  return (
    <PageHeaderContext.Provider value={{ value, setValue }}>
      {children}
    </PageHeaderContext.Provider>
  );
}

export function usePageHeaderGetter() {
  const { value } = useContext(PageHeaderContext);
  return value;
}
export function usePageHeaderSetter(value: PageHeaderProps) {
  const { setValue } = useContext(PageHeaderContext);
  useEffect(() => {
    if (window.__GLOBAL_HEADER__) {
      setValue(value);
    }
  }, []);
}

