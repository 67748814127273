import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { forwardRef, useRef } from "react";
import { LongPressOptions, useLongPress } from "./../../modules/helpers";
export interface ButtonProps extends MuiButtonProps {
  title?: string;
  loading?: boolean;
  onLongClick?: () => void;
  onClickInOut?: (isIn: boolean) => void;
  longPressOptions?: LongPressOptions;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      title,
      loading,
      startIcon,
      disabled,
      onClick,
      children,
      onLongClick,
      onClickInOut,
      longPressOptions,
      ...buttonProps
    }: ButtonProps,
    ref
  ) => {
    const justClicked = useRef(false);

    const longPressEvent = useLongPress(
      () => {
        //LongPress

        onLongClick && onLongClick();
      },
      (evt: any) => {
        //Press

        if (!justClicked.current) {
          justClicked.current = true;
          setTimeout(() => {
            justClicked.current = false;
          }, 400);
          onClick && onClick(evt);
        }
      },
      inOut => {
        //Cancel LongPress

        onClickInOut && onClickInOut(inOut);
      },
      longPressOptions
    );

    return (
      <MuiButton
        ref={ref}
        startIcon={loading ? <CircularProgress size={20} /> : startIcon}
        disabled={loading || disabled}
        /*onClick={evt => {
        if (!justClicked.current) {
          justClicked.current = true;
          setTimeout(() => {
            justClicked.current = false;
          }, 400);
          onClick && onClick(evt);
        }
      }}*/
        {...longPressEvent}
        variant="contained"
        color="primary"
        {...buttonProps}
      >
        {children || title}
      </MuiButton>
    );
  }
);
//@ts-ignore
Button.muiName = "Button";
export default Button;

