import { ReactElement } from "react";

export interface LayoutRouterProps {
  children: Array<ReactElement>;
  getLayoutIndex: () => number;
}
export default function LayoutRouter({
  children,
  getLayoutIndex,
}: LayoutRouterProps) {
  return children[getLayoutIndex()];
}

