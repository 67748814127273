import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  SettingsState,
  selectSettingsByKey,
  updateSettings,
} from "./settingsSlice";

interface UseSettingsProps<K extends keyof SettingsState> {
  key: K;
}

export default function useSettings<K extends keyof SettingsState>({
  key,
}: UseSettingsProps<K>): [
  SettingsState[K],
  (values: Partial<SettingsState[K]>) => void
] {
  const settings = useAppSelector(state => selectSettingsByKey(state, key));
  const dispatch = useAppDispatch();

  function setSettings(values: Partial<SettingsState[K]>) {
    dispatch(updateSettings({ key, settings: { ...settings, ...values } }));
  }

  return [(settings || {}) as any, setSettings];
}
