import { Box, BoxProps, styled, useTheme } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactNode } from "react";

import { Divider, Typography, TypographyProps } from "@mui/material";
import Spacer from "./../Spacer";

const Wrapper = styled(Box)`
  padding-top: ${props => props.theme.spacing(0.5)};
  padding-bottom: ${props => props.theme.spacing(0.5)};
  //background-color: ${props => props.theme.palette.background.default};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopContainer = styled("div")`
  flex: 1;
  display: flex;
`;
const Container = styled("div")`
  flex: 1;
  //flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TypographyContainer = styled("div")`
  flex: 1;
`;

export interface ListItemProps extends BoxProps {
  icon?: JSX.Element;
  title?: string;
  description?: string;
  titleProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  divider?: boolean;
  dividerHeight?: number;
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  rightContent?: ReactNode;
  rightAction?: ReactNode;
}
export default function ListItem({
  icon,
  title,
  description,
  titleProps,
  descriptionProps,
  divider,
  rightContent,
  bottomContent,
  topContent,
  rightAction,
  dividerHeight = 1,
  ...rest
}: ListItemProps) {
  const theme = useTheme();
  return (
    <>
      <Wrapper {...rest}>
        {icon}
        {!!icon && <Spacer w={1} />}
        <TopContainer>
          {topContent}
          <Container>
            <TypographyContainer>
              {!!title && (
                <Typography variant="subtitle2" {...titleProps}>
                  {title}
                </Typography>
              )}
              {!!description && (
                <Typography
                  variant="body2"
                  color="GrayText"
                  {...descriptionProps}
                >
                  {description}
                </Typography>
              )}
              {bottomContent}
            </TypographyContainer>
            {!!rightContent && <Spacer w={0.5} />}
            {rightContent}
          </Container>
        </TopContainer>
        {rightAction}
        {!!rest.onClick && <ChevronRightIcon />}
      </Wrapper>
      {divider && <Divider style={{ height: dividerHeight }} />}
    </>
  );
}

