import { Box, BoxProps, styled, Typography } from "@mui/material";
import { CalendarCellItem, VIEWS } from "./calendarApi";

const CellHeader = styled(Box)<BoxProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing(0.5)};
  background-color: ${props => props.theme.palette.background.sheet};
`;

export interface CalendarCellHeaderProps extends CalendarCellItem {
  view: VIEWS;
}

export default function CalendarCellHeader({
  view,
  moment,
  isSelected,
  dayOfMonth,
}: CalendarCellHeaderProps) {
  if (view === VIEWS.MONTH) {
    return (
      <CellHeader>
        <Typography variant="h6" color={isSelected ? "white" : undefined}>
          {dayOfMonth}
        </Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.WEEK) {
    return (
      <CellHeader>
        <Typography variant="h6">{moment.format("dddd LL")}</Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.WORK_WEEK) {
    return null;
    /*return (
      <div className={classes.dayHeader}>
        <Text variant="text">{day.dayOfMonth}</Text>
      </div>
    );*/
  } else if (view === VIEWS.DAY) {
    return (
      <CellHeader>
        <Typography variant="h4">{moment.format("dddd LL")}</Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.AGENDA) {
    return (
      <CellHeader>
        <Typography variant="h6">{moment.format("dddd LL")}</Typography>
      </CellHeader>
    );
  }
  return null;
}

