//import { useGrid } from "common";
import { globalMessageHandler } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { useState } from "react";
import {
  BookingItem,
  checkIn,
  checkOut,
  getBookingService,
} from "./bookingApi";
import { BOOKING_STATUSES } from "./bookingConstants";

export default function useBookingList() {
  const params = useParams<
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "program_variant_id"
    | "partner_id"
    | "partner_group_id"
  >();
  const [status, setStatus] = useState<BOOKING_STATUSES[]>([
    BOOKING_STATUSES.ACTIVE,
  ]);

  const scope = {
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
  };
  const nonEmptyKeys = Object.keys(params); //.filter(key => obj[key] !== null && obj[key] !== undefined && obj[key] !== "");
  const listId = `bookings_${nonEmptyKeys.join("_")}`;
  console.log(listId);
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0 ? "bookings/" : ""
  }`;
  const service = getBookingService({
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
  });

  async function checkInAsync(booking: BookingItem) {
    //setCheckInLoading(true);
    const { data, success } = await checkIn(booking.id);
    //setCheckInLoading(false);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeres regisztráció.",
      });
    }
  }
  async function checkOutAsync(booking: BookingItem) {
    //setCheckOutLoading(true);
    const { data, success } = await checkOut(booking.id);
    //setCheckOutLoading(false);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeresen törölted a regisztrációt.",
      });
    }
  }

  return {
    ...service,
    params,
    scope,
    editUrl,
    status,
    setStatus,
    listId,
    checkInAsync,
    checkOutAsync,
  };
}
