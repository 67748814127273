import {
  Checkbox,
  Chip,
  ChipTypeMap,
  InputBaseProps,
  TextField,
  useTheme,
} from "@mui/material";
import MuiAutocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteProps as MuiAutocompleteProps,
} from "@mui/material/Autocomplete";
import { get } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTextFieldColorIndicatorSx } from "../TextField/TextField";
import { ColorIndicatorProps } from "../types";

const getSelectedItem = (options: any, value: any) => {
  if (!options) {
    return null;
  }
  //eslint-disable-next-line eqeqeq
  return options.find((option: any) => String(option.value) === String(value));
};

const getSelectedItems = (options: any, value: any) => {
  if (!options) {
    return [];
  }
  return options.filter(
    //option => value && Array.isArray(value) && value.indexOf(option.value) >= 0
    //type insensitive
    (option: any) =>
      value &&
      Array.isArray(value) &&
      value.findIndex(item => String(item) === String(option.value)) >= 0
  );
};

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
> extends ColorIndicatorProps,
    Omit<
      MuiAutocompleteProps<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
      >,
      "renderInput" | "onChange" | "value" | "options"
    > {
  error?: string;
  label?: string;
  helperText?: string;
  checkboxSelect?: boolean;
  isDirty?: boolean;
  required?: boolean;
  editable?: boolean;
  placeholder?: string;
  /** use Array As source but act ase sinle select */
  fakeMultiple?: boolean;
  loadItems?: (queryParams?: string, onlyTrashed?: boolean) => Promise<T[]>;
  onChange?: Function;
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  value?: any;
  options?: T[];
  emptyDisabledText?: string;
  colorAttribute?: string;
  InputProps?: InputBaseProps;
}

export default function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>({
  multiple,
  fakeMultiple,
  error,
  options: _options,
  label: _label,
  helperText,
  checkboxSelect = true,
  onChange,
  isDirty,
  editable,
  value,
  emptyDisabledText,
  required,
  renderOption,
  disableCloseOnSelect,
  indicatorColor,
  indicatorOpacity,
  loadItems,
  renderInput: rawRenderInput,
  InputProps,
  colorAttribute,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
  const uniqueId = useRef(`${rest.id}_${new Date().getTime()}`);
  const theme = useTheme();

  const [options, setOptions] = useState<T[] | undefined>(_options);
  const label = required ? (
    <span>
      {_label} <span style={{ color: "red" }}>*</span>
    </span>
  ) : (
    _label
  );
  function renderInput(params: AutocompleteRenderInputParams): JSX.Element {
    if (rawRenderInput) {
      return rawRenderInput(params);
    }
    return (
      <TextField
        {...params}
        InputLabelProps={
          rest.placeholder
            ? { ...params.InputLabelProps, shrink: true }
            : params.InputLabelProps
        }
        placeholder={value ? undefined : rest.placeholder}
        error={error ? true : false}
        helperText={error || helperText}
        inputProps={{
          ...params.inputProps,
          ...(rest.disabled && emptyDisabledText
            ? { value: emptyDisabledText }
            : undefined),
          //autoComplete: `new-password${new Date().getTime()}`, // disable autocomplete and autofill
          autoComplete: "off",
          id: uniqueId.current,
        }}
        sx={{
          ...getTextFieldColorIndicatorSx(rest.id, {
            indicatorColor,
            indicatorOpacity,
          }),
          ...(params as any).sx,
        }}
        label={label}
        InputProps={{ ...params.InputProps, ...InputProps }}
      />
    );
  }

  const _renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: any,
      { selected }: AutocompleteRenderOptionState
    ): React.ReactNode => {
      //console.log(option);
      const color = colorAttribute ? get(option, colorAttribute) : undefined;
      return (
        <li
          {...optionProps}
          style={
            color
              ? {
                  ...optionProps?.style,
                  backgroundColor: color,
                  color: theme.palette.getContrastText(color),
                }
              : optionProps.style
          }
          key={rest.freeSolo ? option : option.value}
        >
          {multiple && checkboxSelect && (
            <Checkbox size="small" color="primary" checked={selected} />
          )}
          {rest.freeSolo ? option : option.label}
        </li>
      );
    },
    [rest.freeSolo, multiple, checkboxSelect]
  );

  const handleChange = (evt: any, newOptions: any, action: string) => {
    let v: Array<string | number | null> | string | number | null = null;

    if (rest.freeSolo) {
      //Using string array as options
      if (fakeMultiple) {
        v = newOptions ? [newOptions] : null;
      } else {
        v = newOptions || null;
      }
    } else {
      if (multiple) {
        if (newOptions && newOptions.length > 0) {
          if (action === "createOption") {
            v = newOptions.map((o: any) =>
              typeof o === "string" ? o : o.value
            );
          } else {
            v = newOptions.map((o: any) => o.value);
          }
        }
      } else if (fakeMultiple) {
        v = newOptions ? [newOptions.value] : null;
      } else {
        v = newOptions ? newOptions.value : null;
      }
    }
    //console.log({ action, newOptions });
    onChange && onChange({ target: { value: v } }, newOptions);
  };

  const getValue = useCallback(() => {
    if (rest.freeSolo) {
      return value;
    }
    if (multiple) {
      return getSelectedItems(options, value) || [];
    } else if (fakeMultiple) {
      const items = getSelectedItems(options, value);
      return items && items.length > 0 ? items[0] : null;
    } else {
      return getSelectedItem(options, value) || null;
    }
  }, [value, rest.freeSolo, options]);

  async function loadOptions() {
    if (loadItems && !options) {
      const options = await loadItems();
      setOptions(options);
    }
  }

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    setOptions(_options);
  }, [_options]);

  return (
    //@ts-ignore
    <MuiAutocomplete
      renderOption={renderOption || _renderOption}
      renderInput={renderInput}
      onChange={handleChange}
      options={options || []}
      multiple={multiple}
      renderTags={
        colorAttribute
          ? (value, getTagProps) =>
              value.map((option: any, index) => {
                const color = colorAttribute
                  ? get(option, colorAttribute)
                  : undefined;
                let tagProps: any = getTagProps({ index });
                return (
                  <Chip
                    //variant="outlined"
                    label={option.label}
                    size="small"
                    {...tagProps}
                    style={
                      color
                        ? {
                            ...tagProps?.style,
                            backgroundColor: color,
                            color: theme.palette.getContrastText(color),
                          }
                        : tagProps.style
                    }
                  />
                );
              })
          : undefined
      }
      disableCloseOnSelect={
        multiple && checkboxSelect ? true : disableCloseOnSelect
      }
      value={getValue()}
      {...rest}
    />
  );
}
Autocomplete.displayName = "Autocomplete";
