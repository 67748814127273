import { globalMessageHandler } from "common";
import { EventItem, updateEventAvailability } from "features/events/eventApi";

import moment from "moment";
import { useState } from "react";
import { calendarAction } from "./useEventCalendar";

export interface UseLimitCloseButtonProps {
  item: EventItem;
}

export interface UseLimitCloseButtonReturnType {
  setOnlineClosed: (varient_id: number[] | number, is_closed: boolean) => void;
  setClosed: (varient_id: number[] | number, is_closed: boolean) => void;
  onlineCloseLoading: boolean;
  closeLoading: boolean;
}

export default function useLimitCloseButton({
  item,
}: UseLimitCloseButtonProps): UseLimitCloseButtonReturnType {
  const [onlineCloseLoading, setOnlineCloseLoading] = useState(false);

  function setOnlineClosed(variant_id: number[] | number, is_closed: boolean) {
    console.log({ variant_id, is_closed });
    let variantIds = typeof variant_id === "number" ? [variant_id] : variant_id;
    const title = `${item?.program?.name} ${
      item?.start_time ? moment(item?.start_time).format("L LT") : ""
    }`;
    const message = is_closed
      ? "Biztosan lezárod az online foglalást?"
      : "Biztosan megnyitod az online foglalást?";
    globalMessageHandler.confirm({ message, title }, async () => {
      setOnlineCloseLoading(true);

      const { success, data } = await updateEventAvailability(
        item.id,
        variantIds,
        is_closed,
        true
      );

      setOnlineCloseLoading(false);
      if (success) {
        calendarAction("refresh", data);
        setTimeout(() => {
          if (is_closed) {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen lezártad az online foglalást.",
            });
          } else {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen megnyitottad az online foglalást.",
            });
          }
        }, 300);
      }
      return !!success;
    });
  }
  const [closeLoading, setCloseLoading] = useState(false);
  function setClosed(variant_id: number[] | number, is_closed: boolean) {
    console.log({ variant_id, is_closed });
    let variantIds = typeof variant_id === "number" ? [variant_id] : variant_id;
    const title = `${item?.program?.name} ${
      item?.start_time ? moment(item?.start_time).format("L LT") : ""
    }`;
    const message = is_closed
      ? "Biztosan lezárod a foglalást?"
      : "Biztosan megnyitod a foglalást?";
    globalMessageHandler.confirm({ message, title }, async () => {
      setCloseLoading(true);
      const { success, data } = await updateEventAvailability(
        item.id,
        variantIds,
        is_closed,
        false
      );
      setCloseLoading(false);
      if (success) {
        calendarAction("refresh", data);
        setTimeout(() => {
          if (is_closed) {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen lezártad a foglalást.",
            });
          } else {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen megnyitottad a foglalást.",
            });
          }
        }, 300);
      }
      return !!success;
    });
  }

  return {
    closeLoading,
    onlineCloseLoading,
    setClosed,
    setOnlineClosed,
  };
}

