import { Box, Chip } from "@mui/material";
import { ScheduleEvent } from "features/schedules/scheduleApi";
import React from "react";

interface EventCellProps {
  params?: any;
}

const EventCell: React.FC<EventCellProps> = ({ params }) => {
  const events: ScheduleEvent[] = params?.row?.events || [];
  const daysInHungarian = [
    "Hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
    "Vasárnap",
  ];

  const getDaysLabel = (days: number[]): string | null => {
    const sortedDays = days.sort((a, b) => a - b);
    const allDays = [1, 2, 3, 4, 5, 6, 7];
    const weekend = [6, 7];
    const weekdays = [1, 2, 3, 4, 5];

    if (JSON.stringify(sortedDays) === JSON.stringify(allDays))
      return "Minden nap";
    if (JSON.stringify(sortedDays) === JSON.stringify(weekend))
      return "Hétvége";
    if (JSON.stringify(sortedDays) === JSON.stringify(weekdays))
      return "Hétköznap";
    return null;
  };

  return (
    <Box display="flex" flexDirection="column" padding="2px" fontSize="0.75rem">
      {events?.map((event, index) => {
        const daysLabel = getDaysLabel(event.days);

        return (
          <Box
            key={index}
            marginBottom="4px"
            display="flex"
            flexDirection="column"
            padding="4px"
            sx={{
              border: "1px solid transparent",
              borderRadius: "4px",
              transition: "border-color 0.3s, background-color 0.3s",
              "&:hover": {
                borderColor: "primary.light",
                backgroundColor: "rgba(144, 202, 249, 0.1)",
              },
            }}
          >
            <Box display="flex" flexWrap="wrap" marginBottom="2px">
              {event.start_times?.map((time, idx) => (
                <Chip
                  key={idx}
                  label={time}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: "primary.light",
                    color: "primary.contrastText",
                  }}
                />
              ))}
            </Box>
            <Box display="flex" flexWrap="wrap">
              {daysLabel ? (
                <Chip
                  label={daysLabel}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: "secondary.light",
                    color: "secondary.contrastText",
                  }}
                />
              ) : (
                event.days?.map((day, idx) => (
                  <Chip
                    key={idx}
                    label={daysInHungarian[day - 1]}
                    size="small"
                    sx={{
                      margin: "1px",
                      fontSize: "0.75rem",
                      backgroundColor: "secondary.light",
                      color: "secondary.contrastText",
                    }}
                  />
                ))
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default EventCell;

