import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  Rules,
  ValidationErrors,
  Validator,
  globalMessageHandler,
} from "common";
import I18n from "i18n";
import { get } from "lodash";
import { useState } from "react";
import { ProfileForm } from "./profileAPI";
import {
  selectProfile,
  selectProfileStatus,
  updateProfileAsync,
} from "./profileSlice";

export interface ProfileFromProps {
  defaultValues?: ProfileForm;
}

export function validateProfileForm(values: ProfileForm) {
  const rules: Rules = {
    first_name: Validator.rule.string().required(),
    //last_name: Validator.rule.string().required(),
    email: Validator.rule.string().required(),
  };
  return Validator.validate(rules, values, "ProfileForm");
}

export default function useProfileFrom() {
  const defaultValues = useAppSelector(selectProfile);
  const [item, setItem] = useState<ProfileForm>(defaultValues);

  const [errors, setErrors] = useState<ValidationErrors>({});
  const status = useAppSelector(selectProfileStatus);
  const dispatch = useAppDispatch();
  function onChange(values: ProfileForm) {
    setItem(values);
  }

  function normalizeEmptyValue(value: any) {
    if (value === "") {
      return null;
    }
    if (value === undefined) {
      return null;
    }
    if (value === null) {
      return null;
    }
    if (value === 0) {
      return 0;
    }
    if (
      typeof value === "string" &&
      !isNaN(Number(value)) &&
      value === String(Number(value))
    ) {
      return Number(value);
    }
    return value;
  }

  function getIsDirty(id?: string): boolean {
    if (id) {
      let oldValue: any = normalizeEmptyValue(get(defaultValues, id));
      let newValue: any = normalizeEmptyValue(get(item, id));
      return JSON.stringify(oldValue) !== JSON.stringify(newValue);
    }
    let hasDirty = false;
    Object.keys(item as any).forEach((key: string) => {
      let oldValue: any = normalizeEmptyValue(get(defaultValues, key));
      let newValue: any = normalizeEmptyValue((item as any)[key]);

      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        hasDirty = true;
      }
    });

    return hasDirty;
  }

  async function updateProfile() {
    const result = validateProfileForm(item);
    setErrors(result.errors);
    if (!result.success) {
      return;
    }
    const response = await dispatch(updateProfileAsync(item));

    if (response.meta.requestStatus === "fulfilled") {
      globalMessageHandler.snack({
        severity: "success",
        message: I18n.t("App.successSaved"),
      });
    } else {
      if (response.payload) {
        setErrors(response.payload);
      }
    }
  }

  return {
    item,
    errors,
    loading: status === "loading",
    onChange,
    updateProfile,
    getIsDirty,
  };
}

