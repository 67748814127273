import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { Button, Checkbox, PageHeader, Spacer, Toolbar } from "common";
import I18n from "i18n";
import usePermissionForm from "./usePermissionForm";

export interface PermissionFormProps {
  showHeader?: boolean;
}

export default function PermissionForm({
  showHeader = false,
}: PermissionFormProps) {
  const { save, permissions, loading, saving, changePermission, isDirty } =
    usePermissionForm();

  return (
    <>
      {showHeader && (
        <>
          <PageHeader
            avatar={<VerifiedUserIcon />}
            title={I18n.t("Permission.title")}
          />
          <Spacer h={2} />
        </>
      )}
      {/*<DataGrid
        {...grid}
        columns={columns}
        headerItems={["search", "refresh"]}
      />*/}
      <Grid container spacing={2}>
        {permissions.map((permissions, index) => {
          return (
            <Grid item xs={12} md={6} key={permissions.model} display="flex">
              <Card
                elevation={0}
                sx={{ flex: 1, border: 1, borderColor: "divider" }}
              >
                <CardHeader title={I18n.t(`${permissions.model}.title`)} />
                <CardContent>
                  {permissions.actions.map((action, actionIndex) => {
                    return (
                      <Checkbox
                        disabled={action.disabled}
                        onChange={(evt: any) => {
                          changePermission(
                            index,
                            actionIndex,
                            evt.target.value
                          );
                        }}
                        key={action.id}
                        value={action.value}
                        label={I18n.t(`Permission.actions.${action.name}`)}
                      />
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Spacer h={2} />
      <Toolbar>
        <Spacer right />
        <Button
          disabled={!isDirty}
          loading={saving}
          title={I18n.t("App.save")}
          onClick={() => save()}
        />
      </Toolbar>
    </>
  );
}

