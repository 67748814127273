import api from "app/api";
import { NOTIFICATION_TYPES } from "app/constants";
import { getFirebaseToken } from "common";

//import { getDeviceId, getExpoToken } from "common";
//import { RootStackParamList } from "navigation/types";
import QueryString from "qs";

export const NOTIFICATION_PAGE_COUNT = 10;
export interface NotificationItem {
  id: string;
  title: string;
  body: string;
  data: {
    screen: string;
    params: any;
    severity: "WARNING" | "INFO" | "DANGER" | "SUCCESS";
  };
  type: NOTIFICATION_TYPES;
  seen: boolean;
  created_at: string;
}
export interface NotificationCount {
  total: number;
  byType: [id: NOTIFICATION_TYPES, value: number];
}

export function loadNotifications(
  types: NOTIFICATION_TYPES[],
  page: number = 1,
  prePage: number = NOTIFICATION_PAGE_COUNT
) {
  const filter = QueryString.stringify(
    { NotificationSearch: { type: types }, page, "per-page": prePage },
    {
      encode: false,
    }
  );
  return api.get(`/notifications${filter ? `?${filter}` : ""}`);
}

export function loadNotificationCount() {
  return api.get("/notifications/new-count");
}

export function readNotification(id: string) {
  return api.post(`/notifications/seen/${id}`);
}

export function readAllNotification() {
  return api.post(`/notifications/seen`);
}

export async function subscribe(firebaseToken?: string) {
  const device_uuid = "device_uuid";
  const device_platform = "web";
  const device_name = "Chrome";
  const device_token = firebaseToken || (await getFirebaseToken({}));
  console.log({
    NotificationSubscribeForm: {
      device_uuid,
      device_name,
      device_platform,
      device_token,
    },
  });
  return;
  if (device_token) {
    api.post("/notifications/subscribe", {
      NotificationSubscribeForm: {
        device_uuid,
        device_name,
        device_platform,
        device_token,
      },
    });
  }
}

