import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
  Theme,
  useTheme,
} from "@mui/material";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import I18n from "i18n";
import { EventItem, EventVariant } from "../eventApi";
import LimitCloseButton from "./LimitCloseButton";
import summaryTableSx from "./summaryTableStyle";

interface VariantSummaryProps extends TableRowProps {
  event: EventItem;
  items: Array<ProgramVariantItem & EventVariant>;
  summary: ProgramVariantItem & EventVariant;
}

function rederRow(item: any, event: EventItem, sx?: SxProps<Theme>) {
  return (
    <TableRow key={item.variant_id} sx={sx}>
      <TableCell sx={{ textAlign: "left!important" }}>{item.name}</TableCell>
      <TableCell>{item.number_of_places}</TableCell>
      <TableCell>{item.number_of_guests}</TableCell>
      <TableCell>{item.number_of_adults}</TableCell>
      <TableCell>{item.number_of_kids}</TableCell>
      <TableCell>{item.number_of_babies}</TableCell>
      <TableCell>
        <LimitCloseButton item={item as any} event={event} isOnline />
      </TableCell>
      <TableCell>
        <LimitCloseButton event={event} item={item as any} />
      </TableCell>
    </TableRow>
  );
}

export default function VariantSummary({
  items,
  event,
  summary,
  ...tableRowProps
}: VariantSummaryProps) {
  const theme = useTheme();

  return (
    <Table padding="none" sx={summaryTableSx}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: "left!important" }}>
            {I18n.t("ProgramVariant.title")}
          </TableCell>
          <TableCell>{I18n.t("Event.reserved")}</TableCell>
          <TableCell>{I18n.t("Event.guests")}</TableCell>
          <TableCell>{I18n.t("Event.adults")}</TableCell>
          <TableCell>{I18n.t("Event.kids")}</TableCell>
          <TableCell>{I18n.t("Event.babies")}</TableCell>
          <TableCell>{I18n.t("Event.online")}</TableCell>
          <TableCell>{I18n.t("Event.availability")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map(item => {
          return rederRow(item, event, {});
        })}
        {rederRow(summary, event, {
          borderTop: theme => `solid 2px ${theme.palette.info.main}`,
          borderBottom: "none",
          "& td": {
            borderBottom: "none",
            fontWeight: 700,
            fontStyle: "italic",
          },
        })}
      </TableBody>
    </Table>
  );
}
