import CheckboxGroup, {
  CheckboxGroupProps,
} from "common/components/CheckboxGroup/CheckboxGroup";
import useDictionaries from "features/dictionaries/useDictionaries";

export interface ProgramCheckboxSelectorProps
  extends Omit<CheckboxGroupProps, "options"> {}

export default function ProgramCheckboxSelector(
  props: ProgramCheckboxSelectorProps
) {
  const { dictionaries } = useDictionaries();
  return <CheckboxGroup options={dictionaries.programs} {...props} />;
}
