import { DbFile, FileItem } from "common/types";
import { useEffect, useRef, useState } from "react";

type UseFilePickerObjectProps = {
  value?: { file_name: string; file_id: string; url: string } | null;
  uri?: string;
  upload: Function;
  onChange?: Function;
  onChangeBase64?: Function;
  file?: DbFile;
  validate?: (file: any) => boolean | Promise<boolean>;
  useObjectValue: true;
};
type UseFilePickerIdProps = {
  value?: string | number | null;
  uri?: string;
  upload: Function;
  onChange?: Function;
  onChangeBase64?: Function;
  file?: DbFile;
  validate?: (file: any) => boolean | Promise<boolean>;
  useObjectValue: false;
};
export type UseFilePickerProps =
  | UseFilePickerObjectProps
  | UseFilePickerIdProps;
export default function useFilePicker({
  upload,
  uri,
  value,
  onChange,
  onChangeBase64,
  validate,
  file: _file,
  useObjectValue,
}: UseFilePickerProps) {
  const [file, setFile] = useState<FileItem>();
  const justUploadedRef = useRef<boolean>(false);
  function toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  function downloadUrl() {
    return file?.uri;
  }

  function clearFile() {
    //props.uri && Linking.openURL(props.uri);
    setFile(undefined);
    justUploadedRef.current = false;
    onChange && onChange({ target: { value: null } });
  }

  async function onUploadButtonChange(files: any) {
    if (validate) {
      if (!(await validate(files[0]))) {
        return;
      }
    }
    const f = await upload(files[0]);
    console.log({ f });
    if (f) {
      f.uri = await toBase64(files[0]);

      setFile(f);
      justUploadedRef.current = true;
      if (onChangeBase64) {
        onChangeBase64 && onChangeBase64({ target: { value: f.uri } }, f.uri);
      } else {
        if (useObjectValue) {
          onChange &&
            onChange(
              {
                target: { value: { file_id: f.id, file_name: files[0].name } },
              },
              f.uri
            );
        } else {
          onChange && onChange({ target: { value: f.id } }, f.uri);
        }
      }
    }
    return f;
  }

  useEffect(() => {
    if (justUploadedRef.current) {
      justUploadedRef.current = false;
    } else {
      if (value) {
        if (useObjectValue) {
          if (value?.url || uri) {
            setFile({
              name: _file
                ? `${_file?.name}.${_file?.extension}`
                : String(value?.file_name),
              size: _file?.size,
              mimeType: _file?.mime_type,
              id: value?.file_id,
              uri: uri || value?.url,
            });
          }
        } else {
          if (uri) {
            setFile({
              name: _file
                ? `${_file?.name}.${_file?.extension}`
                : String(value),
              size: _file?.size,
              mimeType: _file?.mime_type,
              id: value,
              uri: uri,
            });
          }
        }
      } else {
        setFile(undefined);
      }
    }
  }, [uri, value]);

  return {
    file,
    downloadUrl,
    clearFile,
    onUploadButtonChange,
  };
}

