import InterestsIcon from "@mui/icons-material/Interests";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import ProgramVariantForm from "./ProgramVariantForm";
import { ProgramVariantItem } from "./programVariantApi";
import useProgramVariantList from "./useProgramVariantList";
export interface ProgramVariantListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function ProgramVariantList({
  showHeader = true,
  ...props
}: ProgramVariantListProps) {
  const { params, scope, editUrl, ...service } = useProgramVariantList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<ProgramVariantItem> = [
    {
      field: "name",
      headerName: I18n.t("ProgramVariant.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      hide: !!params.program_id,
      hideable: !params.program_id,
      field: "program.name",
      headerName: I18n.t("ProgramVariant.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "code",
      headerName: I18n.t("ProgramVariant.attributes.code"),
      flex: 1,
    },
    {
      field: "min_unit",
      headerName: I18n.t("ProgramVariant.attributes.min_unit"),
      flex: 1,
    },
    {
      field: "sku",
      headerName: I18n.t("ProgramVariant.attributes.sku"),
      flex: 1,
    },
    {
      field: "kitchen_group",
      headerName: I18n.t("ProgramVariant.attributes.kitchen_group"),
      flex: 1,
    },
    {
      field: "active",
      headerName: I18n.t("ProgramVariant.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<InterestsIcon />}
          title={I18n.t("ProgramVariant.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="program-variants"
        renderModalForm={props => <ProgramVariantForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`ProgramVariant.attributes.${field.replaceAll(".", "_")}`)}
        canCreate={hasPermissions("program_variant.create")}
        canDelete={hasPermissions([
          "program_variant.editany",
          "program_variant.editown",
        ])}
        canEdit={hasPermissions([
          "program_variant.editany",
          "program_variant.editown",
        ])}
        canDeleteRow={params => canEditItem("program_variant", params.row)}
        canEditRow={params => canEditItem("program_variant", params.row)}
        canBatchActions={hasPermissions([
          "program_variant.editany",
          "program_variant.editown",
        ])}
        canHandleTrash={hasPermissions([
          "program_variant.editany",
          "program_variant.editown",
        ])}
      />
    </>
  );
}

