import api from "app/api";
import { Options } from "common";
import { OptionGroupItem } from "features/option-groups/optionGroupApi";
import { ProgramItem } from "features/programs/programApi";
export interface OptionItem {
  id: number;
  name: string;
  program_id: number;
  program?: ProgramItem;
  code: string;
  option_group_id: number;
  option_group?: OptionGroupItem;
  limit: number;
  active: boolean;
}

export type OptionOptions = Options<"role" | "status">;

export function getOptionService(scope?: any) {
  return api.getService<OptionItem, OptionItem, OptionOptions>(
    "program-options",
    {
      scope,
      //params goes here
    }
  );
}

