import { SvgIcon, SvgIconProps } from "@mui/material";

export interface WangoghLogoProps extends SvgIconProps {
  typo?: boolean;
}
export default function WangoghLogo({
  typo = true,
  ...props
}: WangoghLogoProps) {
  return (
    <SvgIcon viewBox={`0 0 157.526 ${typo ? "69.858" : "51.858"}`} {...props}>
      <path
        d="M74.626,18.483c.037-6.4-6.179-7.146-6.179-7.146-6.576-.215-7.679,6.178-7.679,6.178l-.137,23.818c-.282,1.977.013,3.343-.509,4.435-2.439,5.09-7.5,1.847-8.207,1.364.01-.021.02-.039.03-.061,0,0-1.185-.236-1.244-4.148L50.524,17.86a6.581,6.581,0,0,0-6.933-6.932s-6.754.533-6.932,7.111l.178,25.6s-.711,5.332-5.866,4.977h0s-4.622-.178-4.266-8.354v-22.4s-.179-6.044-7.466-6.754c0,0-6.4,1.066-6.4,6.4V44.523a19.138,19.138,0,0,0,5.688,12.622h.13c3.06,2.948,12.755,10.4,24.989.978h0c7.958,7.154,18.11,3.658,18.11,3.658,13.538-4.9,12.724-18.235,12.724-18.235Z"
        transform="translate(-12.84 -10.928)"
        fill="#36a9e1"
      />
      <path
        d="M122.448,18.483c.036-6.4-6.181-7.146-6.181-7.146-6.575-.215-7.679,6.178-7.679,6.178l-.136,23.818c-.283,1.977.014,3.343-.51,4.435-2.44,5.093-7.507,1.844-8.206,1.363.01-.021.02-.039.03-.06,0,0-1.185-.236-1.244-4.148L98.345,17.86a6.581,6.581,0,0,0-6.933-6.932s-6.755.533-6.931,7.111l.176,25.6s-.71,5.332-5.865,4.977c0,0-4.622-.178-4.266-8.354v-22.4s-.179-6.044-7.466-6.754c0,0-6.4,1.066-6.4,6.4V44.523a19.138,19.138,0,0,0,5.688,12.622h.129c3.061,2.948,12.756,10.4,24.99.978h0c7.957,7.154,18.109,3.658,18.109,3.658,13.539-4.9,12.726-18.235,12.726-18.235Z"
        transform="translate(-12.84 -10.928)"
        fill="#95c11f"
      />
      <path
        d="M170.365,18.483c.037-6.4-6.18-7.146-6.18-7.146-6.577-.215-7.678,6.178-7.678,6.178l-.138,23.818c-.283,1.977.015,3.343-.509,4.435-2.439,5.09-7.5,1.847-8.207,1.364.011-.021.021-.039.031-.061,0,0-1.186-.236-1.246-4.148l-.176-25.063a6.582,6.582,0,0,0-6.932-6.932s-6.756.533-6.933,7.111l.177,25.6s-.71,5.332-5.865,4.977c0,0-4.622-.178-4.266-8.354v-22.4s-.178-6.044-7.466-6.754c0,0-6.4,1.066-6.4,6.4V44.523a19.137,19.137,0,0,0,5.689,12.622h.129c3.061,2.948,12.755,10.4,24.99.978h0c7.957,7.154,18.108,3.658,18.108,3.658,13.539-4.9,12.726-18.235,12.726-18.235Z"
        transform="translate(-12.84 -10.928)"
        fill="#706f6f"
      />
      {typo && (
        <>
          <path
            d="M24.752,80.636l-3.345-10.5h1.725l2.025,6.569a4.531,4.531,0,0,1,.165.564c.05.214.1.432.136.652s.077.425.112.615.067.345.1.465l-.435-.016q.225-.793.353-1.229c.085-.29.152-.513.2-.668s.094-.287.134-.4l1.89-4.74h1.456L31.1,76.69q.15.362.263.713c.075.236.142.47.2.7s.126.483.2.743l-.39.105c.05-.27.088-.482.113-.638s.047-.282.067-.382.04-.2.06-.292.053-.218.1-.368.107-.355.188-.616l1.994-6.524h1.74l-3.345,10.5H31.157l-2.775-6.571.136.061-2.671,6.51Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M36.613,80.636l4.065-10.5h1.65l4.035,10.5H44.638l-2.4-6.33q-.045-.106-.173-.51c-.085-.271-.182-.57-.292-.9s-.21-.633-.3-.908-.15-.452-.18-.533l.345-.015q-.09.255-.218.646l-.27.824q-.142.435-.277.818l-.225.637-2.385,6.271Zm1.8-2.611.6-1.559h4.77l.69,1.559Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M49.112,80.636v-10.5h1.455l6.42,8.594-.24.136c-.04-.25-.075-.522-.1-.818s-.058-.61-.082-.945-.045-.69-.06-1.065-.028-.767-.038-1.178-.015-.835-.015-1.274v-3.45h1.62v10.5H56.582l-6.315-8.281.165-.209q.075.915.128,1.552t.089,1.08q.037.442.053.72c.01.186.017.338.023.457s.007.226.007.316v4.365Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M66.731,80.786a5.47,5.47,0,0,1-3.87-1.56A5.2,5.2,0,0,1,61.7,77.509a5.429,5.429,0,0,1-.412-2.123,5.342,5.342,0,0,1,.412-2.1,5.183,5.183,0,0,1,1.156-1.7,5.35,5.35,0,0,1,1.732-1.133,5.589,5.589,0,0,1,2.13-.4,5.4,5.4,0,0,1,1.553.225,5.179,5.179,0,0,1,1.372.629,3.936,3.936,0,0,1,1,.93l-1.05,1.1a5.252,5.252,0,0,0-.923-.721,4.259,4.259,0,0,0-.967-.434,3.4,3.4,0,0,0-.99-.151,3.847,3.847,0,0,0-1.492.285,3.773,3.773,0,0,0-1.2.8,3.619,3.619,0,0,0-.8,1.193,3.841,3.841,0,0,0-.285,1.493,3.7,3.7,0,0,0,.3,1.477,3.829,3.829,0,0,0,.832,1.223,3.969,3.969,0,0,0,1.23.825,3.7,3.7,0,0,0,1.478.3,3.534,3.534,0,0,0,1.11-.173,3.029,3.029,0,0,0,.93-.487,2.331,2.331,0,0,0,.63-.728,1.8,1.8,0,0,0,.225-.878v-.3l.225.209h-3.21v-1.6h4.65c.02.109.035.217.045.322s.017.205.022.3.008.188.008.277a4.767,4.767,0,0,1-.36,1.883,4.373,4.373,0,0,1-1,1.463,4.511,4.511,0,0,1-1.485.952A4.919,4.919,0,0,1,66.731,80.786Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M79.067,80.786a5.123,5.123,0,0,1-2.047-.405,4.989,4.989,0,0,1-1.635-1.133,5.105,5.105,0,0,1-1.08-1.71,6.24,6.24,0,0,1,0-4.3,5.115,5.115,0,0,1,1.08-1.71,4.957,4.957,0,0,1,1.635-1.132,5.366,5.366,0,0,1,4.095,0A4.927,4.927,0,0,1,82.75,71.53a5.228,5.228,0,0,1,1.08,1.718,6.171,6.171,0,0,1,0,4.275,5.207,5.207,0,0,1-1.08,1.718,4.96,4.96,0,0,1-1.635,1.14A5.127,5.127,0,0,1,79.067,80.786Zm0-1.575a3.4,3.4,0,0,0,1.4-.285,3.247,3.247,0,0,0,1.1-.8,3.8,3.8,0,0,0,.728-1.215,4.555,4.555,0,0,0,0-3.045,3.8,3.8,0,0,0-.728-1.215,3.231,3.231,0,0,0-1.1-.8,3.593,3.593,0,0,0-2.8,0,3.351,3.351,0,0,0-1.11.8,3.534,3.534,0,0,0-.728,1.207,4.738,4.738,0,0,0,0,3.067,3.527,3.527,0,0,0,.728,1.216,3.346,3.346,0,0,0,2.512,1.08Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M92.206,80.786a5.475,5.475,0,0,1-3.871-1.56,5.2,5.2,0,0,1-1.162-1.717,5.429,5.429,0,0,1-.412-2.123,5.342,5.342,0,0,1,.412-2.1,5.2,5.2,0,0,1,1.155-1.7,5.344,5.344,0,0,1,1.733-1.133,5.588,5.588,0,0,1,2.129-.4,5.393,5.393,0,0,1,1.553.225,5.171,5.171,0,0,1,1.373.629,3.948,3.948,0,0,1,1,.93l-1.05,1.1a5.21,5.21,0,0,0-.922-.721,4.259,4.259,0,0,0-.967-.434,3.4,3.4,0,0,0-.991-.151,3.851,3.851,0,0,0-1.492.285,3.769,3.769,0,0,0-1.2.8,3.622,3.622,0,0,0-.8,1.193,3.841,3.841,0,0,0-.284,1.493,3.7,3.7,0,0,0,.3,1.477,3.848,3.848,0,0,0,.833,1.223,3.969,3.969,0,0,0,1.23.825,3.7,3.7,0,0,0,1.477.3,3.529,3.529,0,0,0,1.11-.173,3.034,3.034,0,0,0,.931-.487,2.315,2.315,0,0,0,.629-.728,1.805,1.805,0,0,0,.226-.878v-.3l.225.209h-3.21v-1.6h4.65c.019.109.034.217.044.322s.018.205.023.3.008.188.008.277a4.75,4.75,0,0,1-.361,1.883,4.373,4.373,0,0,1-1,1.463,4.5,4.5,0,0,1-1.484.952A4.919,4.919,0,0,1,92.206,80.786Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M100,80.636v-10.5h1.619v10.5Zm.7-4.455V74.62h6.87v1.561Zm6.15,4.455v-10.5h1.621v10.5Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M126.434,80.786a5.32,5.32,0,0,1-1.582-.225,4.121,4.121,0,0,1-1.321-.683,4.99,4.99,0,0,1-1.057-1.132l1.11-1.275a5.044,5.044,0,0,0,1.409,1.455,3.075,3.075,0,0,0,1.606.405,2.829,2.829,0,0,0,1.035-.181,1.794,1.794,0,0,0,.734-.5,1.069,1.069,0,0,0,.271-.719,1.463,1.463,0,0,0-.09-.51,1.294,1.294,0,0,0-.278-.436,2.044,2.044,0,0,0-.48-.359,3.788,3.788,0,0,0-.683-.293,6.71,6.71,0,0,0-.9-.218,6.692,6.692,0,0,1-1.41-.434,3.336,3.336,0,0,1-1.005-.675,2.58,2.58,0,0,1-.6-.923A3.278,3.278,0,0,1,123,72.926a2.519,2.519,0,0,1,.277-1.17,2.929,2.929,0,0,1,.765-.938,3.554,3.554,0,0,1,1.147-.615,4.565,4.565,0,0,1,1.425-.218,5.025,5.025,0,0,1,1.486.21,3.734,3.734,0,0,1,1.2.623,3.517,3.517,0,0,1,.87,1.012l-1.141,1.125a3.679,3.679,0,0,0-.674-.832,2.771,2.771,0,0,0-2.805-.5,1.618,1.618,0,0,0-.683.5,1.21,1.21,0,0,0-.248.758,1.37,1.37,0,0,0,.12.577,1.3,1.3,0,0,0,.368.465,2.361,2.361,0,0,0,.667.36,6.955,6.955,0,0,0,1.005.278,8.123,8.123,0,0,1,1.471.457,4.44,4.44,0,0,1,1.095.653,2.61,2.61,0,0,1,.682.84,2.21,2.21,0,0,1,.233,1.005,3.186,3.186,0,0,1-.465,1.74,3.041,3.041,0,0,1-1.32,1.133A4.824,4.824,0,0,1,126.434,80.786Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M137.916,80.786a5.127,5.127,0,0,1-2.048-.405,4.989,4.989,0,0,1-1.635-1.133,5.137,5.137,0,0,1-1.08-1.71,6.255,6.255,0,0,1,0-4.3,5.147,5.147,0,0,1,1.08-1.71,4.957,4.957,0,0,1,1.635-1.132,5.366,5.366,0,0,1,4.095,0A4.932,4.932,0,0,1,141.6,71.53a5.261,5.261,0,0,1,1.08,1.718,6.171,6.171,0,0,1,0,4.275,5.24,5.24,0,0,1-1.08,1.718,4.964,4.964,0,0,1-1.636,1.14A5.126,5.126,0,0,1,137.916,80.786Zm0-1.575a3.392,3.392,0,0,0,1.4-.285,3.261,3.261,0,0,0,1.1-.8,3.8,3.8,0,0,0,.727-1.215,4.538,4.538,0,0,0,0-3.045,3.8,3.8,0,0,0-.727-1.215,3.245,3.245,0,0,0-1.1-.8,3.432,3.432,0,0,0-3.915.8,3.532,3.532,0,0,0-.727,1.207,4.738,4.738,0,0,0,0,3.067,3.525,3.525,0,0,0,.727,1.216,3.35,3.35,0,0,0,2.513,1.08Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M146.272,80.636v-10.5h1.621v10.5Zm.721-8.941V70.136h6.09v1.559Zm0,4.47v-1.56h5.415v1.56Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
          <path
            d="M158.5,80.636V71.695h-2.939V70.136h7.589v1.559h-3.03v8.941Z"
            transform="translate(-12.84 -10.928)"
            fill="#706f6f"
          />
        </>
      )}
    </SvgIcon>
  );
}

