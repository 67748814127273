import DialogTitle from "./DialogTitle";
export default DialogTitle;
export type { DialogTitleProps } from "./DialogTitle";
export { default as useDialog, useRouteDialog } from "./useDialog";
export type {
  DialogParams,
  UseDialogProps,
  UseDialogReturnProps,
  UseRouteDialogProps,
} from "./useDialog";

