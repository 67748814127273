import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION_TYPES } from "app/constants";
import { RootState } from "app/store";
import {
  loadNotificationCount,
  loadNotifications,
  NotificationCount,
  NotificationItem,
  readAllNotification,
  readNotification,
} from "./notificationsAPI";

export interface NotificationsState {
  data: NotificationItem[];
  status: "idle" | "loading" | "failed";
  total: number;
  page: number;
  count: {
    data: NotificationCount;
    status: "idle" | "loading" | "failed";
  };
}

const initialState: NotificationsState = {
  data: [],
  status: "idle",
  total: 0,
  page: 1,
  count: {
    data: {} as NotificationCount,
    status: "idle",
  },
};

export type NotificationsQuery = {
  types: NOTIFICATION_TYPES[];
  page?: number;
};

export const loadNotificationsAsync = createAsyncThunk(
  "notifications/load",
  async (query: NotificationsQuery, { rejectWithValue }) => {
    const { data, error, success } = await loadNotifications(
      query?.types || [],
      query?.page || 1
    );

    if (success) {
      return { page: query?.page || 1, data: data.data, total: data.total };
    }
    return rejectWithValue(error);
  }
);

export const loadNotificationCountAsync = createAsyncThunk(
  "notifications/count",
  async (_, { rejectWithValue }) => {
    const { data, error, success } = await loadNotificationCount();

    if (success) {
      return data.data;
    }
    return rejectWithValue(error);
  }
);

export const readNotificationsAsync = createAsyncThunk(
  "notifications/update",
  async (id: string, { rejectWithValue }) => {
    const { data, error, success } = await readNotification(id);
    if (success) {
      return id;
    }
    return rejectWithValue(error);
  }
);
export const readAllNotificationsAsync = createAsyncThunk(
  "notifications/readAll",
  async (_, { rejectWithValue }) => {
    const { data, error, success } = await readAllNotification();
    if (success) {
      return true;
    }
    return rejectWithValue(error);
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotifications: state => {
      state.data = [];
    },
    addNotification: (state, action) => {
      const item = state.data.find(n => n.id === action.payload.id);
      if (!item) {
        state.data.push(action.payload);
      }
    },
    resetStatus: state => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    //Load notifications
    builder
      .addCase(loadNotificationsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(loadNotificationsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.total = action.payload.total;
        if (action.payload.page === 1) {
          state.data = action.payload.data;
        } else {
          if (!state.data) {
            state.data = [];
          }
          state.data = state.data.concat(action.payload.data);
        }
      });
    //Load notificationcount
    builder
      .addCase(loadNotificationCountAsync.pending, state => {
        if (!state.count) {
          state.count = {
            data: {} as NotificationCount,
            status: "idle",
          };
        }
        state.count.status = "loading";
      })
      .addCase(loadNotificationCountAsync.fulfilled, (state, action) => {
        if (!state.count) {
          state.count = {
            data: {} as NotificationCount,
            status: "idle",
          };
        }
        state.count.status = "idle";
        state.count.data = action.payload;
      });
    //Update notifications
    builder
      .addCase(readNotificationsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(readNotificationsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let item = state.data.find(n => n.id === action.payload);
        if (item) {
          item.seen = true;
        }
      });
    //read all notifications
    builder
      .addCase(readAllNotificationsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(readAllNotificationsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.forEach(notification => {
          notification.seen = true;
        });
      });
  },
});

export const { clearNotifications, resetStatus, addNotification } =
  notificationsSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.notifications.data;
export const selectNotificationsStatus = (state: RootState) =>
  state.notifications.status;
export const selectNotificationsTotal = (state: RootState) =>
  state.notifications.total;
export const selectNotificationCount = (state: RootState) =>
  state.notifications.count
    ? state.notifications.count.data
    : ({} as NotificationCount);
export const selectNotificationcountStatus = (state: RootState) =>
  state.notifications.count.status;

export default notificationsSlice.reducer;

