import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ReorderIcon from "@mui/icons-material/Reorder";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { DataGridBodyProps } from "./DataGridBody";
import { GridCellParams } from "./types";

export interface DataGridRowProps<T extends { [key: string]: any } = any>
  extends DataGridBodyProps {
  item: T;
  index: number;
  refresh: () => void;
}

export default function DataGridRow<T extends { [key: string]: any } = any>({
  state,
  columns,
  hasAction,
  canBatchActions,
  canView,
  canEdit,
  canEditRow,
  canDeleteRow,
  canDelete,
  handleToggleSelectRow,
  onEditClick,
  editUrl,
  getCellValue,
  rowOrderProps,
  item,
  index,
  canOrderRows,
  deleteItem,
  restoreItem,
  rowSx,
  rowActions,
  refresh,
}: DataGridRowProps<T>) {
  const theme = useTheme();
  //Sorting start
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  //Sorting end

  function renderCell({
    id,
    value,
    formattedValue,
    row,
    column,
    onEditClick,
    index,
  }: GridCellParams<T>) {
    if (column.type === "boolean") {
      return value ? (
        <CheckIcon sx={{ color: theme => theme.palette.success.main }} />
      ) : (
        <CloseIcon sx={{ color: theme => theme.palette.error.main }} />
      );
    }
    if (column.type === "boolean3") {
      return value === null ? (
        <CloseIcon sx={{ color: theme => theme.palette.action.disabled }} />
      ) : value ? (
        <CheckIcon sx={{ color: theme => theme.palette.success.main }} />
      ) : (
        <CloseIcon sx={{ color: theme => theme.palette.error.main }} />
      );
    }
    if (column.type === "color") {
      return (
        <Box
          sx={{ borderRadius: theme.shape.borderRadius }}
          style={{ backgroundColor: formattedValue }}
        >
          <Typography
            align="center"
            color={theme.palette.getContrastText(
              value || theme.palette.text.primary
            )}
          >
            {value}
          </Typography>
        </Box>
      );
    }
    return value ? String(value) : "";
  }

  let _canEdit = false;
  if (canEdit) {
    _canEdit = true;
  }
  if (canEditRow && !canEditRow({ row: item, id: item.id } as any)) {
    _canEdit = false;
  }

  return (
    <TableRow
      key={item?.id}
      ref={setNodeRef}
      style={style}
      sx={rowSx ? rowSx(item) : undefined}
    >
      {canOrderRows && (
        <TableCell
          //padding="checkbox"
          sx={{
            //padding: "0px!important",
            paddingBottom: "0px!important",
            paddingRight: "0px!important",
            cursor: "move",
          }}
          {...attributes}
          {...listeners}
        >
          <ReorderIcon />
        </TableCell>
      )}
      {canBatchActions && (
        <TableCell
          padding="checkbox"
          sx={{
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
          }}
        >
          <Checkbox
            color="primary"
            sx={{ padding: 1 }}
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={state.selectedIds.includes(item.id)}
            //onChange={onSelectAllClick}
            onClick={evt => handleToggleSelectRow(evt, item.id)}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
      )}
      {columns
        .filter(c => c.hide !== true && !state.hidden_columns.includes(c.field))
        .map(column => {
          let value: any = getCellValue
            ? getCellValue(column, item)
            : get(item, column.field);

          return (
            <TableCell
              key={column.field}
              sx={{
                maxWidth: "1px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {column.renderCell
                ? column.renderCell({
                    onEditClick,
                    id: item.id,
                    value,
                    formattedValue: column.valueFormatter
                      ? column.valueFormatter({
                          id: item.id,
                          value,
                          row: item,
                          column,
                          index,
                        })
                      : value,
                    row: item,
                    column,
                    index,
                  })
                : renderCell({
                    onEditClick,
                    id: item.id,
                    value,
                    formattedValue: column.valueFormatter
                      ? column.valueFormatter({
                          id: item.id,
                          value,
                          row: item,
                          column,
                          index,
                        })
                      : value,
                    row: item,
                    column,
                    index,
                  })}
            </TableCell>
          );
        })}
      {hasAction && (
        <TableCell padding="none" width={100}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: "flex-end", paddingRight: 1 }}
          >
            {!!rowActions &&
              rowActions(
                { row: item, id: item.id, columns } as any,
                (id: any, item: T) => onEditClick && onEditClick(id, item),
                refresh
              )}
            {!state.trash && (
              <>
                {_canEdit ? (
                  editUrl ? (
                    <IconButton
                      size="small"
                      //@ts-ignore
                      component={Link}
                      to={editUrl.replace(":id", item.id as string)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => onEditClick && onEditClick(item.id, item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )
                ) : (
                  canView &&
                  (editUrl ? (
                    <IconButton
                      size="small"
                      //@ts-ignore
                      component={Link}
                      to={editUrl.replace(":id", item.id as string)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => onEditClick && onEditClick(item.id, item)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  ))
                )}
                {canDelete && (
                  <IconButton
                    size="small"
                    onClick={() => deleteItem && deleteItem(item)}
                    disabled={
                      canDeleteRow
                        ? !canDeleteRow({ row: item, id: item.id } as any)
                        : undefined
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            )}
            {state.trash && canDelete && (
              <>
                <Tooltip title="Visszaállítás">
                  <IconButton
                    size="small"
                    onClick={() => restoreItem && restoreItem(item)}
                  >
                    <RestoreFromTrashIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Végleges törlés">
                  <IconButton
                    size="small"
                    onClick={() => deleteItem && deleteItem(item)}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
}
