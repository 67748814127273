import { SxProps, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const inputBaseSx: SxProps<Theme> = {
  "& td": {
    paddingRight: "1px!important",
    "&>.MuiInputBase-root": {
      minHeight: "42px",
    },
  },
  "& .MuiFormControl-root": {
    padding: "0px",
    "&.error .MuiInputBase-root.MuiFilledInput-root": {
      backgroundColor: (theme: Theme) =>
        `${alpha(theme.palette.error.light, 0.1)}!important`,
    },
  },
  "& .MuiInputBase-root, & .MuiDatePicker-root, & .MuiSelect-root, & .MuiInputBase-root.MuiFilledInput-root":
    {
      "& fieldset": {
        display: "none",
      },
      //display: "block",
      width: "100%",
      //height: "100%",
      padding: "4px",
      border: "1px solid transparent!important",
      borderRadius: "4px",
      transition: (theme: Theme) =>
        theme.transitions.create(
          ["border-color", "box-shadow", "background-color"],
          {
            duration: theme.transitions.duration.short,
          }
        ),

      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.primary.light, 0.1),
        border: "1px solid transparent",
      },
      "&.error": {
        backgroundColor: (theme: Theme) =>
          `${alpha(theme.palette.error.light, 0.1)}!important`,
        border: "1px solid transparent",
      },
      "&:focus-within": {
        borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.5),
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.primary.light, 0.15),
        boxShadow: (theme: Theme) =>
          `inset 0 0 0 1px ${alpha(theme.palette.primary.main, 0.3)}`,
      },
      "&.Mui-disabled": {
        backgroundColor: "action.disabledBackground",
        "&:hover": {
          backgroundColor: "action.disabledBackground",
        },
        "&:focus-within": {
          borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.5),
          backgroundColor: (theme: Theme) =>
            alpha(theme.palette.primary.light, 0.15),
          boxShadow: (theme: Theme) =>
            `inset 0 0 0 1px ${alpha(theme.palette.primary.main, 0.3)}`,
        },
      },
      "& .MuiInputBase-input, & textarea.MuiInputBase-input": {
        padding: "4px 4px",
        border: "none",
        outline: "none",
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        fontFamily: "inherit",
        fontSize: "inherit",
        color: "inherit",
        "&:focus": {
          outline: "none",
        },
      },
    },
  "& .MuiAutocomplete-root": {
    "& .MuiInputBase-root": {
      padding: "4px",
    },
    padding: "4px 0 !important",
    backgroundColor: "transparent",
    "& .MuiAutocomplete-popupIndicator": {
      color: (theme: Theme) => theme.palette.text.secondary,
    },
    "& .MuiAutocomplete-clearIndicator": {
      color: (theme: Theme) => theme.palette.text.secondary,
    },
    "& .MuiAutocomplete-listbox": {
      backgroundColor: (theme: Theme) => theme.palette.background.paper,
      color: (theme: Theme) => theme.palette.text.primary,
    },
    "& .MuiAutocomplete-option": {
      '&[data-focus="true"]': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.primary.light, 0.2),
      },
    },
  },
};

export default inputBaseSx;

