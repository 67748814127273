export enum ROLES {
  SUPERADMIN = "SuperAdmin",
  ADMIN = "Admin",
  REPORTING = "Reporting",
  COORDINATOR = "Coordinator",
  CUSTOMER_CARE = "CustomerCare",
  OTA = "OTA",
  STREET_SALES = "StreetSales",
  CATERING = "Catering",
  KITCHEN = "Kitchen",
  HEAD_OFFICE = "HeadOffice",
}

export enum NOTIFICATION_TYPES {
  SHIFT_CONFIRM = "SHIFT_CONFIRM",
  SHIFT_DECLINE = "SHIFT_DECLINE",
  SHIFT_PUBLISH = "SHIFT_PUBLISH",
  SHIFT_UPDATE = "SHIFT_UPDATE",
  SHIFT_REQUEST = "SHIFT_REQUEST",
  SHIFT_CANCEL = "SHIFT_CANCEL",
  MISC = "MISC",
}
