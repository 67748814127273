import CheckboxGroup, {
  CheckboxGroupProps,
} from "common/components/CheckboxGroup/CheckboxGroup";
import useDictionaries from "features/dictionaries/useDictionaries";

export interface BoatCheckboxSelectorProps
  extends Omit<CheckboxGroupProps, "options"> {}

export default function BoatCheckboxSelector(props: BoatCheckboxSelectorProps) {
  const { dictionaries } = useDictionaries();
  return <CheckboxGroup options={dictionaries.boats} {...props} />;
}
