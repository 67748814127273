import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import I18n from "i18n";
import { useState } from "react";

interface EditSaveButtonProps extends ButtonProps {
  /**
   * Function to call when the Save button is clicked.
   */
  onSave: () => void;

  /**
   * Optional function to call when the Cancel button is clicked.
   */
  onCancel?: () => void;

  /**
   * Optional controlled state for edit mode.
   */
  isEditMode?: boolean;

  /**
   * Optional callback to notify parent components about edit mode changes.
   */
  onEditModeChange?: (isEditMode: boolean) => void;

  /**
   * Custom props for the edit button.
   */
  editButtonProps?: ButtonProps;

  /**
   * Custom props for the cancel button.
   */
  cancelButtonProps?: ButtonProps;

  /**
   * Custom props for the save button.
   */
  saveButtonProps?: ButtonProps;

  /**
   * The type of animation to use.
   * Options: 'slide', 'fade', 'scale', 'rotate', 'flip', 'apple'.
   * Defaults to 'slide'.
   */
  animationType?: "slide" | "fade" | "scale" | "rotate" | "flip" | "apple";

  /**
   * Additional properties for the animation.
   */
  animationProps?: any;

  /**
   * If true, shows a loading indicator in the save button.
   */
  loading?: boolean;
}

/**
 * EditSaveButton Component
 *
 * A custom button component that toggles between edit mode and save/cancel buttons.
 * Supports multiple animations and customization options.
 *
 * @param {EditSaveButtonProps} props - The props for the EditSaveButton component.
 * @returns {JSX.Element} The rendered EditSaveButton component.
 *
 * @example
 * <EditSaveButton
 *   onSave={handleSave}
 *   onCancel={handleCancel}
 *   isEditMode={isEditMode}
 *   onEditModeChange={setIsEditMode}
 *   editButtonProps={{ variant: 'contained', color: 'primary' }}
 *   cancelButtonProps={{ variant: 'outlined', color: 'secondary' }}
 *   saveButtonProps={{ variant: 'contained', color: 'success' }}
 *   animationType="scale"
 *   animationProps={{ transition: { duration: 0.5 } }}
 *   loading={true}
 *   sx={{ mt: 2 }}
 * />
 */
const EditSaveButton: React.FC<EditSaveButtonProps> = ({
  onSave,
  onCancel,
  isEditMode: controlledIsEditMode,
  onEditModeChange,
  editButtonProps,
  cancelButtonProps,
  saveButtonProps,
  animationType = "slide",
  animationProps,
  loading = false,
  ...buttonProps
}) => {
  const [uncontrolledIsEditMode, setUncontrolledIsEditMode] = useState(false);
  const isEditMode =
    controlledIsEditMode !== undefined
      ? controlledIsEditMode
      : uncontrolledIsEditMode;

  const handleEditClick = () => {
    if (onEditModeChange) {
      onEditModeChange(true);
    } else {
      setUncontrolledIsEditMode(true);
    }
  };

  const handleSaveClick = () => {
    if (!controlledIsEditMode) {
      setUncontrolledIsEditMode(false);
    }
    onSave();
  };

  const handleCancelClick = () => {
    if (onEditModeChange) {
      onEditModeChange(false);
    } else {
      setUncontrolledIsEditMode(false);
    }
    if (onCancel) {
      onCancel();
    }
  };

  const animations = {
    slide: {
      initial: { opacity: 0, x: 50 },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: 50 },
    },
    fade: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    scale: {
      initial: { opacity: 0, scale: 0.8 },
      animate: { opacity: 1, scale: 1 },
      exit: { opacity: 0, scale: 0.8 },
    },
    rotate: {
      initial: { opacity: 0, rotate: 90 },
      animate: { opacity: 1, rotate: 0 },
      exit: { opacity: 0, rotate: 90 },
    },
    flip: {
      initial: { opacity: 0, rotateY: 90 },
      animate: { opacity: 1, rotateY: 0 },
      exit: { opacity: 0, rotateY: 90 },
    },
    apple: {
      initial: { opacity: 0, scale: 0.5, y: -50 },
      animate: { opacity: 1, scale: 1, y: 0 },
      exit: { opacity: 0, scale: 0.5, y: -50 },
    },
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {!isEditMode ? (
        <Button
          color="primary"
          variant="contained"
          {...buttonProps}
          startIcon={<EditIcon />}
          {...editButtonProps}
          onClick={handleEditClick}
        >
          {editButtonProps?.children || I18n.t("App.edit")}
        </Button>
      ) : (
        <motion.div
          initial={animations[animationType].initial}
          animate={animations[animationType].animate}
          exit={animations[animationType].exit}
          style={{ display: "flex", gap: "8px" }}
          {...animationProps}
        >
          <Button
            color="inherit"
            variant="outlined"
            {...buttonProps}
            startIcon={<CancelIcon />}
            {...cancelButtonProps}
            onClick={handleCancelClick}
          >
            {cancelButtonProps?.children || I18n.t("App.cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            {...buttonProps}
            startIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
            {...saveButtonProps}
            onClick={handleSaveClick}
          >
            {saveButtonProps?.children || I18n.t("App.save")}
          </Button>
        </motion.div>
      )}
    </div>
  );
};

export default EditSaveButton;

