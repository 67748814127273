import { createContext, useContext, useEffect, useState } from "react";
import { Location, useLocation } from "react-router-dom";

type NavigationState = {
  history: Location[];
  prevLocation?: Location;
  location: Location;
};

const Context = createContext<NavigationState>({
  history: [],
  location: { state: null, key: "start", ...window.location },
});
export interface NavigationContextProps {
  children: any;
}
export default function NavigationContext({
  children,
}: NavigationContextProps) {
  const location = useLocation();
  const [state, setState] = useState<NavigationState>({
    history: [],
    location,
  });
  //const state = useRef<NavigationState>({ history: [], location });

  useEffect(() => {
    /*state.current.history.push(location);
    state.current.prevLocation =
      state.current.history[state.current.history.length - 2];
    if (state.current.history.length > 5) {
      state.current.history.shift();
    }*/
    const current = { ...state };
    const loc = { ...location, ...window.location };
    current.history.push(loc);
    current.location = loc;
    current.prevLocation = current.history[current.history.length - 2];
    if (current.history.length > 5) {
      current.history.shift();
    }
    setState(current);
  }, [location]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export function useNavigationContext() {
  return useContext(Context);
}

