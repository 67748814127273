import { Dictionary } from "./dictionaryApi";

export function resolveOption<K extends keyof Dictionary>(
  dictionaries: Dictionary,
  key: K,
  id: any
): Dictionary[K] | undefined {
  if (!dictionaries) {
    return undefined;
  }
  return (dictionaries as any)[`__${key}_map`]?.[id] as Dictionary[K];
}

export function resolveOptions<K extends keyof Dictionary>(
  dictionaries: Dictionary,
  key: K,
  ids: any[]
): Dictionary[K][] | undefined {
  if (!dictionaries) {
    return undefined;
  }
  return ids?.map(
    id => (dictionaries as any)?.[`__${key}_map`]?.[id]
  ) as Dictionary[K][];
}

export function filterOptions<K extends keyof Dictionary>(
  dictionaries: Dictionary,
  key: K,
  predictate: (item: K) => K[]
): Dictionary[K][] | undefined {
  if (!dictionaries || !dictionaries[key]) {
    return undefined;
  }
  return (dictionaries[key] as any).filter(predictate);
}

