import ExpandMore from "@mui/icons-material/ExpandMore";
import { Menu, Tab, TabProps } from "@mui/material";
import { MouseEvent, cloneElement, useState } from "react";
import { Link } from "react-router-dom";

export interface NavigationTabProps extends Omit<TabProps, "component"> {
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
  //to?: To;
  children?: any;
  download?: string;
  absolute?: boolean;
}

export default function NavigationTab({
  reloadDocument,
  replace,
  state,
  children,
  download,
  absolute,
  sx,
  ...props
}: NavigationTabProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const key = props.title;

  if (children) {
    return (
      <>
        {/* @ts-ignore */}
        <Tab
          id={`tab-${key}`}
          aria-controls={open ? `manu-${key}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          iconPosition="end"
          sx={{ minHeight: "48px", ...sx }}
          icon={<ExpandMore />}
          {...props}
        />
        <Menu
          keepMounted
          id={`menu-${key}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {Array.isArray(children)
            ? children.map((child: any, index: number) => {
                return cloneElement(child, {
                  onClick: (evt: any) => {
                    handleClose();
                    child.props?.onClick && child.props?.onClick(evt);
                  },
                  key: index,
                  value: child.props.value
                    ? `${props.value}/${child.props.value}`
                    : child.props.value,
                });
              })
            : children
            ? cloneElement(children, {
                onClick: (evt: any) => {
                  handleClose();
                  children.props?.onClick && children.props?.onClick(evt);
                },
              })
            : null}
        </Menu>
      </>
    );
  }
  if (typeof props.value === "string") {
    return (
      //@ts-ignore
      <Tab
        reloadDocument={reloadDocument}
        replace={replace}
        state={state}
        to={absolute ? `/${props.value}` : props.value}
        component={Link}
        sx={sx}
        {...props}
      />
    );
  }
  if (download) {
    return (
      //@ts-ignore
      <Tab component="a" href={download} download sx={sx} {...props} />
    );
  }
  return <Tab sx={sx} {...props} />;
}

