import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import {
  ScheduleEvent,
  ScheduleLimit,
  ScheduleOption,
  ScheduleVariant,
} from "features/schedules/scheduleApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import I18n from "i18n";

export function validateEvents(
  events: ScheduleEvent[],
  duration: number
): string | null {
  let errors: string[] = [];

  events?.forEach(event => {
    if (!event.days || event.days.length === 0) {
      errors.push(I18n.t("Event.daysRequired"));
    }
    if (!event.start_times || event.start_times.length === 0) {
      errors.push(I18n.t("Event.startTimesRequired"));
    }
    event.start_times?.forEach(time => {
      if (!/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(time)) {
        errors.push(I18n.t("Event.invalidTimeFormat", { time }));
      }
    });

    const sortedTimes = event.start_times?.slice().sort() || [];
    for (let i = 0; i < sortedTimes.length - 1; i++) {
      const startTime = sortedTimes[i];
      const nextTime = sortedTimes[i + 1];
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      const [nextHours, nextMinutes] = nextTime.split(":").map(Number);
      const startTimeInMinutes = startHours * 60 + startMinutes;
      const nextTimeInMinutes = nextHours * 60 + nextMinutes;
      const timeDifference = nextTimeInMinutes - startTimeInMinutes;

      if (timeDifference < duration) {
        errors.push(
          I18n.t("Event.timesTooClose", { startTime, nextTime, duration })
        );
      }
    }
  });

  return errors.length > 0 ? errors.join(", ") : null;
}

export function validateLimits(
  limits?: ScheduleLimit[],
  programs?: ProgramItem[],
  programVariants?: ProgramVariantItem[]
): string | null {
  let errors: string[] = [];

  const boatLimits =
    limits?.filter(limit => limit.type === LIMIT_TYPES.BOAT) || [];
  if (boatLimits.length !== 1) {
    errors.push(I18n.t("Event.limits.singleBoatLimitRequired"));
  }

  const boatLimit = boatLimits[0];
  const variantIds = programVariants?.map(variant => variant.id);
  if (
    boatLimit &&
    !variantIds?.every(id => boatLimit.variant_ids.includes(id))
  ) {
    errors.push(I18n.t("Event.limits.boatLimitVariantIdsMismatch"));
  }

  if (programs && programs?.length > 1) {
    programs?.forEach(program => {
      const programLimits = limits?.filter(
        limit =>
          limit.type === LIMIT_TYPES.PROGRAM && limit.program_id === program.id
      );
      if (programLimits?.length !== 1) {
        errors.push(
          I18n.t("Event.limits.singleProgramLimitRequired", {
            program: program.name,
          })
        );
      }
      const programLimit = programLimits ? programLimits[0] : undefined;
      const programVariantIds = programVariants
        ?.filter(variant => variant.program_id === program.id)
        .map(variant => variant.id);
      if (
        programLimit &&
        !programVariantIds?.every(id => programLimit.variant_ids.includes(id))
      ) {
        errors.push(
          I18n.t("Event.limits.programLimitVariantIdsMismatch", {
            program: program.name,
          })
        );
      }
    });
  }

  limits?.forEach(limit => {
    if (!limit.variant_ids || limit.variant_ids.length === 0) {
      errors.push(I18n.t("Event.limits.variantIdsRequired"));
    }
    if ((limit.number_of_places || 0) <= 0) {
      errors.push(I18n.t("Event.limits.numberOfPlacesRequired"));
    }
    if ((limit.number_of_online_places || 0) <= 0) {
      errors.push(I18n.t("Event.limits.numberOfOnlinePlacesRequired"));
    }
    if ((limit.places_tolerance || 0) <= 0) {
      errors.push(I18n.t("Event.limits.placesToleranceRequired"));
    }
    if (limit.type === LIMIT_TYPES.CUSTOM && !limit.name) {
      errors.push(I18n.t("Event.limits.nameRequiredForCustom"));
    }
    if (limit.type === LIMIT_TYPES.PROGRAM && !limit.program_id) {
      errors.push(I18n.t("Event.limits.programIdRequiredForProgram"));
    }
    if (!limit.type) {
      errors.push(I18n.t("Event.limits.typeRequired"));
    }
  });

  return errors.length > 0 ? errors.join(", ") : null;
}

export function validateVariants(variants: any[]): string | null {
  let errors: string[] = [];

  variants?.forEach(variant => {
    if ((variant.variant_id || 0) <= 0) {
      errors.push(I18n.t("Event.variants.variantIdRequired"));
    }
    if ((variant.adult_price || 0) <= 0) {
      errors.push(I18n.t("Event.variants.priceAdultRequired"));
    }
    if ((variant.kid_price ?? 0) < 0) {
      errors.push(I18n.t("Event.variants.priceKidRequired"));
    }
  });

  return errors.length > 0 ? errors.join(", ") : null;
}

export function validateOptions(
  options: ScheduleOption[],
  hasOptions?: boolean
): string | null {
  let errors: string[] = [];
  if (!hasOptions) {
    return null;
  }
  options?.forEach(option => {
    if ((option.option_id || 0) <= 0) {
      errors.push(I18n.t("Event.options.optionIdRequired"));
    }
    /* if ((option.price || 0) <= 0) {
      errors.push(I18n.t("Event.options.priceRequired"));
    }*/
  });

  return errors.length > 0 ? errors.join(", ") : null;
}

export function validateAll(
  events: ScheduleEvent[],
  duration: number,
  limits: ScheduleLimit[],
  variants: ScheduleVariant[],
  options: ScheduleOption[],
  programs: ProgramItem[],
  programVariants: ProgramVariantItem[],
  hasOptions: boolean
): Record<string, string | null> {
  const eventsError = validateEvents(events, duration);
  const limitsError = validateLimits(limits, programs, programVariants);
  const variantsError = validateVariants(variants);
  const optionsError = validateOptions(options, hasOptions);

  return {
    events: eventsError,
    limits: limitsError,
    variants: variantsError,
    options: optionsError,
  };
}
