import api from "app/api";
import settings from "app/settings";

export interface LoginForm {
  email: string;
  password: string;
}

export function login(item: LoginForm) {
  return api.post(settings.loginUrl, item, { public: true });
}

export function refresh() {
  return api.post("/auth/refresh");
}

export function logout() {
  return api.post("/auth/logout");
}

