import { DialogContent } from "@mui/material";
import {
  Autocomplete,
  DatePicker,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import I18n from "i18n";
import useHistoryForm, { UseHistoryFormProps } from "./useHistoryForm";
export interface HistoryFormProps extends UseHistoryFormProps {}

export default function HistoryForm({
  id,
  afterSave,
  onClose,
}: HistoryFormProps) {
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    requiredFields,
    onChange,
    save,
    saving,
  } = useHistoryForm({
    id,
    afterSave,
  });
  const title = I18n.t("History.title");
  /*isCreate
    ? I18n.t("History.addTitle")
    : I18n.t("History.editTitle");*/
  return (
    <>
      <PageHeader title={title} onClose={onClose} />
      <DialogContent>
        <Form
          loading={loading}
          item={item}
          options={options}
          errors={errors}
          onChange={onChange}
          translationCategory="History.attributes"
          isDirty={getIsDirty}
          requiredFields={requiredFields}
          disabled
        >
          <FieldsContainer>
            <Field fullWidth>
              <TextField id="class_name" />
            </Field>
            <Field fullWidth>
              <TextField id="item_id" />
            </Field>
            <Field fullWidth>
              <TextField
                id="data"
                multiline
                value={JSON.stringify(item.data, null, 2)}
              />
            </Field>
            {!params?.user_id && (
              <Field fullWidth>
                <Autocomplete id="user_id" />
              </Field>
            )}
            <Field fullWidth>
              <DatePicker id="created_at" />
            </Field>
          </FieldsContainer>
        </Form>
      </DialogContent>
    </>
  );
}

