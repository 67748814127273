import BoatForm from "features/boats/BoatForm";
import BoatList from "features/boats/BoatList";
import BookingOptionForm from "features/booking-options/BookingOptionForm";
import BookingOptionList from "features/booking-options/BookingOptionList";
import BookingForm from "features/bookings/BookingForm";
import BookingList from "features/bookings/BookingList";
import CateringBookingList from "features/catering/CateringBookingList";
import CateringPage from "features/catering/CateringPage";
import EventForm from "features/events/EventForm";
import EventList from "features/events/EventList";
import ExchangeRateForm from "features/exchange-rates/ExchangeRateForm";
import ExchangeRateList from "features/exchange-rates/ExchangeRateList";
import FileList from "features/files/FileList";
import HistoryList from "features/histories/HistoryList";
import KitchenPage from "features/kitchen/KitchenPage";
import OptionGroupForm from "features/option-groups/OptionGroupForm";
import OptionGroupList from "features/option-groups/OptionGroupList";
import OptionForm from "features/options/OptionForm";
import OptionList from "features/options/OptionList";
import PartnerGroupForm from "features/partner-groups/PartnerGroupForm";
import PartnerGroupList from "features/partner-groups/PartnerGroupList";
import PartnerForm from "features/partners/PartnerForm";
import PartnerList from "features/partners/PartnerList";
import PermissionForm from "features/permissions/PermissionForm";
import ProfileForm from "features/profile/ProfileForm";
import useAccess from "features/profile/useAccess";
import ProgramVariantForm from "features/program-variants/ProgramVariantForm";
import ProgramVariantList from "features/program-variants/ProgramVariantList";
import ProgramForm from "features/programs/ProgramForm";
import ProgramList from "features/programs/ProgramList";
import RoleForm from "features/roles/RoleForm";
import RoleList from "features/roles/RoleList";
import RoleTabs from "features/roles/RoleTabs";
import ScheduleForm from "features/schedules/ScheduleForm";
import ScheduleList from "features/schedules/ScheduleList";
import UserForm from "features/users/UserForm";
import UserList from "features/users/UserList";
import UserTabs from "features/users/UserTabs";
import AdminLayout from "layouts/Admin/AdminLayout";
import Home from "pages/home/Home";
import PageNotFound from "pages/page-not-found/PageNotFound";
import { Navigate, Route, Routes } from "react-router-dom";

export default function AdminRouter() {
  const { hasRoles } = useAccess();
  return (
    <Routes>
      <Route path="/login" element={<Navigate to="/" />} />
      <Route path="/" element={<AdminLayout />}>
        <Route path="" element={<Home />} />
        <Route path="kitchen" element={<KitchenPage />} />
        <Route path="catering" element={<CateringPage />}>
          <Route path=":start/:boat_id" element={<CateringBookingList />} />
        </Route>
        {/*CREATOR_HANDLED_CONTENT_START*/}
        <Route path="users">
          <Route path="" element={<UserList />} />
          <Route path=":user_id" element={<UserTabs />}>
            <Route path="permissions" element={<PermissionForm />} />
            <Route path="" element={<UserForm />} />
          </Route>
        </Route>
        <Route path="partner-groups">
          <Route path="" element={<PartnerGroupList />} />
          <Route path=":partner_group_id">
            <Route path="partners">
              <Route path="" element={<PartnerList showHeader={false} />} />
              <Route path=":partner_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<PartnerForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<PartnerGroupForm />} />
          </Route>
        </Route>
        <Route path="partners">
          <Route path="" element={<PartnerList />} />
          <Route path=":partner_id">
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<PartnerForm />} />
          </Route>
        </Route>
        <Route path="programs">
          <Route path="" element={<ProgramList />} />
          <Route path=":program_id">
            <Route path="program-variants">
              <Route
                path=""
                element={<ProgramVariantList showHeader={false} />}
              />
              <Route path=":program_variant_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<ProgramVariantForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>
            <Route path="options">
              <Route path="" element={<OptionList showHeader={false} />} />
              <Route path=":option_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<OptionForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>
            <Route path="events">
              <Route path="" element={<EventList showHeader={false} />} />
              <Route path=":event_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<EventForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<ProgramForm />} />
          </Route>
        </Route>
        <Route path="program-variants">
          <Route path="" element={<ProgramVariantList />} />
          <Route path=":program_variant_id">
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<ProgramVariantForm />} />
          </Route>
        </Route>
        <Route path="option-groups">
          <Route path="" element={<OptionGroupList />} />
          <Route path=":option_group_id">
            <Route path="options">
              <Route path="" element={<OptionList showHeader={false} />} />
              <Route path=":option_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<OptionForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<OptionGroupForm />} />
          </Route>
        </Route>
        <Route path="options">
          <Route path="" element={<OptionList />} />
          <Route path=":option_id">
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<OptionForm />} />
          </Route>
        </Route>
        <Route path="boats">
          <Route path="" element={<BoatList />} />
          <Route path=":boat_id">
            <Route path="schedules">
              <Route path="" element={<ScheduleList showHeader={false} />} />
              <Route path=":schedule_id">
                <Route path="" element={<ScheduleForm />} />
              </Route>
            </Route>
            <Route path="events">
              <Route path="" element={<EventList showHeader={false} />} />
              <Route path=":event_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<EventForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>
            <Route path="events">
              <Route path="" element={<EventList showHeader={false} />} />
              <Route path=":event_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<EventForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<BoatForm />} />
          </Route>
        </Route>
        <Route path="schedules">
          <Route path="" element={<ScheduleList />} />
          <Route path=":schedule_id">
            <Route path="events">
              <Route path="" element={<EventList showHeader={false} />} />
              <Route path=":event_id">
                <Route path="bookings">
                  <Route path="" element={<BookingList showHeader={false} />} />
                  <Route path=":booking_id">
                    <Route path="booking-options">
                      <Route
                        path=""
                        element={<BookingOptionList showHeader={false} />}
                      />
                      <Route
                        path=":booking_option_id"
                        element={<BookingOptionForm />}
                      />
                    </Route>

                    <Route path="" element={<BookingForm />} />
                  </Route>
                </Route>
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<EventForm />} />
              </Route>
            </Route>
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<ScheduleForm />} />
          </Route>
        </Route>
        <Route path="events">
          <Route path="" element={<EventList />} />
          <Route path=":event_id">
            <Route path="bookings">
              <Route path="" element={<BookingList showHeader={false} />} />
              <Route path=":booking_id">
                <Route path="booking-options">
                  <Route
                    path=""
                    element={<BookingOptionList showHeader={false} />}
                  />
                  <Route
                    path=":booking_option_id"
                    element={<BookingOptionForm />}
                  />
                </Route>

                <Route path="" element={<BookingForm />} />
              </Route>
            </Route>
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<EventForm />} />
          </Route>
        </Route>
        <Route path="bookings">
          <Route path="" element={<BookingList />} />
          <Route path=":booking_id">
            <Route path="booking-options">
              <Route
                path=""
                element={<BookingOptionList showHeader={false} />}
              />
              <Route
                path=":booking_option_id"
                element={<BookingOptionForm />}
              />
            </Route>

            <Route path="" element={<BookingForm />} />
          </Route>
        </Route>
        <Route path="booking-options">
          <Route path="" element={<BookingOptionList />} />
          <Route path=":booking_option_id" element={<BookingOptionForm />} />
        </Route>
        <Route path="exchange-rates">
          <Route path="" element={<ExchangeRateList />} />
          <Route path=":exchange_rate_id" element={<ExchangeRateForm />} />
        </Route>
        {/*CREATOR_HANDLED_CONTENT_END*/}
        <Route path="history" element={<HistoryList />} />
        <Route path="roles">
          <Route path="" element={<RoleList />} />
          <Route path=":role_id" element={<RoleTabs />}>
            <Route path="" element={<RoleForm />} />
            <Route path="permissions" element={<PermissionForm />} />
          </Route>
        </Route>
        <Route path="files" element={<FileList />} />
        <Route path="profile" element={<ProfileForm />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}
