import {
  Option,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { Dictionary } from "features/dictionaries/dictionaryApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { OptionItem } from "features/options/optionApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { deepClone } from "helpers/cloneHelper";
import { generateTimeOptions } from "helpers/dateHelper";
import { validateAll } from "helpers/validationHelper";
import I18n from "i18n";
import { omit } from "lodash";
import { useEffect, useMemo } from "react";
import { Params, useNavigate } from "react-router-dom";
import { ScheduleItem, getScheduleService } from "./scheduleApi";
import { LIMIT_TYPES } from "./scheduleConstants";

export type RouteParams = {
  schedule_id?: string;
};

export interface UseScheduleFormReturnType
  extends Omit<UseFormReturnType<ScheduleItem>, "options"> {
  params?: Readonly<
    Params<
      | "schedule_id"
      | "boat_id"
      | "schedule_option_id"
      | "event_id"
      | "booking_id"
      | "booking_option_id"
    >
  >;
  times: string[];
  programs: Option[];
  days: Option[];
  type: Option[];
  disabledFields: string[];
  hasOptions: boolean;
  options: Dictionary;
  clone: () => void;
}

export interface UseScheduleFormProps {
  defaultValues?: Partial<ScheduleItem>;
}

export enum SCHEDULETIMING_DAYS {
  DAYS_1 = "1",
  DAYS_2 = "2",
  DAYS_3 = "3",
  DAYS_4 = "4",
  DAYS_5 = "5",
  DAYS_6 = "6",
  DAYS_7 = "7",
}
let __SCHEDULE_TIMING_TO_CLONE: any = null;

export default function useScheduleForm({
  defaultValues,
}: UseScheduleFormProps): UseScheduleFormReturnType {
  const navigate = useNavigate();
  const params = useParams<
    | "schedule_id"
    | "boat_id"
    | "schedule_option_id"
    | "event_id"
    | "booking_id"
    | "booking_option_id"
  >();
  const scope = { boats: params.boat_id };
  const service = getScheduleService(scope);
  const { dictionaries, resolveOptions, filterOptions } = useDictionaries();

  const form = useForm<ScheduleItem>({
    id: params.schedule_id,
    translationCategory: "Schedule",
    defaultValues,
    rules: {
      name: Validator.rule.string().required(),
      boat_id: params.boat_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      duration: Validator.rule.any().required(),
      date_from: Validator.rule.any().required(),
      date_to: Validator.rule.any().required(),
      program_ids: Validator.rule.any().required(),
      events: Validator.rule.any().required(),
      limits: Validator.rule.any().required(),
      variants: Validator.rule.any().required(),
    },
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
    customValidation(newItem) {
      return customValidation(newItem);
    },
  });

  const { options, programs, hasOptions, times, days, type } = useMemo(() => {
    const programs = resolveOptions("programs", form.item.program_ids);
    return {
      options: {
        boat_id: dictionaries.boats,
        program_ids: dictionaries.programs,
        variants: filterOptions<ProgramVariantItem>("variants", item =>
          form.item?.program_ids?.includes(item.program_id)
        ),
        options: filterOptions<OptionItem>("options", item =>
          form.item?.program_ids?.includes(item.program_id)
        ),
      } as any as Dictionary,
      times: generateTimeOptions(15),
      programs,
      hasOptions: !!programs?.find(p => p.has_options),
      days: enumToOptions(SCHEDULETIMING_DAYS, "options.days"),
      type: enumToOptions(LIMIT_TYPES, "Limit.options.type"),
    };
  }, [form.item]);

  function customValidation(newItem: ScheduleItem) {
    const customErrors = validateAll(
      newItem.events,
      newItem.duration,
      newItem.limits,
      newItem.variants,
      newItem.options,
      programs,
      options?.variants,
      hasOptions
    );

    let errors: any = {};
    if (customErrors.events) errors.events = customErrors.events;
    if (customErrors.limits) errors.limits = customErrors.limits;
    if (customErrors.variants) errors.variants = customErrors.variants;
    if (customErrors.options) errors.options = customErrors.options;

    return Object.keys(errors).length > 0 ? errors : undefined;
  }

  let rules: any = {};

  if (hasOptions) {
    rules.options = Validator.rule.any().required();
  }
  form.addRules(rules);

  function clone() {
    __SCHEDULE_TIMING_TO_CLONE = deepClone(form.item);
    navigate("../create");
  }

  useEffect(() => {
    if (__SCHEDULE_TIMING_TO_CLONE) {
      if (form.isCreate) {
        const newItem: any = deepClone(
          omit(__SCHEDULE_TIMING_TO_CLONE, [
            "id",
            "is_modified",
            "has_event",
            "event",
            "created_by_name",
            "created_by",
            "created_at",
            "updated_by_name",
            "updated_by",
            "updated_at",
          ])
        );
        form.setAttributes(newItem);
      }
      __SCHEDULE_TIMING_TO_CLONE = null;
    }
  }, []);

  let disabledFields = [];
  if (form.item.has_event) {
    disabledFields.push("date_from");
    disabledFields.push("date_to");
    disabledFields.push("program_ids");
    disabledFields.push("events");
  }
  if (
    !form.item.boat_id ||
    !form.item.program_ids ||
    form.item.program_ids.length === 0
  ) {
    disabledFields.push("variants");
    disabledFields.push("limits");
  }

  return {
    ...form,
    options,
    params,
    days,
    type,
    times,
    hasOptions,
    programs,
    clone,
    disabledFields,
  };
}
