import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Fragment, ReactNode } from "react";
import Toolbar from "./../Toolbar";
import PageHeader, { PageHeaderProps } from "./PageHeader";

export interface PageHeaderBreadcrumbsProps extends PageHeaderProps {
  parents?: ReactNode[];
}
export default function PageHeaderBreadcrumbs({
  parents,
  ...props
}: PageHeaderBreadcrumbsProps) {
  if (!parents || parents.length === 0) {
    return <PageHeader {...props} />;
  }

  return (
    <Toolbar style={{ flexWrap: "wrap" }}>
      {parents?.map((parent: any, index: number) => {
        return (
          <Fragment key={index}>
            {parent}
            <Toolbar sx={{ padding: 1 }}>
              <DoubleArrowIcon />
            </Toolbar>
          </Fragment>
        );
      })}
      <PageHeader {...props} backUrl={parents ? undefined : props.backUrl} />
    </Toolbar>
  );
}

