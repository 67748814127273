import LightModeIcon from "@mui/icons-material/LightMode";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  alpha,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getMonogram,
  AvatarMenu as MuiXAvatarMenu,
  AvatarMenuProps as MuiXAvatarMenuProps,
  useDownloadUrl,
} from "common";
import { logoutAsync } from "features/identity/identitySlice";
import { selectNotificationCount } from "features/notifications/notificationsSlice";
import {
  selectProfile,
  selectProfileStatus,
} from "features/profile/profileSlice";
import ThemeModeSwitcher from "features/theme/ThemeModeSwitcher";
import I18n from "i18n";
import { Link } from "react-router-dom";

export interface AavatarMenuProps extends MuiXAvatarMenuProps {}
export default function AvatarMenu({ children, ...props }: AavatarMenuProps) {
  const profile = useAppSelector(selectProfile);
  const profileStatus = useAppSelector(selectProfileStatus);
  const notificationCount = useAppSelector(selectNotificationCount);
  const dispatch = useAppDispatch();
  const { downloadUrl } = useDownloadUrl();
  function handleLogout() {
    dispatch(logoutAsync());
  }
  const theme = useTheme();

  return (
    <MuiXAvatarMenu
      badgeContent={notificationCount.total}
      menuProps={{
        transformOrigin: { horizontal: "right", vertical: "top" },
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      }}
      avatarProps={{
        src: profile.file_id ? downloadUrl(profile.file_id) : undefined,
        style: {
          backgroundColor: alpha(theme.palette.primary.dark, 0.6),
          color: "white",
        },
      }}
      avatar={
        profileStatus === "loading" ? (
          <CircularProgress size={20} />
        ) : (
          getMonogram(profile)
        )
      }
      {...props}
    >
      <MenuItem to="/profile" component={Link}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("Profile.title")} />
      </MenuItem>
      <MenuItem to="/settings" component={Link}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("Settings.title")} />
      </MenuItem>
      {children}
      <Divider />
      <MenuItem onClick={() => handleLogout()}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={I18n.t("App.logout")} />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <LightModeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemSecondaryAction>
          <ThemeModeSwitcher size="small" />
        </ListItemSecondaryAction>
      </MenuItem>
      <Divider />
      <Typography
        variant="body2"
        align="center"
        sx={{ fontSize: 11, color: "#666666" }}
      >
        {I18n.t("App.version")} {process.env.REACT_APP_VERSION}
      </Typography>
    </MuiXAvatarMenu>
  );
}

