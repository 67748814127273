//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  UserItem,
  getUserService,
} from "./userApi";

export default function useUserList() {
  const params = useParams();
  const scope = undefined;
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "users/"
      : ""
  }`
  const service = getUserService(scope);

  return { ...service, params, scope, editUrl };
}

