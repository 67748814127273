import { useParams } from "react-router-dom";
import { getRoleService } from "./roleApi";

export default function useRoleList() {
  const params = useParams();
  const service = getRoleService();

  return { ...service, params };
}

