import {
  Box,
  BoxProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";
import { ColorIndicatorProps } from "../types";

export interface SwitchProps
  extends ColorIndicatorProps,
    Omit<
      MuiSwitchProps,
      "error" | "onChange" | "onBlur" | "value" | "checked"
    > {
  error?: string;
  helperText?: string;
  mask?: string;
  onChange?: Function;
  onBlur?: Function;
  onChangeByKeyPress?: Function;
  useDelayedKeystrokes?: boolean;
  unmask?: boolean;
  editable?: boolean;
  isDirty?: boolean;
  value?: boolean;
  label?: string;
  required?: boolean;
  numeric?: boolean;
  labelFirst?: boolean;
  fullWidth?: boolean;
  boxProps?: BoxProps;
}

export default function Switch({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  onChangeByKeyPress,
  editable,
  useDelayedKeystrokes = true,
  isDirty,
  label,
  numeric,
  indicatorColor,
  indicatorOpacity,
  labelFirst,
  fullWidth,
  sx,
  boxProps,
  ...props
}: SwitchProps) {
  if (labelFirst) {
    return (
      <Box
        {...boxProps}
        sx={
          fullWidth
            ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...boxProps?.sx,
              }
            : boxProps?.sx
        }
      >
        {label ? `${label}` : ""}
        <MuiSwitch
          checked={value ? true : false}
          onChange={(event, checked) => {
            const numericChecked: any = checked ? 1 : 0;
            onChange &&
              onChange({
                target: {
                  value: numeric
                    ? (numericChecked as never)
                    : (checked as never),
                },
              });
          }}
          sx={{
            marginLeft: fullWidth ? "auto" : undefined,
            ...sx,
          }}
          {...props}
        />
      </Box>
    );
  }
  return (
    <Box>
      <MuiSwitch
        checked={value ? true : false}
        onChange={(event, checked) => {
          const numericChecked: any = checked ? 1 : 0;
          onChange &&
            onChange({
              target: {
                value: numeric ? (numericChecked as never) : (checked as never),
              },
            });
        }}
        {...props}
      />
      {label ? ` ${label}` : ""}
    </Box>
  );
}

