import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  AvatarProps,
  Box,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@mui/material";
import I18n from "i18n";
import { ReactNode } from "react";
import Spacer from "../../Spacer";
import { UploadFileTypes, UploadIconButton } from "../../UploadButton";
import { previewFile } from "./../../FilePreview";
import Toolbar from "./../../Toolbar";
import useFilePicker, { UseFilePickerProps } from "./useFilePicker";
export type AvatarFileProps = {
  buttonSize?: IconButtonProps["size"];
  readOnly?: boolean;
  size?: number;
  fallBackComponent?: ReactNode;
  helperText?: string;
  error?: string;
  id?: any;
  isDirty?: boolean;
  required?: boolean;
  placeholder?: string;
  buttonsPlacement?: "right" | "bottom";
  showEditIconOverlay?: boolean;
  useObjectValue?: boolean;
} & Omit<AvatarProps, "onChange" | "component" | "children" | "src"> &
  Omit<UseFilePickerProps, "useObjectValue">;
export default function AvatarFile({
  upload,
  uri,
  value,
  onChange,
  buttonSize = "small",
  readOnly,
  size = 56,
  fallBackComponent,
  helperText,
  error,
  isDirty,
  required,
  placeholder,
  buttonsPlacement = "right",
  showEditIconOverlay,
  onClick,
  id,
  useObjectValue,
  validate,
  file: _file,
  ...avatarProps
}: AvatarFileProps) {
  const { file, downloadUrl, clearFile, onUploadButtonChange } = useFilePicker({
    value: value as any,
    uri,
    upload,
    onChange,
    validate,
    file: _file,
    useObjectValue: (useObjectValue ? true : false) as any,
  });

  return (
    <Toolbar
      key={`profile-${uri}`}
      sx={{
        flexDirection: buttonsPlacement === "right" ? "row" : "column",
        position: "relative",
      }}
    >
      <Avatar
        //style={{ cursor: "pointer" }}
        src={file?.uri}
        {...avatarProps}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          cursor: file?.uri ? "pointer" : "default",
          ...avatarProps.sx,
        }}
        onClick={
          onClick
            ? onClick
            : () =>
                !!file?.uri &&
                previewFile({
                  url: file?.uri,
                  title: file?.name,
                  fileName: file?.name,
                })
        }
      >
        {!file?.uri && fallBackComponent}
      </Avatar>
      {showEditIconOverlay && (
        <Box
          sx={{
            position: "absolute",
            color: "white",
            left: 0,
            //width: size,
            //height: size,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            pointerEvents: "none",
          }}
        >
          <EditIcon sx={{ color: "white" }} />
        </Box>
      )}
      {!readOnly && (
        <Box
          sx={{
            display: "flex",
            flexDirection:
              buttonsPlacement === "right" ? "column" : "row-reverse",
          }}
        >
          {!!file && (
            <Tooltip title={I18n.t("App.clear")}>
              <IconButton size={buttonSize} onClick={clearFile}>
                <ClearIcon fontSize={buttonSize} />
              </IconButton>
            </Tooltip>
          )}
          {buttonsPlacement === "bottom" && <Spacer w={1} />}
          <UploadIconButton
            src={file?.uri}
            title={I18n.t("App.upload")}
            accept={UploadFileTypes.DocumentsAndImages}
            onChange={onUploadButtonChange}
          >
            <CloudUploadIcon fontSize={buttonSize} />
          </UploadIconButton>
        </Box>
      )}
    </Toolbar>
  );
}

