import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { DialogActions, DialogContent } from "@mui/material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { createContext } from "react";
import useOptionForm, {
  UseOptionFormProps,
  UseOptionFormReturnType,
} from "./useOptionForm";
export const OptionContext = createContext<UseOptionFormReturnType | undefined>(
  undefined
);
export interface OptionFormProps extends UseOptionFormProps {}

export default function OptionForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: OptionFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useOptionForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
  const title = isCreate ? I18n.t("Option.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider
      value={{
        program_option_id: id,
        program_id: item.program_id,
        option_group_id: item.option_group_id,
      }}
    >
      <OptionContext.Provider value={form}>
        <PageHeader
          avatar={<WorkspacePremiumIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        />
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="Option.attributes"
            translationPlaceholdersCategory="Option.placeholders"
            translationHelpersCategory="Option.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("option", item)}
          >
            <FieldsContainer>
              <Field fullWidth>
                <TextField id="name" />
              </Field>
              {!params?.program_id && (
                <Field fullWidth>
                  <Autocomplete id="program_id" />
                </Field>
              )}
              <Field fullWidth>
                <TextField id="code" />
              </Field>
              {!params?.option_group_id && (
                <Field fullWidth>
                  <Autocomplete id="option_group_id" />
                </Field>
              )}
              <Field fullWidth>
                <TextField id="limit" />
              </Field>
              <Field fullWidth>
                <Checkbox id="active" />
              </Field>{" "}
            </FieldsContainer>
          </Form>
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </OptionContext.Provider>
    </ParamsContext.Provider>
  );
}

