import { Box, BoxProps, styled, Typography } from "@mui/material";

import { CalendarHeaderItem, VIEWS } from "./calendarApi";

interface ContainerProps extends BoxProps {
  view?: VIEWS;
}
const Container = styled(Box, {
  shouldForwardProp: prop => prop !== "view",
})<ContainerProps>`
  flex: 1;
  display: flex;
  align-items: ${props =>
    props.view === VIEWS.MONTH ? "center" : "flex-start"};
`;

export interface CalendarHeaderCellProps extends BoxProps, CalendarHeaderItem {
  view: VIEWS;
}
export default function CalendarHeaderCell({
  view,
  dayNameShort,
}: CalendarHeaderCellProps) {
  if (view === VIEWS.MONTH) {
    return (
      <Container key={dayNameShort} view={view}>
        <Typography variant="body1" align="center" sx={{ flex: 1 }}>
          {dayNameShort}
        </Typography>
      </Container>
    );
  }
  return <Container key={dayNameShort} view={view}></Container>;
}

