import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
import {
  ColorPicker,
  DialogTitle,
  Field,
  FieldsContainer,
  Form,
  RadioGroup,
  TextField,
} from "common";
import I18n from "i18n";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "./TableEditor";

interface ObjectFromProps extends Omit<DialogProps, "onChange"> {
  item?: Table;
  onChange?: (item?: Table) => void;
}

const ObjectFrom: React.FC<ObjectFromProps> = ({
  item: originalItem,
  onChange,
  ...dialogProps
}) => {
  const [item, setItem] = useState<Table | undefined>(originalItem);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  function apply() {
    if (onChange) {
      onChange(item);
    }
  }

  useEffect(() => {
    setItem(originalItem);
  }, [originalItem]);
  useEffect(() => {
    if (dialogProps.open) {
      setTimeout(() => {
        handleFocus();
      }, 100);
    }
  }, [dialogProps.open]);

  return (
    <Dialog maxWidth="xs" fullWidth {...dialogProps}>
      <DialogTitle onClose={dialogProps.onClose}>Table Settings</DialogTitle>
      <DialogContent>
        <Form
          loading={false}
          onChange={setItem}
          item={item}
          translationCategory="Table.attributes"
        >
          <FieldsContainer sx={{ pt: 1 }}>
            <Field fullWidth>
              <TextField
                useDelayedKeystrokes={false}
                //autoFocus
                id="name"
                onEnter={apply}
                inputRef={inputRef}
                //onFocus={handleFocus}
              />
            </Field>
            <Field fullWidth>
              <ColorPicker id="color" useDelayedKeystrokes={false} />
            </Field>
            <Field fullWidth>
              <TextField
                id="max_person"
                type="number"
                useDelayedKeystrokes={false}
              />
            </Field>
            <Field fullWidth>
              <RadioGroup
                row
                id="type"
                label={undefined}
                options={[
                  { label: "Asztal", value: "table" },
                  { label: "Egyéb", value: "other" },
                ]}
              />
            </Field>
          </FieldsContainer>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dialogProps.onClose!({}, "backdropClick")}>
          {I18n.t("App.cancel")}
        </Button>
        <Button onClick={apply} color="primary">
          {I18n.t("App.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectFrom;
