import Chair from "@mui/icons-material/Chair";
import CommentIcon from "@mui/icons-material/Comment";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import {
  Box,
  Chip,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Button, globalMessageHandler, Toolbar } from "common";
import TableSelectorModal from "components/TableSelector/TableSelectorModal";
import {
  checkIn,
  checkOut,
  setTableNumbers,
} from "features/bookings/bookingApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import { size } from "lodash";
import moment from "moment";
import { useState } from "react";
import { Booking } from "./cateringApi";
import OrderItem from "./OrderItem";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function BookingListItem({
  booking,
  other,
  reservedTables,
  afterChange,
}: {
  booking: Booking;
  other?: boolean;
  reservedTables: string[];
  afterChange?: (booking: Booking) => void;
}) {
  const { resolveOption } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    booking?.program_variant?.program_id || booking?.program_id
  );

  const qty: number =
    (booking.number_of_adults || 0) + (booking.number_of_kids || 0);
  const qty_babies: number = booking.number_of_babies || 0;
  const [open, setOpen] = useState(false);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [checkOutLoading, setCheckOutLoading] = useState(false);

  async function setTablesAsync(tables: string[] | null) {
    const { data, success } = await setTableNumbers(booking.id, tables);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeres regisztráció.",
      });
    }
  }
  async function checkInAsync() {
    setCheckInLoading(true);
    const { data, success } = await checkIn(booking.id);
    setCheckInLoading(false);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeres regisztráció.",
      });
    }
  }
  async function checkOutAsync() {
    setCheckOutLoading(true);
    const { data, success } = await checkOut(booking.id);
    setCheckOutLoading(false);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeresen törölted a regisztrációt.",
      });
    }
  }
  return (
    <>
      <ListItem
        divider
        sx={{
          //position: "relative",
          /* backgroundColor: booking.check_in_at
            ? theme => alpha(theme.palette.success.main, 0.2)
            : undefined,*/
          "&.MuiListItem-secondaryAction": {
            pr: other ? 18 : 12,
          },
        }}
      >
        <ListItemText
          disableTypography
          primary={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={1}
            >
              <Box display="flex" flexDirection="column">
                <Toolbar sx={{ gap: 1 }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                    {booking.name}
                  </Typography>
                  {!!booking.check_in_at && (
                    <Box
                      sx={{
                        backgroundColor: "success.dark",
                        fontSize: "0.7rem",
                        height: "18px",
                        pl: "3px",
                        pr: "3px",
                        borderRadius: "3px",
                      }}
                    >
                      regisztrált
                    </Box>
                  )}
                  {!booking.paid && (
                    <Box
                      sx={{
                        backgroundColor: "error.dark",
                        fontSize: "0.7rem",
                        pl: "3px",
                        pr: "3px",
                        borderRadius: "3px",
                        height: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      fizetetlen
                    </Box>
                  )}
                </Toolbar>
                <Typography variant="body2">{booking.email}</Typography>
                {booking.description && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      color: theme => theme.palette.info.main,
                    }}
                  >
                    <CommentIcon fontSize="small" />
                    <Typography variant="body2">
                      {booking.description}
                    </Typography>
                  </Box>
                )}
                <Toolbar sx={{ gap: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "5px",
                      backgroundColor: program?.color,
                    }}
                  />
                  <Typography>{booking.program_variant_name}</Typography>
                  <Typography>
                    <b>{qty}</b> fő
                    {qty_babies > 0 ? ` + ${qty_babies} baba` : ""}
                  </Typography>

                  {program.has_layout && booking.table_numbers && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <EventSeatIcon fontSize="small" />
                      <Typography variant="body1" fontWeight={500}>
                        {booking.table_numbers?.join(", ")}
                      </Typography>
                    </Box>
                  )}
                </Toolbar>
                <Toolbar>
                  <Typography variant="body2" color="text.secondary">
                    {booking.booking_number}
                  </Typography>
                </Toolbar>
              </Box>
              <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
                {booking.variant_name && (
                  <OrderItem
                    name={booking.variant_name}
                    qty={qty}
                    type="variant"
                  />
                )}
                {booking.booking_options?.map((option, idx) => (
                  <OrderItem
                    key={idx}
                    name={option.name}
                    qty={option.qty}
                    type="option"
                  />
                ))}
              </Box>
            </Box>
          }
        />
        {!!other && (
          <ListItemSecondaryAction>
            <Chip
              color="info"
              label={moment(booking.start_time).format("L LT")}
            />
          </ListItemSecondaryAction>
        )}
        {!other && (
          <ListItemSecondaryAction>
            {program?.has_layout ? (
              <Button
                title="Reg."
                color={
                  size(booking.table_numbers) > 0 && !!booking.check_in_at
                    ? "success"
                    : "secondary"
                }
                size="small"
                startIcon={<Chair />}
                onClick={evt => {
                  //evt.preventDefault();
                  evt.stopPropagation();
                  console.log("Asztalok kiválasztása", booking);
                  setOpen(true);
                }}
              ></Button>
            ) : (
              <Button
                loading={checkInLoading}
                title="Reg."
                size="small"
                color={booking.check_in_at ? "success" : "secondary"}
                onClick={evt => {
                  //evt.preventDefault();
                  evt.stopPropagation();
                  globalMessageHandler.confirm(
                    {
                      message: `Biztosan regisztrálod az alábbi foglalást? ${booking.name}`,
                    },
                    () => {
                      checkInAsync();
                    }
                  );
                }}
              />
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <TableSelectorModal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
        guestCount={booking.number_of_guests}
        tables={program?.seating_layout || ([] as any)}
        value={booking.table_numbers}
        reserved={reservedTables}
        onChange={evt => {
          setTablesAsync(evt);
        }}
        onRemove={close => {
          globalMessageHandler.confirm(
            {
              message: "Biztosan törlöd a regisztrációt?",
            },
            async () => {
              checkOutAsync();
              close();
            }
          );
        }}
      />
    </>
  );
}

export default BookingListItem;
