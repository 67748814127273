import { clearProfile, loadProfileAsync, resetStatus } from "./profileSlice";

const profileMiddleware =
  (store: any) => (next: any) => async (action: any) => {
    const nextAction = await next(action);

    //Load profile after login
    if (action.type === "identity/login/fulfilled") {
      await store.dispatch(loadProfileAsync());
    }

    //Clear profile data on logout
    if (action.type === "identity/logout/fulfilled") {
      await store.dispatch(clearProfile());
    }

    //Clear profile loading state on page reload
    if (action.type === "persist/REHYDRATE") {
      if (store.getState().profile.status !== "idle") {
        await store.dispatch(resetStatus());
      }
    }

    return nextAction;
  };

export default profileMiddleware;

