import { Box, useMediaQuery, useTheme } from "@mui/material";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import DateRangeSelector2 from "components/DateRangeSeletor/DateRangeSelector2";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import useSettings from "features/settings/useSettings";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

interface KitchenFilterProps {
  onFilter: (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => void;
}

export default function KitchenFilter({ onFilter }: KitchenFilterProps) {
  const [range, setRange] = useState<{ start: Moment; end: Moment }>({
    start: moment().startOf("week"),
    end: moment().endOf("week"),
  });

  const [settings, setSettings] = useSettings({ key: "kitchen" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    onFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds
    );
  }, [range, settings.boatIds, settings.programIds]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <DateRangeSelector2
        value={range}
        onChange={(start, end) => {
          setRange({ start, end });
        }}
        dateButtonMinWidth={240}
      />
      <BoatCheckboxSelector
        color={theme.palette.primary.main}
        value={settings.boatIds || []}
        onChange={evt =>
          setSettings({
            boatIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
      />
      <ProgramCheckboxSelector
        value={settings.programIds || []}
        onChange={evt =>
          setSettings({
            programIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
      />
    </Box>
  );
}
