
import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FakeEvent } from "common/types";
import { get } from "lodash";
import useList from "./hooks/useList";
import inputBaseSx from "./inputStyle";

type ColumnType<T = any> = {
  id: string;
  title?: string;
  inputComponent: React.ElementType;
  inputProps?: ((item?: T, index?: number, value?: any) => void) | any;
  headerProps?: TableCellProps;
  cellProps?: TableCellProps;
};
type Column<T = any> =
  | ColumnType<T>
  | ((item?: T, index?: number) => ColumnType<T>);

type Texts = {
  addTitle?: string;
};

type ListProps<T> = {
  id?: string;
  label?: string;
  value?: T[];
  columns?: Column<T>[];
  onChange?: (value: FakeEvent<T[] | null | undefined>) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  error?: string;
  helperText?: string;
  showHeader?: boolean;
  inline?: boolean;
  texts?: Texts;
  loading?: boolean;
  rowIsDisabled?: (item?: T, index?: number) => boolean;
  onRowCahnge?: (item: T, index: number, field: keyof T, value: any) => T;
};

type Class<T> = T & {};

const defaultTextsEn = {
  addTitle: "add item",
  deleteConfirmation: "Delete Confirmation",
  deletePrompt: "Are you sure you want to delete this item?",
  cancel: "Cancel",
  delete: "Delete",
  noItems: "No items added, use the '+' button.",
};
const defaultTextsHu = {
  addTitle: "elem hozzáadása",
  deleteConfirmation: "Törlés megerősítése",
  deletePrompt: "Biztosan törölni szeretnéd ezt az elemet?",
  cancel: "Mégse",
  delete: "Törlés",
  noItems: "Nincsenek hozzáadott elemek, használd a '+' gombot.",
};

const List = <T extends Class<{ [key: string]: any }>>({
  id,
  label,
  value = [],
  columns = [],
  onChange,
  disabled,
  icon,
  error,
  helperText,
  texts: _texts,
  inline,
  showHeader = true,
  loading,
  rowIsDisabled,
  onRowCahnge,
}: ListProps<T>) => {
  const rowErrors: any = error && typeof error === "object" ? error : {};
  const texts = { ...defaultTextsHu, ..._texts };
  const {
    items,
    handleAddItem,
    handleDeleteItem,
    handleChangeItem,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleDelete,
  } = useList(value, onChange, onRowCahnge);

  const renderInputComponent = (
    InputComponent: React.ElementType,
    _inputProps: any,
    value: any,
    index: number,
    item: T,
    error: any,
    handleChange: (event: any) => void
  ) => {
    const inputProps =
      typeof _inputProps === "function"
        ? _inputProps(item, index, value)
        : _inputProps;

    const filedIsDisabled =
      disabled || (rowIsDisabled ? rowIsDisabled(item, index) : undefined);
    if ((InputComponent as any).displayName?.includes("Autocomplete")) {
      return (
        <InputComponent
          className={error ? "error" : undefined}
          disabled={filedIsDisabled}
          {...inputProps}
          value={value || []}
          onChange={(event: any) =>
            handleChange({ target: { value: event.target.value } })
          }
          disableClearable
          fullWidth
          size="small"
          sx={{ "& .MuiInputBase-root": { padding: 0 } }}
          //renderInput={params => <TextField {...params} />}
        />
      );
    } else {
      return (
        <InputComponent
          className={error ? "error" : undefined}
          disabled={filedIsDisabled}
          {...inputProps}
          value={value || ""}
          onChange={handleChange}
          fullWidth
          size="small"
          sx={{ "& .MuiInputBase-root": { padding: 0 } }}
        />
      );
    }
  };
  const cardSx = inline
    ? {
        border: "none",
        "& .MuiCardContent-root": {
          p: 0,
        },
      }
    : {};
  const actionSx = inline ? { pl: 0, pr: 0 } : {};
  return (
    <Card sx={cardSx}>
      {!!showHeader && (
        <CardHeader
          title={label}
          titleTypographyProps={{ variant: "h6" }}
          avatar={icon}
          action={
            <IconButton
              color="primary"
              onClick={handleAddItem}
              disabled={disabled}
            >
              <Add />
            </IconButton>
          }
        />
      )}
      <CardContent>
        <TableContainer>
          <Table size="small" padding="none">
            <TableHead>
              <TableRow>
                {columns.map(_column => {
                  const column =
                    typeof _column === "function" ? _column() : _column;
                  return (
                    <TableCell key={column.id} {...column.headerProps}>
                      {column.title}
                    </TableCell>
                  );
                })}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow sx={inputBaseSx} key={index}>
                  {columns.map(_column => {
                    const column =
                      typeof _column === "function"
                        ? _column(item, index)
                        : _column;
                    return (
                      <TableCell key={column.id} {...column.cellProps}>
                        {renderInputComponent(
                          column.inputComponent,
                          column.inputProps,
                          item[column.id],
                          index,
                          item,
                          get(rowErrors, `${index}.${column.id}`),
                          event =>
                            handleChangeItem(
                              index,
                              column.id,
                              event.target.value === ""
                                ? null
                                : event.target.value
                            )
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right">
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDialog(index)}
                      disabled={
                        disabled ||
                        (rowIsDisabled ? rowIsDisabled(item, index) : undefined)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {items.length === 0 && !loading && !disabled && (
          <Alert severity="info" sx={{ mt: 3 }}>
            {texts.noItems}
          </Alert>
        )}
        {error && typeof error === "string" && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {error}
          </Alert>
        )}
      </CardContent>
      {!showHeader && (
        <CardActions sx={actionSx}>
          <Button
            color="primary"
            variant="text"
            onClick={handleAddItem}
            disabled={disabled}
            startIcon={<Add />}
          >
            {texts.addTitle}
          </Button>
        </CardActions>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {texts.deleteConfirmation}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {texts.deletePrompt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {texts.cancel}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {texts.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default List;

