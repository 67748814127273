import { Box, BoxProps } from "@mui/material";

export type SkeletonAnimationTypes =
  | "shimmer"
  | "pulse"
  | "wave"
  | "glow"
  | "flash"
  | "bounce"
  | "weave"
  | "ripple"
  | "twistFade"
  | "glitchPulse";

/**
 * Props for the SkeletonAnimation component.
 *
 * @typedef {Object} SkeletonAnimationProps
 * @property {ReactNode} children - The content to be displayed within the skeleton animation.
 * @property {string} [animationType='shimmer'] - The type of skeleton animation. "shimmer" | "pulse" | "wave" | "glow" | "flash" | "bounce" | "ripple" | "twistFade" | "glitchPulse"
 * @property {string} [animationDuration='1.5s'] - Duration of the skeleton animation.
 * @property {string} [skeletonClass='field'] - The class name to apply the skeleton animation to.
 * @property {string} [backgroundColor='#f0f0f0'] - Background color of the skeleton element.
 * @property {boolean} [loading=true] - Flag to turn on/off the skeleton animation.
 */

/**
 * SkeletonAnimation component applies a trendy skeleton animation to its children.
 *
 * @param {SkeletonAnimationProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const SkeletonAnimation = ({
  children,
  animationType = "shimmer",
  animationDuration = "1.5s",
  skeletonClass = "field",
  backgroundColor = "#f0f0f0",
  loading = true,
  ...rest
}: BoxProps & {
  animationType?: SkeletonAnimationTypes;
  animationDuration?: string;
  skeletonClass?: string;
  backgroundColor?: string;
  loading?: boolean;
}): JSX.Element => {
  const animationStyles: any = {
    shimmer: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        )`,
        animation: `skeleton-shimmer ${animationDuration} infinite`,
      },
    },
    pulse: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        animation: `skeleton-pulse ${animationDuration} infinite`,
      },
    },
    wave: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        )`,
        animation: `skeleton-wave ${animationDuration} infinite`,
      },
    },
    glow: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        animation: `skeleton-glow ${animationDuration} infinite`,
      },
    },
    flash: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        animation: `skeleton-flash ${animationDuration} infinite`,
      },
    },
    bounce: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        animation: `skeleton-bounce ${animationDuration} infinite`,
      },
    },
    weave: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "200%",
        height: "100%",
        background: `linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.25) 25%,
          rgba(255, 255, 255, 0.75) 50%,
          rgba(255, 255, 255, 0.25) 75%
        )`,
        animation: `skeleton-weave ${animationDuration} infinite`,
      },
    },
    ripple: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "200%",
        height: "200%",
        background: `radial-gradient(circle, rgba(255, 255, 255, 0.1) 20%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.1) 80%)`,
        borderRadius: "50%",
        transform: "translate(-50%, -50%) scale(0)",
        animation: `skeleton-ripple ${animationDuration} infinite`,
      },
    },
    twistFade: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: backgroundColor,
        animation: `skeleton-twist-fade ${animationDuration} infinite`,
      },
    },
    glitchPulse: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: backgroundColor,
        animation: `skeleton-glitch-pulse ${animationDuration} infinite`,
      },
    },
  };

  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        [`& .${skeletonClass} div:has(> input)`]: {
          position: "relative",
          overflow: "hidden",
          backgroundColor: loading ? backgroundColor : undefined,
          borderRadius: "4px",
          ...(loading ? animationStyles[animationType] : {}),
        },
        "@keyframes skeleton-shimmer": {
          "0%": {
            transform: "translateX(-100%)",
          },
          "100%": {
            transform: "translateX(100%)",
          },
        },
        "@keyframes skeleton-pulse": {
          "0%": {
            opacity: 1,
          },
          "50%": {
            opacity: 0.5,
          },
          "100%": {
            opacity: 1,
          },
        },
        "@keyframes skeleton-wave": {
          "0%": {
            transform: "translateX(-100%)",
          },
          "100%": {
            transform: "translateX(100%)",
          },
        },
        "@keyframes skeleton-glow": {
          "0%": {
            opacity: 0.8,
          },
          "50%": {
            opacity: 1,
          },
          "100%": {
            opacity: 0.8,
          },
        },
        "@keyframes skeleton-flash": {
          "0%": {
            opacity: 0.4,
          },
          "50%": {
            opacity: 0.8,
          },
          "100%": {
            opacity: 0.4,
          },
        },
        "@keyframes skeleton-bounce": {
          "0%, 100%": {
            transform: "translateY(0)",
          },
          "50%": {
            transform: "translateY(-10px)",
          },
        },
        "@keyframes skeleton-weave": {
          "0%": {
            transform: "translateX(-50%) translateY(0)",
          },
          "50%": {
            transform: "translateX(0) translateY(-10px)",
          },
          "100%": {
            transform: "translateX(50%) translateY(0)",
          },
        },
        "@keyframes skeleton-ripple": {
          "0%": {
            transform: "translate(-50%, -50%) scale(0)",
            opacity: 1,
          },
          "100%": {
            transform: "translate(-50%, -50%) scale(1)",
            opacity: 0,
          },
        },
        "@keyframes skeleton-twist-fade": {
          "0%": {
            transform: "rotate(0deg)",
            opacity: 1,
          },
          "50%": {
            transform: "rotate(180deg)",
            opacity: 0.5,
          },
          "100%": {
            transform: "rotate(360deg)",
            opacity: 1,
          },
        },
        "@keyframes skeleton-glitch-pulse": {
          "0%": {
            transform: "translate(0, 0)",
            opacity: 1,
          },
          "20%": {
            transform: "translate(2px, -2px)",
            opacity: 0.8,
          },
          "40%": {
            transform: "translate(-2px, 2px)",
            opacity: 1,
          },
          "60%": {
            transform: "translate(2px, 2px)",
            opacity: 0.8,
          },
          "80%": {
            transform: "translate(-2px, -2px)",
            opacity: 1,
          },
          "100%": {
            transform: "translate(0, 0)",
            opacity: 1,
          },
        },
        ...rest.sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SkeletonAnimation;

