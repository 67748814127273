import {
  globalMessageHandler,
  GridModalFormProps,
  loadMultipleOptions,
  useForm,
  UseFormReturnType,
} from "common";
import { getUserService } from "features/users/userApi";
import I18n from "i18n";
import { Params, useParams } from "react-router-dom";
import { getHistoryService, HistoryItem, HistoryOptions } from "./historyApi";

export interface UseHistoryFormProps extends GridModalFormProps {}

type RouteParams = {
  history_id?: string;
};

export interface UseHistoryFormReturnType
  extends UseFormReturnType<HistoryItem, HistoryOptions> {
  params?: Readonly<Params<"history_id" | "user_id">>;
}

export default function useHistoryForm({
  id,
  afterSave,
}: UseHistoryFormProps): UseHistoryFormReturnType {
  const params = useParams<"history_id" | "user_id">();
  const scope = { users: params.user_id };
  const service = getHistoryService(scope);

  const form = useForm<HistoryItem, HistoryOptions>({
    id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "History",
    rules: {
      /* class_name: Validator.rule.string().required(),
      item_id: Validator.rule.any().required(),
      data: Validator.rule.string().required(),
      user_id: params.user_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      created_at: Validator.rule.string().required(),*/
    },
    afterSave,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      user_id: () => getUserService(undefined).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}

