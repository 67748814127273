import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import { TextField, Toolbar } from "common";
import { BookingItem, getBookingService } from "features/bookings/bookingApi";
import { openCalendarBookingModal } from "features/events/Calendar/EventCalendarModals";
import I18n from "i18n";
import moment from "moment";
import { MouseEvent, useEffect, useRef, useState } from "react";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.default, //theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  //width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  //color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: "theme.palette.background.default",
}));

export interface BookingSearchProps {
  setBooking: (booking: Partial<BookingItem>) => void;
}

export default function BookingSearch({ setBooking }: BookingSearchProps) {
  const service = getBookingService();
  const [keyword, setKeyword] = useState<string | number | null>(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<BookingItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const debouncer = useRef<any>();

  async function load() {
    if (!keyword) {
      setItems([]);
      return;
    }
    setLoading(true);
    const { success, data } = await service.paginateItems(`keyword=${keyword}`);
    setLoading(false);
    if (success) {
      setItems(data || []);
    }
  }

  //const loadDebounced = debounce(load, 600);
  function loadDebounced() {
    if (debouncer.current) {
      clearTimeout(debouncer.current);
    }
    debouncer.current = setTimeout(() => {
      load();
    }, 600);
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;
  const inited = useRef(false);
  useEffect(() => {
    if (inited.current) {
      loadDebounced();
    } else {
      inited.current = true;
    }
  }, [keyword]);

  return (
    <Toolbar sx={{ gap: 2, flex: 1, minWidth: 200 }}>
      <TextField
        onClick={event => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        placeholder={I18n.t("Booking.searchPlaceholder")}
        fullWidth
        clearable
        onChange={evt => setKeyword(evt.target.value || null)}
        value={keyword}
        onEnter={load}
        onEscape={() => setAnchorEl(null)}
        useDelayedKeystrokes={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ minWidth: 22 }}>
              {loading ? <CircularProgress size={18} /> : <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [-2, 16],
            },
          },
        ]}
        sx={{
          //maxWidth: "xs",
          //backgroundColor: "green",
          backgroundColor: "background.default",
          maxWidth: "calc(600px - 46px)",
          width: "100%",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            {items.map(item => {
              return (
                <ListItemButton
                  key={item.id}
                  onClick={() => {
                    openCalendarBookingModal(item?.event, item);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: item.event?.program?.color,
                        color: theme =>
                          item.event?.program?.color
                            ? theme.palette.getContrastText(
                                item.event?.program?.color
                              )
                            : undefined,
                      }}
                    >
                      {item.event?.program?.code}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      <Box
                        sx={{
                          "& span": { color: "info.main" },
                        }}
                      >
                        <Typography>{item.email}</Typography>
                        <Typography fontWeight={500}>
                          <span>{item.booking_number}</span>
                          {item.external_booking_number
                            ? ` (${item.external_booking_number})`
                            : ""}
                        </Typography>
                      </Box>
                    }
                  />
                  <Toolbar sx={{ gap: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {moment(item.event?.start_time).format("dddd L")}
                      </Typography>
                      <Toolbar sx={{ gap: 1 }}>
                        <Typography>
                          {item.number_of_guests} {I18n.t("Booking.guests")}
                        </Typography>
                        <Typography>
                          {I18n.numberToCurrency(
                            item.adult_price * item.number_of_adults +
                              item.kid_price * item.number_of_kids
                          )}
                        </Typography>
                      </Toolbar>
                    </Box>
                    <ChevronRightIcon />
                  </Toolbar>
                </ListItemButton>
              );
            })}
            {items.length === 0 && !loading && (
              <ListItem>
                {!keyword ? (
                  <ListItemText
                    primary={I18n.t("Booking.searchInfoPrimary")}
                    secondary={I18n.t("Booking.searchInfoSecondary")}
                  />
                ) : (
                  <ListItemText
                    primary={I18n.t("Booking.noResultsPrimary")}
                    secondary={I18n.t("Booking.noResultsSecondary")}
                  />
                )}
              </ListItem>
            )}
          </List>
        </ClickAwayListener>
      </StyledPopper>
    </Toolbar>
  );
}
