import api from "app/api";
import { Options } from "common";
import { size } from "lodash";

export interface RoleItem {
  id: number;
  team_id: number;
  name: string;
  guard_name: string;
}

export type RoleOptions = Options<"role" | "status">;

export function getRoleService(scope?: any) {
  return api.getService<RoleItem, RoleItem, RoleOptions>("roles", {
    scope,
    //params goes here
    listParser: data => {
      return {
        total: size(data),
        data,
        page: 1,
        pageSize: 100,
      };
    },
  });
}

