import useDictionaries from "features/dictionaries/useDictionaries";
import {
  EventItem,
  EventLimit,
  EventOption,
  EventVariant,
} from "features/events/eventApi";
import { OptionItem } from "features/options/optionApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { useMemo } from "react";

export type UseProgramSummaryResult = {
  variants: Array<ProgramVariantItem & EventVariant>;
  options: Array<OptionItem & EventOption>;
  summary: ProgramVariantItem & EventVariant;
  limits: EventLimit[];
};
export interface UseProgramSummaryProps {
  item: EventItem;
}
export default function useProgramSummary({
  item,
}: UseProgramSummaryProps): UseProgramSummaryResult {
  const { resolveOption } = useDictionaries();

  return useMemo<UseProgramSummaryResult>(() => {
    let summary: ProgramVariantItem & EventVariant = {
      name: "Összesen",
      number_of_adults: 0,
      number_of_kids: 0,
      number_of_babies: 0,
      number_of_guests: 0,
      number_of_places: 0,
      closed: false,
      online_closed: false,
      variant_id: 0,
      adult_price: 0,
      kid_price: 0,
    } as any;

    let variants: any[] = [];
    let variantIds: number[] = [];
    item?.variants?.forEach(variant => {
      const variantModel = resolveOption<ProgramVariantItem>(
        "variants",
        variant.variant_id
      );
      if (variantModel?.program_id === item.program_id) {
        summary.number_of_adults += variant.number_of_adults;
        summary.number_of_kids += variant.number_of_kids;
        summary.number_of_babies += variant.number_of_babies;
        summary.number_of_guests += variant.number_of_guests;
        summary.number_of_places += variant.number_of_places;
        variants.push({
          ...variant,
          ...variantModel,
          label: variantModel.name,
        });
        variantIds.push(variant.variant_id);
      }
    });

    let limits: any[] = [];
    item?.limits?.forEach(limit => {
      const programModel = resolveOption<ProgramItem>(
        "programs",
        limit.program_id
      );
      //console.log(limit.variant_ids.some(l => variantIds.includes(l)));
      if (limit.variant_ids.some(l => variantIds.includes(l))) {
        if (programModel) {
          limits.push({ ...limit, name: programModel.name });
        } else {
          limits.push(limit);
        }
      }
    });

    let options: any[] = [];
    item?.options?.forEach(option => {
      const optionModel = resolveOption<OptionItem>(
        "options",
        option.option_id
      );
      if (optionModel.program_id === item.program_id) {
        options.push({
          ...option,
          ...optionModel,
        });
      }
    });
    return {
      variants,
      options,
      summary,
      limits,
    };
  }, [item]);
}
