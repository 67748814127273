import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  useTheme,
} from "@mui/material";
import settings from "app/settings";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { IMaskMixin } from "react-imask";
import { ColorIndicatorProps } from "../types";
import { getColorsFromString } from "./../../../modules/helpers";
declare global {
  interface Window {
    lastScroll: number;
  }
}

export function getTextFieldColorIndicatorSx(
  id: any,
  { indicatorColor, indicatorOpacity }: ColorIndicatorProps
) {
  let sx = {};
  if (indicatorColor === "auto") {
    sx = {
      "& .MuiFilledInput-root": {
        boxShadow: `inset 5px 0 0 0 ${
          getColorsFromString(
            id,
            indicatorOpacity || settings.constants?.autoColorOpacity || 1
          ).backgroundColor
        }`,
      },
    };
  } else if (indicatorColor) {
    sx = {
      "& .MuiFilledInput-root": {
        boxShadow: `inset 5px 0 0 0 ${indicatorColor}`,
      },
    };
  }
  return sx;
}

export interface TextFieldProps
  extends ColorIndicatorProps,
    Omit<MuiTextFieldProps, "error" | "onChange" | "onBlur" | "value"> {
  value?: string | number | null;
  error?: string;
  helperText?: string;
  mask?: any;
  numberOptions?: {
    scale?: number; // digits after point, 0 for integers
    signed?: boolean; // disallow negative
    thousandsSeparator?: string; // any single char
    padFractionalZeros?: boolean; // if true, then pads zeros at end to the length of scale
    normalizeZeros?: boolean; // appends or removes zeros at ends
    radix?: string; // fractional delimiter
    mapToRadix?: string[]; // symbols to process as radix

    // additional number interval options (e.g.)
    min?: number;
    max?: number;
  };
  onChange?: (event: { target: { value?: string | number | null } }) => void;
  onBlur?: Function;
  onEnter?: Function;
  onEscape?: Function;
  onChangeByKeyPress?: Function;
  useDelayedKeystrokes?: boolean;
  unmask?: boolean;
  editable?: boolean;
  isDirty?: boolean;
  required?: boolean;
  clearable?: boolean;
  onClear?: Function;
  alwaysShowClearButton?: boolean;
}

const MaskedTextField = IMaskMixin((props: any) => <MuiTextField {...props} />);

interface MetadataObj {
  [key: string]: any;
}

export default function TextField({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  onChangeByKeyPress,
  editable,
  useDelayedKeystrokes = true,
  isDirty,
  label: _label,
  required,
  clearable,
  indicatorColor,
  indicatorOpacity,
  onEnter,
  alwaysShowClearButton,
  onClear,
  onEscape,
  numberOptions,
  ...textFieldProps
}: TextFieldProps) {
  const [innerValue, setInnerValue] = useState<any>(value);
  const timer = useRef<any>();
  const fieldRef = useRef<HTMLDivElement>(null);

  const getValue = () => {
    return innerValue === undefined ? null : innerValue;
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  /**
   * Scroll to first error field
   */
  useEffect(() => {
    const t = new Date().getTime();

    if (!window.lastScroll) {
      window.lastScroll = 0;
    }
    if (error && fieldRef.current && window.lastScroll < t - 1000) {
      window.lastScroll = t;
      var headerOffset = 100;
      var elementPosition =
        fieldRef.current && fieldRef.current.getBoundingClientRect
          ? fieldRef.current.getBoundingClientRect().top
          : 0;
      var offsetPosition = elementPosition - headerOffset;

      /*window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });*/
    }
  }, [error]);

  const handleChange = (evt: any) => {
    if (evt.target.value === "") {
      evt.target.value = null;
    }

    if (
      evt.target.value &&
      (textFieldProps.type === "number" || textFieldProps.mask === Number)
    ) {
      ///evt.persist && evt.persist();
      evt = { target: { value: Number(evt.target.value) } };
    }

    if (evt.target.value === getValue()) {
      return;
    }

    setInnerValue(evt.target.value);
    onChangeByKeyPress && onChangeByKeyPress(evt);

    if (useDelayedKeystrokes) {
      evt.persist && evt.persist();
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
      timer.current = setTimeout(() => {
        onChange && onChange(evt);
      }, 800);
    } else {
      onChange && onChange(evt);
    }
  };

  const handleAccept = (unmaskedValue: any) => {
    if (
      unmaskedValue &&
      (textFieldProps.type === "number" || textFieldProps.mask === Number)
    ) {
      handleChange({ target: { value: Number(unmaskedValue) } });
    } else {
      handleChange({ target: { value: unmaskedValue } });
    }
  };

  const handleBlur = (evt: any) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
    if (textFieldProps.unmask) {
      const e = { target: { value: innerValue } };
      if (String(value) !== String(innerValue)) {
        onChange && onChange(e);
      }
      onBlur && onBlur(e);
    } else {
      if (evt.target.value === "") {
        evt.target.value = null;
      }

      if (String(evt.target.value) !== String(value)) {
        if (
          evt.target.value &&
          (textFieldProps.type === "number" || textFieldProps.mask === Number)
        ) {
          evt = { target: { value: Number(evt.target.value) } };
        }
        onChange && onChange(evt);
        return;
      }

      onBlur && onBlur(evt);
    }
  };

  const Input = textFieldProps.mask ? MaskedTextField : MuiTextField;
  let MaskedProps: MetadataObj = {};
  if (textFieldProps.mask === Number) {
    MaskedProps = { ...MaskedProps, ...numberOptions };
  }
  if (textFieldProps.unmask) {
    MaskedProps.onAccept = handleAccept;
  }

  const label = required ? (
    <span>
      {_label} <span style={{ color: "red" }}>*</span>
    </span>
  ) : (
    _label
  );
  const theme = useTheme();

  function handleOnEnter(evt: KeyboardEvent<HTMLInputElement>) {
    if (evt.key === "Enter") {
      onEnter && onEnter(evt);
    }
    if (evt.key === "Escape") {
      onEscape && onEscape(evt);
    }
  }

  return (
    <Input
      //@ts-ignore
      ref={fieldRef}
      label={textFieldProps.hiddenLabel ? undefined : label}
      value={
        !!innerValue || innerValue === 0
          ? (textFieldProps.mask ? String(innerValue) : innerValue) || ""
          : ""
      }
      onBlur={handleBlur}
      color={isDirty ? "secondary" : "primary"}
      onChange={!textFieldProps.unmask ? handleChange : undefined}
      error={error ? true : false}
      helperText={error || helperText}
      onKeyDown={onEnter ? handleOnEnter : undefined}
      sx={{
        ...getTextFieldColorIndicatorSx(textFieldProps.id, {
          indicatorColor,
          indicatorOpacity,
        }),
        ...textFieldProps?.sx,
      }}
      inputProps={{
        autoCorrect: "off",
        autoCapitalize: "none",
      }}
      {...MaskedProps}
      {...textFieldProps}
      InputLabelProps={{
        shrink: value && value !== "" ? true : undefined,
        ...textFieldProps.InputLabelProps,
      }}
      InputProps={
        clearable
          ? {
              ...textFieldProps.InputProps,
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ marginRight: textFieldProps.multiline ? 0 : -6 }}
                >
                  {(!!value || alwaysShowClearButton) && (
                    <IconButton
                      onClick={() => {
                        handleChange({ target: { value: null } });
                        onClear && onClear();
                      }}
                      sx={{ color: theme.palette.text.primary }}
                      size="small"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }
          : textFieldProps.InputProps
      }
    />
  );
}

