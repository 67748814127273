import { SxProps, Theme } from "@mui/material";

const summaryTableSx: SxProps<Theme> = {
  "& td, th": {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
  },
  "& td": {
    heigh: "unset",
    height: "30px",
  },
  "& tr:hover": {
    "& td": {
      backgroundColor: "action.hover",
    },
  },
};

export default summaryTableSx;

