import api from "app/api";
import { Options } from "common";

export interface ExchangeRateItem {
  id: number;
  type: string;
  rate: number;
  date_from: string;
  date_to: string;
}

export type ExchangeRateOptions = Options<"role" | "status">;

export function getExchangeRateService(scope?: any) {
  return api.getService<
    ExchangeRateItem,
    ExchangeRateItem,
    ExchangeRateOptions
  >("exchange-rates", {
    scope,
    //params goes here
  });
}

