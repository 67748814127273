import api from "app/api";
import { Options } from "common";
import { UserItem } from "features/users/userApi";
export interface HistoryItem {
  id: number;
  class_name: string;
  item_id: number;
  data: string;
  user_id: number;
  user?: UserItem;
  created_at: string;
}

export type HistoryOptions = Options<"role" | "status">;

export function getHistoryService(scope?: any) {
  return api.getService<HistoryItem, HistoryItem, HistoryOptions>("histories", {
    scope,
    //params goes here
  });
}

