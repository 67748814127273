import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Link } from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  GridColumns,
  PageHeader,
} from "common";

import getRemoteUri from "common/modules/helpers";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { FileItem } from "./fileApi";
import useFileList from "./useFileList";

export interface FileListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function FileList({
  showHeader = true,
  ...props
}: FileListProps) {
  const { params, ...service } = useFileList();
  const { hasPermissions } = useAccess();
  let columns: GridColumns<FileItem> = [
    {
      field: "name",
      headerName: I18n.t("File.attributes.name"),
      renderCell: params => (
        <Link href={getRemoteUri(params.row)} download>
          {params.value}.{params.row.extension}
        </Link>
      ),
      flex: 1,
    },
    /* {
      field: "extension",
      headerName: I18n.t("File.attributes.extension"),
      width: 90,
    },*/
    {
      field: "mime_type",
      headerName: I18n.t("File.attributes.mime_type"),
    },
    {
      field: "size",
      headerName: I18n.t("File.attributes.size"),
      renderCell: params => I18n.numberToHumanSize(params.value),
    },
    {
      field: "created_at",
      headerName: I18n.t("File.attributes.created_at"),
      type: "datetime",
      width: 180,
    },
    {
      field: "original_extension",
      headerName: I18n.t("File.attributes.original_extension"),
      width: 90,
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<PhotoLibraryIcon />}
          title={I18n.t("File.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="files"
        {...props}
        defaultState={{ sorting: [{ field: "created_at", direction: "desc" }] }}
        //getHeaderName={field => I18n.t(`File.attributes.${field.replaceAll(".", "_")}`)}
        canDelete={hasPermissions(["file.create"])}
        canView={false}
        canBatchActions={hasPermissions(["file.create"])}
        canHandleTrash={hasPermissions(["file.create"])}
      />
    </>
  );
}

