import api from "app/api";
import { Options } from "common";

export interface FileItem {
  id: number;
  name: string;
  extension: string;
  original_extension: string;
  mime_type: string;
  size: number;
}

export type FileOptions = Options<"type">;

export function getFileService(scope?: any) {
  return api.getService<FileItem, FileItem, FileOptions>("files", {
    scope,
    //params goes here
  });
}

