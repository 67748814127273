import { Euro } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { TextField, TextFieldProps } from "common";
export interface PriceProps extends TextFieldProps {}
export default function Price({ size, sx, ...props }: PriceProps) {
  console.log({ price: props.value });
  return (
    <TextField
      mask={Number}
      //useDelayedKeystrokes={false}
      unmask
      size="small"
      sx={{
        ...(size === "small"
          ? {
              minHeight: "27px",
              "& input": {
                padding: "3px",
                paddingLeft: "8px",
                paddingRight: "8px",
              },
            }
          : {}),
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Euro
              fontSize="small"
              color={props.disabled ? "disabled" : undefined}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
