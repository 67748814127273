import api from "app/api";
import { DbFile } from "common/types";

export interface FileItem {
  created_at: string;
  created_by: number;
  deleted_at: string;
  extension: string;
  id: number;
  mime_type: string;
  name: string;
  size: number;
  updated_at: string;
  updated_by: number;
}

export interface ProfileForm {
  email: string;
  first_name: string;
  last_name: string;
  file_id: number;
  file: DbFile;
}

export function loadProfile() {
  return api.get("/auth/profile");
}

export function updateProfile(item: ProfileForm) {
  return api.put("/auth/profile", item);
}

