import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import I18n from "i18n";
import { PageHeader, Spacer, DataGridBaseBaseProps, DataGridV2 as DataGrid, GridColumns } from "common";
import useAccess from "features/profile/useAccess";
import useProgramList from "./useProgramList";
import { ProgramItem } from "./programApi";
import { DataGridLinkColumn } from "common";//FOS
export interface ProgramListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function ProgramList({ showHeader = true, ...props }: ProgramListProps) {
  const { params, scope, editUrl, ...service } = useProgramList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<ProgramItem> = [
    { field: "name", headerName: I18n.t("Program.attributes.name"), renderCell: params => (
          <DataGridLinkColumn href={`${editUrl}${params.row?.id}`} params={params} />
        ), flex: 1 },
    { field: "color", headerName: I18n.t("Program.attributes.color"), type: "color", flex: 1 },
    { field: "code", headerName: I18n.t("Program.attributes.code"), flex: 1 },
    { field: "sku", headerName: I18n.t("Program.attributes.sku"), flex: 1 },
    { field: "duration", headerName: I18n.t("Program.attributes.duration"), type: "number" },
    { field: "has_options", headerName: I18n.t("Program.attributes.has_options"), type: "boolean" },
    { field: "sort_by", headerName: I18n.t("Program.attributes.sort_by"), type: "number" },
    { field: "active", headerName: I18n.t("Program.attributes.active"), type: "boolean" },
      ];

  return (
    <>
      {showHeader && (
          <PageHeader
            avatar={<TheaterComedyIcon />}
            title={I18n.t("Program.title")}
          />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="programs"
        {...props}
        //getHeaderName={field => I18n.t(`Program.attributes.${field.replaceAll(".", "_")}`)}
        canOrderRows
            editUrl={`${editUrl}:id`}
        canCreate={hasPermissions("program.create")}
        canDelete={hasPermissions(["program.editany", "program.editown"])}
        canEdit={hasPermissions(["program.editany", "program.editown"])}
        canDeleteRow={params => canEditItem("program", params.row)}
        canEditRow={params => canEditItem("program", params.row)}
        canBatchActions={hasPermissions(["program.editany", "program.editown"])}
        canHandleTrash={hasPermissions(["program.editany", "program.editown"])}      />
    </>
  );
}

