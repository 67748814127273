import UserMenu from "layouts/UserMenu";

import { useAppSelector } from "app/hooks";
import { selectNotificationCount } from "features/notifications/notificationsSlice";

export default function AdminUserMenu() {
  const notificationCount = useAppSelector(selectNotificationCount);

  return (
    <UserMenu>
      {/*<MenuItem to="/setup/users" component={Link}>
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("P.headerTitle")} />
  </MenuItem>*/}
    </UserMenu>
  );
}

