// @ts-nocheck

import { VIEWS } from "common";
import { CalendarCellProps } from "common/components/CalendarPro";
import StartBoatCellViewDay from "./StartBoatCellViewDay";
import StartBoatCellViewMonth from "./StartBoatCellViewMonth";
import StartBoatCellViewWeek from "./StartBoatCellViewWeek";
import useCell from "./useCell";

//export const CALENDAR_MONTH_CELL_WIDTH = "14.2857143%";
export const CALENDAR_MONTH_CELL_WIDTH = "14.25%";

export interface StartBoatCellProps
  extends CalendarCellProps<CalendarEventItem> {}
export default function StartBoatCell(props: StartBoatCellProps) {
  const { startTimes } = useCell(props);

  if (props.view && [VIEWS.DAY, VIEWS.AGENDA].includes(props.view)) {
    return <StartBoatCellViewDay startTimes={startTimes} {...props} />;
  }
  if (props.view && [VIEWS.WEEK].includes(props.view)) {
    return <StartBoatCellViewWeek startTimes={startTimes} {...props} />;
  }

  return <StartBoatCellViewMonth startTimes={startTimes} {...props} />;
}

