import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  alpha,
  useTheme,
} from "@mui/material";
import { FakeEvent } from "common/types";
import { BaseInputProps } from "../types";

export interface CounterButtonProps extends BaseInputProps {
  sx?: ButtonGroupProps["sx"];
  groupProps?: ButtonGroupProps;
  onChange?: (event: FakeEvent<number | null | undefined>) => void;
  showCount?: boolean;
  size?: ButtonGroupProps["size"];
  fullWidth?: boolean;
}

export default function CounterButton({
  value,
  onChange,
  sx,
  groupProps,
  size,
  showCount = true,
  label: _label,
  required,
  disabled,
  error,
  helperText,
  fullWidth,
}: CounterButtonProps) {
  function getIntValue() {
    return value ? parseInt(value as any) : 0;
  }
  const displayCounter = getIntValue() > 0;
  function handleIncrement(evt: any) {
    let v = getIntValue() + 1;
    onChange && onChange({ target: { value: v } });
  }
  function handleDecrement(evt: any) {
    let v = getIntValue() - 1;
    if (v < 0) {
      v = 0;
    }
    onChange && onChange({ target: { value: v } });
  }

  const label =
    required && _label ? (
      <span>
        {_label} <span style={{ color: "red" }}>*</span>
      </span>
    ) : (
      _label
    );
  const theme = useTheme();
  //let bgColor = disabled ? theme.palette.action.disabled : undefined;
  let bgColor: string | undefined = undefined;
  let borderColor: string | undefined = disabled
    ? theme.palette.action.disabledBackground
    : alpha(theme.palette.primary.main, 0.6);

  if (!!value) {
    if (disabled) {
      bgColor = theme.palette.action.disabledBackground;
      borderColor = theme.palette.action.disabledBackground;
    } else {
      bgColor = theme.palette.primary.main;
      borderColor = theme.palette.primary.main;
    }
  }
  const inputColor = bgColor
    ? theme.palette.getContrastText(bgColor)
    : undefined;

  return (
    <FormControl fullWidth={fullWidth}>
      {!!label && <FormLabel>{label}</FormLabel>}

      <ButtonGroup
        size={size}
        aria-label="small outlined button group"
        fullWidth={fullWidth}
        {...groupProps}
        sx={{ ...sx, ...(groupProps?.sx as any) }}
        disabled={disabled}
      >
        <Button onClick={handleDecrement}>-</Button>
        {showCount && (
          <InputBase
            disabled={disabled}
            fullWidth
            sx={{
              flex: 1,
              minWidth: 60,
              border: theme => `solid 1px ${borderColor}`,
              backgroundColor: bgColor,

              "& input": {
                textAlign: "center",
                fontWeight: "bold",
                color: inputColor,
              },
            }}
            value={getIntValue()}
            onChange={evt => {
              const v = evt.target?.value
                ? parseInt(evt.target?.value as any)
                : 0;
              if (isNaN(v)) {
                onChange && onChange({ target: { value: null } });
              } else {
                onChange && onChange({ target: { value: v } });
              }
            }}
          />
        )}
        <Button onClick={handleIncrement}>+</Button>
      </ButtonGroup>
      {(error || helperText) && (
        <FormHelperText error={error ? true : false}>
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

