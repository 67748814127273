import { Box, Chip } from "@mui/material";
import { Option } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import React from "react";

interface Limit {
  variant_ids: number[];
  number_of_places: number;
  number_of_online_places: number;
  places_tolerance: number;
  min_unit: number;
}

interface LimitsCellProps {
  params?: any;
  variants?: Option[];
}

const LimitsCell: React.FC<LimitsCellProps> = ({ params }) => {
  const limits: Limit[] = params?.row?.limits || [];
  const { resolveOption } = useDictionaries();
  return (
    <Box display="flex" flexDirection="column" padding="2px" fontSize="0.75rem">
      {limits?.map((limit, index) => (
        <Box
          key={index}
          marginBottom="4px"
          display="flex"
          flexDirection="column"
          padding="4px"
          sx={{
            border: "1px solid transparent",
            borderRadius: "4px",
            transition: "border-color 0.3s, background-color 0.3s",
            "&:hover": {
              borderColor: "primary.light",
              backgroundColor: "rgba(144, 202, 249, 0.1)",
            },
          }}
        >
          <Box display="flex" flexWrap="wrap" marginBottom="2px">
            {limit.variant_ids.map((id, idx) => {
              const variant = resolveOption("variants", id);
              return (
                <Chip
                  key={idx}
                  label={resolveOption("variants", id)?.label}
                  size="small"
                  sx={{
                    margin: "1px",
                    fontSize: "0.75rem",
                    backgroundColor: variant
                      ? "primary.light"
                      : "secondary.light",
                    color: variant
                      ? "primary.contrastText"
                      : "secondary.contrastText",
                  }}
                />
              );
            })}
            <Chip
              label={`Places: ${limit.number_of_places}`}
              size="small"
              sx={{
                margin: "1px",
                fontSize: "0.75rem",
                backgroundColor: "secondary.light",
                color: "secondary.contrastText",
              }}
            />
            <Chip
              label={`Online: ${limit.number_of_online_places}`}
              size="small"
              sx={{
                margin: "1px",
                fontSize: "0.75rem",
                backgroundColor: "secondary.light",
                color: "secondary.contrastText",
              }}
            />
            <Chip
              label={`Tolerance: ${limit.places_tolerance}`}
              size="small"
              sx={{
                margin: "1px",
                fontSize: "0.75rem",
                backgroundColor: "secondary.light",
                color: "secondary.contrastText",
              }}
            />
            <Chip
              label={`Min Unit: ${limit.min_unit}`}
              size="small"
              sx={{
                margin: "1px",
                fontSize: "0.75rem",
                backgroundColor: "secondary.light",
                color: "secondary.contrastText",
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LimitsCell;

