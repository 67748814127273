import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
} from "@mui/material";
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from "@mui/material/RadioGroup";

export interface RadioGroupProps
  extends Omit<MuiRadioGroupProps, "children" | "onChange"> {
  error?: string;
  label?: string;
  helperText?: string;
  isDirty?: boolean;
  required?: boolean;
  editable?: boolean;
  disabled?: boolean;
  onChange?: Function;
  value?: number | string;
  options?: any[];
  numeric?: boolean;
}

export default function RadioGroup({
  error,
  options,
  label: _label,
  helperText,
  onChange,
  isDirty,
  editable,
  value,
  required,
  disabled,
  numeric,
  ...rest
}: RadioGroupProps) {
  const label =
    required && _label ? (
      <span>
        {_label} <span style={{ color: "red" }}>*</span>
      </span>
    ) : (
      _label
    );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    //const v = (event.target as HTMLInputElement).value;
    if (numeric && value) {
      onChange && onChange({ target: { value: parseInt(event.target.value) } });
      return;
    }
    onChange && onChange(event);
  };
  return (
    <FormControl disabled={disabled}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <MuiRadioGroup
        onChange={handleChange}
        {...rest}
        value={value === 0 ? 0 : value || ""}
      >
        {options?.map((option: any, index: number) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              disabled={option?.disabled}
            />
          );
        })}
      </MuiRadioGroup>
      {(!!error || !!helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

