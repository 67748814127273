import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  BoxProps,
  FormHelperText,
  FormHelperTextProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactNode } from "react";
import Spacer from "./../Spacer";

const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface HintProps extends BoxProps {
  title?: string;
  message?: string;
  icon?: ReactNode;
  titleColor?: TypographyProps["color"];
  color?: FormHelperTextProps["color"];
  titleProps?: TypographyProps;
  messageProps?: FormHelperTextProps;
  onlyText?: boolean;
}

export default function Hint({
  title,
  message,
  icon,
  titleColor = "GrayText",
  color,
  titleProps,
  messageProps,
  onlyText,
  sx,
  ...restOfProps
}: HintProps) {
  return (
    <Container sx={sx as any} {...restOfProps}>
      {!!icon && (
        <>
          {icon}
          <Spacer w={1} />
        </>
      )}
      {!icon && !onlyText && (
        <>
          <InfoIcon color="info" fontSize="small" />
          <Spacer w={1} />
        </>
      )}
      {!!title && (
        <Typography variant="body2" color={color} {...titleProps}>
          {title}
        </Typography>
      )}
      {!!message && (
        <FormHelperText color={color} {...messageProps}>
          {message}
        </FormHelperText>
      )}
    </Container>
  );
}

