import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import FilePreview, { FilePreviewType } from "./FilePreview";

const Transition = React.forwardRef((props: any, ref: any) => {
  return (
    // use Slide to display a clearer transition,
    // can replace it with Fade
    <Grow
      ref={ref}
      {...props}
      // do something after animation exited
      /*onExited={() => {
        //history.goBack();
        props.onExited()
      }}*/
    />
  );
});

export function previewFile({
  url,
  title,
  fileName,
  type,
}: {
  url: string;
  title?: string;
  fileName?: string;
  type?: FilePreviewType;
}) {
  let event = new CustomEvent("onFilePreview", {
    detail: {
      url,
      title,
      fileName,
      type,
    },
  });
  document.dispatchEvent(event);
}

export default function GlobalFilePreview() {
  const [url, setUrl] = useState<any>();
  const [type, setType] = useState<FilePreviewType>();
  const [title, setTitle] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  //"https://udg.huninsolex.hu/api/files/download/3?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwNzAxNTIsImV4cCI6MTY1NDA5ODk1MiwianRpIjoyLCJyb2xlIjoxfQ.5udUyugso6k2RBoYVFDhU_OcLTbBr1SRfAvhQuJUUrg"
  //"https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf"
  //"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"

  const handleFilePreview = useCallback(
    //@ts-ignore
    ({ detail: { url, title, fileName, type } }) => {
      setUrl(url);
      setTitle(title);
      setFileName(fileName);
      setType(type);
    },
    []
  );

  useEffect(() => {
    //@ts-ignore
    document.addEventListener("onFilePreview", handleFilePreview);
    return () =>
      //@ts-ignore
      document.removeEventListener("onFilePreview", handleFilePreview);
  }, []);

  return (
    <Dialog
      open={!!url}
      onClose={() => setUrl(null)}
      maxWidth="xl"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {title || "Preview"}
            {fileName && <Typography variant="body2">{fileName}</Typography>}
          </Box>

          <Box
            sx={{ display: "felx", flexDirection: "row", alignItems: "center" }}
          >
            {/*<Tooltip title="Open in new tab">
              <IconButton component={Link} href={url} target="_blank">
                <OpenInNewIcon />
              </IconButton>
  </Tooltip>*/}
            <Tooltip title="Download">
              <IconButton component={Link} href={url} download>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={() => setUrl(null)}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FilePreview url={url} title={title} fileName={fileName} type={type} />
      </DialogContent>
    </Dialog>
  );
}

