import { Grid, GridProps } from "@mui/material";
import { ReactNode } from "react";

export interface FieldsContainerProps extends GridProps {
  children: ReactNode;
}

export default function FieldsContainer({
  children,
  ...props
}: FieldsContainerProps) {
  return (
    <Grid container spacing={2} {...props}>
      {children}
    </Grid>
  );
}

