import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import EventForm from "./EventForm";
import { EventItem } from "./eventApi";
import useEventList from "./useEventList";
export interface EventListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function EventList({
  showHeader = true,
  ...props
}: EventListProps) {
  const { params, scope, editUrl, ...service } = useEventList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<EventItem> = [
    {
      field: "start_time",
      headerName: I18n.t("Event.attributes.start_time"),
      type: "datetime",
    },
    {
      hide: !!params.boat_id,
      hideable: !params.boat_id,
      field: "boat.name",
      headerName: I18n.t("Event.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.program_id,
      hideable: !params.program_id,
      field: "program.name",
      headerName: I18n.t("Event.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.schedule_id,
      hideable: !params.schedule_id,
      valueFormatter: params =>
        `${params?.row?.schedule?.name} ${params?.row?.schedule?.date_from}-${params?.row?.schedule?.date_to}`,
      field: "schedule.{name} {date_from}-{date_to}",
      headerName: I18n.t("Event.attributes.schedule_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/schedules/${params.row?.schedule_id}`}
          params={params}
        />
      ),
    },
    {
      field: "duration",
      headerName: I18n.t("Event.attributes.duration"),
      hideable: true,
      type: "number",
    },
    {
      field: "limits",
      headerName: I18n.t("Event.attributes.limits"),
      type: "json",
    },
    {
      field: "variants",
      headerName: I18n.t("Event.attributes.variants"),
      type: "json",
    },
    {
      field: "options",
      headerName: I18n.t("Event.attributes.options"),
      type: "json",
    },
    {
      field: "number_of_guests",
      headerName: I18n.t("Event.attributes.number_of_guests"),
      type: "number",
    },
    {
      field: "number_of_seats",
      headerName: I18n.t("Event.attributes.number_of_seats"),
      type: "number",
    },
    {
      field: "number_of_babies",
      headerName: I18n.t("Event.attributes.number_of_babies"),
      type: "number",
    },
    {
      field: "closed",
      headerName: I18n.t("Event.attributes.closed"),
      type: "json",
    },
    {
      field: "online_closed",
      headerName: I18n.t("Event.attributes.online_closed"),
      type: "json",
    },
    {
      field: "custom",
      headerName: I18n.t("Event.attributes.custom"),
      type: "boolean",
      hideable: true,
    },
    {
      field: "active",
      headerName: I18n.t("Event.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<CalendarMonthIcon />}
          title={I18n.t("Event.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="events"
        renderModalForm={props => <EventForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Event.attributes.${field.replaceAll(".", "_")}`)}
        defaultState={{
          hidden_columns: ["schedule_id", "boat_id", "duration", "custom"],
        }}
        canCreate={hasPermissions("event.create")}
        canDelete={hasPermissions(["event.editany", "event.editown"])}
        canEdit={hasPermissions(["event.editany", "event.editown"])}
        canDeleteRow={params => canEditItem("event", params.row)}
        canEditRow={params => canEditItem("event", params.row)}
        canBatchActions={hasPermissions(["event.editany", "event.editown"])}
        canHandleTrash={hasPermissions(["event.editany", "event.editown"])}
      />
    </>
  );
}

