
import { Divider } from "@mui/material";
import {
  Autocomplete,
  Field,
  FieldsContainer,
  Form,
  Switch,
  enumToOptions,
} from "common";
import { VIEWS } from "common/components/CalendarPro";
import SettingsButton from "components/SettingsButton/SettingsButton";
import { CalendarFontSizes } from "features/settings/settingsSlice";
import useSettings from "features/settings/useSettings";

export interface CalendarSettingsProps {}
export default function CalendarSettings({}: CalendarSettingsProps) {
  const [settings, setSettings] = useSettings({ key: "calendar" });
  return (
    <SettingsButton title="Naptár beállítások">
      <Form
        item={settings}
        onChange={(item: any) => setSettings(item)}
        translationCategory="Calendar.settings"
      >
        <FieldsContainer>
          <Field fullWidth>
            <Autocomplete
              id="view"
              options={enumToOptions(VIEWS, "Calendar.views").filter(
                v => v.value !== VIEWS.WORK_WEEK
              )}
            />
          </Field>
          <Field fullWidth>
            <Autocomplete
              id="font_size"
              options={enumToOptions(CalendarFontSizes, "Calendar.font_sizes")}
            />
          </Field>
          <Field fullWidth>
            <Switch id="show_indicators" labelFirst fullWidth />
            <Divider />
            <Switch id="show_full_events" labelFirst fullWidth />
            <Divider />
            <Switch id="show_closed_events" labelFirst fullWidth />
            <Divider />
            <Switch id="always_show_remaining_spaces" labelFirst fullWidth />
          </Field>
        </FieldsContainer>
      </Form>
    </SettingsButton>
  );
}

