import { identity, pickBy } from "lodash";
import { createContext, useContext, useMemo } from "react";
import { Params, useParams as useRouteParams } from "react-router-dom";

export const ParamsContext = createContext<any>(undefined);
export function useParams<
  ParamsOrKey extends string | Record<string, string | undefined> = string
>(): Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
> {
  const routeParams = useRouteParams<ParamsOrKey>();
  const parentParams = useContext(ParamsContext);

  const params = useMemo(
    () =>
      ({
        ...pickBy(parentParams, identity),
        ...pickBy(routeParams, identity),
      } as any),
    [routeParams, parentParams]
  );
  return params;
}

