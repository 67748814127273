import { useAppDispatch, useAppSelector } from "app/hooks";
import { SessionHandler as MuiXSessionHandler } from "common";
import {
  logoutAsync,
  refreshAsync,
  selectAccessTokenExpiresAt,
} from "features/identity/identitySlice";

export default function SessionHandler() {
  const tokenExpiresAt = useAppSelector(selectAccessTokenExpiresAt);
  const dispatch = useAppDispatch();

  return (
    <MuiXSessionHandler
      tokenExpiresAt={tokenExpiresAt}
      logout={() => dispatch(logoutAsync())}
      refreshToken={() => dispatch(refreshAsync())}
    />
  );
}

