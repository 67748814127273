import PersonIcon from "@mui/icons-material/Person";
import PhotoIcon from "@mui/icons-material/Photo";
import { Avatar } from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  GridColumns,
  PageHeader,
  previewFile,
} from "common";
import getRemoteUri from "common/modules/helpers";
import useDictionaries from "features/dictionaries/useDictionaries";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import useUserList from "./useUserList";
import { UserItem } from "./userApi";
export interface UserListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function UserList({
  showHeader = true,
  ...props
}: UserListProps) {
  const { params, scope, editUrl, ...service } = useUserList();
  const { hasPermissions, canEditItem } = useAccess();
  const { resolveOption, dictionaries } = useDictionaries();
  let columns: GridColumns<UserItem> = [
    //{ field: "first_name", headerName: I18n.t("User.attributes.first_name"), hideable: true, flex: 1 },
    //{ field: "last_name", headerName: I18n.t("User.attributes.last_name"), hideable: true, flex: 1 },
    {
      field: "file_id",
      headerName: I18n.t("User.attributes.file_id"),
      renderCell: params2 => {
        //TODO: URL
        const photoUrl = getRemoteUri({
          id: params2.row?.file_id,
          extension: "jpg",
        } as any);
        return (
          <Avatar
            onClick={() => {
              photoUrl &&
                previewFile({
                  url: photoUrl,
                  title: params2.row.name,
                  type: "img",
                });
            }}
            src={photoUrl}
            variant="rounded"
            sx={{
              cursor: "pointer",
              background: "transparent",
              border: theme =>
                `solid 1px ${
                  photoUrl ? theme.palette.primary.light : theme.palette.divider
                }`,
              color: photoUrl ? "primary.main" : "divider",
              width: "25px",
              height: "25px",
            }}
          >
            {!photoUrl && <PhotoIcon />}
          </Avatar>
        );
      },
      width: 60,
      sortable: false,
    },
    {
      field: "name",
      headerName: I18n.t("User.attributes.name"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
    },
    { field: "email", headerName: I18n.t("User.attributes.email"), flex: 1 },
    {
      field: "login_at",
      headerName: I18n.t("User.attributes.login_at"),
      type: "datetime",
    },
    {
      field: "role_ids",
      headerName: I18n.t("User.attributes.role_ids"),
      sortable: false,
      renderCell: params => {
        console.log(params.row.role_ids);
        console.log(dictionaries.roles);
        return params.row.role_ids
          .map(role_id => resolveOption("roles", role_id)?.label)
          .join(", ");
      },
    },
    {
      field: "settings",
      headerName: I18n.t("User.attributes.settings"),
      type: "json",
    },
    {
      field: "active",
      headerName: I18n.t("User.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader avatar={<PersonIcon />} title={I18n.t("User.title")} />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="users"
        {...props}
        //getHeaderName={field => I18n.t(`User.attributes.${field.replaceAll(".", "_")}`)}
        defaultState={{
          hidden_columns: ["first_name", "last_name"],
        }}
        editUrl={`${editUrl}:id`}
        canCreate={hasPermissions("user.create")}
        canDelete={hasPermissions(["user.editany", "user.editown"])}
        canEdit={hasPermissions(["user.editany", "user.editown"])}
        canDeleteRow={params => canEditItem("user", params.row)}
        canEditRow={params => canEditItem("user", params.row)}
        //canBatchActions={hasPermissions(["user.editany", "user.editown"])}
        canHandleTrash={hasPermissions(["user.editany", "user.editown"])}
      />
    </>
  );
}
