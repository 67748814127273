import api from "app/api";
import { Options } from "common";
import { ProgramItem } from "features/programs/programApi";
export interface ProgramVariantItem {
  id: number;
  name: string;
  program_id: number;
  program?: ProgramItem;
  code: string;
  sku: string;
  kitchen_group: string;
  min_unit: number;
  active: boolean;
}

export type ProgramVariantOptions = Options<"role" | "status">;

export function getProgramVariantService(scope?: any) {
  return api.getService<
    ProgramVariantItem,
    ProgramVariantItem,
    ProgramVariantOptions
  >("program-variants", {
    scope,
    //params goes here
  });
}

