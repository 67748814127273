//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  OptionGroupItem,
  getOptionGroupService,
} from "./optionGroupApi";

export default function useOptionGroupList() {
  const params = useParams();
  const scope = undefined;
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "option-groups/"
      : ""
  }`
  const service = getOptionGroupService();

  return { ...service, params, scope, editUrl };
}

