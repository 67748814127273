import api from "app/api";
import { Options } from "common";
import { Table } from "components/TableEditor/TableEditor";

export interface ProgramItem {
  id: number;
  name: string;
  color: string;
  code: string;
  sku: string;
  duration: number;
  has_options: boolean;
  has_layout: boolean;
  sort_by: number;
  seating_layout?: Table;
  active: boolean;
}

export type ProgramOptions = Options<"role" | "status">;

export function getProgramService(scope?: any) {
  return api.getService<ProgramItem, ProgramItem, ProgramOptions>("programs", {
    scope,
    //params goes here
  });
}

