import MenuIcon from "@mui/icons-material/Menu";
import { AppBarProps, ToolbarProps } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import Spacer from "../Spacer";
export interface HeaderProps extends Omit<AppBarProps, "title" | "content"> {
  title?: String;
  right?: ReactNode;
  logo?: ReactNode;
  content?: ReactNode;
  onMenuClick?: Function;
  showMenuButton?: boolean;
  toolbarProps?: ToolbarProps;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  mdUp?: boolean;
}

export default function Header({
  title,
  logo,
  right,
  onMenuClick,
  showMenuButton,
  toolbarProps,
  content,
  open,
  setOpen,
  mdUp,
  ...rest
}: HeaderProps) {
  return (
    <AppBar
      {...rest}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        ...rest.sx,
      }}
    >
      <Toolbar {...toolbarProps}>
        {showMenuButton && (
          <IconButton
            color="inherit"
            onClick={() => onMenuClick && onMenuClick()}
          >
            <MenuIcon />
          </IconButton>
        )}
        {logo}
        {!!logo && !!title && <Spacer w={1} />}
        {!!title && (
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        )}
        {!!content && (
          <>
            <Spacer w={1} />
            {content}
          </>
        )}
        <Spacer right />
        {right}
      </Toolbar>
    </AppBar>
  );
}

