import { useTheme } from "@mui/material/styles";
import Logo from "app/components/Logo/Logo";
//@ts-ignore
import { Header as MuiXHeader, HeaderProps as MuiXHeaderProps } from "common";
import { usePageHeaderGetter } from "common/components/PageHeader/PageHeaderProvider";
import I18n from "i18n";

export interface HeaderProps extends MuiXHeaderProps {}

export default function Header(props: HeaderProps) {
  const theme = useTheme();
  const headerProps = usePageHeaderGetter();
  return (
    <MuiXHeader
      style={{ border: "none" }}
      logo={<Logo size={40} />}
      title={I18n.t("App.title")}
      sx={{ background: theme.palette.primary.main, zIndex: 1210 }}
      {...props}
    />
  );
}

