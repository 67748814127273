import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getOptionGroupService } from "features/option-groups/optionGroupApi";
import { getProgramService } from "features/programs/programApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import { OptionItem, OptionOptions, getOptionService } from "./optionApi";

export interface UseOptionFormProps extends GridModalFormProps {}

type RouteParams = {
  program_option_id?: string;
};

export interface UseOptionFormReturnType
  extends UseFormReturnType<OptionItem, OptionOptions> {
  params?: Readonly<
    Params<
      | "program_option_id"
      | "program_id"
      | "option_group_id"
      | "booking_option_id"
    >
  >;
}

export default function useOptionForm({
  id,
  afterSave,
  defaultValues,
}: UseOptionFormProps): UseOptionFormReturnType {
  const params = useParams<
    "program_option_id" | "program_id" | "option_group_id" | "booking_option_id"
  >();
  const scope = {
    programs: params.program_id,
    "option-groups": params.option_group_id,
  };
  const service = getOptionService(scope);

  const form = useForm<OptionItem, OptionOptions>({
    id: params.program_option_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Option",
    rules: {
      name: Validator.rule.string().required(),
      program_id: params.program_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      code: Validator.rule.string().required(),
      option_group_id: params.option_group_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      program_id: () => getProgramService(undefined).loadAutocomplete(),
      option_group_id: () =>
        getOptionGroupService(undefined).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}

