type DeepClone<T> = T extends object
  ? {
      [K in keyof T]: DeepClone<T[K]>;
    }
  : T;
export function deepCloneJson<T>(obj: T): DeepClone<T> {
  return JSON.parse(JSON.stringify(obj));
}

export function deepClone<T>(obj: T): T {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const res: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      res[key] = deepClone(obj[key]);
    }
  }
  return Object.assign(res, obj) as T;
}
