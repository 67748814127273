import { useParams } from "react-router-dom";
import { getFileService } from "./fileApi";

export default function useFileList() {
  const params = useParams();
  const service = getFileService();
  /*const list = useGrid<
    FileFormItem,
    FileListItem
  >({
    storageType: "urlParams",
    id: "Files",
    translationCategory: "File",
    ...service,
  });*/

  return { ...service, params };
}

