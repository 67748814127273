import { Box, BoxProps, alpha, useTheme } from "@mui/material";

import { EventItem } from "features/events/eventApi";
import { useEventCalculator } from "../../useEventCalculator";
import { BoatGroup } from "../cells/useStartBoatCell";
import EventDot from "./EventDot";

export interface StartBoatGroupProps extends Omit<BoxProps, "onClick"> {
  item: BoatGroup;
  onClick?: (item: EventItem) => void;
  onAddClick?: (item: EventItem) => void;
  eventProps?: Omit<BoxProps, "onClick">;
  isSelectedFunc?: (item: EventItem) => boolean;
}
export default function StartBoatGroup({
  item,
  onClick,
  onAddClick,
  sx,
  eventProps,
  isSelectedFunc,
  ...props
}: StartBoatGroupProps) {
  const theme = useTheme();
  const { getBoatData } = useEventCalculator();
  const { boatLimit, boatSx, boatClassName } = getBoatData(item.events[0]);

  return (
    <Box
      className={boatClassName}
      sx={{
        flex: 1,
        display: "flex",
        gap: "1px",
        borderRadius: "3px",
        border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.2)}`,
        "&:hover": {
          border: theme => `solid 1px ${alpha(theme.palette.info.main, 1)}`,
        },
        padding: "2px",
        ...(boatSx as any),
        ...sx,
      }}
    >
      {item.events.map(event => (
        <EventDot
          key={event.id}
          boatLimit={boatLimit}
          item={event}
          isSelected={isSelectedFunc ? isSelectedFunc(event) : undefined}
          onClick={onClick ? () => onClick && onClick(event) : undefined}
          onAddClick={
            onAddClick ? () => onAddClick && onAddClick(event) : undefined
          }
          {...eventProps}
        />
      ))}
    </Box>
  );
}
