import { BoxProps, FormControlLabel } from "@mui/material";
import { Toolbar } from "common";
import { FakeEvent } from "common/types";
import { useCallback } from "react";
import Checkbox from "../Checkbox/Checkbox";

export interface CheckboxGroupProps extends Omit<BoxProps, "onChange"> {
  error?: string;
  label?: string;
  helperText?: string;
  isDirty?: boolean;
  required?: boolean;
  editable?: boolean;
  disabled?: boolean;
  onChange?: (evt: FakeEvent<(number | string)[]>) => void;
  value?: (number | string)[];
  options?: any[];
  multiple?: boolean;
}

export default function CheckboxGroup({
  error,
  options,
  label: _label,
  helperText,
  onChange,
  isDirty,
  editable,
  value = [],
  required,
  disabled,
  sx,
  color,
  multiple = true,
  ...props
}: CheckboxGroupProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const optionValue = parseInt(event.target.value);
      let newValue;
      if (multiple) {
        const isChecked = value.includes(optionValue);
        newValue = isChecked
          ? value.filter(v => v !== optionValue).map(v => parseInt(v as any))
          : [...value, optionValue];
      } else {
        newValue = checked ? [optionValue] : [];
      }
      onChange && onChange({ target: { value: newValue } });
    },
    [onChange, value, multiple]
  );

  return (
    <Toolbar
      sx={{
        gap: 1,
        flex: 1,
        maxWidth: "100%",
        justifyContent: "center",
        ...sx,
      }}
      {...props}
    >
      {options?.map((option: any) => {
        const isChecked = value.includes(option.value);
        return (
          <FormControlLabel
            key={option.value}
            value={option.value}
            sx={{
              marginLeft: 0,
              marginRight: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
              verticalAlign: "baseline",
              "& .MuiButtonBase-root": {
                marginTop: "-2px",
              },
              minWidth: "16px",
            }}
            disableTypography
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleChange}
                disabled={option?.disabled}
                color={option?.color || color}
              />
            }
            label={option.label}
          />
        );
      })}
    </Toolbar>
  );
}
