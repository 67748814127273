import { AddBox, AddHome } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Remove from "@mui/icons-material/Remove";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Spacer, Toolbar } from "common";
import { FakeEvent } from "common/types";
import { uniqueId } from "lodash";
import React, { useMemo, useState } from "react";
import { Rnd } from "react-rnd";
import ObjectFrom from "./ObjectFrom";

export interface Table {
  id: string;
  external?: string;
  name: string;
  color: string;
  window?: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  max_person?: number;
  type?: "table" | "other";
}

interface TableEditorProps {
  id?: string;
  label?: string;
  value?: Table[];
  onChange?: (tables: FakeEvent<Table[]>) => void;
  gridSize?: number;
}

const TableEditor: React.FC<TableEditorProps> = ({
  value = [],
  onChange,
  label,
  gridSize = 15,
}) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  /*const item: Table | null =
    value && selectedIndex !== null ? value[selectedIndex] : ({} as Table);*/

  const item = useMemo(() => {
    return value && selectedIndex !== null ? value[selectedIndex] : undefined;
  }, [value, selectedIndex]);

  function apply(item?: Table) {
    if (selectedIndex !== null && item) {
      const updatedTables = [...(value || [])];
      updatedTables[selectedIndex] = item;
      if (onChange) {
        onChange({ target: { value: updatedTables } });
      }
    }
  }

  const addObject = (type: "table" | "other") => {
    let maxID = 0;

    value?.forEach(v => {
      if (v.type === "table") {
        maxID = Math.max(parseInt(v.name), maxID);
      }
    });
    /* color: type === "table" ? "#00FF00" : "#fff500",
    //x: gridSize + 2,
    //y: gridSize + 2,
    x: item.x + gridSize + 1 + item.width,
    y: item.y,*/

    const newTable: Table = {
      color:
        type === "table"
          ? theme.palette.success.main
          : theme.palette.warning.main,
      x: item ? item.x + gridSize + 1 + item.width : gridSize + 2,
      y: item ? item.y : gridSize + 2,
      type,
      max_person: type === "table" ? 2 : undefined,
      id: `${uniqueId(type)}`,
      name: type === "table" ? `${maxID + 1}` : "Új",
      width: type === "table" ? gridSize * 3 - 1 : gridSize * 5 - 1,
      height: gridSize * 3 - 1,
    };
    const updatedTables = [...(value || []), newTable];
    setSelectedIndex(updatedTables.length - 1);
    if (onChange) {
      onChange({ target: { value: updatedTables } });
    }
  };

  const removeTable = () => {
    const updatedTables = (value || []).filter(
      (v, index) => index !== selectedIndex
    );
    setSelectedIndex(null);
    if (onChange) {
      onChange({ target: { value: updatedTables } });
    }
  };

  const handleDragStop = (id: string, x: number, y: number) => {
    const updatedTables = value?.map(table =>
      table.id === id
        ? {
            ...table,
            x: Math.round(x / gridSize) * gridSize + 2,
            y: Math.round(y / gridSize) * gridSize + 2,
            width: Math.round(table.width / gridSize) * gridSize - 1,
            height: Math.round(table.height / gridSize) * gridSize - 1,
          }
        : table
    );
    onChange && onChange({ target: { value: updatedTables } });
  };

  const handleResizeStop = (id: string, width: number, height: number) => {
    const updatedTables = value?.map(table =>
      table.id === id
        ? {
            ...table,
            x: Math.round(table.x / gridSize) * gridSize + 2,
            y: Math.round(table.y / gridSize) * gridSize + 2,
            width: Math.round(width / gridSize) * gridSize - 1,
            height: Math.round(height / gridSize) * gridSize - 1,
          }
        : table
    );
    onChange && onChange({ target: { value: updatedTables } });
  };

  const handleTableDoubleClick = (index: number) => {
    setSelectedIndex(index);
    setDialogOpen(true);
  };
  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "tbackground.default",
        border: theme => `solid 1px ${theme.palette.divider}`,
        borderRadius: theme => `${theme.shape.borderRadius}px`,
        p: 2,
      }}
    >
      <Toolbar>
        {!!label && <Typography variant="button">{label}</Typography>}
        <Spacer right />

        {isEditMode && (
          <>
            <IconButton
              onClick={() => removeTable()}
              title="Kiválasztott asztal eltávolítása"
            >
              <Remove />
            </IconButton>
            <IconButton
              onClick={() => addObject("table")}
              title="Új asztal hozzáadása"
            >
              <AddBox />
            </IconButton>
            <IconButton
              onClick={() => addObject("other")}
              title="Új objektum hozzáadása"
            >
              <AddHome />
            </IconButton>
          </>
        )}
        <IconButton
          onClick={() => setIsEditMode(!isEditMode)}
          title={isEditMode ? "Szerkesztés kikapcsolása" : "Szerkesztés"}
        >
          {isEditMode ? <Close /> : <Edit />}
        </IconButton>
      </Toolbar>
      <Box
        sx={{
          position: "relative",
          //width: "100%",
          height: "500px",
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.sheet,
          backgroundImage: `linear-gradient(to right,  ${theme.palette.divider} 1px, transparent 1px),linear-gradient(to bottom,  ${theme.palette.divider} 1px, transparent 1px)`,
          backgroundSize: `${gridSize}px ${gridSize}px`,
          borderRadius: `${theme.shape.borderRadius}px`,
          //overflow: "hidden",
          pointerEvents: isEditMode ? undefined : "none",
        }}
      >
        {value?.map((table, index) => (
          <Rnd
            key={table.id}
            size={{ width: table.width, height: table.height }}
            position={{ x: table.x, y: table.y }}
            onDragStop={(e, d) => {
              handleDragStop(table.id, d.x, d.y);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              handleResizeStop(
                table.id,
                parseInt(ref.style.width, 10),
                parseInt(ref.style.height, 10)
              );
            }}
            onClick={() => handleClick(index)}
            onDoubleClick={() => handleTableDoubleClick(index)}
            bounds="parent"
            grid={[gridSize, gridSize]}
            enableResizing={{
              bottom: false,
              bottomLeft: false,
              bottomRight: true,
              left: false,
              right: false,
              top: false,
              topLeft: false,
              topRight: false,
            }}
            style={{
              border:
                selectedIndex === index && isEditMode
                  ? `2px dashed ${theme.palette.error.main}`
                  : `2px solid ${table.color}`,
              backgroundColor: table.color,
              position: "absolute",
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                fontWeight: 700,
                color: theme.palette.getContrastText(table.color),
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 2,
                  fontWeight: 700,
                  color: theme.palette.getContrastText(table.color),
                  opacity: 0.7,
                  zIndex: -1,
                }}
              >
                {table.max_person}
              </Typography>
              {table.name}
            </Box>
          </Rnd>
        ))}
      </Box>
      <ObjectFrom
        item={item}
        open={isDialogOpen}
        onClose={handleDialogClose}
        onChange={item => {
          handleDialogClose();
          apply(item);
        }}
      />
    </Box>
  );
};

export default TableEditor;
