import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, BoxProps, Tooltip, useTheme } from "@mui/material";
import { Button } from "common";
import I18n from "i18n";
import { EventItem, EventLimit } from "../eventApi";
import useLimitCloseButton from "./useLimitCloseButton";

interface LimitCloseButtonProps extends BoxProps {
  item?: EventLimit;
  event: EventItem;
  isOnline?: boolean;
  //setClosed: (variant_id: number[], is_closed: boolean) => void;
}

export default function LimitCloseButton({
  item,
  event,
  isOnline,
  //setClosed,
  ...boxProps
}: LimitCloseButtonProps) {
  const theme = useTheme();
  const { setClosed, setOnlineClosed } = useLimitCloseButton({ item: event });
  const state = {
    closed: isOnline ? item?.online_closed : item?.closed,
    variant_ids: item?.variant_ids || (item as any)?.variant_id,
    over_limit: isOnline ? item?.online_over_limit : item?.over_limit,
    over_tolerance: isOnline
      ? item?.online_over_tolerance
      : item?.over_tolerance,
    number_of_places: isOnline
      ? item?.number_of_online_places
      : item?.number_of_places,
  };
  let closedTooltip = state.closed
    ? I18n.t("Event.open")
    : I18n.t("Event.close");
  if (isOnline) {
    closedTooltip = state.closed
      ? I18n.t("Event.openOnline")
      : I18n.t("Event.closeOnline");
  }

  const toggleClosed = isOnline
    ? () => setOnlineClosed(state.variant_ids, !state.closed)
    : () => setClosed(state.variant_ids, !state.closed);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 1,
      }}
      {...boxProps}
    >
      <Box minWidth={30}>{state.number_of_places}</Box>
      <Box
        sx={{
          minWidth: "18px",
          maxWidth: "18px",
          minHeight: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {state.closed === null && (
          <Tooltip title={I18n.t("Event.variantClosed")}>
            <InfoOutlined fontSize="small" color="info" />
          </Tooltip>
        )}
      </Box>
      {state.closed !== true && (
        <>
          {state.over_limit && (
            <Button
              disabled={!!item?.closed}
              color="error"
              title={I18n.t("Event.close")}
              size="small"
              variant="text"
              onClick={toggleClosed}
              sx={{ minWidth: 50 }}
            />
          )}
          {!state.over_limit && item?.over_tolerance && (
            <Button
              disabled={!!item?.closed}
              color="warning"
              title={I18n.t("Event.close")}
              size="small"
              variant="text"
              onClick={toggleClosed}
              sx={{ minWidth: 50 }}
            />
          )}
          {!state.over_limit && !state.over_tolerance && (
            <Button
              disabled={!!item?.closed}
              sx={{ minWidth: 50 }}
              variant="text"
              title={I18n.t("Event.close")}
              onClick={toggleClosed}
              color="inherit"
              size="small"
            />
          )}
        </>
      )}
      {state.closed && (
        <Button
          disabled={isOnline && !!item?.closed}
          sx={{ minWidth: 50 }}
          variant="text"
          color="success"
          title={I18n.t("Event.open")}
          onClick={toggleClosed}
          size="small"
        />
      )}
    </Box>
  );
}
