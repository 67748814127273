import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { BoatItem } from "./boatApi";
import BoatForm from "./BoatForm";
import useBoatList from "./useBoatList";
export interface BoatListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function BoatList({
  showHeader = true,
  ...props
}: BoatListProps) {
  const { params, scope, editUrl, ...service } = useBoatList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<BoatItem> = [
    {
      field: "name",
      headerName: I18n.t("Boat.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      field: "capacity",
      headerName: I18n.t("Boat.attributes.capacity"),
      type: "number",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<DirectionsBoatIcon />}
          title={I18n.t("Boat.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="boats"
        renderModalForm={props => <BoatForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Boat.attributes.${field.replaceAll(".", "_")}`)}
        canOrderRows
        canCreate={hasPermissions("boat.create")}
        canDelete={hasPermissions(["boat.editany", "boat.editown"])}
        canEdit={hasPermissions(["boat.editany", "boat.editown"])}
        canDeleteRow={params => canEditItem("boat", params.row)}
        canEditRow={params => canEditItem("boat", params.row)}
        canBatchActions={hasPermissions(["boat.editany", "boat.editown"])}
        canHandleTrash={hasPermissions(["boat.editany", "boat.editown"])}
      />
    </>
  );
}

