import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  FileItem,
  ProfileForm,
  loadProfile,
  updateProfile,
} from "./profileAPI";

export interface ProfileData {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  roles: string[];
  permissions: string[];
  file_id: number;
  file: FileItem;
}

export interface ProfileState {
  data: ProfileData;
  status: "idle" | "loading" | "failed";
}

const initialState: ProfileState = {
  data: {} as ProfileData,
  status: "idle",
};

export const loadProfileAsync = createAsyncThunk(
  "profile/load",
  async (_, { rejectWithValue }) => {
    const { data, error, success } = await loadProfile();

    if (success) {
      return data;
    }
    return rejectWithValue(error);
  }
);

export const updateProfileAsync = createAsyncThunk(
  "profile/update",
  async (item: ProfileForm, { rejectWithValue }) => {
    const { data, error, success } = await updateProfile(item);
    if (success) {
      return data;
    }
    return rejectWithValue(error);
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfile: state => {
      state.data = {} as ProfileData;
    },
    resetStatus: state => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    //Load profile
    builder
      .addCase(loadProfileAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(loadProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
    //Update profile
    builder
      .addCase(updateProfileAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(updateProfileAsync.rejected, state => {
        state.status = "idle";
      });
  },
});

export const { clearProfile, resetStatus } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile.data;
export const selectProfileStatus = (state: RootState) => state.profile.status;
export const selectProfileRoles = (state: RootState) =>
  state.profile.data.roles;
export const selectProfilePermissions = (state: RootState) =>
  state.profile.data.permissions;

export default profileSlice.reducer;

