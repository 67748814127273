import HandshakeIcon from "@mui/icons-material/Handshake";
import I18n from "i18n";
import { PageHeader, Spacer, DataGridBaseBaseProps, DataGridV2 as DataGrid, GridColumns } from "common";
import useAccess from "features/profile/useAccess";
import PartnerForm from "./PartnerForm";
import usePartnerList from "./usePartnerList";
import { PartnerItem } from "./partnerApi";
import { DataGridModalEditColumn } from "common";
import { DataGridLinkColumn } from "common";
export interface PartnerListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function PartnerList({ showHeader = true, ...props }: PartnerListProps) {
  const { params, scope, editUrl, ...service } = usePartnerList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<PartnerItem> = [
    { field: "name", headerName: I18n.t("Partner.attributes.name"), renderCell: params => (
          <DataGridModalEditColumn href={`${editUrl}${params.row?.id}`} params={params} />
        ), flex: 1 },
    { field: "email", headerName: I18n.t("Partner.attributes.email"), flex: 1 },
    { field: "phone", headerName: I18n.t("Partner.attributes.phone"), flex: 1 },
    { hide: !!params.partner_group_id, hideable: !params.partner_group_id, field: "partner_group.name", headerName: I18n.t("Partner.attributes.partner_group_id"), renderCell: params => (
        <DataGridLinkColumn href={`/partner-groups/${params.row?.partner_group_id}`} params={params} />
      ) },
    { field: "commission", headerName: I18n.t("Partner.attributes.commission"), type: "number" },
    { field: "online", headerName: I18n.t("Partner.attributes.online"), type: "boolean" },
    { field: "active", headerName: I18n.t("Partner.attributes.active"), type: "boolean" },
      ];

  return (
    <>
      {showHeader && (
          <PageHeader
            avatar={<HandshakeIcon />}
            title={I18n.t("Partner.title")}
          />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="partners"
        renderModalForm={props => <PartnerForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Partner.attributes.${field.replaceAll(".", "_")}`)}
                canCreate={hasPermissions("partner.create")}
        canDelete={hasPermissions(["partner.editany", "partner.editown"])}
        canEdit={hasPermissions(["partner.editany", "partner.editown"])}
        canDeleteRow={params => canEditItem("partner", params.row)}
        canEditRow={params => canEditItem("partner", params.row)}
        canBatchActions={hasPermissions(["partner.editany", "partner.editown"])}
        canHandleTrash={hasPermissions(["partner.editany", "partner.editown"])}      />
    </>
  );
}

