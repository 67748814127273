import settings from "app/settings";
import { useEffect, useState } from "react";
import { tokenIsExpired, tokenWillExpire } from "./../api/apiHelper";

let interval: any;

function stopListen() {
  if (interval) {
    clearInterval(interval);
  }
}

function startListen(intervalAction: Function, time: number) {
  stopListen();
  intervalAction();
  interval = setInterval(() => {
    if (intervalAction) {
      intervalAction();
    }
  }, 5000);
}

export interface UseSessionHandlerProps {
  tokenExpiresAt: number | undefined;
  logout: Function;
  refreshToken: Function;
  time?: number;
}
export default function useSessionHandler({
  tokenExpiresAt,
  logout,
  refreshToken,
  time = 5000,
}: UseSessionHandlerProps) {
  const [open, setOpen] = useState<boolean>(false);

  function handleLogout() {
    logout();
  }
  function handleRefresh() {
    setOpen(false);
    refreshToken();
  }
  useEffect(() => {
    if (tokenExpiresAt !== undefined) {
      startListen(() => {
        if (
          tokenExpiresAt &&
          tokenWillExpire(
            tokenExpiresAt,
            settings.refreshTokenNotificationDeltaTime
          )
        ) {
          if (open === false) {
            setOpen(true);
          }
        }

        if (tokenExpiresAt && tokenIsExpired(tokenExpiresAt)) {
          handleLogout();
        }
      }, time);
    }

    return () => {
      stopListen();
    };
  }, [open, tokenExpiresAt]);
  return {
    open,
    handleLogout,
    handleRefresh,
  };
}

