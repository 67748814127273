import { Box, Paper, Typography } from "@mui/material";
import Logo from "app/components/Logo/Logo";
import {
  Button,
  Field,
  FieldsContainer,
  Form,
  FormToolbar,
  PasswordField,
  Spacer,
  TextField,
} from "common";
import useLoginFrom from "features/identity/useLoginFrom";
import I18n from "i18n";
import { Link } from "react-router-dom";

export default function Login() {
  const {
    item,
    errors,
    onChange,
    login,
    loading,
    emailFieldRef,
    passwordFieldRef,
    autoFilled,
  } = useLoginFrom({});

  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "500px",
          minWidth: "200px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Link
            style={{
              flex: 1,
              maxWidth: 100,
              maxHeight: 100,
              display: "flex",
              justifyContent: "center",
            }}
            to="/"
          >
            <Logo size={60} />
          </Link>
        </Box>
        <Spacer h={4} />
        <Typography variant="h3" align="center">
          {I18n.t("App.title")}
        </Typography>
        <Spacer h={4} />
        <Paper sx={{ padding: 4 }} elevation={5}>
          <Typography variant="h5">{I18n.t("LoginForm.title")}</Typography>
          <Spacer h={4} />
          <Form
            item={item}
            errors={errors}
            onChange={onChange}
            translationCategory="LoginForm.attributes"
          >
            <FieldsContainer>
              <Field fullWidth>
                <TextField
                  id="email"
                  inputRef={emailFieldRef}
                  useDelayedKeystrokes={false}
                  onEnter={() => login()}
                  InputLabelProps={{
                    shrink: autoFilled,
                  }}
                />
              </Field>
              <Field fullWidth>
                <PasswordField
                  inputRef={passwordFieldRef}
                  id="password"
                  useDelayedKeystrokes={false}
                  onEnter={() => login()}
                  InputLabelProps={{
                    shrink: autoFilled,
                  }}
                />
              </Field>
            </FieldsContainer>
          </Form>
          <Spacer h={2} />
          <FormToolbar variant="dense">
            <Spacer right />
            <Button
              loading={loading}
              onClick={() => login()}
              title={I18n.t("LoginForm.login")}
            />
          </FormToolbar>
        </Paper>
      </Box>
    </Box>
  );
}

