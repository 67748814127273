//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  ScheduleItem,
  getScheduleService,
} from "./scheduleApi";

export default function useScheduleList() {
  const params = useParams<"boat_id">();
  const scope = { "boats": params.boat_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "schedules/"
      : ""
  }`
  const service = getScheduleService(scope);

  return { ...service, params, scope, editUrl };
}

