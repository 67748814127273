import Check from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  darken,
  lighten,
  useTheme,
} from "@mui/material";

const Dummy = () => null;
export interface CheckboxProps extends Omit<MuiCheckboxProps, "color"> {
  color?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  color = "#000000",
  sx,
  ...props
}: CheckboxProps) => {
  const theme = useTheme();

  return (
    <MuiCheckbox
      checkedIcon={
        <Check
          sx={{
            fontSize: "1rem",
            color: theme.palette.getContrastText(color),
            stroke: theme.palette.getContrastText(color),
          }}
          fontSize="small"
        />
      }
      icon={<Dummy />}
      indeterminateIcon={
        <RemoveIcon
          sx={{
            fontSize: "1rem",
            color: theme.palette.getContrastText(color),
            stroke: theme.palette.getContrastText(color),
          }}
          fontSize="small"
        />
      }
      sx={{
        width: "16px",
        minWidth: "16px",
        minHeight: "16px",
        //paddingLeft: "2px",
        //paddingRight: "2px",
        height: "16px",
        borderRadius: "2px",
        padding: 0,
        mr: 1,
        backgroundColor: color,
        color: theme.palette.getContrastText(color),
        borderColor:
          theme.palette.mode === "dark"
            ? lighten(color, 0.3)
            : darken(color, 0.3),
        borderWidth: "1px",
        borderStyle: "solid",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          backgroundColor: color,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default Checkbox;

