import { useAppDispatch, useAppSelector } from "app/hooks";
import { Switch, SwitchProps } from "common";
import { selectThemeMode, setThemeMode } from "features/theme/themeSlice";

export interface ThemeModeSwitcherProps
  extends Omit<SwitchProps, "onChange" | "value"> {}

export default function ThemeModeSwitcher(props: ThemeModeSwitcherProps) {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector(selectThemeMode);

  function handleThemeModeChange(event: any) {
    dispatch(setThemeMode(event.target.value ? "dark" : "light"));
  }

  return (
    <Switch
      value={themeMode === "dark"}
      onChange={handleThemeModeChange}
      {...props}
    />
  );
}

