//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  OptionItem,
  getOptionService,
} from "./optionApi";

export default function useOptionList() {
  const params = useParams<"program_id" | "option_group_id">();
  const scope = { "programs": params.program_id, "option-groups": params.option_group_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "options/"
      : ""
  }`
  const service = getOptionService({ "programs": params.program_id, "option-groups": params.option_group_id });

  return { ...service, params, scope, editUrl };
}

