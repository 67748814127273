import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { VIEWS } from "common/components/CalendarPro";

export enum CalendarFontSizes {
  SMALL = "calFontSizeSmall",
  NORMAL = "calFontSizeNormal",
  LARGE = "calFontSizeLarge",
}

export type CalendarSettings = {
  view: VIEWS;
  show_indicators: boolean;
  show_full_events: boolean;
  show_closed_events: boolean;
  always_show_remaining_spaces: boolean;
  font_size: CalendarFontSizes;
};
export type CateringSettings = {
  boatIds: number[];
  programIds: number[];
};
export type KitchenSettings = {
  boatIds: number[];
  programIds: number[];
};
export type SettingsState = {
  calendar: CalendarSettings;
  catering: CateringSettings;
  kitchen: KitchenSettings;
  // Add more settings keys here as needed
};
const initialState: SettingsState = {
  calendar: {
    view: VIEWS.WEEK,
    show_closed_events: true,
    show_full_events: true,
    show_indicators: true,
    always_show_remaining_spaces: false,
    font_size: CalendarFontSizes.SMALL,
  },
  catering: {
    boatIds: [],
    programIds: [],
  },
  kitchen: {
    boatIds: [],
    programIds: [],
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (
      state,
      action: PayloadAction<{ key: keyof SettingsState; settings: any }>
    ) => {
      state[action.payload.key] = action.payload.settings;
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const selectSettingsByKey = (
  state: RootState,
  key: keyof SettingsState
) => state.settings[key];

export default settingsSlice.reducer;
