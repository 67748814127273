import api from "app/api";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";

export interface BookingOption {
  id: number;
  name: string;
  qty: number;
  option_group_id: number;
  option_group_name: string;
}

export interface Booking {
  id: number;
  other: boolean;
  booking_number: string;
  external_booking_number?: string;
  program_variant_id: number;
  program_id: number;
  start_time: string;
  boat_id: number;
  boat_name: string;
  number_of_adults: number | null;
  number_of_kids: number | null;
  number_of_babies: number | null;
  number_of_guests: number | null;
  name: string;
  email: string;
  description: string | null;
  variant_name: string | null;
  program_variant_name: string | null;
  table_numbers: string[] | null;
  booking_options: BookingOption[];
  program_variant?: ProgramVariantItem;
  check_in_at?: string;
  paid?: boolean;
}

export interface Summary {
  name: string;
  qty: number;
  type: "option" | "variant";
  item_name?: string;
  id: number;
}

export interface GuestSummary {
  number_of_guests: number;
  number_of_adults: number;
  number_of_kids: number;
  number_of_babies: number;
  guests_arrived: number;
  bookings_arrived: number;
  number_of_bookings: number;
  tables_reserved: number;
  tables_left: number;
  bookings_unpaid: number;
}

export interface CateringItem {
  start_time: string;
  boat_id: number;
  boat_name: string;
  program_ids: number[];
  summary: Summary[];
  guestSummary: { [key: string | number]: GuestSummary };
  bookings: Booking[];
}

export function getCateringData(
  startDate: string | undefined,
  endDate: string | undefined,
  boatIds: number[] | null,
  programIds: number[] | null
) {
  return api.get<CateringItem[]>("/catering", {
    params: {
      from: startDate,
      to: endDate,
      boatIds,
      programIds,
    },
  });
}
