import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  OptionGroupItem,
  OptionGroupOptions,
  getOptionGroupService,
} from "./optionGroupApi";
import { OPTIONGROUP_TYPES } from "./optionGroupConstants";

export interface UseOptionGroupFormProps extends GridModalFormProps {}

type RouteParams = {
  option_group_id?: string;
};

export interface UseOptionGroupFormReturnType
  extends UseFormReturnType<OptionGroupItem, OptionGroupOptions> {
  params?: Readonly<
    Params<"option_group_id" | "program_option_id" | "booking_option_id">
  >;
}

export default function useOptionGroupForm({
  id,
  afterSave,
  defaultValues,
}: UseOptionGroupFormProps): UseOptionGroupFormReturnType {
  const params = useParams<
    "option_group_id" | "program_option_id" | "booking_option_id"
  >();
  const scope = undefined;
  const service = getOptionGroupService(scope);

  const form = useForm<OptionGroupItem, OptionGroupOptions>({
    id: params.option_group_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "OptionGroup",
    rules: {
      name: Validator.rule.string().required(),
      type: Validator.rule.string().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      type: () => enumToOptions(OPTIONGROUP_TYPES, "OptionGroup.options.type"),
    }),
  });

  return { ...form, params };
}

