import Link, { LinkProps } from "@mui/material/Link";
import { GridCellParams } from "../types";

export interface DataGridModalEditColumnProps<T = any> extends LinkProps {
  params: GridCellParams<T>;
}
export default function DataGridModalEditColumn<T = any>({
  params,
  ...rest
}: DataGridModalEditColumnProps<T>) {
  if (!params.row) {
    return null;
  }
  return (
    <Link
      href={`#`}
      onClick={evt => {
        evt.preventDefault();
        //@ts-ignore
        params.onEditClick && params.onEditClick(params.row.id, params.row);
      }}
      {...rest}
    >
      {params.value}
    </Link>
  );
}

