import PersonIcon from "@mui/icons-material/Person";
import { Box } from "@mui/material";
import api from "app/api";
import {
  AvatarFile,
  Button,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  PasswordField,
  Spacer,
  TextField,
  Toolbar,
  useDownloadUrl,
} from "common";
import I18n from "i18n";
import useProfileFrom from "./useProfileFrom";

export default function ProfileForm() {
  const { item, errors, loading, onChange, updateProfile, getIsDirty } =
    useProfileFrom();
  const { uploadFile } = api.getService("files");
  const { downloadUrl } = useDownloadUrl();
  const avatarUri = downloadUrl(item.file_id);
  return (
    <Box maxWidth="sm">
      <PageHeader
        avatar={<PersonIcon />}
        title={I18n.t("Profile.title")}
        backUrl=".."
      />
      <Spacer h={2} />
      <Form
        item={item}
        errors={errors}
        onChange={onChange}
        translationCategory="Profile.attributes"
        isDirty={getIsDirty}
      >
        <FieldsContainer>
          <Field fullWidth sx={{ justifyContent: "center", display: "flex" }}>
            <AvatarFile
              value={item.file_id}
              id="file_id"
              upload={uploadFile}
              uri={avatarUri}
              buttonSize="medium"
              size={80}
              buttonsPlacement="bottom"
              sx={{
                border: theme => `solid 3px ${theme.palette.action.active}`,
              }}
            />
          </Field>
          <Field xs={12} md={6}>
            <TextField id="first_name" />
          </Field>
          <Field xs={12} md={6}>
            <TextField id="last_name" />
          </Field>
          <Field xs={12} md={6}>
            <TextField id="email" />
          </Field>
          <Field xs={12} md={6}>
            <PasswordField id="password" fake />
          </Field>
        </FieldsContainer>
      </Form>
      <Spacer h={2} />
      <Toolbar>
        <Spacer right />
        <Button
          loading={loading}
          onClick={() => updateProfile()}
          disabled={!getIsDirty()}
        >
          {I18n.t("App.save")}
        </Button>
      </Toolbar>
    </Box>
  );
}

