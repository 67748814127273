import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FileItem } from "common/types";
import { get } from "lodash";
import QueryString from "qs";
import { SaveFormProps } from "./../../components/Form";
import { Options } from "./../helpers";

export interface PaginationResponse<L = any> {
  success: boolean;
  error?: any;
  data?: L[];
  total: number;
  page: number;
  pageSize: number;
}

export interface Service<T, L = any, O extends Options = Options> {
  loadItems(queryParams?: string, onlyTrashed?: boolean): Promise<L[]>;
  loadAutocomplete(queryParams?: string, onlyTrashed?: boolean): Promise<L[]>;
  paginateItems(
    queryParams?: string,
    onlyTrashed?: boolean
  ): Promise<PaginationResponse<L>>;
  loadItem(id: any, onlyTrashed?: boolean): Promise<T>;
  uploadFile(file: FileItem): Promise<T>;
  updateItem(
    id: number,
    item: Partial<T>,
    onlyTrashed?: boolean
  ): Promise<SaveFormProps<T>>;
  moveItem(id: number, item: T, targetId: number): Promise<SaveFormProps<T>>;
  createItem(item: Partial<T>): Promise<SaveFormProps<T>>;
  deleteItem(id: any, onlyTrashed?: boolean): Promise<SaveFormProps<T>>;
  deleteItems(ids: any[], onlyTrashed?: boolean): Promise<SaveFormProps<T>>;
  restoreItem(id: any): Promise<SaveFormProps<T>>;
  restoreItems(ids: any[]): Promise<SaveFormProps<T>>;
  loadOptions<_O = O>(
    requestConfig?: AxiosRequestConfig,
    component?: string
  ): Promise<_O>;
}
export interface ApiConfig {
  optionParser?(data: any, component?: string): any;
  itemParser?(data: any): any;
  listParser?(data: any): any;
  formErrorParser?(data: any): any;
  paginationParser?(data: any): PaginationResponse;
  uploadFile?(file: FileItem, access_token: string): any;
  /**
   * Triggered on unhandled errors;
   * error object
   * @param error
   */
  axiosFatalErrorHandler?(error: any): void;
  /**
   * Add additional headers to all request;
   * @param state redux state default type any
   * @returns headers { [key: string]: string } | undefined
   */
  headers?(state: any): object | undefined | void;
}

export interface ServiceConfig {
  optionsURL?: string;
  optionParser?(data: any, component?: string): any;
  itemParser?(data: any): any;
  itemExporter?(data: any): any;
  listParser?(data: any): any;
  formErrorParser?(data: any): any;
  paginationParser?(data: any): PaginationResponse;
  uploadFile?(file: FileItem, access_token: string): any;
  axiosFatalErrorHandler?(error: any): void;
  noIdParam?: boolean;
  scope?: any;
  urlParams?: any;
}

const createApiService =
  (api: AxiosInstance, getStore: Function, apiConfig?: ApiConfig) =>
  <T, L = any, O extends Options = Options>(
    _url: string,
    config?: ServiceConfig
  ): Service<T, L, O> => {
    let url = _url;
    if (config?.scope) {
      let scopeParams: any[] = [];
      Object.keys(config.scope).forEach(key => {
        if (config.scope[key]) {
          if (scopeParams.length === 0) {
            scopeParams.push(`${key}/${config.scope[key]}`);
          }
        }
      });
      if (scopeParams.length > 0) {
        url = `${scopeParams.join("/")}/${_url}`;
      }
    }

    async function loadItems(
      queryParams?: string,
      onlyTrashed?: boolean
    ): Promise<L[]> {
      let params = [];
      if (queryParams) {
        params.push(queryParams);
      }
      if (onlyTrashed) {
        params.push("onlyTrashed=1");
      }
      if (config?.urlParams) {
        Object.keys(config?.urlParams).forEach(key => {
          params.push(`${key}=${config?.urlParams[key]}`);
        });
      }
      const { data } = await api.get(
        `${url}${params.length > 0 ? `?${params.join("&")}` : ""}`
      );
      if (config?.listParser) {
        return config.listParser(data);
      }
      if (apiConfig?.listParser) {
        return apiConfig.listParser(data);
      }

      return data;
    }
    async function loadAutocomplete(
      queryParams?: string,
      onlyTrashed?: boolean
    ): Promise<any> {
      let params = [];
      if (queryParams) {
        params.push(queryParams);
      }
      if (onlyTrashed) {
        params.push("onlyTrashed=1");
      }
      const { data } = await api.get(
        `${url}/autocomplete${params.length > 0 ? `?${params.join("&")}` : ""}`
      );
      /*if (config?.listParser) {
        return config.listParser(data);
      }
      if (apiConfig?.listParser) {
        return apiConfig.listParser(data);
      }*/

      return data;
    }
    async function paginateItems(
      queryParams?: string,
      onlyTrashed?: boolean
    ): Promise<PaginationResponse<L>> {
      let params = [];
      if (queryParams) {
        params.push(queryParams);
      }
      if (onlyTrashed) {
        params.push("onlyTrashed=1");
      }

      if (config?.urlParams) {
        Object.keys(config?.urlParams).forEach(key => {
          params.push(`${key}=${config?.urlParams[key]}`);
        });
      }

      const { success, data, error } = await api.get(
        `${url}${params.length > 0 ? `?${params.join("&")}` : ""}`
      );
      if (!success) {
        return {
          success,
          error,
          total: 0,
          page: 1,
          pageSize: 10,
        };
      }
      if (config?.paginationParser) {
        return config.paginationParser(data);
      }
      if (apiConfig?.paginationParser) {
        return apiConfig.paginationParser(data);
      }

      return {
        data: data.data,
        success,
        error,
        total: data.total,
        page: data.current_page,
        pageSize: data.per_page,
      };
    }

    async function loadItem(id: any, onlyTrashed?: boolean): Promise<T> {
      let query = `${url}/${id}${onlyTrashed ? "?onlyTrashed=1" : ""}`;
      if (config?.noIdParam) {
        query = `${url}${onlyTrashed ? "?onlyTrashed=1" : ""}`;
      }
      const { data } = await api.get(query);
      if (config?.itemParser) {
        return config.itemParser(data);
      }
      if (apiConfig?.itemParser) {
        return apiConfig.itemParser(data);
      }
      return data;
    }

    async function uploadFile(file: FileItem): Promise<T> {
      var formData = new FormData();
      const store = getStore();
      const state = store.getState();
      let access_token = get(state, "identity.data.access_token");

      /*formData.append("file", {
        //@ts-ignore
        uri: file.uri,
        name: file.name,
        type: file.mimeType || "image/jpg",
      });*/
      formData.append("file", file as any);

      if (config?.uploadFile) {
        return config.uploadFile(file, access_token);
      }

      if (apiConfig?.uploadFile) {
        return apiConfig.uploadFile(file, access_token);
      }

      const response = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    }

    async function loadOptions<_O = O>(
      requestConfig?: AxiosRequestConfig,
      component?: string
    ): Promise<_O> {
      let params = [];

      if (config?.urlParams) {
        Object.keys(config?.urlParams).forEach(key => {
          params.push(`${key}=${config?.urlParams[key]}`);
        });
      }
      if (component) {
        params.push(`component=${component}`);
      }

      const { data, request } = await api.get(
        config?.optionsURL ||
          `${url}/options${params.length > 0 ? `?${params.join("&")}` : ""}`,
        requestConfig
      );
      //console.trace(request._url, request.fromCache);
      if (config?.optionParser) {
        return config.optionParser(data, component);
      }
      if (apiConfig?.optionParser) {
        return apiConfig.optionParser(data, component);
      }
      return data;
    }

    async function moveItem(
      id: number,
      item: T,
      targetId: number
    ): Promise<SaveFormProps<T>> {
      let response = await api.post(`${url}/${id}/move`, { targetId });
      if (response.success && response.data && config?.itemParser) {
        response.data = config.itemParser(response.data);
      } else if (response.success && response.data && apiConfig?.itemParser) {
        response.data = apiConfig.itemParser(response.data);
      }

      if (!response.success && config?.formErrorParser) {
        response.error = config.formErrorParser(response.error);
      } else if (!response.success && apiConfig?.formErrorParser) {
        response.error = apiConfig.formErrorParser(response.error);
      }

      return response;
    }

    async function updateItem(
      id: number,
      item: T,
      onlyTrashed?: boolean
    ): Promise<SaveFormProps<T>> {
      let response = await api.put(
        `${url}/${id}${onlyTrashed ? "?onlyTrashed=1" : ""}`,
        config?.itemExporter ? config.itemExporter(item) : item
      );
      if (response.success && response.data && config?.itemParser) {
        response.data = config.itemParser(response.data);
      } else if (response.success && response.data && apiConfig?.itemParser) {
        response.data = apiConfig.itemParser(response.data);
      }

      if (!response.success && config?.formErrorParser) {
        response.error = config.formErrorParser(response.error);
      } else if (!response.success && apiConfig?.formErrorParser) {
        response.error = apiConfig.formErrorParser(response.error);
      }

      return response;
    }

    async function createItem(item: T): Promise<SaveFormProps<T>> {
      let response = await api.post(
        `${url}`,
        config?.itemExporter ? config.itemExporter(item) : item
      );
      if (response.success && response.data && config?.itemParser) {
        response.data = config.itemParser(response.data);
      } else if (response.success && response.data && apiConfig?.itemParser) {
        response.data = apiConfig.itemParser(response.data);
      }

      if (!response.success && config?.formErrorParser) {
        response.error = config.formErrorParser(response.error);
      } else if (!response.success && apiConfig?.formErrorParser) {
        response.error = apiConfig.formErrorParser(response.error);
      }

      return response;
    }

    function deleteItem(
      id: any,
      onlyTrashed?: boolean
    ): Promise<SaveFormProps<T>> {
      return api.delete(`${url}/${id}${onlyTrashed ? "?onlyTrashed=1" : ""}`);
    }

    function deleteItems(
      ids: any[],
      onlyTrashed?: boolean
    ): Promise<SaveFormProps<T>> {
      return api.delete(
        `${url}?${QueryString.stringify({ ids })}${
          onlyTrashed ? "&onlyTrashed=1" : ""
        }`
      );
    }

    function restoreItem(id: any): Promise<SaveFormProps<T>> {
      return api.post(`${url}/${id}/restore`);
    }

    function restoreItems(ids: any[]): Promise<SaveFormProps<T>> {
      return api.post(`${url}/restore?${QueryString.stringify({ ids })}`);
    }

    return {
      loadItems,
      loadItem,
      updateItem,
      createItem,
      deleteItem,
      loadOptions,
      uploadFile,
      paginateItems,
      restoreItem,
      deleteItems,
      restoreItems,
      loadAutocomplete,
      moveItem,
    };
  };

export default createApiService;

