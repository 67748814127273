import CloseIcon from "@mui/icons-material/Close";
import PaletteIcon from "@mui/icons-material/Palette";
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import Spacer from "common/components/Spacer";
import { useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { ColorPickerBaseProps } from "react-colorful/dist/types";
import TextField, { TextFieldProps } from "../TextField";
export interface ColorPickerProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  value?: string;
  onChange?: (event: { target: { value?: string } }) => void;
  pickerProps?: Partial<ColorPickerBaseProps<string>>;
  changeOnClickAway?: boolean;
}
export function ColorPicker({
  pickerProps,
  changeOnClickAway = true,
  onChange,
  value,
  ...props
}: ColorPickerProps) {
  const theme = useTheme();
  const [color, setColor] = useState<ColorPickerProps["value"]>(value);
  const [open, setOpen] = useState(false);
  //const requestedByUser = useRef(false);
  const textFieldRef = useRef<null | HTMLDivElement>(null);

  /*const onChangeDebounced = debounce(
    (c: ColorPickerProps["value"]) =>
      onChange && onChange({ target: { value: c } }),
    300
  );*/

  /*function handleColorChange(c?: string) {
    requestedByUser.current = true;
    setColor(c);
  }*/

  function handleAccept() {
    onChange && onChange({ target: { value: color } });
  }

  useEffect(() => {
    setColor(value);
  }, [value]);

  /*useEffect(() => {
    if (requestedByUser.current) {
      requestedByUser.current = false;
      onChangeDebounced(color);
    }
  }, [color]);*/

  return (
    <Box>
      <TextField
        inputRef={textFieldRef}
        onClick={() => !open && setOpen(true)}
        onChange={onChange as any}
        value={value}
        InputProps={{
          //readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{ borderRadius: "3px", width: "20px", height: "20px" }}
                style={{ backgroundColor: value }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ marginRight: -6 }}>
              {!!color && color !== "" && props.clearable && (
                <IconButton
                  disabled={props.disabled}
                  onClick={() => {
                    onChange && onChange({ target: { value: null } } as any);
                  }}
                  sx={{ color: theme.palette.text.secondary }}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                disabled={props.disabled}
                //onClick={() => setOpen(true)}
                onClick={evt => {
                  evt.stopPropagation();
                  //handleClick()
                  //onChange && onChange({ target: { value: null } } as any);
                  if (open) {
                    if (changeOnClickAway) {
                      handleAccept();
                    } else {
                      setOpen(false);
                    }
                  } else {
                    setOpen(true);
                  }
                }}
                size="small"
              >
                <PaletteIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      <Popper
        id={props.id}
        open={open}
        anchorEl={textFieldRef.current}
        transition
        placement="bottom-start"
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper
              elevation={5}
              sx={{
                p: 1,
              }}
            >
              <ClickAwayListener
                onClickAway={() => {
                  if (changeOnClickAway) {
                    handleAccept();
                  }
                  open && setOpen(false);
                }}
              >
                <Box>
                  <HexColorPicker color={color} onChange={setColor} />
                  <Spacer h={1} />
                  <Box
                    sx={{ borderRadius: theme.shape.borderRadius }}
                    style={{ backgroundColor: color }}
                  >
                    <Typography
                      align="center"
                      color={theme.palette.getContrastText(
                        color || theme.palette.text.primary
                      )}
                    >
                      {color}
                    </Typography>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

