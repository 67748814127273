import { Toolbar, ToolbarProps } from "@mui/material";
import { ReactNode } from "react";

export interface FormToolbarProps extends ToolbarProps {
  children: ReactNode;
}

export default function FormToolbar({ children, ...props }: FormToolbarProps) {
  return (
    <Toolbar disableGutters {...props}>
      {children}
    </Toolbar>
  );
}

