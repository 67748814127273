import AvatarMenu from "layouts/AvatarMenu";

import { useAppSelector } from "app/hooks";
import { selectNotificationCount } from "features/notifications/notificationsSlice";

export default function AdminAvatarMenu() {
  const notificationCount = useAppSelector(selectNotificationCount);
  return (
    <AvatarMenu>
      {/*<MenuItem to="/setup/users" component={Link}>
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("P.headerTitle")} />
  </MenuItem>*/}
    </AvatarMenu>
  );
}

