import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RefreshIcon from "@mui/icons-material/Refresh";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { Button, ButtonProps, Spacer, Toolbar, enumToOptions } from "common";
import { NAVIGATE, VIEWS } from "common/components/CalendarPro";
import DateRangeSelector from "components/DateRangeSeletor/DateRangeSeletor";
import I18n from "i18n";
import { Moment } from "moment";
import { Fragment, ReactNode, useEffect, useState } from "react";

function CalendarButton(props: ButtonProps) {
  return <Button {...props} />;
}

export interface CalendarToolbarProps {
  label?: string | null;
  view: VIEWS;
  views: VIEWS[];
  onNavigate: (
    type: NAVIGATE,
    d?: Moment,
    noDelay?: boolean,
    _view?: VIEWS
  ) => void;
  onView: (_view: VIEWS) => void;

  buttons?: any[] | ReactNode;
  customToolbar: any;
  headerContent?: ReactNode;
  loading?: boolean;
  showRefreshButton?: boolean;
  refresh?: Function;
  date?: Moment;
  sx?: BoxProps["sx"];
}
export function setCalendarLoading(isLoading: boolean) {
  let event = new CustomEvent("setCalendarLoading", {
    detail: {
      isLoading,
    },
  });
  document.dispatchEvent(event);
}
export default function CalendarToolbar({
  label,
  view,
  views,
  onView,
  onNavigate,
  buttons,
  customToolbar,
  //loading,
  refresh,
  sx,
  headerContent,
  showRefreshButton = true,
  date,
}: CalendarToolbarProps) {
  const theme = useTheme();
  const viewOptions = enumToOptions(VIEWS, "CALNDAR.views");
  const [loading, setLoading] = useState(false);

  function handleSetLoading({
    detail: { isLoading },
  }: {
    detail: { isLoading: boolean };
  }) {
    setLoading(isLoading);
  }
  useEffect(() => {
    //@ts-ignore
    document.addEventListener("setCalendarLoading", handleSetLoading);
    return () =>
      //@ts-ignore
      document.removeEventListener("setCalendarLoading", handleSetLoading);
  }, []);
  return (
    <Box
      sx={{
        p: 1,
        pt: 2,
        position: "relative",
        flex: 1,
        //maxWidth: "calc(100% - 64px)",
        display: "flex",
        flexDirection: "column",
        "& input": {
          //padding: "6px 10px",
          //minHeight: "24px",
        },
        ...sx,
      }}
    >
      <Toolbar sx={{ gap: 1, flex: 1 }}>
        <IconButton onClick={() => onNavigate(NAVIGATE.PREVIOUS)}>
          <ChevronLeftIcon />
        </IconButton>
        <DateRangeSelector
          sx={{ minWidth: 180, minHeight: 37 }}
          //color="info"
          value={date}
          view={view}
          onChange={(startOf, newView) => {
            console.log(startOf);
            onNavigate(NAVIGATE.DATE, startOf, true, newView);
          }}
        />
        <IconButton onClick={() => onNavigate(NAVIGATE.NEXT)}>
          <ChevronRightIcon />
        </IconButton>
        <ToggleButtonGroup
          exclusive
          onChange={(evt, v) => onView(v)}
          value={view}
          size="small"
        >
          <ToggleButton
            title={I18n.t("Calendar.monthView")}
            value={VIEWS.MONTH}
            aria-label={I18n.t("Calendar.monthView")}
          >
            <CalendarViewMonthIcon />
          </ToggleButton>
          <ToggleButton
            title={I18n.t("Calendar.weekView")}
            value={VIEWS.WEEK}
            aria-label={I18n.t("Calendar.weekView")}
          >
            <CalendarViewWeekIcon />
          </ToggleButton>
          <ToggleButton
            title={I18n.t("Calendar.dayView")}
            value={VIEWS.DAY}
            aria-label={I18n.t("Calendar.dayView")}
          >
            <CalendarViewDayIcon />
          </ToggleButton>
          <ToggleButton
            title={I18n.t("Calendar.agendaView")}
            value={VIEWS.AGENDA}
            aria-label={I18n.t("Calendar.agendaView")}
          >
            <ViewAgendaIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Spacer right />
        {headerContent}
        {buttons && !Array.isArray(buttons) && buttons}
        {buttons &&
          Array.isArray(buttons) &&
          buttons.map((button, index) => {
            return (
              <Fragment key={index}>
                <CalendarButton
                  onClick={button.onClick}
                  variant="outlined"
                  title={button.title}
                />
                {buttons.length - 1 > index && <Spacer w={0.5} />}
              </Fragment>
            );
          })}
        {showRefreshButton && (
          <IconButton
            disabled={loading}
            onClick={refresh ? () => refresh() : undefined}
            sx={{ justifySelf: "flex-end" }}
          >
            {loading ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              <RefreshIcon />
            )}
          </IconButton>
        )}
      </Toolbar>
      {customToolbar}
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
        />
      )}
    </Box>
  );
}

