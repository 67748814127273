import { DialogActions, DialogContent } from "@mui/material";
import { TextField } from "common";
import { Autocomplete } from "common";
import { Checkbox } from "common";
import BookingList from "features/bookings/BookingList";
import KeyIcon from "@mui/icons-material/Key";
import { Paper } from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";import I18n from "i18n";
import {
  Button,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  CreatedUpdatedBy,
  Spacer
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import usePartnerForm, {
  UsePartnerFormProps,
  UsePartnerFormReturnType
} from "./usePartnerForm";
import { createContext } from "react";
import useAccess from "features/profile/useAccess";
import HistoryListDialog from "features/histories/HistoryListDialog";
export const PartnerContext = createContext<
  UsePartnerFormReturnType | undefined
>(undefined);
export interface PartnerFormProps
  extends UsePartnerFormProps {}

export default function PartnerForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: PartnerFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = usePartnerForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
    const title = isCreate ? I18n.t("Partner.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider
      value={{ partner_id: id, partner_group_id: item.partner_group_id }}
    >
    <PartnerContext.Provider value={form} >
      <PageHeader avatar={<HandshakeIcon />} title={title} subtitle={subtitle} onClose={onClose} loading={loading} />
      <DialogContent>
        <Form
          loading={loading}
          item={item}
          options={options}
          errors={errors}
          onChange={onChange}
          translationCategory="Partner.attributes"
          translationPlaceholdersCategory="Partner.placeholders"
          translationHelpersCategory="Partner.helpers"
          isDirty={getIsDirty}
          requiredFields={getRequiredFields()}
          disabled={!canEditItem("partner", item)}
        >
          <FieldsContainer>
                  <Field>
        <TextField id="name" />
      </Field>
      <Field>
        <TextField id="email" />
      </Field>
      <Field>
        <TextField id="phone" />
      </Field>
      { !params?.partner_group_id && (<Field>
        <Autocomplete id="partner_group_id" />
      </Field>)}
      <Field>
        <TextField id="commission" />
      </Field>
      <Field>
        <Checkbox id="online" />
      </Field>
      <Field>
        <Checkbox id="active" />
      </Field>          </FieldsContainer>
        </Form>
        {isUpdate && (<><Spacer h={3} />{hasPermissions(["booking.viewany", "booking.viewown"]) && <BookingList icon={<KeyIcon />} title={I18n.t("Booking.title")} showHeader={false} modalProps={{ maxWidth: "lg" }} /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/ />}</>)}      </DialogContent>
      <DialogActions>
        {isUpdate && <CreatedUpdatedBy item={item} sx={{p: 1, paddingTop: 0, paddingBottom: 0.5, marginRight: "auto"}} renderDialog={
          hasPermissions(["history.viewany", "history.viewown"])
            ? props => <HistoryListDialog {...props} subtitle={title} />
            : undefined
        } />}
        <Button
          title={I18n.t("App.save")}
          disabled={!isDirty}
          onClick={() => save()}
          loading={saving}
        />
      </DialogActions>
    </PartnerContext.Provider>
  </ParamsContext.Provider>
  );
}

