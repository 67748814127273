import { useMatch, useParams } from "react-router-dom";

type RouteParams = {
  tab?: string;
} & {};

export interface UseSelectedTabProps {
  pattern?: string;
  tabParam?: string;
}

export default function useSelectedTab({
  pattern,
  tabParam = "tab",
}: UseSelectedTabProps) {
  let params: any = useParams<"tab">();

  const match = useMatch(pattern || "");
  const matchTab = useMatch(`${pattern}/:${tabParam}/*`);
  const matchTab1 = useMatch(`:a/:b/${pattern}/:${tabParam}/*`);
  const matchTab2 = useMatch(`:a/:b/:c/:d/${pattern}/:${tabParam}/*`);
  if (pattern) {
    params = match
      ? match.params
      : matchTab
      ? matchTab.params
      : matchTab1
      ? matchTab1.params
      : matchTab2
      ? matchTab2.params
      : params;
  }

  return params.tab;
}

