import api from "app/api";
import { Options } from "common";

export interface BoatItem {
  id: number;
  name: string;
  capacity: number;
  bottom_floor: any[];
  top_floor: any[];
  sort_by: number;
}

export type BoatOptions = Options<"role" | "status">;

export function getBoatService(scope?: any) {
  return api.getService<BoatItem, BoatItem, BoatOptions>("boats", {
    scope,
    //params goes here
  });
}

