import api from "app/api";
import { Options } from "common";
import { BookingItem } from "features/bookings/bookingApi";
import { OptionItem } from "features/options/optionApi";
export interface BookingOptionItem {
  id: number;
  booking_id: number;
  booking?: BookingItem;
  program_option_id: number;
  option?: OptionItem;
  qty: number;
  price: number;
}

export type BookingOptionOptions = Options<"role" | "status">;

export function getBookingOptionService(scope?: any) {
  return api.getService<
    BookingOptionItem,
    BookingOptionItem,
    BookingOptionOptions
  >("booking-options", {
    scope,
    //params goes here
  });
}
