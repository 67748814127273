import LightModeIcon from "@mui/icons-material/LightMode";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  DropDownMenu as MuiXDropDownMenu,
  DropDownMenuProps as MuiXDropDownMenuProps,
} from "common";
import { logoutAsync } from "features/identity/identitySlice";
import { selectNotificationCount } from "features/notifications/notificationsSlice";
import ThemeModeSwitcher from "features/theme/ThemeModeSwitcher";
import I18n from "i18n";
import { Link } from "react-router-dom";

export interface AUserMenuProps extends MuiXDropDownMenuProps {}
export default function UserMenu({ children, ...props }: AUserMenuProps) {
  const notificationCount = useAppSelector(selectNotificationCount);
  const dispatch = useAppDispatch();
  function handleLogout() {
    dispatch(logoutAsync());
  }
  return (
    <MuiXDropDownMenu
      from={<SettingsIcon />}
      badgeContent={notificationCount.total}
      menuProps={{
        transformOrigin: { horizontal: "left", vertical: "bottom" },
        anchorOrigin: { horizontal: "left", vertical: "top" },
        sx: {
          mt: 0,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: -7,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      {...props}
    >
      <MenuItem to="/profile" component={Link}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("Profile.title")} />
      </MenuItem>
      <MenuItem to="/settings" component={Link}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={I18n.t("Settings.title")} />
      </MenuItem>
      {children}
      <Divider />
      <MenuItem onClick={() => handleLogout()}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={I18n.t("App.logout")} />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <LightModeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemSecondaryAction>
          <ThemeModeSwitcher size="small" />
        </ListItemSecondaryAction>
      </MenuItem>
      <Divider />
      <Typography
        variant="body2"
        align="center"
        sx={{ fontSize: 11, color: "#666666" }}
      >
        {I18n.t("App.version")} {process.env.REACT_APP_VERSION}
      </Typography>
    </MuiXDropDownMenu>
  );
}

