import EuroIcon from "@mui/icons-material/Euro";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { ChangeEvent, useEffect, useState } from "react";

interface PriceInputBaseProps {
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  switchDisabled?: boolean;
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "&.Mui-disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

const PriceInputBase: React.FC<PriceInputBaseProps> = ({
  value,
  onChange,
  disabled = false,
  placeholder = "",
  switchDisabled = false,
}) => {
  const [isPriceEnabled, setIsPriceEnabled] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (!isPriceEnabled && value !== 0) {
      onChange({
        target: { value: 0 as any } as EventTarget & HTMLInputElement,
      } as ChangeEvent<HTMLInputElement>);
    }
  }, [isPriceEnabled, value, onChange]);

  const handleTogglePrice = () => {
    setIsPriceEnabled(prev => !prev);
  };

  return (
    <StyledInputBase
      value={isPriceEnabled ? value : 0}
      onChange={onChange}
      disabled={!isPriceEnabled || disabled}
      placeholder={placeholder}
      inputProps={{ type: "number" }}
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            size="small"
            onClick={handleTogglePrice}
            edge="start"
            disabled={switchDisabled}
            sx={
              switchDisabled
                ? {
                    "& svg": {
                      fill: theme => theme.palette.info.main,
                    },
                  }
                : undefined
            }
            color={isPriceEnabled ? "success" : "default"}
          >
            <Box position="relative" display="flex" alignItems="center">
              <EuroIcon fontSize="small" />
              {!isPriceEnabled && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ transform: "rotate(45deg)", opacity: 0.5 }}
                >
                  <Box
                    width="100%"
                    height="2px"
                    bgcolor={theme.palette.text.primary}
                  />
                </Box>
              )}
            </Box>
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PriceInputBase;
