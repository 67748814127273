import api from "app/api";
import { Options } from "common";

export interface OptionGroupItem {
  id: number;
  name: string;
  type: string;
  has_price: boolean;
  has_description: boolean;
  required: boolean;
  kitchen: boolean;
  active: boolean;
}

export type OptionGroupOptions = Options<"role" | "status">;

export function getOptionGroupService(scope?: any) {
  return api.getService<
    OptionGroupItem,
    OptionGroupItem,
    OptionGroupOptions
  >("option-groups", {
    scope,
    //params goes here
  });
}

