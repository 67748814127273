import { Box, BoxProps, styled, useTheme } from "@mui/material";
import { ReactComponent as SvgLogoNormal } from "assets/logo.svg";
import { useMemo } from "react";

const LogoNormal = styled(SvgLogoNormal)`
  width: 70%;
  height: 70%;
`;

export interface LogoProps extends BoxProps {
  primaryColor?: string;
  secondaryColor?: string;
  mode?: "light" | "dark";
  size?: number;
}
export default function Logo({
  primaryColor,
  secondaryColor,
  mode,
  sx,
  size = 30,
  ...boxProps
}: LogoProps) {
  const theme = useTheme();
  const boxSxProp = useMemo<BoxProps["sx"]>(
    () => ({
      borderRadius: 1000,
      backgroundColor: "#FFFFFF",
      width: size,
      height: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& path.cls-1": {
        fill: theme.palette.logo.primary,
      },
      "& path.cls-4": {
        fill: theme.palette.logo.primary,
      },
      "& path.cls-2": {
        fill: theme.palette.logo.secondary,
      },
      "& path.cls-3": {
        fill: theme.palette.logo.secondary,
      },
      ...sx,
    }),
    [sx, theme]
  );
  return (
    <Box sx={boxSxProp} {...boxProps}>
      <LogoNormal />
    </Box>
  );
}

