import settings from "app/settings";
import { useSelector } from "react-redux";

export default function useDownloadUrl(inline?: boolean) {
  const accessToken = useSelector(
    (state: any) => state.identity.data.access_token
  );
  function downloadUrl(itemId: any) {
    if (!itemId) {
      return undefined;
    }
    return `${settings.apiHost}/files/${itemId}?token=${accessToken}${
      inline ? "&inline=1" : ""
    }`;
  }
  return { downloadUrl };
}

export function useFileUrl(id?: any, inline?: boolean) {
  const { downloadUrl } = useDownloadUrl(inline);
  return downloadUrl(id);
}

