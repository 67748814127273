import {
  globalMessageHandler,
  GridModalFormProps,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  getPartnerGroupService,
  PartnerGroupItem,
  PartnerGroupOptions,
} from "./partnerGroupApi";

export interface UsePartnerGroupFormProps extends GridModalFormProps {}

type RouteParams = {
  partner_group_id?: string;
};

export interface UsePartnerGroupFormReturnType
  extends UseFormReturnType<PartnerGroupItem, PartnerGroupOptions> {
  params?: Readonly<
    Params<
      "partner_group_id" | "partner_id" | "booking_id" | "booking_option_id"
    >
  >;
}

export default function usePartnerGroupForm({
  id,
  afterSave,
  defaultValues,
}: UsePartnerGroupFormProps): UsePartnerGroupFormReturnType {
  const params = useParams<
    "partner_group_id" | "partner_id" | "booking_id" | "booking_option_id"
  >();
  const scope = undefined;
  const service = getPartnerGroupService(scope);

  const form = useForm<PartnerGroupItem, PartnerGroupOptions>({
    id: params.partner_group_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "PartnerGroup",
    rules: {
      name: Validator.rule.string().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}

