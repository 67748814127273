import { TextFieldProps } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAppSelector } from "app/hooks";
import { selectLanguage } from "features/language/languageSlice";
import moment, { Moment } from "moment";
import { useState } from "react";
import { ColorIndicatorProps } from "../types";
export interface DatePickerProps
  extends ColorIndicatorProps,
    Omit<MuiDatePickerProps<Moment>, "value" | "onChange" | "renderInput"> {
  id?: string;
  valueFormat?: string;
  variant?: "outlined" | "standard" | "standard";
  label?: string;
  value?: any;
  isDirty?: boolean;
  clearable?: boolean;
  required?: boolean;
  onChange?: Function;
  placeholder?: string;
  textFieldProps?: Omit<TextFieldProps, "variant">;
  error?: string;
  helperText?: string;
  openOnInputClick?: boolean;
}
export default function DatePicker({
  id,
  variant, // = "outlined",
  label: _label,
  valueFormat = "YYYY-MM-DD",
  value: rawValue,
  onChange,
  placeholder,
  textFieldProps,
  error,
  helperText,
  isDirty,
  //inputFormat,
  clearable = true,
  required,
  indicatorColor,
  indicatorOpacity,
  openOnInputClick = true,
  slotProps,
  ...pickerProps
}: DatePickerProps) {
  const language = useAppSelector(selectLanguage);
  const [open, setOpen] = useState<boolean>(false);
  function exportDate(d: any) {
    if (!d) {
      return null;
    }
    const v = moment(d);

    if (!v.isValid()) {
      return null;
    }
    return d.format(valueFormat);
  }
  function parseDate(d: any) {
    if (!d) {
      return null;
    }
    const v = moment(d);

    if (!v.isValid()) {
      return null;
    }
    return d;
  }
  const value = parseDate(rawValue);

  const handleChange = (newValue: any) => {
    onChange && onChange({ target: { value: exportDate(newValue) } });
  };

  function getValue(): Moment | null {
    return !value || value === "" ? null : moment(value);
  }

  const label = required ? (
    <span>
      {_label} <span className="required-label-asterisk">*</span>
    </span>
  ) : (
    _label
  );

  let _slotProps: any = {
    ...slotProps,
    textField: {
      ...slotProps?.textField,
      ...textFieldProps,
      helperText: error || helperText,
      error: !!error,
    },
  };
  if (openOnInputClick) {
    _slotProps.textField = {
      ..._slotProps?.textField,
      onClick: () => {
        setOpen(true);
      },
    };
  }

  return (
    <LocalizationProvider adapterLocale={language} dateAdapter={AdapterMoment}>
      <MuiDatePicker<Moment>
        //calendars={1}
        open={open}
        onClose={() => setOpen(false)}
        //clearable
        onOpen={() => setOpen(true)}
        label={label}
        value={getValue()}
        onChange={handleChange}
        slotProps={_slotProps}
        {...pickerProps}
      />
    </LocalizationProvider>
  );
}

