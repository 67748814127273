import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Badge,
  IconButton,
  IconButtonProps,
  Menu,
  MenuProps,
} from "@mui/material";
import { ReactNode, cloneElement, useState } from "react";

export interface DropDownMenuProps {
  children?: ReactNode;
  iconButtonProps?: Omit<IconButtonProps, "children" | "onClick" | "size">;
  menuProps?: Omit<
    MenuProps,
    "children" | "anchorEl" | "open" | "onClose" | "onClick" | "PaperProps"
    //| "transformOrigin"
    //| "anchorOrigin"
  >;
  badgeContent?: string | number;
  from?: ReactNode;
}

export default function DropDownMenu({
  children,
  menuProps,
  iconButtonProps,
  badgeContent,
  from,
}: DropDownMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const triggerIsButton = ["Button", "IconButton"].includes(
    //@ts-ignore
    from?.type?.muiName
  );
  return (
    <>
      {triggerIsButton && from ? (
        cloneElement(from as any, { onClick: handleClick })
      ) : (
        <IconButton onClick={handleClick} size="small" {...iconButtonProps}>
          <Badge badgeContent={badgeContent} color="error">
            {!!from ? from : <MoreHorizIcon />}
          </Badge>
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              ...menuProps?.sx,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        {...menuProps}
      >
        {children}
      </Menu>
    </>
  );
}

