import {
  globalMessageHandler,
  GridModalFormProps,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import I18n from "i18n";
import { Params, useParams } from "react-router-dom";
import { getRoleService, RoleItem, RoleOptions } from "./roleApi";

type RouteParams = {
  role_id?: string;
};

export interface UseRoleFormReturnType
  extends UseFormReturnType<RoleItem, RoleOptions> {
  params?: Readonly<Params<"role_id">>;
}

export interface UseRoleFormProps extends GridModalFormProps {}

export default function useRoleForm({
  id,
  onClose,
  afterSave,
}: UseRoleFormProps): UseRoleFormReturnType {
  const params = useParams<"role_id">();
  const scope = undefined;
  const service = getRoleService(scope);

  const form = useForm<RoleItem, RoleOptions>({
    id: params.role_id,
    translationCategory: "Role",
    ignoreNavigateAfterCreate: true,
    rules: {
      name: Validator.rule.any().required(),
    },
    afterSave,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}

