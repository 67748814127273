import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import useUploadButton, { UseUploadButtonProps } from "./useUploadButton";

export interface UploadIconButtonProps
  extends UseUploadButtonProps,
    Omit<IconButtonProps<any>, "onChange" | "component" | "children"> {
  onValidate?: Function;
  title?: string;
  children?: ReactNode;
}

export default function UploadIconButton({
  onChange,
  multiple,
  accept,
  children,
  onValidate,
  title,
  ...buttonProps
}: UploadIconButtonProps) {
  const { loading, fileInput } = useUploadButton({
    onChange,
    multiple,
    accept,
  });
  return (
    <Tooltip title={title || ""}>
      <IconButton
        //loading={loading}
        component="label"
        onClick={(evt: any) => {
          if (onValidate) {
            if (!onValidate()) {
              evt.stopPropagation();
              evt.preventDefault();
            }
          }
        }}
        {...buttonProps}
      >
        {children}
        {fileInput}
      </IconButton>
    </Tooltip>
  );
}

