import { TableBody, TableRowProps } from "@mui/material";
import DataGridRow from "./DataGridRow";
import {
  GridColumn,
  GridColumns,
  GridRowParams,
  GridState,
  RowOrderProps,
} from "./types";

export interface DataGridBodyProps<T extends { [key: string]: any } = any> {
  columns: GridColumns<T>;
  state: GridState<T>;
  hasAction?: boolean;
  canBatchActions?: boolean;
  canView?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  handleToggleSelectRow: (event: unknown, id: any) => void;
  onEditClick?: (id: any, item: T) => void;
  editUrl?: string;
  canEditRow?: (params: GridRowParams) => boolean;
  canDeleteRow?: (params: GridRowParams) => boolean;
  getCellValue?: (column: GridColumn<T>, row: T) => any;
  rowOrderProps: RowOrderProps;
  canOrderRows?: boolean;
  deleteItem: (item: any) => void;
  restoreItem: (item: any) => void;
  rowSx?: (item: T) => TableRowProps["sx"] | undefined;
  refresh: () => void;
  rowActions?: (
    params: GridRowParams,
    openModal: Function,
    refresh: Function
  ) => React.ReactElement[];
}

export default function DataGridBody<T extends { [key: string]: any } = any>({
  state,
  columns,
  hasAction,
  canBatchActions,
  canView,
  canEdit,
  canEditRow,
  canDeleteRow,
  canDelete,
  handleToggleSelectRow,
  onEditClick,
  editUrl,
  getCellValue,
  rowOrderProps,
  canOrderRows,
  deleteItem,
  restoreItem,
  rowSx,
  rowActions,
  refresh,
}: DataGridBodyProps<T>) {
  return (
    <TableBody sx={{ "& td": { paddingTop: 0.5, paddingBottom: 0.5 } }}>
      {state.data.map((item, index) => {
        return (
          <DataGridRow
            key={item.id}
            index={index}
            item={item}
            state={state}
            columns={columns}
            hasAction={hasAction}
            canBatchActions={canBatchActions}
            canView={canView}
            canEdit={canEdit}
            canEditRow={canEditRow}
            canDeleteRow={canDeleteRow}
            canDelete={canDelete}
            handleToggleSelectRow={handleToggleSelectRow}
            onEditClick={onEditClick}
            editUrl={editUrl}
            getCellValue={getCellValue}
            rowOrderProps={rowOrderProps}
            canOrderRows={canOrderRows}
            deleteItem={deleteItem}
            restoreItem={restoreItem}
            rowSx={rowSx}
            rowActions={rowActions}
            refresh={refresh}
          />
        );
      })}
    </TableBody>
  );
}
