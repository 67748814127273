import { Box, BoxProps, styled, Typography } from "@mui/material";
import { CalendarCellItem, VIEWS } from "common";

const CellHeader = styled(Box)<BoxProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing(0.5)};
  background-color: ${props => props.theme.palette.background.sheet};
`;

export interface CalendarCellHeaderProps extends CalendarCellItem {
  view: VIEWS;
}

export default function CalendarCellHeaderSmall({
  view,
  moment,
  isSelected,
  dayOfMonth,
}: CalendarCellHeaderProps) {
  const getLabel = () => {
    if (!moment) return "Select Date Range";
    if (view === VIEWS.DAY) return moment.format("YYYY MMMM Do dddd");
    if ([VIEWS.WEEK, VIEWS.AGENDA].includes(view)) {
      const startOfWeek = moment.clone().startOf("isoWeek");
      const endOfWeek = moment.clone().endOf("isoWeek");
      return `${startOfWeek.format("YYYY MMMM D")} - ${endOfWeek.format("Do")}`;
    }
    if (view === VIEWS.MONTH) return moment.format("YYYY MMMM");
  };
  if (view === VIEWS.MONTH) {
    return (
      <CellHeader>
        <Typography variant="body1" color={isSelected ? "white" : undefined}>
          {dayOfMonth}
        </Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.WEEK) {
    return (
      <CellHeader>
        <Typography variant="body1">{getLabel()}</Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.WORK_WEEK) {
    return null;
    /*return (
      <div className={classes.dayHeader}>
        <Text variant="text">{day.dayOfMonth}</Text>
      </div>
    );*/
  } else if (view === VIEWS.DAY) {
    return (
      <CellHeader>
        <Typography variant="body1">{getLabel()}</Typography>
      </CellHeader>
    );
  } else if (view === VIEWS.AGENDA) {
    return (
      <CellHeader>
        <Typography variant="body1">{getLabel()}</Typography>
      </CellHeader>
    );
  }
  return null;
}

