import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import I18n from "i18n";
import { get } from "lodash";
import moment from "moment-timezone";
import QueryString from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { globalMessageHandler } from "./../GlobalMessaging";
import {
  GridColumn,
  ModalApi,
  UseDataGridProps,
  UseDataGridReturnType,
} from "./types";
import useDataGridState from "./useDataGridState";

export default function useDataGrid<T = any>({
  id,
  columns: rawColumns,
  data,
  loadItems,
  deleteItem,
  deleteItems,
  restoreItem,
  restoreItems,
  canEdit,
  canDelete,
  canView = true,
  canHandleTrash,
  canBatchActions,
  canCreate,
  canEditRow,
  canDeleteRow,
  createItem,
  rowActions,
  moveItem,
  defaultState,
  afterDelete,
  primaryKey = "id",
  apiRef,
  dataChanged,
  showFooter = true,
  filter,
}: UseDataGridProps<T>): UseDataGridReturnType {
  const [drag, setDrag] = useState<any>(false);
  const keywordTimeout = useRef<any>();
  const firstLoad = useRef<boolean>(true);
  /*const [state, _setState] = useState<GridState>({
    ...defaultGridState,
    ...defaultState,
    data: data || [],
  });*/
  const modalApi = useRef<ModalApi>();
  const [state, setState] = useDataGridState({
    id,
    defaultState: { ...defaultState, data: data || [] },
  });

  /*const columns = rawColumns.map(c => ({
    ...c,
    hide: c.hide === true ? true : state.hidden_columns.includes(c.field),
  }));*/
  const hasAction: boolean = !!(canEdit || canDelete || canView || rowActions);

  const columns = useMemo(() => {
    let cols = rawColumns.slice();
    if (state.trash) {
      cols.push({
        field: "deleted_at",
        headerName: I18n.t("App.deleted_at"),
        width: 90,
      });
    }
    return cols;
  }, [state.trash, rawColumns]);

  //Sorting start

  const [activeItem, setActiveItem] = useState<any>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  async function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id === over.id) {
      //event.preventDefault();
      return;
    }

    handleMoveItem(active, over);
  }

  function handleDragStart(event: any) {
    setActiveItem(state.data?.find(i => i.id === event.active.id));
  }

  //Sorting end

  /*function setState(_state: Partial<GridState>) {
    return _setState({ ...state, ..._state });
  }*/

  /**
   *
   * @param newState Merge object to grid state after success load. Example clear the selection.
   */
  async function load(newState?: any) {
    if (loadItems) {
      const query = QueryString.stringify({
        page: state.current_page,
        keyword: state.keyword,
        "per-page": showFooter ? state.per_page : 1000,
        onlyTrashed: state.trash ? 1 : undefined,
        sort: state.sorting.map(sort =>
          sort.direction === "desc" ? `-${sort.field}` : `${sort.field}`
        ),
        filter,
      });

      setState({ loading: true });
      const wasFirstLoad = firstLoad.current ? true : false;
      firstLoad.current = false;
      const data = await loadItems(query);
      if (data) {
        dataChanged && dataChanged(data.data, wasFirstLoad);
        setState({ ...data, loading: false, ...newState });
      }
    }
  }

  function handleToggleTrash(event: unknown) {
    setState({ trash: !state.trash });
  }
  function handleToggleSelectAll(event: unknown) {
    let itemCount = 0;
    let selectedCount = 0;
    let newSelectedIds: any[] = [];
    state.data.forEach(row => {
      itemCount++;
      if (state.selectedIds.includes(row.id)) {
        selectedCount++;
      }
      newSelectedIds.push(row.id);
    });
    if (itemCount > 0 && itemCount === selectedCount) {
      setState({ selectedIds: [] });
    } else {
      setState({ selectedIds: newSelectedIds });
    }
  }

  function handleToggleSelectRow(event: unknown, id: any) {
    let newSelectedIds: any[] = [...state.selectedIds];
    let selectedIndex = newSelectedIds.findIndex(i => i === id);
    if (selectedIndex >= 0) {
      newSelectedIds.splice(selectedIndex, 1);
    } else {
      newSelectedIds.push(id);
    }
    setState({ selectedIds: newSelectedIds });
  }

  function handlePageChange(event: unknown, newPage: number) {
    setState({ current_page: newPage + 1, selectedIds: [] });
  }

  function handleSortChange(field: string, multiple?: boolean) {
    let sorting = [...state.sorting];
    const sortIndex = sorting.findIndex(s => s.field === field);
    let sort = sorting[sortIndex];
    if (sort) {
      if (sort.direction === "desc") {
        sorting.splice(sortIndex, 1);
      } else {
        sort.direction = "desc";
      }
    } else {
      if (multiple) {
        sorting.push({ field, direction: "asc" });
      } else {
        sorting = [{ field, direction: "asc" }];
      }
    }
    setState({ sorting });
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState({ per_page: parseInt(event.target.value, 10) });
  }
  function handleKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (keywordTimeout.current) {
      clearTimeout(keywordTimeout.current);
    }

    setState({ keyword: event.target.value });
  }
  function handleKeywordClear() {
    setState({ keyword: "" });
  }

  function handleStart(e: any, colIndex: number) {
    const element = document.getElementById(`${id}-col-${colIndex}`);
    if (element) {
      let iniMouse = e.clientX;
      let iniSize = element.offsetWidth;

      setDrag({
        iniMouse: iniMouse,
        iniSize: iniSize,
      });
    }
  }

  function handleEnd(e: any, colIndex: number) {
    /*let iniMouse = drag.iniMouse;
    let iniSize = drag.iniSize;
    let endMouse = e.clientX;

    let endSize = iniSize + (endMouse - iniMouse);
    const element = document.getElementById(`${id}-col-${colIndex}`);
    if (element) {
      //element.style.minWidth = `${endSize}px`;
      //element.style.maxWidth = `${endSize}px`;
      //@ts-ignore
      element.width = endSize;
    }*/
    return;
  }

  function handleToggleColumn(column: string) {
    if (state.hidden_columns.includes(column)) {
      setState({
        hidden_columns: state.hidden_columns.filter(c => c !== column),
      });
    } else {
      setState({ hidden_columns: [...state.hidden_columns, column] });
    }
  }
  function handleMove(e: any, colIndex: number) {
    if (e.clientX) {
      let iniMouse = drag.iniMouse;
      let iniSize = drag.iniSize;
      let endMouse = e.clientX;

      let endSize = iniSize + (endMouse - iniMouse);
      const element = document.getElementById(`${id}-col-${colIndex}`);
      if (element) {
        //element.style.minWidth = `${endSize}px`;
        //element.style.maxWidth = `${endSize}px`;
        //@ts-ignore
        element.width = endSize;
      }
    }
  }

  async function handleMoveItem(active: any, over: any) {
    if (moveItem) {
      const oldIndex = state.data.findIndex(r => r.id === active.id);
      const newIndex = state.data.findIndex(r => r.id === over.id);
      const newItems = arrayMove(state.data, oldIndex, newIndex);
      setState({ data: newItems });

      const { data, success } = await moveItem(active.id, active, over.id);
      if (success) {
        dataChanged && dataChanged(newItems, false);
      } else {
        //const newItems = arrayMove(state.data, newIndex, oldIndex);
        setState({ data: state.data });
      }
    }

    /*if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }*/
  }

  function getCellValue(column: GridColumn<T>, item: T) {
    let value: any = get(item, column.field);
    if ((column.valueOptions, column.type !== "multiSelect")) {
      let option: any = Array.isArray(column.valueOptions)
        ? column.valueOptions.find((o: any) => o.value === value)
        : null;
      if (option) {
        value = option.label;
      }
    }
    if ((column.valueOptions, column.type === "multiSelect")) {
      let options: any = Array.isArray(column.valueOptions)
        ? column.valueOptions
            .filter((o: any) => value && value.includes(o.value))
            .map((o: any) => o.label)
        : null;

      if (options && options.length > 0) {
        value = options.join(", ");
      }
    }

    if (column.type === "datetime") {
      return value ? moment(value).format("L LT") : "";
    }
    if (column.type === "date") {
      return value ? moment(value).format("L") : "";
    }
    if (column.type === "time") {
      return value ? moment(value, "HH:mm").format("LT") : "";
    }
    if (column.type === "currency") {
      return value ? I18n.numberToCurrency(value) : "";
    }
    return value;
  }

  //DB Actions start
  function deleteSelectedItems() {
    globalMessageHandler.confirm(
      {
        message: I18n.t(
          state.trash
            ? "App.deleteSelectedTrashConfirm"
            : "App.deleteSelectedConfirm",
          { count: state.selectedIds?.length }
        ),
      },
      async () => {
        if (deleteItems) {
          const result = await deleteItems(state.selectedIds, state.trash);

          if (result.success) {
            setState({ selectedIds: [] });
            load({ selectedIds: [] });
          }
          return result;
        }
      }
    );
  }

  function restoreSelectedItems() {
    globalMessageHandler.confirm(
      {
        message: I18n.t("App.restoreSelectedConfirm", {
          count: state.selectedIds.length,
        }),
      },
      async () => {
        if (restoreItems) {
          const result = await restoreItems(state.selectedIds);

          if (result.success) {
            load({ selectedIds: [] });
          }
          return result;
        }
      }
    );
  }

  async function _deleteItem(item: any, setLoading?: Function) {
    const id = item[primaryKey];
    let confirmAttribute = false;
    let attributeValue = id;
    if (confirmAttribute) {
      attributeValue = item[confirmAttribute];
    } else if (columns[0]) {
      attributeValue = item[columns[0].field];
    }
    /*if (attributeValue !== id) {
      const d = moment(attributeValue);
      if (d.isValid()) {
        attributeValue = d.format("L");
      }
    }*/

    globalMessageHandler.confirm(
      {
        message: I18n.t("App.deleteItemConfirm", {
          item: `${attributeValue}`,
        }),
      },
      async () => {
        if (deleteItem) {
          setLoading && setLoading(true);
          const { success } = await deleteItem(id, state.trash);
          setLoading && setLoading(false);
          if (success) {
            afterDelete && afterDelete();
            globalMessageHandler.snack({
              severity: "success",
              message: I18n.t("App.successDeleted"),
            });
            load();
          }
        }
      }
    );
  }

  async function _restoreItem(item: any, setLoading?: Function) {
    const id = item[primaryKey];
    let confirmAttribute = false;
    globalMessageHandler.confirm(
      {
        message: I18n.t("App.restoreItemConfirm", {
          item: `${confirmAttribute ? item[confirmAttribute] : id}`,
        }),
      },
      async () => {
        if (restoreItem) {
          setLoading && setLoading(true);
          const { success } = await restoreItem(id);
          setLoading && setLoading(false);
          if (success) {
            globalMessageHandler.snack({
              severity: "success",
              message: I18n.t("App.successRestored"),
            });
            load();
          }
        }
      }
    );
  }
  function _createItem(item: Partial<T>) {
    return createItem(item as T);
  }
  //DB Actions end
  const result: UseDataGridReturnType<T> = {
    state,
    columns,
    hasAction,
    rowActions,
    handleStart,
    handleEnd,
    handleMove,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
    load,
    deleteSelectedItems,
    restoreSelectedItems,
    deleteItem: _deleteItem,
    restoreItem: _restoreItem,
    createItem: _createItem,
    handleKeywordChange,
    handleKeywordClear,
    handleToggleSelectAll,
    handleToggleSelectRow,
    handleToggleTrash,
    getCellValue,
    handleMoveItem,
    handleToggleColumn,
    modalApi,
    //Sorting
    rowOrderProps: {
      activeItem,
      sensors,
      handleDragStart,
      handleDragEnd,
    },
  };
  useEffect(() => {
    if (apiRef) {
      apiRef.current = result;
    }
  }, []);

  useEffect(() => {
    load();
  }, [
    state.current_page,
    state.per_page,
    state.sorting,
    state.keyword,
    state.trash,
    filter,
  ]);

  return result;
}
