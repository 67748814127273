import { alpha } from "@mui/material";
//@ts-ignore
import tgrx from "@tgrx/getcontrasttext";
import settings from "app/settings";
import { DbFile } from "common/types";
import I18n from "i18n";
import { get, isEqual, size } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import stc from "string-to-color";

export const transformFieldOptions = (options?: any) => {
  if (!options) {
    return [];
  }
  if (!Array.isArray(options) && typeof options === "object") {
    const keys = Object.keys(options);
    if (typeof options[keys[0]] === "string") {
      options = keys.map(key => ({
        label: options ? options[key] : key,
        id: key,
      }));
    } else {
      options = keys.map(key => ({
        ...(options ? options[key] : key),
        id: key,
      }));
    }
  }
  return options.map((option: any) => ({
    ...option,
    value: option.id, //isNaN(key) ? key : parseInt(key),
    label: option.label,
  }));
};

export const transformOptions = (baseFields: any) => {
  let options: any = {};

  Object.keys(baseFields).forEach(field => {
    const rawOptions = get(baseFields, `${field}.data`);
    if (rawOptions) {
      options[field] = transformFieldOptions(rawOptions);
    }
  });
  return options;
};

export const transformFormErrors = (response: any) => {
  const form_errors = get(response, "form_errors", {});
  let errors: any = {};
  Object.keys(form_errors).forEach(field => {
    if (
      size(form_errors[field]) > 0 &&
      typeof form_errors[field][0] === "object"
    ) {
      errors[field] = form_errors[field];
    } else {
      errors[field] = form_errors[field].join(", ");
    }
  });
  return errors;
};
export const arrayIncludes = (arr: any, value: any) => {
  if (!arr) {
    return false;
  }
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr.find(o => o == value) ? true : false;
};
export const getOptionLabel = (options: any, value: any) => {
  if (!options) {
    return value;
  }
  if (!value && value !== 0) {
    return null;
  }
  let label = null;
  if (Array.isArray(value)) {
    const _options = options
      .filter((o: any) => arrayIncludes(value, o.value))
      .map((o: any) => o.label);
    label = _options.length > 0 ? _options.join(", ") : value;
  } else {
    const option = options.find((o: any) => String(o.value) === String(value));
    label = option ? option.label : value;
  }
  if (label == "") {
    return null;
  }
  return label;
};

export const getOption = (options: any, value: any) => {
  if (!options) {
    return value;
  }
  if (!value && value !== 0) {
    return null;
  }
  let label = null;
  if (Array.isArray(value)) {
    const _options = options
      .filter((o: any) => arrayIncludes(value, o.value))
      .map((o: any) => o);
    label = _options.length > 0 ? _options.join(", ") : value;
  } else {
    const option = options.find((o: any) => String(o.value) === String(value));
    label = option;
  }

  return label;
};

export const getBooleanLabel = (value: any) => {
  return I18n.t(value ? "App.yes" : "App.no");
};

export type Option = {
  label: string;
  value: any;
};

//export type Options<T = string> = { [key: T]: Option[] };
export type Options<E extends string = string> = Record<E, Option[]>;

export function enumToOptions(
  entries: any,
  translationCategory?: string
): Option[] {
  let o = Object.entries(entries).map(([_, value]: any) => ({
    value: value,
    label: translationCategory
      ? I18n.t(`${translationCategory}.${value}`)
      : value,
  }));
  return o;
}

export type LongPressOptions = {
  shouldPreventDefault?: boolean;
  delay?: number;
};
export function useLongPress(
  onLongPress: Function,
  onClick: Function,
  onClickInOut?: (inOut: boolean) => void,
  { shouldPreventDefault = true, delay = 1000 }: LongPressOptions = {}
) {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<any>();
  const target = useRef<any>();

  const start = useCallback(
    (event: any) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      onClickInOut && onClickInOut(true);
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event: any, shouldTriggerClick: boolean = true) => {
      //if (timeout.current) {
      onClickInOut && onClickInOut(false);
      //}
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick(event);
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e: any) => start(e),
    onTouchStart: (e: any) => start(e),
    onMouseUp: (e: any) => clear(e),
    onMouseLeave: (e: any) => clear(e, false),
    onTouchEnd: (e: any) => clear(e),
  };
}

const isTouchEvent = (event: any) => {
  return "touches" in event;
};

const preventDefault = (event: any) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export function useDeepEffect(fn: Function, deps: any[]) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj: any, index: number) =>
      isEqual(obj, deps[index])
    );

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
    // eslint-disable-next-line
  }, deps);
}

export function getContrastColor(
  color?: string,
  opacity: number = 1,
  contrastThreshold: number = 3
): string {
  if (!color) {
    return "#000000";
  }

  return tgrx.default({
    background: color,
    contrastThreshold: contrastThreshold / opacity,
    warnings: false,
  });
}

export function getColorsFromString(
  str?: string,
  opacity?: number
): {
  color?: string;
  backgroundColor?: string;
} {
  if (!str) {
    return {};
  }
  let backgroundColor = opacity ? alpha(stc(str), opacity) : stc(str);
  const color = getContrastColor(backgroundColor, opacity);
  return { color, backgroundColor };
}

export function getMonogram(profile: {
  first_name: string;
  last_name: string;
}) {
  if (!profile || !profile?.first_name) {
    return null;
  }
  let monogram = profile.first_name[0] ? profile.first_name[0] : "";
  monogram += profile.last_name[0] ? profile.last_name[0] : "";

  if (I18n.locale === "hu") {
    monogram = profile.last_name[0] ? profile.last_name[0] : "";
    monogram += profile.first_name[0] ? profile.first_name[0] : "";
  }

  return monogram;
}

export function getName(profile: { first_name: string; last_name: string }) {
  if (!profile || !profile?.first_name) {
    return null;
  }
  let monogram = `${profile.first_name} ${profile.last_name}`;

  if (I18n.locale === "hu") {
    monogram = `${profile.last_name} ${profile.first_name}`;
  }

  return monogram;
}

export function getBrowserName(): string {
  let userAgent = navigator.userAgent;
  let browserName: string;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}

export default function getRemoteUri(file?: DbFile) {
  if (!file?.id) {
    return "";
  }
  //return `${settings.apiHost}/download/${file.id}`;
  return `${settings.apiHost?.replace("/v1", "/files")}/file_${file.id}.${
    file.extension
  }`;
}

