import { useAppSelector } from "app/hooks";
import I18n from "i18n";
import { ReactNode, createContext } from "react";
import { AppLanguage, selectLanguage } from "./languageSlice";

const AppLanguageContext = createContext<AppLanguage>("hu");
interface AppLanguageProviderProps {
  children: ReactNode;
}
export default function AppLanguageProvider({
  children,
}: AppLanguageProviderProps) {
  const language = useAppSelector(selectLanguage);
  I18n.defaultLocale = language;
  I18n.locale = language;
  return (
    <AppLanguageContext.Provider key={language} value={language}>
      {children}
    </AppLanguageContext.Provider>
  );
}

