import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
export type AppLanguage = "hu" | "en";
const initialState: { lang: AppLanguage } = { lang: "hu" };

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<AppLanguage>) => {
      state.lang = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState) => state.language.lang;

export default languageSlice.reducer;

