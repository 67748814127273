import settings from "app/settings";
import { I18n as I18nJS } from "i18n-js";
import en from "./en.json";
import hu from "./hu.json";
//@ts-ignore
const I18n = new I18nJS(
  {
    hu,
    en,
  },
  settings.i18n
);
export default I18n;

