import { GlobalStyles, Theme, alpha } from "@mui/material";
function getSidebarColor(_theme: Omit<Theme, "components">) {
  return _theme.palette.mode === "dark"
    ? _theme.palette.background.paper
    : _theme.palette.primary.main;
}
export default function LayoutStyles2() {
  return (
    <GlobalStyles
      styles={theme => `
      .MuiDrawer-paper {
        ::-webkit-scrollbar {
          width: 0px;
        }
        ::-webkit-scrollbar-thumb {
        }
      }
      .AppContent {
      display: flex;
      /*height:100vh;*/
      }
      .AppSidebar {
        .MuiPaper-root {
          background-color: ${theme.palette.background.default};
        }
        & temporary {
          .AppSidebarWrapper {
          
          }
        }
        .AppSidebarWrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          color: ${theme.palette.getContrastText(getSidebarColor(theme))};
          background-color: ${getSidebarColor(theme)};
          border-radius:0;
        }
        .MuiMenuItem-root.Mui-selected {
          border-top-right-radius:0;
          border-bottom-right-radius:0;
        }

        .MuiMenuItem-root.NavigationGroupTitle {
          min-height: 46px;
          flex:0;
          z-index:1000;
          padding-left:${theme.spacing(2.5)};
          padding-right:${theme.spacing(2)};
          .MuiTypography-root {
            font-weight:500;
            color:${theme.palette.getContrastText(getSidebarColor(theme))};
          }
          &.opened .MuiTypography-root {
            color:${theme.palette.getContrastText(getSidebarColor(theme))};
            font-weight:700;
          }
        }
        .MuiMenuItem-root {
          padding:0;
          a {
            flex:1;
            align-self: stretch;
            justify-content: center;
            .MuiListItemIcon-root {
            margin-right:0;
            padding-left:${theme.spacing(2)};
            padding-right:${theme.spacing(2)};
            }
          }
          min-height: 46px;
          .MuiListItemIcon-root {
            color:${alpha(
              theme.palette.getContrastText(getSidebarColor(theme)),
              0.8
            )};
            align-items:center;  
            justify-content:center;  
          }
          &.Mui-selected {
            background-color:${alpha(theme.palette.common.white, 0.2)};
          }
        }
        .MuiMenuItem-root .MuiTypography-root {
          color:${alpha(
            theme.palette.getContrastText(getSidebarColor(theme)),
            0.8
          )};
        }
        .MuiMenuItem-root.Mui-selected .MuiTypography-root {
          font-weight:700;
          color:${theme.palette.getContrastText(getSidebarColor(theme))};
        }
        .MuiList-root {
          padding:0;
        }

        /* Navigation Group Item */

        .ListNavigationGroup .MuiMenuItem-root {
          padding:0;
          :hover {
            z-index: 900;
          }
          :hover .MuiTypography-root {
            color:${theme.palette.getContrastText(getSidebarColor(theme))};
            font-weight:700;
          }
          a {
            flex:1;
            align-self: stretch;
            justify-content: center;
            .MuiListItemIcon-root {
            margin-right:0;
            }
          }
        }
      }
    `}
    />
  );
}

