import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, IconButton, Stack } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { DataGridHeaderProps } from "./DataGridHeader";

import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    /*transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}*/
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export interface DataGridBulkActionsProps extends DataGridHeaderProps {
  children: any;
}
export default function DataGridBulkActions({
  children,
  state,
  deleteSelectedItems,
  restoreSelectedItems,
}: DataGridBulkActionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack
        direction="row"
        alignContent="center"
        aria-controls={open ? "bulk-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {children}
        <Box
          sx={{
            //backgroundColor: "red",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <IconButton size="small" onClick={handleClick}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </Stack>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!state.trash && (
          <MenuItem
            onClick={() => deleteSelectedItems && deleteSelectedItems()}
            disableRipple
          >
            <DeleteIcon />
            Kijelöltek törlése
          </MenuItem>
        )}
        {state.trash && (
          <MenuItem
            onClick={() => deleteSelectedItems && deleteSelectedItems()}
            disableRipple
          >
            <DeleteForeverIcon />
            Kijelöltek végleges törlés
          </MenuItem>
        )}
        {state.trash && (
          <MenuItem
            onClick={() => restoreSelectedItems && restoreSelectedItems()}
            disableRipple
          >
            <RestoreFromTrashIcon />
            Kijelöltek visszaállítása
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}

