//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  PartnerItem,
  getPartnerService,
} from "./partnerApi";

export default function usePartnerList() {
  const params = useParams<"partner_group_id">();
  const scope = { "partner-groups": params.partner_group_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "partners/"
      : ""
  }`
  const service = getPartnerService({ "partner-groups": params.partner_group_id });

  return { ...service, params, scope, editUrl };
}

