import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import BookingOptionForm from "./BookingOptionForm";
import { BookingOptionItem } from "./bookingOptionApi";
import useBookingOptionList from "./useBookingOptionList";
export interface BookingOptionListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function BookingOptionList({
  showHeader = true,
  ...props
}: BookingOptionListProps) {
  const { params, scope, editUrl, ...service } = useBookingOptionList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<BookingOptionItem> = [
    {
      hide: !!params.booking_id,
      hideable: !params.booking_id,
      valueFormatter: params =>
        `${params?.row?.booking?.event?.program?.name} ${params?.row?.booking?.event?.start_time}`,
      field: "booking.{event.program.name} {event.start_time}",
      headerName: I18n.t("BookingOption.attributes.booking_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/bookings/${params.row?.booking_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.event.{start_time} {program.name}",
      hide: true,
      hideable: true,
      headerName: I18n.t("Booking.attributes.event_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/events/${params.row?.booking?.event_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.event.boat.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.booking?.event?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.event.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.booking?.event?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.event.schedule.{name} {date_from}-{date_to}",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.schedule_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/schedules/${params.row?.booking?.event?.schedule_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.program_variant.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("ProgramVariant.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.booking?.program_variant?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.partner.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Booking.attributes.partner_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/partners/${params.row?.booking?.partner_id}`}
          params={params}
        />
      ),
    },
    {
      field: "booking.partner.partner_group.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Partner.attributes.partner_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/partner-groups/${params.row?.booking?.partner?.partner_group_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.program_option_id,
      hideable: !params.program_option_id,
      field: "option.name",
      headerName: I18n.t("BookingOption.attributes.program_option_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/options/${params.row?.program_option_id}`}
          params={params}
        />
      ),
    },
    {
      field: "option.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Option.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.option?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "option.option_group.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Option.attributes.option_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/option-groups/${params.row?.option?.option_group_id}`}
          params={params}
        />
      ),
    },
    {
      field: "qty",
      headerName: I18n.t("BookingOption.attributes.qty"),
      type: "number",
    },
    {
      field: "price",
      headerName: I18n.t("BookingOption.attributes.price"),
      type: "currency",
      align: "right",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<TheaterComedyIcon />}
          title={I18n.t("BookingOption.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="booking-options"
        renderModalForm={props => <BookingOptionForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`BookingOption.attributes.${field.replaceAll(".", "_")}`)}
        defaultState={{
          hidden_columns: [
            "event_id",
            "boat_id",
            "program_id",
            "schedule_id",
            "program_variant_id",
            "partner_id",
            "partner_group_id",
            "option_group_id",
          ],
        }}
        canCreate={hasPermissions("booking_option.create")}
        canDelete={hasPermissions([
          "booking_option.editany",
          "booking_option.editown",
        ])}
        canEdit={hasPermissions([
          "booking_option.editany",
          "booking_option.editown",
        ])}
        canDeleteRow={params => canEditItem("booking_option", params.row)}
        canEditRow={params => canEditItem("booking_option", params.row)}
        canBatchActions={hasPermissions([
          "booking_option.editany",
          "booking_option.editown",
        ])}
      />
    </>
  );
}
