import {
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getRoleService } from "features/roles/roleApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import { UserItem, UserOptions, getUserService } from "./userApi";

type RouteParams = {
  user_id?: string;
};

export interface UseUserFormReturnType
  extends UseFormReturnType<UserItem, UserOptions> {
  params?: Readonly<Params<"user_id">>;
}

export interface UseUserFormProps {}

export default function useUserForm({}: UseUserFormProps): UseUserFormReturnType {
  const params = useParams<"user_id">();
  const scope = undefined;
  const service = getUserService(scope);

  const form = useForm<UserItem, UserOptions>({
    id: params.user_id,
    translationCategory: "User",
    rules: {
      first_name: Validator.rule.string().required(),
      last_name: Validator.rule.string().required(),
      email: Validator.rule.string().required(),
    },
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      role_ids: () => getRoleService().loadAutocomplete(),
    }),
  });

  return { ...form, params };
}

