import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { useAppSelector } from "app/hooks";
import createAppTheme from "app/theme";
import { selectThemeMode } from "features/theme/themeSlice";
import { ReactNode, useMemo } from "react";

interface AppThemeProviderProps {
  children: ReactNode;
}
export default function AppThemeProvider({ children }: AppThemeProviderProps) {
  const themeMode = useAppSelector(selectThemeMode);
  const theme = useMemo(() => {
    return createAppTheme(themeMode || "light");
  }, [themeMode]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

