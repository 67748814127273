import { DialogActions, DialogContent } from "@mui/material";
import {
  Button,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import I18n from "i18n";
import useRoleForm, { UseRoleFormProps } from "./useRoleForm";

export interface RoleFormModalProps extends UseRoleFormProps {}

export default function RoleFormModal(props: RoleFormModalProps) {
  const {
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    getIsDirty,
    requiredFields,
    onChange,
    save,
    saving,
    params,
  } = useRoleForm(props);

  const title = isCreate ? I18n.t("Role.addTitle") : I18n.t("Role.editTitle");
  return (
    <>
      <PageHeader title={title} onClose={props.onClose} />
      <DialogContent>
        <Form
          loading={loading}
          item={item}
          options={options}
          errors={errors}
          onChange={onChange}
          translationCategory="Role.attributes"
          isDirty={getIsDirty}
          requiredFields={requiredFields}
        >
          <FieldsContainer>
            <Field fullWidth>
              <TextField id="name" />
            </Field>
          </FieldsContainer>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          title={I18n.t("App.save")}
          disabled={!isDirty}
          onClick={() => save()}
          loading={saving}
        />
      </DialogActions>
    </>
  );
}

