import { Box, BoxProps, useTheme } from "@mui/material";

import { EventItem } from "features/events/eventApi";
import { useEventCalculator } from "../../useEventCalculator";
import { BoatGroup } from "../cells/useStartBoatCell";
import EventDot from "./EventDot";

export interface StartBoatGroupWeekProps extends Omit<BoxProps, "onClick"> {
  item: BoatGroup;
  onClick?: (item: EventItem) => void;
  onAddClick?: (item: EventItem) => void;
  eventProps?: Omit<BoxProps, "onClick">;
  isSelectedFunc?: (item: EventItem) => boolean;
}
export default function StartBoatGroupWeek({
  item,
  onClick,
  onAddClick,
  sx,
  eventProps,
  isSelectedFunc,
  ...props
}: StartBoatGroupWeekProps) {
  const theme = useTheme();
  const { getBoatData } = useEventCalculator();
  const { boatLimit, boatSx, boatClassName } = getBoatData(item.events[0]);

  return (
    <Box
      className={boatClassName}
      sx={{
        flex: 1,
        display: "flex",
        gap: "1px",
        borderRadius: "5px",
        padding: "1px",
        ...(boatSx as any),
        ...sx,
      }}
    >
      {item.events.map(event => (
        <EventDot
          sx={{ flex: 1 }}
          key={event.id}
          boatLimit={boatLimit}
          item={event}
          isSelected={isSelectedFunc ? isSelectedFunc(event) : undefined}
          onClick={onClick ? () => onClick && onClick(event) : undefined}
          onAddClick={
            onAddClick ? () => onAddClick && onAddClick(event) : undefined
          }
          {...eventProps}
        />
      ))}
    </Box>
  );
}
