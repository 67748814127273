import moment, { Moment } from "moment";
import { normalizeWeeks } from "./calendarHelper";

export enum NAVIGATE {
  PREVIOUS = "PREV",
  NEXT = "NEXT",
  TODAY = "TODAY",
  DATE = "DATE",
}
export enum VIEWS {
  MONTH = "MONTH",
  WEEK = "WEEK",
  WORK_WEEK = "WORK_WEEK",
  DAY = "DAY",
  AGENDA = "AGENDA",
}

export interface CalendarEventItem {
  id: number;
  start: Moment;
  end: Moment;
  title: string;
  detail?: string;
  color?: string;
  textColor?: string;
  allDay?: boolean;
}

export interface CalendarDateRangeItem {
  start: Moment;
  end: Moment;
}

export interface CalendarHeaderItem {
  date: Moment;
  dayName: string;
  dayNameShort: string;
}

export interface CalendarCellItem<
  EventType extends CalendarEventItem = CalendarEventItem
> {
  moment: Moment;
  day: string;
  dayOfMonth: string;
  date: string;
  isHoliday: boolean;
  isOtherMonth: boolean;
  isCurrentDay: boolean;
  isSelected: boolean;
  events: EventType[];
}

const formatDateRange = (start: Moment, end: Moment): string => {
  if (start.month() === end.month()) {
    return `${start.format("YYYY MMM DD")} - ${end.format("DD")}`;
  }
  return start.format("YYYY MMM DD") + " - " + end.format("MMM DD");
};

const calendar = <EventType extends CalendarEventItem = CalendarEventItem>(
  date: Moment,
  view: VIEWS,
  events: EventType[] = [],
  selectedDate?: Moment,
  sortFunction?: (a: any, b: any) => number,
  dayNameShortFunc?: (date: Moment) => string
) => {
  //const date = moment().add(1, "month");
  if (!view) {
    view = VIEWS.WEEK;
  }

  if (!date) {
    date = moment();
  }

  const getDates = (d: Moment) => {
    const startOfDay = d.clone().startOf("day");
    const endOfDay = d.clone().endOf("day");

    //Week view
    const startOfWeek = d.clone().startOf("week");
    const endOfWeek = d.clone().endOf("week");
    const endOfWorkWeek = d.clone().endOf("week").subtract(2, "day");

    //Month view
    const startOfMonth = d.clone().startOf("month");
    const endOfMonth = d.clone().endOf("month");
    const startOfWeekInMonthStarts = startOfMonth.clone().startOf("week");
    const endOfWeekInMonthEnds = endOfMonth.clone().endOf("week");
    return {
      startOfDay,
      endOfDay,
      startOfWeek,
      endOfWeek,
      endOfWorkWeek,
      startOfMonth,
      endOfMonth,
      startOfWeekInMonthStarts,
      endOfWeekInMonthEnds,
    };
  };

  const dates = getDates(date);

  const eventsMap: { [key: string]: EventType[] } = {};

  const getDayKey = (d: Moment) => {
    return d.year() * 1000 + d.dayOfYear();
  };
  events.sort((a: any, b: any) =>
    sortFunction ? sortFunction(a, b) : a.start.unix() - b.start.unix()
  );
  events.forEach((event: any) => {
    moment().dayOfYear();
    const key = getDayKey(event.start);
    if (!eventsMap[key]) {
      eventsMap[key] = [] as EventType[];
    }
    eventsMap[key].push(event);
  });
  const isHoliday = (d: Moment): boolean => {
    return d.day() === 6 || d.day() === 0;
  };

  const isOtherMonth = (d: Moment): boolean => {
    return d.month() !== date.month();
  };
  const isCurrentDay = (d: Moment): boolean => {
    return d.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
  };

  const getDayParams = (d: Moment): CalendarCellItem<EventType> => ({
    moment: d.clone(),
    day: d.format("dddd"),
    dayOfMonth: d.format("DD"),
    date: d.format("L"),
    isHoliday: isHoliday(d),
    isOtherMonth: isOtherMonth(d),
    isCurrentDay: isCurrentDay(d),
    events: eventsMap[getDayKey(d)] || [],
    isSelected: selectedDate
      ? selectedDate.format("YYYY-MM-DD") === d.format("YYYY-MM-DD")
      : false,
  });
  const getDayParams2 = (d: Moment): CalendarCellItem<EventType> => ({
    moment: d.clone(),
    day: d.format("dddd"),
    dayOfMonth: d.format("DD"),
    date: d.format("L"),
    isHoliday: isHoliday(d),
    isOtherMonth: isOtherMonth(d),
    isCurrentDay: isCurrentDay(d),
    events: [],
    isSelected: selectedDate
      ? selectedDate.format("YYYY-MM-DD") === d.format("YYYY-MM-DD")
      : false,
  });

  const getHeaders = (): CalendarHeaderItem[] => {
    var headers: CalendarHeaderItem[] = [];
    let currDate;
    if (view === VIEWS.MONTH) {
      currDate = dates.startOfWeekInMonthStarts.clone().startOf("day");
      for (let i = 0; i < 7; i++) {
        headers.push({
          date: currDate,
          dayName: currDate.format("dddd"),
          dayNameShort: dayNameShortFunc
            ? dayNameShortFunc(currDate)
            : currDate.format("dddd").substring(0, 3),
        });
        currDate.add(1, "day");
      }
    } else if (view === VIEWS.WEEK) {
      currDate = dates.startOfWeek.clone().startOf("day");
      for (let i = 0; i < 7; i++) {
        headers.push({
          date: currDate,
          dayName: currDate.format("dddd DD"),
          dayNameShort: dayNameShortFunc
            ? dayNameShortFunc(currDate)
            : currDate.format("dddd").substring(0, 3),
        });
        currDate.add(1, "day");
      }
    } else if (view === VIEWS.DAY) {
      currDate = dates.startOfDay.clone().startOf("day");
      headers.push({
        date: currDate,
        dayName: currDate.format("dddd"),
        dayNameShort: dayNameShortFunc
          ? dayNameShortFunc(currDate)
          : currDate.format("dddd").substring(0, 3),
      });
    }

    return headers;
  };

  const calData = (start: any, end: any) => {
    let dates: CalendarCellItem<EventType>[] = [];

    let currDate: Moment = start.clone().startOf("day").subtract(1, "day");
    let lastDate: Moment = end.clone().startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(getDayParams(currDate));
    }

    return dates;
  };

  const getLayout = () => {
    switch (view) {
      case VIEWS.MONTH:
        return {
          columns: 7,
          rows: 5,
        };
      case VIEWS.WEEK:
        return {
          columns: 7,
          rows: 1,
        };
      case VIEWS.WORK_WEEK:
        return {
          columns: 5,
          rows: 1,
        };
      case VIEWS.DAY:
        return {
          columns: 1,
          rows: 1,
        };
      case VIEWS.AGENDA:
        return {
          columns: 1,
          rows: 1,
        };
      default:
        return null;
    }
  };

  const getRange = (d: Moment, v: VIEWS): CalendarDateRangeItem => {
    const _dates = getDates(d);
    switch (v) {
      case VIEWS.MONTH:
        return {
          start: _dates.startOfWeekInMonthStarts,
          end: _dates.endOfWeekInMonthEnds,
        };
      case VIEWS.WEEK:
        return { start: _dates.startOfWeek, end: _dates.endOfWeek };
      case VIEWS.WORK_WEEK:
        return { start: _dates.startOfWeek, end: _dates.endOfWorkWeek };
      case VIEWS.DAY:
        return { start: _dates.startOfDay, end: _dates.endOfDay };
      case VIEWS.AGENDA:
        return { start: _dates.startOfWeek, end: _dates.endOfWeek };
      //This is never happens
      default:
        return { start: _dates.startOfDay, end: _dates.endOfDay };
    }
  };

  const getData = () => {
    switch (view) {
      case VIEWS.MONTH:
        return calData(
          dates.startOfWeekInMonthStarts,
          dates.endOfWeekInMonthEnds
        );
      case VIEWS.WEEK:
        return calData(dates.startOfWeek, dates.endOfWeek);
      case VIEWS.WORK_WEEK:
        return calData(dates.startOfWeek, dates.endOfWorkWeek);
      case VIEWS.DAY:
        return calData(dates.startOfDay, dates.endOfDay);
      case VIEWS.AGENDA:
        return calData(dates.startOfWeek, dates.endOfWeek);
      default:
        return null;
    }
  };
  const getData2 = () => {
    return normalizeWeeks(events as any, view, "monday");
  };

  const getLabel = (d: Moment): string | null => {
    const _dates = getDates(d);
    switch (view) {
      case VIEWS.MONTH:
        return d.format("YYYY MMM");
      case VIEWS.WEEK:
        return formatDateRange(_dates.startOfWeek, _dates.endOfWeek);
      case VIEWS.WORK_WEEK:
        return formatDateRange(_dates.startOfWeek, _dates.endOfWorkWeek);
      case VIEWS.DAY:
        return _dates.startOfDay.format("L");
      case VIEWS.AGENDA:
        return formatDateRange(_dates.startOfWeek, _dates.endOfWeek);
      default:
        return null;
    }
  };

  const navigateNext = (d: Moment, _view?: VIEWS): Moment => {
    const v = _view || view;
    if (v === VIEWS.MONTH) {
      return d.clone().add(1, "month").startOf("month");
    } else if (v === VIEWS.WEEK) {
      return d.clone().add(1, "week").startOf("week");
    } else if (v === VIEWS.WORK_WEEK) {
      return d.clone().add(1, "week").startOf("week");
    } else if (v === VIEWS.DAY) {
      return d.clone().add(1, "day");
    } else if (v === VIEWS.AGENDA) {
      return d.clone().add(1, "week").startOf("week");
    }
    return d;
  };
  const navigatePrev = (d: Moment, _view?: VIEWS): Moment => {
    const v = _view || view;
    if (v === VIEWS.MONTH) {
      return d.clone().subtract(1, "month").startOf("month");
    } else if (v === VIEWS.WEEK) {
      return d.clone().subtract(1, "week").startOf("week");
    } else if (v === VIEWS.WORK_WEEK) {
      return d.clone().subtract(1, "week").startOf("week");
    } else if (v === VIEWS.DAY) {
      return d.clone().subtract(1, "day");
    } else if (v === VIEWS.AGENDA) {
      return d.clone().subtract(1, "week").startOf("week");
    }
    return d;
  };

  const navigateToday = (d: Moment): Moment => {
    //TODO: Implement
    return d;
  };

  const navigateDay = (d: Moment, _view?: VIEWS): Moment => {
    //TODO: Implement
    const v = _view || view;
    if (v === VIEWS.MONTH) {
      return d.clone().startOf("month");
    } else if (v === VIEWS.WEEK) {
      return d.clone().startOf("week");
    } else if (v === VIEWS.WORK_WEEK) {
      return d.clone().startOf("week");
    } else if (v === VIEWS.DAY) {
      return d.clone();
    } else if (v === VIEWS.AGENDA) {
      return d.clone().startOf("week");
    }
    return d;
  };

  const navigate = (type: NAVIGATE, d: Moment, _view?: VIEWS) => {
    switch (type) {
      case NAVIGATE.NEXT:
        return navigateNext(d);
      case NAVIGATE.PREVIOUS:
        return navigatePrev(d);
      case NAVIGATE.TODAY:
        return navigateToday(d);
      case NAVIGATE.DATE:
        return navigateDay(d, _view);
      default:
        return date;
    }
  };

  return {
    headers: getHeaders(),
    data: getData(),
    data2: getData2(),
    layout: getLayout(),
    getDayParams2,
    getLabel,
    getRange,
    navigate,
    getDates,
    dates,
  };
};

export default calendar;

