import { Paper, PaperProps } from "@mui/material";

export interface FormBlockProps extends PaperProps {}

export default function FormBlock({ children, ...props }: FormBlockProps) {
  return (
    <Paper
      {...props}
      sx={{
        p: 2,
        ...props.sx,
      }}
    >
      {children}
    </Paper>
  );
}

