import Link, { LinkProps } from "@mui/material/Link";
export interface DataGridLinkColumnProps extends LinkProps {
  params: any; //GridCellParams;
}
export default function DataGridLinkColumn({
  params,
  ...rest
}: DataGridLinkColumnProps) {
  if (!params.row) {
    return null;
  }
  return <Link {...rest}>{params.formattedValue}</Link>;
}

