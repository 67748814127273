import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import I18n from "i18n";
import { PageHeader, Spacer, DataGridBaseBaseProps, DataGridV2 as DataGrid, GridColumns } from "common";
import useAccess from "features/profile/useAccess";
import ExchangeRateForm from "./ExchangeRateForm";
import useExchangeRateList from "./useExchangeRateList";
import { ExchangeRateItem } from "./exchangeRateApi";
import { DataGridModalEditColumn } from "common";
import { enumToOptions } from "common";
import { EXCHANGERATE_TYPES } from "./exchangeRateConstants";
export interface ExchangeRateListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function ExchangeRateList({ showHeader = true, ...props }: ExchangeRateListProps) {
  const { params, scope, editUrl, ...service } = useExchangeRateList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<ExchangeRateItem> = [
    { field: "type", headerName: I18n.t("ExchangeRate.attributes.type"), renderCell: params => (
          <DataGridModalEditColumn href={`${editUrl}${params.row?.id}`} params={params} />
        ), type: "singleSelect", valueOptions: enumToOptions(EXCHANGERATE_TYPES, "ExchangeRate.options.type") },
    { field: "rate", headerName: I18n.t("ExchangeRate.attributes.rate"), type: "number" },
    { field: "date_from", headerName: I18n.t("ExchangeRate.attributes.date_from"), type: "date" },
    { field: "date_to", headerName: I18n.t("ExchangeRate.attributes.date_to"), type: "date" },
      ];

  return (
    <>
      {showHeader && (
          <PageHeader
            avatar={<CurrencyExchangeIcon />}
            title={I18n.t("ExchangeRate.title")}
          />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="exchange-rates"
        renderModalForm={props => <ExchangeRateForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`ExchangeRate.attributes.${field.replaceAll(".", "_")}`)}
                canCreate={hasPermissions("exchange_rate.create")}
        canDelete={hasPermissions(["exchange_rate.editany", "exchange_rate.editown"])}
        canEdit={hasPermissions(["exchange_rate.editany", "exchange_rate.editown"])}
        canDeleteRow={params => canEditItem("exchange_rate", params.row)}
        canEditRow={params => canEditItem("exchange_rate", params.row)}
        canBatchActions={hasPermissions(["exchange_rate.editany", "exchange_rate.editown"])}
        canHandleTrash={hasPermissions(["exchange_rate.editany", "exchange_rate.editown"])}      />
    </>
  );
}

