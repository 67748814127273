import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
  Theme,
  useTheme,
} from "@mui/material";
import { OptionItem } from "features/options/optionApi";
import I18n from "i18n";
import { EventOption } from "../eventApi";
import summaryTableSx from "./summaryTableStyle";

interface OptionSummaryProps extends TableRowProps {
  items: Array<OptionItem & EventOption>;
}

function rederRow(item?: OptionItem & EventOption, sx?: SxProps<Theme>) {
  return (
    <TableRow key={item?.option_id} sx={sx}>
      <TableCell sx={{ textAlign: "left!important" }}>{item?.name}</TableCell>
      <TableCell>{item?.number_of_bookings}</TableCell>
      <TableCell>{item?.limit}</TableCell>
    </TableRow>
  );
}

export default function OptionSummary({
  items,
  ...tableRowProps
}: OptionSummaryProps) {
  const theme = useTheme();

  return (
    <Table padding="none" sx={summaryTableSx}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: "left!important" }}>
            {I18n.t("Option.title")}
          </TableCell>
          <TableCell>{I18n.t("Event.quantity")}</TableCell>
          <TableCell>{I18n.t("Event.limit")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map(item => {
          return rederRow(item, {});
        })}
        {rederRow(items.find(i => true) as any, {
          borderTop: theme => `solid 2px ${theme.palette.info.main}`,
          borderBottom: "none",
          "& td": {
            borderBottom: "none",
            fontWeight: 700,
            fontStyle: "italic",
          },
        })}
      </TableBody>
    </Table>
  );
}
