import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getBookingService } from "features/bookings/bookingApi";
import { getOptionService } from "features/options/optionApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  BookingOptionItem,
  BookingOptionOptions,
  getBookingOptionService,
} from "./bookingOptionApi";

export interface UseBookingOptionFormProps extends GridModalFormProps {}

type RouteParams = {
  booking_option_id?: string;
};

export interface UseBookingOptionFormReturnType
  extends UseFormReturnType<BookingOptionItem, BookingOptionOptions> {
  params?: Readonly<
    Params<
      | "booking_option_id"
      | "booking_id"
      | "event_id"
      | "boat_id"
      | "program_id"
      | "schedule_id"
      | "program_variant_id"
      | "partner_id"
      | "partner_group_id"
      | "program_option_id"
      | "option_group_id"
    >
  >;
}

export default function useBookingOptionForm({
  id,
  afterSave,
  defaultValues,
}: UseBookingOptionFormProps): UseBookingOptionFormReturnType {
  const params = useParams<
    | "booking_option_id"
    | "booking_id"
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "program_variant_id"
    | "partner_id"
    | "partner_group_id"
    | "program_option_id"
    | "option_group_id"
  >();
  const scope = {
    bookings: params.booking_id,
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
    options: params.program_option_id,
    "option-groups": params.option_group_id,
  };
  const service = getBookingOptionService(scope);

  const form = useForm<BookingOptionItem, BookingOptionOptions>({
    id: params.booking_option_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "BookingOption",
    rules: {
      booking_id: params.booking_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      program_option_id: params.program_option_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      qty: Validator.rule.any().required(),
      price: Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      booking_id: () =>
        getBookingService({
          events: params.event_id,
          boats: params.boat_id,
          programs: params.program_id,
          schedules: params.schedule_id,
          "program-variants": params.program_variant_id,
          partners: params.partner_id,
          "partner-groups": params.partner_group_id,
        }).loadAutocomplete(),
      program_option_id: () =>
        getOptionService({
          programs: params.program_id,
          "option-groups": params.option_group_id,
        }).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}

