import { CircularProgress, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export type FilePreviewType = "img" | "pdf" | "none";

function getType(url?: string): FilePreviewType {
  if (!url) {
    return "none";
  }

  if (url.includes("image/jpeg")) {
    return "img";
  }
  if (url.includes("image/jpg")) {
    return "img";
  }
  if (url.includes("image/png")) {
    return "img";
  }
  if (url.includes("application/pdf")) {
    return "pdf";
  }

  let _url = url.toLowerCase();
  if (_url.includes(".jpeg")) {
    return "img";
  }
  if (_url.includes(".jpg")) {
    return "img";
  }
  if (_url.includes(".png")) {
    return "img";
  }
  if (_url.includes(".pdf")) {
    return "pdf";
  }
  return "none";
}
export interface FilePreviewProps {
  url?: string;
  title?: string;
  fileName?: string;
  type?: FilePreviewType;
}
export default function FilePreview({
  url,
  title,
  fileName,
  type: _type,
}: FilePreviewProps) {
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const type = _type || getType(url);
  const theme = useTheme();
  const loadImage = useCallback((url: any) => {
    const img = new Image();
    img.onload = () => {
      setImage(url);
      setLoading(false);
    };
    img.onerror = img.onabort = () => {
      //reject({success: false, url})

      setLoading(false);
    };
    setLoading(true);
    img.src = url;
  }, []);

  function handleLoadSuccess({ numPages, ...rest }: any) {
    setLoading(false);
    setPages([...new Array(numPages).fill(null)]);
  }

  function handleLoadError(error: any) {
    setLoading(false);
    setPages([]);
  }

  useEffect(() => {
    if (url) {
      if (type === "pdf") {
        setLoading(true);
      } else {
        loadImage(url);
      }
    } else {
      setPages([]);
      setImage(undefined);
    }
  }, [url]);

  return (
    <Box
      sx={{
        overflowX: "hidden",
        minHeight: 200,
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& canvas": {
          border: `solid 1px ${theme.palette.divider}`,
          width: "100% !important",
          maxWidth: "1024px",
          height: "auto !important",
        },
      }}
    >
      {loading && <CircularProgress />}
      {type === "pdf" && (
        //@ts-ignore
        <Document
          //key={url}
          file={url}
          //options={options}
          onLoadSuccess={handleLoadSuccess}
          onLoadError={handleLoadError}
        >
          {pages.map((_p, index) => {
            return (
              //@ts-ignore
              <Page
                key={`${index}`}
                pageNumber={index + 1}
                className="pdf-page"
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={595 * 2}
                height={841 * 2}
                //className={"pdf-modal-page" + (index > 0 ? " margin-top-2" : "")}
              />
            );
          })}
        </Document>
      )}
      {type === "img" && !!image && (
        <img style={{ width: "100%" }} title={title} alt={title} src={image} />
      )}
      {!loading && type === "img" && !image && (
        <Typography>Unable to show content.</Typography>
      )}
    </Box>
  );
}

