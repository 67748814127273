import PublicLayout from "layouts/PublicLayout";
import Login from "pages/login/Login";
import PageNotFound from "pages/page-not-found/PageNotFound";
import { Navigate, Route, Routes } from "react-router-dom";

export default function PublicRouter() {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Navigate to="login" />} />
        <Route path="" element={<Navigate to="login" />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

