import I18n from "i18n";
import { Autocomplete } from "common";
import { TextField } from "common";
import { PasswordField } from "common";
import { DateTimePicker } from "common";
import { File } from "common";
import { Checkbox } from "common";
import PersonIcon from "@mui/icons-material/Person";
import {useDownloadUrl} from "common";
import api from "app/api";import {
  Button,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  Spacer,
  Toolbar,
  UseFormReturnType,
  FormBlock,
  CreatedUpdatedBy
} from "common";
import useUserForm, {
  UseUserFormProps,
} from "./useUserForm";
import { UseUserFormReturnType } from "./useUserForm";
import { useOutletContext } from "react-router-dom";
import { Typography } from "@mui/material";
import useAccess from "features/profile/useAccess";
import HistoryListDialog from "features/histories/HistoryListDialog";

export interface UserFormProps
  extends UseUserFormProps {}

export default function UserForm() {
  const { hasPermissions } = useAccess();
  const {
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
    params,
  //} = useUserForm({});
  } = useOutletContext<UseUserFormReturnType>();
     const { downloadUrl } = useDownloadUrl();
    const uploadService = api.getService("/files");
  const title = isCreate
    ? I18n.t("User.addTitle")
    : I18n.t("User.editTitle");
  return (
    <>
    <FormBlock>
      <Typography variant="button">{I18n.t("User.titleSingular")} {I18n.t("App.mainData")}</Typography>
      <Spacer h={3} />
      <Form
        loading={loading}
        item={item}
        options={options}
        errors={errors}
        onChange={onChange}
        translationCategory="User.attributes"
        translationPlaceholdersCategory="User.placeholders"
        translationHelpersCategory="User.helpers"
        isDirty={getIsDirty}
        requiredFields={getRequiredFields()}
      >
        <FieldsContainer>
                <Field>
        <Autocomplete id="role_ids" multiple />
      </Field>
      <Field fullWidth>
        <TextField id="first_name" />
      </Field>
      <Field fullWidth>
        <TextField id="last_name" />
      </Field>
      {isUpdate && (<Field fullWidth>
        <TextField id="name" disabled/>
      </Field>)}
      <Field fullWidth>
        <TextField id="email" />
      </Field>
      <Field fullWidth>
          <PasswordField id="password" fake />
        </Field>
      {isUpdate && (<Field fullWidth>
        <DateTimePicker id="login_at" disabled/>
      </Field>)}
      <Field fullWidth>
        <File id="file_id" uri={downloadUrl(item.file_id)} upload={uploadService.uploadFile} file={item.file}/>
      </Field>
      <Field fullWidth>
        <TextField id="settings" />
      </Field>
      <Field fullWidth>
        <Checkbox id="active" />
      </Field>        </FieldsContainer>
      </Form>
      <Spacer h={2} />
      <Toolbar>
        {isUpdate && <CreatedUpdatedBy item={item} renderDialog={
          hasPermissions(["history.viewany", "history.viewown"])
            ? props => <HistoryListDialog {...props} subtitle={title} />
            : undefined
        } />}
        <Spacer right />
        <Button
          title={I18n.t("App.save")}
          disabled={!isDirty}
          onClick={() => save()}
          loading={saving}
        />
      </Toolbar>
    </FormBlock>
        </>
  );
}

