import {
  GridModalFormProps,
  Option,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { Dictionary } from "features/dictionaries/dictionaryApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { EventItem, getEventService } from "features/events/eventApi";
import { EVENT_STATUSES } from "features/events/eventConstants";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";

import I18n from "i18n";
import { useEffect, useMemo } from "react";
import { Params } from "react-router-dom";

export interface UseEventCalendarFormProps extends GridModalFormProps {}

type RouteParams = {
  event_id?: string;
};

export interface UseEventCalendarFormReturnType
  extends Omit<UseFormReturnType<EventItem>, "options"> {
  params?: Readonly<
    Params<
      | "event_id"
      | "boat_id"
      | "program_id"
      | "schedule_id"
      | "booking_id"
      | "booking_option_id"
    >
  >;
  program?: ProgramItem;
  programs?: ProgramItem[];
  options: Dictionary;
  type: Option[];
}

export default function useEventCalendarForm({
  id,
  afterSave,
  defaultValues,
}: UseEventCalendarFormProps): UseEventCalendarFormReturnType {
  const params = useParams<
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "booking_id"
    | "booking_option_id"
  >();
  const scope = {
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
  };
  const service = getEventService(scope);

  const form = useForm<EventItem>({
    id: params.event_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Event",
    rules: {
      start_time: Validator.rule.any().required(),
      boat_id: params.boat_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      program_id: params.program_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      schedule_id: params.schedule_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      duration: Validator.rule.any().required(),
      status: Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    //loadItem: undefined,
    loadOptions: undefined,
  });
  const { dictionaries, resolveOptions, filterOptions, resolveOption } =
    useDictionaries();

  const { options, program, programs, type } = useMemo(() => {
    const programs = resolveOptions(
      "programs",
      form.item.schedule?.program_ids
    );
    const program = resolveOption<ProgramItem>(
      "programs",
      form.item.program_id
    );
    return {
      options: {
        ...dictionaries,
        boat_id: dictionaries.boats,
        program_id: dictionaries.programs,
        status: enumToOptions(EVENT_STATUSES, "Event.options.status"),
        variants: filterOptions<ProgramVariantItem>("variants", item =>
          form.item?.schedule?.program_ids?.includes(item.program_id)
        ),
      } as any,
      program,
      programs,
      type: enumToOptions(LIMIT_TYPES, "Limit.options.type"),
    };
  }, [form.item.schedule]);

  function handleCalendar({
    detail: { action, item },
  }: {
    detail: { action: "refresh" | "setItem"; item?: any };
  }) {
    if (action === "refresh") {
      if (item) {
        form.setAttributes(item);
      } else {
        form.load();
      }
    }
    if (action === "setItem") {
      form.setAttributes(item);
    }
  }
  useEffect(() => {
    //@ts-ignore
    document.addEventListener("calendarAction", handleCalendar);
    return () =>
      //@ts-ignore
      document.removeEventListener("calendarAction", handleCalendar);
  }, []);

  return {
    ...form,
    options,
    program,
    params,
    programs,
    type,
  };
}

