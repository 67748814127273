import { BookingOptionItem } from "features/booking-options/bookingOptionApi";
import { BookingItem } from "features/bookings/bookingApi";

export function getPrice(item: BookingItem) {
  const adult = (item?.number_of_adults || 0) * (item?.adult_price || 0);
  const kid = (item?.number_of_kids || 0) * (item?.kid_price || 0);

  const options: BookingOptionItem[] = (item as any).booking_options || [];
  console.log({ options });
  let optionsPrice = 0;
  options?.forEach(option => {
    optionsPrice += (option.qty || 0) * (option.price || 0);
  });
  const sum = adult + kid + optionsPrice;
  console.log({
    adult,
    kid,
    options: optionsPrice,
    sum,
  });
  return {
    adult,
    kid,
    options: optionsPrice,
    sum,
  };
}
