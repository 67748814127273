import { Content } from "common";
import { Outlet } from "react-router-dom";

export default function PublicLayout() {
  return (
    <Content>
      <Outlet />
    </Content>
  );
}

