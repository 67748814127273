import { Typography, TypographyProps } from "@mui/material";
import moment from "moment-timezone";
import useSessionTimer, { UseSessionTimerProps } from "./useSessionTimer";

export interface SessionTimerProps
  extends UseSessionTimerProps,
    TypographyProps {}

export default function SessionTimer({
  tokenExpiresAt,
  time,
  ...typographyProps
}: SessionTimerProps) {
  const timeLeft = useSessionTimer({ tokenExpiresAt, time });

  return (
    <Typography {...typographyProps}>
      {timeLeft > 0 ? moment.unix(timeLeft).format("mm:ss") : "Expired"}
    </Typography>
  );
}

