import { Box, Chip } from "@mui/material";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";

interface Program {
  start_times: string[];
  days: number[];
}

interface ProgramCellProps {
  params?: any;
}

const ProgramCell: React.FC<ProgramCellProps> = ({ params }) => {
  const { resolveOptions } = useDictionaries();
  const programs: ProgramItem[] =
    resolveOptions("programs", params?.row?.program_ids) || [];

  return (
    <Box display="flex" flexWrap="wrap" marginBottom="2px">
      {programs?.map((program, idx) => (
        <Chip
          key={idx}
          label={program?.name}
          size="small"
          sx={{
            margin: "1px",
            fontSize: "0.75rem",
            backgroundColor: program?.color,
            color: theme => theme.palette.getContrastText(program?.color),
          }}
        />
      ))}
    </Box>
  );
};

export default ProgramCell;

