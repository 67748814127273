import SecurityIcon from "@mui/icons-material/Security";
import {
  DataGridV2 as DataGrid,
  GridColumns,
  PageHeader,
  Spacer,
} from "common";

import I18n from "i18n";
import RoleFormModal from "./RoleFormModal";
import useRoleList from "./useRoleList";

export interface RoleListProps {
  showHeader?: boolean;
}

export default function RoleList({ showHeader = true }: RoleListProps) {
  const { params, ...service } = useRoleList();

  let columns: GridColumns = [
    { field: "name", flex: 1, headerName: I18n.t("Role.attributes.name") },
  ];

  return (
    <>
      {showHeader && (
        <>
          <PageHeader avatar={<SecurityIcon />} title={I18n.t("Role.title")} />
          <Spacer h={2} />
        </>
      )}
      <DataGrid
        id="roles"
        {...service}
        columns={columns}
        //headerItems={["search", "refresh"]}
        //showFooter={false}
        editUrl=":id"
        canCreate
        canDelete
        canEdit
        canBatchActions
        renderModalForm={props => <RoleFormModal {...props} />}
        sortable={false}
      />
    </>
  );
}

