import api from "app/api";
import {
  CalendarEventItem as BaseCalendarEventItem,
  SaveFormProps,
} from "common";
import { BoatItem } from "features/boats/boatApi";
import { ProgramItem } from "features/programs/programApi";
import {
  ScheduleItem,
  ScheduleLimit,
  ScheduleOption,
  ScheduleVariant,
} from "features/schedules/scheduleApi";
import moment from "moment";
import { EVENT_STATUSES } from "./eventConstants";

export type EventVariant = {
  number_of_adults: number;
  number_of_kids: number;
  number_of_babies: number;
  number_of_guests: number;
  number_of_places: number;
  closed: boolean;
  online_closed: boolean;
} & ScheduleVariant;

export type EventLimit = {
  remaining_online_places: number;
  remaining_places: number;
  reserved_places: number;
  is_shared: boolean;
  over_tolerance: boolean;
  over_limit: boolean;
  online_over_tolerance: boolean;
  online_over_limit: boolean;
  closed: boolean | null;
  online_closed: boolean | null;
} & ScheduleLimit;

export type EventOption = {
  number_of_bookings: number;
} & ScheduleOption;

export type EventItem = {
  id: number;
  start_time: string;
  boat_id: number;
  program_id: number;
  schedule_id: number;
  schedule: ScheduleItem;
  duration: number;
  limits: EventLimit[];
  variants: EventVariant[];
  options: EventOption[];
  summary: EventVariant;
  closed: number[] | null;
  online_closed: number[] | null;
  custom: any;
  status: EVENT_STATUSES;
  private: boolean;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  boat: BoatItem;
  program: ProgramItem;
};

export type CalendarEventItem = EventItem & BaseCalendarEventItem;

export function prepareCalendarItems(items: EventItem[]): CalendarEventItem[] {
  let _items: CalendarEventItem[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(row?.duration, "minutes"),
      color: row.program?.color,
    });
  });

  return _items;
}

export function getEventService(scope?: any) {
  return api.getService<EventItem>("events", {
    scope,
    //params goes here
  });
}

export function getEventCalendarService(scope?: any) {
  return api.getService<EventItem>("events/calendar", {
    scope,
    //params goes here
  });
}

export async function updateEventAvailability(
  eventId: number,
  variant_ids: number[],
  close: boolean,
  online: boolean
): Promise<SaveFormProps<EventItem>> {
  const response = await api.post<EventItem>(
    `events/${eventId}/update-availability`,
    {
      variant_ids,
      online: online ? 1 : null,
      close: close ? 1 : null,
    }
  );
  return response;
}
export async function getClosestEvent(
  programId: number,
  datetime: string
): Promise<EventItem> {
  const response = await api.get<EventItem>(
    `events/find-closest-event?program_id=${programId}&datetime=${datetime}`
  );
  return response.data;
}
export async function getClosestEvents(
  programId: number,
  datetime: string
): Promise<EventItem[]> {
  const response = await api.get<EventItem[]>(
    `events/find-closest-events?program_id=${programId}&datetime=${datetime}`
  );
  return response.data;
}
