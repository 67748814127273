import { persistor, store } from "app/store";
import { GlobalFilePreview } from "common";
import AppThemeProvider from "features/theme/AppThemeProvider";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "text-security/text-security.css";
import GlobalMessaging from "./common/components/GlobalMessaging";
import { NavigationContext } from "./common/components/Navigation";

/**
 * Basic settings
 */

import settings from "app/settings";
import PageHeaderProvider from "common/components/PageHeader/PageHeaderProvider";
import AppLanguageProvider from "features/language/AppLanguageProvider";
import moment from "moment";
//moment.tz.setDefault("Europe/Budapest");
moment.locale(settings.i18n.locale);

interface AppRootProps {
  children: ReactNode;
}
export default function AppProvider({ children }: AppRootProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppThemeProvider>
          <BrowserRouter>
            <AppLanguageProvider>
              <PageHeaderProvider>
                <NavigationContext>
                  {children}
                  <GlobalFilePreview />
                </NavigationContext>
              </PageHeaderProvider>
            </AppLanguageProvider>
          </BrowserRouter>
          <GlobalMessaging />
        </AppThemeProvider>
      </PersistGate>
    </Provider>
  );
}
