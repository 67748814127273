import { useParams } from "react-router-dom";
import { getHistoryService } from "./historyApi";

export default function useHistoryList() {
  const params = useParams<"user_id">();
  const service = getHistoryService({ users: params.user_id });
  /*const list = useGrid<
    HistoryFormItem,
    HistoryListItem
  >({
    storageType: "urlParams",
    id: "Historys",
    translationCategory: "History",
    ...service,
  });*/

  return { ...service, params };
}

