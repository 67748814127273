var earthRadiusMeters = 6367460.0;
var metersPerDegree = (2.0 * Math.PI * earthRadiusMeters) / 360.0;
var degreesPerRadian = 180.0 / Math.PI;
var radiansPerDegree = Math.PI / 180.0;
var metersPerKm = 1000.0;
var meters2PerHectare = 10000.0;
var feetPerMeter = 3.2808399;
var feetPerMile = 5280.0;
var acresPerMile2 = 640;

export function getDistance(value: any): number {
  if (Array.isArray(value)) {
    var polylineLength = 0;
    for (var i = 0; i < value.length; i++) {
      if (i > 0)
        polylineLength += google.maps.geometry.spherical.computeDistanceBetween(
          value[i],
          value[i - 1]
        );
    }
    return Math.round(polylineLength / 10) / 100;
  }
  return 0;
}

export function calculateArea(points: any): number {
  if (points && points.length > 2) {
    var areaMeters2 = PlanarPolygonAreaMeters2(points);
    if (areaMeters2 > 1000000.0)
      areaMeters2 = SphericalPolygonAreaMeters2(points);

    return areaMeters2;
  }
  return 0;
}

export function getAreaText(value: any) {
  let m2 = calculateArea(value);
  let v: any = m2;
  let unit = "m2";
  if (m2 > 1500) {
    v = m2 / 1000000;
    unit = "km2";
  }

  if (v < 100 && unit === "km2") {
    v = v.toFixed(2);
  } else {
    v = Math.round(v);
  }

  return v ? `${Number(v).toFixed(2)} ${unit}` : "";
}

export function getReadableAreaText(m2: any) {
  let v: any = m2;
  let unit = "m2";
  if (m2 > 1500) {
    v = m2 / 1000000;
    unit = "km2";
  }

  if (v < 100 && unit === "km2") {
    v = v.toFixed(2);
  } else {
    v = Math.round(v);
  }

  return v ? `${Number(v).toFixed(2)} ${unit}` : "";
}

function PlanarPolygonAreaMeters2(points: any) {
  var a = 0.0;
  for (var i = 0; i < points.length; ++i) {
    var j = (i + 1) % points.length;
    var xi =
      points[i].lng *
      metersPerDegree *
      Math.cos(points[i].lat * radiansPerDegree);
    var yi = points[i].lat * metersPerDegree;
    var xj =
      points[j].lng *
      metersPerDegree *
      Math.cos(points[j].lat * radiansPerDegree);
    var yj = points[j].lat * metersPerDegree;
    a += xi * yj - xj * yi;
  }
  return Math.abs(a / 2.0);
}
function SphericalPolygonAreaMeters2(points: any) {
  var totalAngle = 0.0;
  //alert(points[0]);
  for (let i = 0; i < points.length; ++i) {
    var j = (i + 1) % points.length;
    var k = (i + 2) % points.length;
    totalAngle += Angle(points[i], points[j], points[k]);
  }
  var planarTotalAngle = (points.length - 2) * 180.0;
  var sphericalExcess = totalAngle - planarTotalAngle;
  if (sphericalExcess > 420.0) {
    totalAngle = points.length * 360.0 - totalAngle;
    sphericalExcess = totalAngle - planarTotalAngle;
  } else if (sphericalExcess > 300.0 && sphericalExcess < 420.0) {
    sphericalExcess = Math.abs(360.0 - sphericalExcess);
  }
  return (
    sphericalExcess * radiansPerDegree * earthRadiusMeters * earthRadiusMeters
  );
}
function Angle(p1: any, p2: any, p3: any) {
  var bearing21 = Bearing(p2, p1);
  var bearing23 = Bearing(p2, p3);
  var angle = bearing21 - bearing23;
  if (angle < 0.0) angle += 360.0;
  return angle;
}
function Bearing(from: any, to: any) {
  var lat1 = from.lat * radiansPerDegree;
  var lon1 = from.lng * radiansPerDegree;
  var lat2 = to.lat * radiansPerDegree;
  var lon2 = to.lng * radiansPerDegree;
  var angle = -Math.atan2(
    Math.sin(lon1 - lon2) * Math.cos(lat2),
    Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
  );
  if (angle < 0.0) angle += Math.PI * 2.0;
  angle = angle * degreesPerRadian;
  return angle;
}

function Areas(areaMeters2: any) {
  var areaHectares = areaMeters2 / meters2PerHectare;
  var areaKm2 = areaMeters2 / metersPerKm / metersPerKm;
  var areaFeet2 = areaMeters2 * feetPerMeter * feetPerMeter;
  var areaMiles2 = areaFeet2 / feetPerMile / feetPerMile;
  var areaAcres = areaMiles2 * acresPerMile2;
  //return areaMeters2.toPrecision(4)+' m&sup2; / '+areaHectares.toPrecision(4)+' hectares / '+areaKm2.toPrecision(4)+' km&sup2; / '+areaFeet2.toPrecision(4)+' ft&sup2; / '+areaAcres.toPrecision(4)+' acres / '+areaMiles2.toPrecision(4)+' mile&sup2;';}
  var area =
    areaMeters2 +
    " m&sup2; / " +
    areaHectares.toFixed(4) +
    " hectares / " +
    areaKm2.toFixed(4) +
    " km&sup2;<br />" +
    areaFeet2.toFixed(2) +
    " ft&sup2; / " +
    areaAcres.toFixed(4) +
    " acres / " +
    areaMiles2.toFixed(4) +
    " mile&sup2;";
  return area;
}

