import { clearNotifications } from "./notificationsSlice";

const notificationMiddleware =
  (store: any) => (next: any) => async (action: any) => {
    const nextAction = await next(action);

    //Load profile after login
    /* if (action.type === "identity/login/fulfilled") {
      subscribe();
      store.dispatch(loadNotificationCountAsync());
    }*/

    //Clear notification data on logout
    if (action.type === "identity/logout/fulfilled") {
      await store.dispatch(clearNotifications());
    }
    if (action.type === "identity/logoutSync") {
      await store.dispatch(clearNotifications());
    }

    return nextAction;
  };

export default notificationMiddleware;

