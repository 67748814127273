import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, IconButtonProps } from "@mui/material";
import DialogTitleMui, {
  DialogTitleProps as DialogTitlePropsMui,
} from "@mui/material/DialogTitle";
export interface DialogTitleProps extends DialogTitlePropsMui {
  onClose?: Function;
  closeButtonProps?: IconButtonProps;
}
export default function DialogTitle({
  onClose,
  children,
  closeButtonProps,
  ...props
}: DialogTitleProps) {
  if (!onClose) {
    <DialogTitleMui {...props}>{children}</DialogTitleMui>;
  }
  return (
    <DialogTitleMui {...props}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>{children}</Box>
        {onClose && (
          <Box>
            <IconButton
              onClick={() => onClose && onClose()}
              {...closeButtonProps}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </DialogTitleMui>
  );
}

