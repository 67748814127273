import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilitIcon from "@mui/icons-material/VisibilityOutlined";
import { IconButton, InputAdornment } from "@mui/material";
import { MouseEvent, useState } from "react";
import TextField, { TextFieldProps } from "../TextField";

export interface PasswordFieldProps extends TextFieldProps {
  /**Show visibilit adornment */
  visibility?: boolean;
  /** Its just a fake password field input type is not password, emulate with text-security-disc font */
  fake?: boolean;
}

export default function PasswordField({
  visibility = true,
  fake = false,
  InputProps,
  ...textFieldProps
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  function handleMouseDownPassword(event: MouseEvent) {
    event.preventDefault();
  }
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function getInputType() {
    let type: string = "password";
    if (fake) {
      type = "text";
    } else {
      type = showPassword ? "text" : "password";
    }
    return type;
  }

  function getInputStyle() {
    if (fake) {
      return showPassword
        ? undefined
        : {
            fontFamily: "text-security-disc",
          };
    }
    return;
  }

  return (
    <TextField
      type={getInputType()}
      InputProps={{
        inputProps: {
          style: getInputStyle(),
          autoComplete: "new-password",
        },
        endAdornment: visibility ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => toggleShowPassword()}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilitIcon />}
            </IconButton>
          </InputAdornment>
        ) : (
          InputProps?.endAdornment
        ),
        ...InputProps,
      }}
      {...textFieldProps}
    />
  );
}

