
import SaveIcon from "@mui/icons-material/Save";
import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

interface FabSaveButtonProps extends FabProps {
  offset?: number;
  visible?: boolean;
  animationType?:
    | "slideUp"
    | "slideDown"
    | "fade"
    | "zoomIn"
    | "zoomOut"
    | "flip"
    | "rotate"
    | "bounce"
    | "slideLeft"
    | "slideRight";
}

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
}));

const animationStyles: { [key: string]: React.CSSProperties } = {
  slideUp: {
    transform: "translateY(120px)",
    transition: "transform 0.3s ease-in-out",
  },
  slideDown: {
    transform: "translateY(-100px)",
    transition: "transform 0.3s ease-in-out",
  },
  fade: { opacity: 0, transition: "opacity 0.3s ease-in-out" },
  zoomIn: { transform: "scale(0)", transition: "transform 0.3s ease-in-out" },
  zoomOut: { transform: "scale(2)", transition: "transform 0.3s ease-in-out" },
  flip: {
    transform: "rotateY(180deg)",
    transition: "transform 0.3s ease-in-out",
  },
  rotate: {
    transform: "rotate(360deg)",
    transition: "transform 0.3s ease-in-out",
  },
  bounce: { animation: "bounce 2s infinite" },
  slideLeft: {
    transform: "translateX(-100px)",
    transition: "transform 0.3s ease-in-out",
  },
  slideRight: {
    transform: "translateX(100px)",
    transition: "transform 0.3s ease-in-out",
  },
};

const FabSaveButton: React.FC<FabSaveButtonProps> = ({
  offset = 100,
  visible = true,
  animationType = "slideUp",
  ...props
}) => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const threshold = document.documentElement.scrollHeight - offset;
      setHidden(scrollPosition >= threshold || !visible);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [offset, visible]);

  return (
    <StyledFab
      {...props}
      style={{
        ...props.style,
        ...(hidden && animationStyles[animationType]),
      }}
    >
      <SaveIcon />
    </StyledFab>
  );
};

export default FabSaveButton;

