import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import I18n from "i18n";
import moment, { Moment } from "moment";
import { Fragment, ReactNode } from "react";

import { enumToOptions } from "./../../modules/helpers";
import Button, { ButtonProps } from "./../Button";
import Spacer from "./../Spacer";

import { DatePicker } from "../Inputs";
import Toolbar from "../Toolbar";
import { NAVIGATE, VIEWS } from "./calendarApi";

function CalendarButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      /*backgroundStyle={{
        paddingVertical: 8,
        paddingHorizontal: 12,
        minWidth: 26,
      }}*/
    />
  );
}

function NavigateButton({ ...props }: ButtonProps) {
  return (
    <Button
      {...props}
      /*backgroundStyle={[
        {
          paddingVertical: 8,
          paddingHorizontal: 8,
          minWidth: 40,
          minHeight: 40,
        },
        backgroundStyle,
      ]}*/
    />
  );
}

export interface CalendarToolbarProps {
  label?: string | null;
  view: VIEWS;
  views: VIEWS[];
  onNavigate: (
    type: NAVIGATE,
    d?: Moment,
    noDelay?: boolean,
    _view?: VIEWS
  ) => void;
  onView: (_view: VIEWS) => void;
  buttons?: any[] | ReactNode;
  customToolbar: any;
  headerContent?: ReactNode;
  loading?: boolean;
  showRefreshButton?: boolean;
  refresh?: Function;
  date?: Moment;
  sx?: BoxProps["sx"];
}

export default function CalendarToolbar({
  label,
  view,
  views,
  onView,
  onNavigate,
  buttons,
  customToolbar,
  loading,
  refresh,
  sx,
  headerContent,
  showRefreshButton = true,
  date,
}: CalendarToolbarProps) {
  const theme = useTheme();
  const viewOptions = enumToOptions(VIEWS, "CALNDAR.views");
  return (
    <Box sx={{ p: 1, overflow: "hidden", ...sx }}>
      <Toolbar sx={{ gap: 1, flex: 1 }}>
        <IconButton onClick={() => onNavigate(NAVIGATE.PREVIOUS)}>
          <ChevronLeftIcon />
        </IconButton>
        <DatePicker
          sx={{ flex: 1, maxWidth: 200 }}
          value={date ? moment(date).format("YYYY-MM-DD") : undefined}
          onChange={(evt: any) => {
            console.log(evt.target.value);
            onNavigate(NAVIGATE.DATE, moment(evt.target.value));
          }}
          /*slotProps={{
            textField: c => ({
              contentEditable: false,
              //helperText: 'MM/DD/YYYY',
              helperText: `${console.log(c)}`,
              InputProps: {
                value: label,
                readOnly: true,
                sx: {
                  "& input": {
                    //pointerEvents: "none",
                  },
                },
              },
            }),
          }}*/
        />
        <IconButton onClick={() => onNavigate(NAVIGATE.NEXT)}>
          <ChevronRightIcon />
        </IconButton>
        <Select onChange={evt => onView(evt.target.value as any)} value={view}>
          {views.map((v, index) => {
            return (
              <MenuItem key={index} value={v}>
                {I18n.t(`Calendar.views.${v}`)}
              </MenuItem>
            );
          })}
        </Select>
        <Spacer right />
        {headerContent}
        {buttons && !Array.isArray(buttons) && buttons}
        {buttons &&
          Array.isArray(buttons) &&
          buttons.map((button, index) => {
            return (
              <Fragment key={index}>
                <CalendarButton
                  onClick={button.onClick}
                  variant="outlined"
                  title={button.title}
                />
                {buttons.length - 1 > index && <Spacer w={0.5} />}
              </Fragment>
            );
          })}
        {showRefreshButton && (
          <IconButton
            disabled={loading}
            onClick={refresh ? () => refresh() : undefined}
            sx={{ justifySelf: "flex-end" }}
          >
            {loading ? (
              <CircularProgress size={21} color="primary" />
            ) : (
              <RefreshIcon />
            )}
          </IconButton>
        )}
      </Toolbar>
      {customToolbar}
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
        />
      )}
    </Box>
  );
}

