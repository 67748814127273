import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settings from "app/settings";
import { RootState } from "app/store";
import { tokenIsExpired, tokenWillExpire } from "common";
import { get } from "lodash";
import moment from "moment";
import { LoginForm, login, logout, refresh } from "./identityAPI";

export interface IdentityData {
  access_token: string;
  expires_in: number;
  expires_at?: number;
  refresh_token: string;
  scope?: string | null;
  success: boolean;
  token_type: string;
}

export interface IdentityState {
  data: IdentityData;
  status: "idle" | "loading" | "failed";
}

const initialState: IdentityState = {
  data: {} as IdentityData,
  status: "idle",
};

export const loginAsync = createAsyncThunk(
  "identity/login",
  async (item: LoginForm, { rejectWithValue }) => {
    const { data, error, success } = await login(item);
    if (success) {
      return data;
    }
    return rejectWithValue(error);
  }
);

export const logoutAsync = createAsyncThunk(
  "identity/logout",
  async (_: void, { getState }) => {
    //On expired token we just should return true as resolce thunk
    const state = getState();
    const access_token = get(state, "identity.data.access_token");
    const expires_at = get(state, "identity.data.expires_at", 0);
    const expired = access_token && tokenIsExpired(expires_at);
    if (expired) {
      return true;
    }
    await logout();
    return true;
  }
);

export const refreshAsync = createAsyncThunk(
  "identity/refresh",
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    const expires_at = get(state, "identity.data.expires_at", 0);

    //If token is expired we just reject this action and perform a logout
    if (tokenIsExpired(expires_at)) {
      dispatch({ type: "identity/logout/fulfilled" });
      return rejectWithValue(false);
    } else {
      //Refres token
      const { data, error, success } = await refresh();
      if (success) {
        return data;
      }
      return rejectWithValue(error);
    }
  },
  {
    condition: (_: void, { getState }): boolean | undefined => {
      //check if token will expire and only that case return true
      //other case this action do nothing
      const state = getState();
      const access_token = get(state, "identity.data.access_token");
      const expires_at = get(state, "identity.data.expires_at", 0);
      const result =
        access_token &&
        tokenWillExpire(expires_at, settings.refreshTokenDeltaTime);
      return result;
    },
  }
);

export const identitySlice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    resetStatus: state => {
      state.status = "idle";
    },
    setIdentityData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    //Login
    builder
      .addCase(loginAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
        state.data.expires_at = moment().unix() + action.payload.expires_in;
      })
      .addCase(loginAsync.rejected, state => {
        state.status = "idle";
      });

    //Refresh token
    builder
      .addCase(refreshAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(refreshAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.data = action.payload;
        state.data.expires_at = moment().unix() + action.payload.expires_in;
      })
      .addCase(refreshAsync.rejected, state => {
        state.status = "idle";
      });

    //Logout
    builder
      .addCase(logoutAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, state => {
        state.status = "idle";
        state.data = {} as IdentityData;
      })
      .addCase(logoutAsync.rejected, state => {
        state.status = "idle";
        state.data = {} as IdentityData;
      });
  },
});

export const { resetStatus, setIdentityData } = identitySlice.actions;

export const selectIdentity = (state: RootState) => state.identity.data;
export const selectIdentityStatus = (state: RootState) => state.identity.status;
export const selectAccessToken = (state: RootState) =>
  state.identity.data.access_token;
export const selectAccessTokenExpiresAt = (state: RootState) =>
  state.identity.data.expires_at;

export default identitySlice.reducer;

