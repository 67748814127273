import { Close as CloseIcon } from "@mui/icons-material";
import {
  alpha,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import moment from "moment";
import { Fragment, useCallback, useState } from "react";
import BookingListItem from "./BookingListItem";
import { KitchenItem } from "./kitchenApi";
import OrderItem from "./OrderItem";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function KitchenListItem({ item }: { item: KitchenItem }) {
  const [open, setOpen] = useState(false);
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderPrograms = useCallback(() => {
    return (
      <>
        {item.program_ids.map(program_id => {
          const program = resolveOption<ProgramItem>("programs", program_id);
          return (
            <Toolbar key={program_id} sx={{ gap: 1 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "5px",
                  backgroundColor: program?.color,
                }}
              />
              <Typography>{program?.name}</Typography>
            </Toolbar>
          );
        })}
      </>
    );
  }, [item.program_ids]);

  const summary = item.summary;
  summary.sort((a, b) => b.qty - a.qty);
  return (
    <Fragment>
      <ListItemButton onClick={() => setOpen(true)} divider>
        <ListItemText
          disableTypography
          primary={
            <Toolbar sx={{ gap: 1, flexWrap: "wrap" }}>
              <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>{`${moment(
                item.start_time
              ).format("L LT")} - ${item.boat_name}`}</Typography>
              {renderPrograms()}
            </Toolbar>
          }
          secondary={
            <Box
              display="flex"
              flexDirection="row"
              gap={1}
              mt={1}
              flexWrap="wrap"
            >
              {summary.map((summary, index) => (
                <OrderItem
                  key={index}
                  name={summary.name}
                  qty={summary.qty}
                  type={summary.type}
                />
              ))}
            </Box>
          }
        />
      </ListItemButton>
      <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="h6">{`${moment(item.start_time).format(
              "L LT"
            )} - ${item.boat_name}`}</Typography>
            {renderPrograms()}
            <Box sx={{ flex: 1 }} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
              sx={{}}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              flexWrap: "wrap",
              p: 1,
              borderRadius: "6px",
              border: theme =>
                `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
              backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
            }}
          >
            <Typography variant="body1" align="center">
              Járat összes rendelése
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {summary.map((summary, index) => (
                <OrderItem
                  key={index}
                  name={summary.name}
                  qty={summary.qty}
                  type={summary.type}
                />
              ))}
            </Box>
          </Box>
          <List disablePadding dense>
            {item.bookings.map(booking => (
              <BookingListItem key={booking.id} booking={booking} />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default KitchenListItem;
