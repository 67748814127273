import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import { DialogActions, DialogContent } from "@mui/material";
import {
  Autocomplete,
  Button,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { createContext } from "react";
import useBookingOptionForm, {
  UseBookingOptionFormProps,
  UseBookingOptionFormReturnType,
} from "./useBookingOptionForm";
export const BookingOptionContext = createContext<
  UseBookingOptionFormReturnType | undefined
>(undefined);
export interface BookingOptionFormProps extends UseBookingOptionFormProps {}

export default function BookingOptionForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: BookingOptionFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useBookingOptionForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
  const title = isCreate
    ? I18n.t("BookingOption.addTitle")
    : `${item?.option?.name}`;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider
      value={{
        booking_option_id: id,
        booking_id: item.booking_id,
        program_option_id: item.program_option_id,
      }}
    >
      <BookingOptionContext.Provider value={form}>
        <PageHeader
          avatar={<TheaterComedyIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        />
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="BookingOption.attributes"
            translationPlaceholdersCategory="BookingOption.placeholders"
            translationHelpersCategory="BookingOption.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("booking_option", item)}
          >
            <FieldsContainer>
              {!params?.booking_id && (
                <Field fullWidth>
                  <Autocomplete id="booking_id" />
                </Field>
              )}
              {!params?.program_option_id && (
                <Field fullWidth>
                  <Autocomplete id="program_option_id" />
                </Field>
              )}
              <Field fullWidth>
                <TextField id="qty" />
              </Field>
              <Field fullWidth>
                <TextField id="price" />
              </Field>
            </FieldsContainer>
          </Form>
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </BookingOptionContext.Provider>
    </ParamsContext.Provider>
  );
}
