import { Box, BoxProps } from "@mui/material";

export interface ContentProps extends BoxProps {}

export default function Content({ children, ...props }: ContentProps) {
  return (
    <Box
      className="AppContent"
      sx={{
        display: "flex",
        flex: 1,
        minHeight: "100%",
        maxWidth: "100%",
        flexDirection: "column",
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

