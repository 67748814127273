import EventSeatIcon from "@mui/icons-material/EventSeat";
import { Box, LinearProgress } from "@mui/material";
import { PageHeader, Spacer } from "common";
import I18n from "i18n";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import CateringFilter from "./CateringFilter";
import CateringList from "./CateringList";
import { CateringItem, getCateringData } from "./cateringApi";

export default function CateringPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [cateringData, setCateringData] = useState<CateringItem[]>([]);

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => {
    setLoading(true);
    const { success, data } = await getCateringData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    if (success) {
      setCateringData(data || []);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        //position: "relative",
      }}
    >
      <PageHeader avatar={<EventSeatIcon />} title={I18n.t("Catering.title")} />
      <CateringFilter onFilter={handleFilter} />
      <Spacer h={1} />
      <CateringList cateringData={cateringData} />
      {loading && (
        <LinearProgress sx={{ position: "fixed", left: 0, top: 0, right: 0 }} />
      )}
      <Outlet />
    </Box>
  );
}
