import { AssignmentReturned, AssignmentTurnedIn } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyIcon from "@mui/icons-material/Key";
import { Chip, ChipProps, IconButton, alpha } from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
  enumToOptions,
  globalMessageHandler,
} from "common";
import { GridState } from "common/components/DataGridV2/types";
import CheckboxGroup from "common/components/Inputs/CheckboxGroup/CheckboxGroup";
import BookingButton from "components/BookingButton/BookingButton";
import { EVENT_STATUSES } from "features/events/eventConstants";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import moment from "moment";
import { ReactNode } from "react";
import BookingForm from "./BookingForm";
import { BookingItem } from "./bookingApi";
import { BOOKING_STATUSES } from "./bookingConstants";
import useBookingList from "./useBookingList";
export interface BookingListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
  defaultState?: Partial<GridState>;
  isInCalendar?: boolean;
}

export default function BookingList({
  showHeader = true,
  isInCalendar,
  defaultState,
  ...props
}: BookingListProps) {
  const {
    params,
    scope,
    editUrl,
    status,
    setStatus,
    listId,
    checkInAsync,
    checkOutAsync,
    ...service
  } = useBookingList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<BookingItem> = [
    {
      field: "booking_number",
      headerName: I18n.t("Booking.attributes.booking_number"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
    },
    {
      field: "external_booking_number",
      headerName: I18n.t("Booking.attributes.external_booking_number"),
    },
    {
      valueFormatter: params =>
        `${moment(params?.row?.event?.start_time).format("L LT")} ${
          params?.row?.event?.program?.name
        }`,
      field: "event.start_time",
      headerName: I18n.t("Event.attributes.start_time"),
      renderCell: params =>
        params?.row?.planned
          ? moment(params?.row?.planned_datetime).format("L LT")
          : moment(params?.row?.event?.start_time).format("L LT"),
    },
    {
      field: "event.boat.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.event?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      field: "event.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.event?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "event.schedule.{program.name} {date_from}-{date_to}",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.schedule_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/schedules/${params.row?.event?.schedule_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.program_variant_id,
      hideable: !params.program_variant_id,
      field: "program_variant.name",
      headerName: I18n.t("Booking.attributes.program_variant_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/program-variants/${params.row?.program_variant_id}`}
          params={params}
        />
      ),
    },
    {
      field: "program_variant.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("ProgramVariant.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_variant?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "custom_partner",
      headerName: I18n.t("Booking.attributes.custom_partner"),
      type: "boolean",
      hideable: true,
    },
    /*{
      field: "partner_name",
      headerName: I18n.t("Booking.attributes.partner_name"),
      hideable: true,
      flex: 1,
    },*/
    {
      hide: !!params.partner_id,
      hideable: !params.partner_id,
      field: "partner.name",
      headerName: I18n.t("Booking.attributes.partner_id"),
      renderCell: params => {
        if (params?.row?.custom_partner) {
          return params?.row?.partner_name;
        }
        return (
          <DataGridLinkColumn
            href={`/partners/${params.row?.partner_id}`}
            params={params}
          />
        );
      },
    },
    {
      field: "partner.partner_group.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Partner.attributes.partner_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/partner-groups/${params.row?.partner?.partner_group_id}`}
          params={params}
        />
      ),
    },
    {
      field: "status",
      headerName: I18n.t("Booking.attributes.status"),
      type: "singleSelect",
      valueOptions: enumToOptions(BOOKING_STATUSES, "Booking.options.status"),
    },
    { field: "name", headerName: I18n.t("Booking.attributes.name"), flex: 1 },
    { field: "email", headerName: I18n.t("Booking.attributes.email"), flex: 1 },
    {
      field: "phone",
      headerName: I18n.t("Booking.attributes.phone"),
      hideable: true,
      flex: 1,
    },
    {
      field: "number_of_adults",
      headerName: I18n.t("Booking.attributes.number_of_adults"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_kids",
      headerName: I18n.t("Booking.attributes.number_of_kids"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_babies",
      headerName: I18n.t("Booking.attributes.number_of_babies"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_guests",
      headerName: I18n.t("Booking.attributes.number_of_guests"),
      type: "number",
    },
    {
      field: "paid",
      headerName: I18n.t("Booking.attributes.paid"),
      type: "boolean",
      hideable: true,
      renderCell: params => {
        let color: ChipProps["color"] = "warning";
        let icon: ReactNode = <DriveFileRenameOutlineIcon />;
        if (params.row?.paid) {
          color = "success";
          icon = <CheckIcon />;
        } else {
          color = "error";
          icon = <CloseIcon />;
        }

        return (
          <Chip
            //className={rootClassName}
            //classes={{ icon: classes.icon }}
            size="small"
            icon={icon}
            label={params.row?.paid ? "Fizetve" : "Fizetetlen"}
            color={color}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: I18n.t("App.created_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "created_by_name",
      headerName: I18n.t("App.created_by_name"),
      hideable: true,
      sortable: false,
      //renderCell: params => params.row?.created_by_name,
    },
    {
      field: "updated_at",
      headerName: I18n.t("App.updated_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "updated_by_name",
      headerName: I18n.t("App.updated_by_name"),
      hideable: true,
    },
    {
      field: "check_in_at",
      headerName: I18n.t("Booking.attributes.check_in_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "description",
      headerName: I18n.t("Booking.attributes.description"),
      hideable: true,
      hide: true,
    },
    {
      field: "sku",
      headerName: I18n.t("Booking.attributes.sku"),
      hideable: true,
      flex: 1,
    },
    {
      field: "adult_price",
      headerName: I18n.t("Booking.attributes.adult_price"),
      type: "currency",
      hideable: true,
      align: "right",
    },
    {
      field: "kid_price",
      headerName: I18n.t("Booking.attributes.kid_price"),
      type: "currency",
      hideable: true,
      align: "right",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader avatar={<KeyIcon />} title={I18n.t("Booking.title")} />
      )}
      <DataGrid
        renderToolbarLeftItems={({ handleCreate }) => (
          <>
            <CheckboxGroup
              options={enumToOptions(
                BOOKING_STATUSES,
                "Booking.options.status"
              )}
              onChange={(evt: any) => setStatus(evt.target.value)}
              value={status}
            />
            {!isInCalendar && (
              <BookingButton
                buttonType="icon"
                onSelect={(programId, event, planned, datetime) => {
                  if (planned) {
                    handleCreate({} as any, {
                      //event_id: event.id,
                      planned_datetime: datetime,
                      planned_event: event,
                      planned_event_id: event?.id,
                      planned: true,
                      status: EVENT_STATUSES.ACTIVE,
                    });
                  } else {
                    handleCreate({} as any, {
                      event_id: event?.id,
                      event: event,
                      status: EVENT_STATUSES.ACTIVE,
                    });
                  }
                }}
              >
                <Add />
              </BookingButton>
            )}
          </>
        )}
        {...service}
        filter={[
          { columnField: "booking.status", operatorValue: "in", value: status },
        ]}
        columns={columns}
        id={listId}
        renderModalForm={props => <BookingForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Booking.attributes.${field.replaceAll(".", "_")}`)}
        rowSx={(item: BookingItem) => {
          if (item.status === BOOKING_STATUSES.CANCELED) {
            return {
              backgroundColor: theme => alpha(theme.palette.error.main, 0.15),
            };
          }
          if (item.planned) {
            return {
              backgroundColor: theme => alpha(theme.palette.warning.main, 0.15),
            };
          }
          if (!!item.check_in_at) {
            return {
              backgroundColor: theme => alpha(theme.palette.success.main, 0.15),
            };
          }
          return {};
        }}
        defaultState={{
          sorting: [
            { field: "status", direction: "desc" },
            { field: "created_at", direction: "desc" },
          ],
          hidden_columns: [
            "event_id",
            "boat_id",
            "program_id",
            "schedule_id",
            "custom_partner",
            "partner_name",
            "partner_group_id",
            "phone",
            "email",
            "number_of_adults",
            "number_of_kids",
            "number_of_babies",
            "paid",
            "check_in_at",
            "description",
            "sku",
            "adult_price",
            "kid_price",
            "created_at",
            "updated_at",
            "updated_by_name",
          ],
          ...defaultState,
        }}
        canCreate={hasPermissions("booking.create")}
        canDelete={hasPermissions(["booking.editany", "booking.editown"])}
        canEdit={hasPermissions(["booking.editany", "booking.editown"])}
        canDeleteRow={params => canEditItem("booking", params.row)}
        canEditRow={params => canEditItem("booking", params.row)}
        canBatchActions={hasPermissions(["booking.editany", "booking.editown"])}
        canHandleTrash={hasPermissions(["booking.editany", "booking.editown"])}
        rowActions={(params, openEdit, refresh) => {
          let actions: any[] = [];
          actions.push(
            <IconButton
              size="small"
              title={
                !!params.row?.check_in_at
                  ? "Regisztráció törlése"
                  : "Regisztráció"
              }
              onClick={() => {
                if (!!params.row?.check_in_at) {
                  globalMessageHandler.confirm(
                    {
                      message: "Biztosan törlöd a regisztrációt?",
                    },
                    async () => {
                      await checkOutAsync(params.row);
                      refresh();
                    }
                  );
                } else {
                  globalMessageHandler.confirm(
                    {
                      message: `Biztosan regisztrálod az alábbi foglalást? ${params.row.name}`,
                    },
                    async () => {
                      await checkInAsync(params.row);
                      refresh();
                    }
                  );
                }
              }}
            >
              {!!params.row?.check_in_at ? (
                <AssignmentTurnedIn />
              ) : (
                <AssignmentReturned />
              )}
            </IconButton>
          );
          return actions;
        }}
      />
    </>
  );
}
