import { Tabs, TabsProps } from "@mui/material";
import { cloneElement, isValidElement } from "react";
import useSelectedTab, { UseSelectedTabProps } from "./useSelectedTab";
export interface TabNavigationProps extends TabsProps, UseSelectedTabProps {
  pattern?: string;
  tabParam?: string;
  replace?: boolean;
  absolute?: boolean;
}

export default function TabNavigation({
  pattern,
  tabParam = "tab",
  children,
  absolute,
  ...tabProps
}: TabNavigationProps) {
  let tab = useSelectedTab({ pattern, tabParam });
  let avaibleTabs: any[] = [];
  if (Array.isArray(children)) {
    children.forEach(child => {
      if (child.props) {
        avaibleTabs.push(child.props.value);
      }
    });
  } else if (isValidElement(children)) {
    if (children.props) {
      avaibleTabs.push(children.props.value);
    }
  }
  let _tab: any = tab || "";
  if (!avaibleTabs.includes(tab || "")) {
    _tab = false;
  }
  return (
    <Tabs value={_tab} variant="scrollable" scrollButtons="auto" {...tabProps}>
      {Array.isArray(children)
        ? children.map((child: any, index: number) => {
            if (!child.props) {
              return null;
            }
            return cloneElement(child, {
              absolute,
              key: index,
            });
          })
        : (children as any)?.props
        ? cloneElement(children as any, {
            absolute,
          })
        : null}
    </Tabs>
  );
}

