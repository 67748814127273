//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  EventItem,
  getEventService,
} from "./eventApi";

export default function useEventList() {
  const params = useParams<"boat_id" | "program_id" | "schedule_id">();
  const scope = { "boats": params.boat_id, "programs": params.program_id, "schedules": params.schedule_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "events/"
      : ""
  }`
  const service = getEventService({ "boats": params.boat_id, "programs": params.program_id, "schedules": params.schedule_id });

  return { ...service, params, scope, editUrl };
}

