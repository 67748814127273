import Refresh from "@mui/icons-material/Refresh";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Toolbar } from "common";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import DateRangeSelector2 from "components/DateRangeSeletor/DateRangeSelector2";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import useSettings from "features/settings/useSettings";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

interface CateringFilterProps {
  onFilter: (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => void;
}

export default function CateringFilter({ onFilter }: CateringFilterProps) {
  const [range, setRange] = useState<{ start: Moment; end: Moment }>({
    start: moment().startOf("week"),
    end: moment().endOf("week"),
  });

  const [settings, setSettings] = useSettings({ key: "catering" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    onFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds
    );
  }, [range, settings.boatIds, settings.programIds]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Toolbar sx={{ flex: 1, alignSelf: "stretch" }}>
        <Box sx={{ width: "36px" }} />
        <Box sx={{ flex: 1 }} />
        <DateRangeSelector2
          numberOfCalendars={1}
          numberOfYears={0}
          value={range}
          onChange={(start, end) => {
            setRange({ start, end });
          }}
          dateButtonMinWidth={240}
        />
        <Box sx={{ flex: 1 }} />
        <IconButton
          onClick={() => {
            onFilter(
              range?.start?.format("YYYY-MM-DD"),
              range?.end?.format("YYYY-MM-DD"),
              settings.boatIds,
              settings.programIds
            );
          }}
        >
          <Refresh />
        </IconButton>
      </Toolbar>
      <BoatCheckboxSelector
        color={theme.palette.primary.main}
        value={settings.boatIds || []}
        onChange={evt =>
          setSettings({
            boatIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
      />
      <ProgramCheckboxSelector
        value={settings.programIds || []}
        onChange={evt =>
          setSettings({
            programIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
      />
    </Box>
  );
}
