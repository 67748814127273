import I18n from "i18n";
import moment, { Moment } from "moment";

export function generateTimeOptions(
  interval: number,
  is24HourFormat: boolean = true
) {
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      let value, label;
      if (is24HourFormat) {
        value = label = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`;
      } else {
        const period = hour < 12 ? "AM" : "PM";
        const hour12 = hour % 12 || 12; // Convert 0 to 12 for 12 AM and 12 PM
        value = label = `${String(hour12).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")} ${period}`;
      }
      timeOptions.push(value);
    }
  }
  return timeOptions;
}
interface DateRange {
  start?: Moment;
  end?: Moment;
}

function formatExactLabel(start: Moment, end: Moment) {
  const startYear = start.format("YYYY");
  const startMonth = start.format("MMMM");
  const startDate = start.format("D");

  const endYear = end.format("YYYY");
  const endMonth = end.format("MMMM");
  const endDate = end.format("D");

  let formattedLabel;

  if (start.isSame(end, "day")) {
    formattedLabel = `${startYear} ${startMonth} ${startDate}`;
  } else if (startYear === endYear) {
    if (startMonth === endMonth) {
      formattedLabel = `${startYear} ${startMonth} ${startDate} - ${endDate}`;
    } else {
      formattedLabel = `${startYear} ${startMonth} ${startDate} - ${endMonth} ${endDate}`;
    }
  } else {
    formattedLabel = `${startYear} ${startMonth} ${startDate} - ${endYear} ${endMonth} ${endDate}`;
  }

  return formattedLabel;
}

export function getLabel(value?: DateRange) {
  if (!value || !value.start || !value.end) {
    return {
      niceLabel: I18n.t("Calendar.ranges.selectDateRange"),
      exactLabel: I18n.t("Calendar.ranges.selectDateRange"),
    };
  }

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const dayBeforeYesterday = moment().subtract(2, "days").startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");
  const dayAfterTomorrow = moment().add(2, "days").startOf("day");
  const thisWeekStart = moment().startOf("week").startOf("day");
  const thisWeekEnd = moment().endOf("week").startOf("day");
  const lastWeekStart = moment()
    .subtract(1, "week")
    .startOf("week")
    .startOf("day");
  const lastWeekEnd = moment().subtract(1, "week").endOf("week").startOf("day");
  const weekBeforeLastStart = moment()
    .subtract(2, "weeks")
    .startOf("week")
    .startOf("day");
  const weekBeforeLastEnd = moment()
    .subtract(2, "weeks")
    .endOf("week")
    .startOf("day");
  const nextWeekStart = moment().add(1, "week").startOf("week");
  const nextWeekEnd = moment().add(1, "week").endOf("week").startOf("day");
  const weekAfterNextStart = moment()
    .add(2, "weeks")
    .startOf("week")
    .startOf("day");
  const weekAfterNextEnd = moment()
    .add(2, "weeks")
    .endOf("week")
    .startOf("day");
  const thisMonthStart = moment().startOf("month");
  const thisMonthEnd = moment().endOf("month").startOf("day");
  const lastMonthStart = moment().subtract(1, "month").startOf("month");
  const lastMonthEnd = moment()
    .subtract(1, "month")
    .endOf("month")
    .startOf("day");
  const nextMonthStart = moment().add(1, "month").startOf("month");
  const nextMonthEnd = moment().add(1, "month").endOf("month").startOf("day");
  const thisYearStart = moment().startOf("year").startOf("day");
  const thisYearEnd = moment().endOf("year").startOf("day");
  const lastYearStart = moment().subtract(1, "year").startOf("year");
  const lastYearEnd = moment().subtract(1, "year").endOf("year").startOf("day");
  const nextYearStart = moment().add(1, "year").startOf("year").startOf("day");
  const nextYearEnd = moment().add(1, "year").endOf("year").startOf("day");

  const start = value.start.clone().startOf("day");
  const end = value.end.clone().startOf("day");

  let niceLabel = I18n.t("Calendar.ranges.custom");
  const exactLabel = formatExactLabel(
    value.start,
    value.end
  ); /*`${value.start.format("YYYY MMMM D")} - ${value.end.format(
    "YYYY MMMM D"
  )}`;*/

  if (start.isSame(today) && end.isSame(today)) {
    niceLabel = I18n.t("Calendar.ranges.today");
  } else if (start.isSame(yesterday) && end.isSame(yesterday)) {
    niceLabel = I18n.t("Calendar.ranges.yesterday");
  } else if (
    start.isSame(dayBeforeYesterday) &&
    end.isSame(dayBeforeYesterday)
  ) {
    niceLabel = I18n.t("Calendar.ranges.dayBeforeYesterday");
  } else if (start.isSame(tomorrow) && end.isSame(tomorrow)) {
    niceLabel = I18n.t("Calendar.ranges.tomorrow");
  } else if (start.isSame(dayAfterTomorrow) && end.isSame(dayAfterTomorrow)) {
    niceLabel = I18n.t("Calendar.ranges.dayAfterTomorrow");
  } else if (start.isSame(thisWeekStart) && end.isSame(thisWeekEnd)) {
    niceLabel = I18n.t("Calendar.ranges.thisWeek");
  } else if (start.isSame(lastWeekStart) && end.isSame(lastWeekEnd)) {
    niceLabel = I18n.t("Calendar.ranges.lastWeek");
  } else if (
    start.isSame(weekBeforeLastStart) &&
    end.isSame(weekBeforeLastEnd)
  ) {
    niceLabel = I18n.t("Calendar.ranges.weekBeforeLast");
  } else if (start.isSame(nextWeekStart) && end.isSame(nextWeekEnd)) {
    niceLabel = I18n.t("Calendar.ranges.nextWeek");
  } else if (start.isSame(weekAfterNextStart) && end.isSame(weekAfterNextEnd)) {
    niceLabel = I18n.t("Calendar.ranges.weekAfterNext");
  } else if (start.isSame(thisMonthStart) && end.isSame(thisMonthEnd)) {
    niceLabel = I18n.t("Calendar.ranges.thisMonth");
  } else if (start.isSame(lastMonthStart) && end.isSame(lastMonthEnd)) {
    niceLabel = I18n.t("Calendar.ranges.lastMonth");
  } else if (start.isSame(nextMonthStart) && end.isSame(nextMonthEnd)) {
    niceLabel = I18n.t("Calendar.ranges.nextMonth");
  } else if (start.isSame(thisYearStart) && end.isSame(thisYearEnd)) {
    niceLabel = I18n.t("Calendar.ranges.thisYear");
  } else if (start.isSame(lastYearStart) && end.isSame(lastYearEnd)) {
    niceLabel = I18n.t("Calendar.ranges.lastYear");
  } else if (start.isSame(nextYearStart) && end.isSame(nextYearEnd)) {
    niceLabel = I18n.t("Calendar.ranges.nextYear");
  } else if (start.isSame(end)) {
    // Assuming you have the following moments defined
    const startOfWeek = 1; // Monday

    // Adjust each week's start to match the week's start day (Monday)
    const lastWeekDay = lastWeekStart
      .clone()
      .isoWeekday(startOfWeek + start.isoWeekday() - 1);
    const thisWeekDay = thisWeekStart
      .clone()
      .isoWeekday(startOfWeek + start.isoWeekday() - 1);
    const nextWeekDay = nextWeekStart
      .clone()
      .isoWeekday(startOfWeek + start.isoWeekday() - 1);
    const weekAfterNextDay = weekAfterNextStart
      .clone()
      .isoWeekday(startOfWeek + start.isoWeekday() - 1);
    const weekBeforeLastDay = weekBeforeLastStart
      .clone()
      .isoWeekday(startOfWeek + start.isoWeekday() - 1);

    const dayOfWeek = start.format("dddd");

    if (start.isSame(lastWeekDay, "day")) {
      niceLabel = I18n.t("Calendar.ranges.lastWeekDay", { dayOfWeek });
    } else if (start.isSame(thisWeekDay, "day")) {
      niceLabel = I18n.t("Calendar.ranges.thisWeekDay", { dayOfWeek });
    } else if (start.isSame(nextWeekDay, "day")) {
      niceLabel = I18n.t("Calendar.ranges.nextWeekDay", { dayOfWeek });
    } else if (start.isSame(weekAfterNextDay, "day")) {
      niceLabel = I18n.t("Calendar.ranges.weekAfterNextDay", { dayOfWeek });
    } else if (start.isSame(weekBeforeLastDay, "day")) {
      niceLabel = I18n.t("Calendar.ranges.weekBeforeLastDay", { dayOfWeek });
    }
  }

  return { niceLabel, exactLabel };
}
