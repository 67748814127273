import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";

function getTimeleft(tokenExpiresAt?: number): number {
  return tokenExpiresAt ? tokenExpiresAt - moment().unix() : 0;
}
export interface UseSessionTimerProps {
  tokenExpiresAt?: number;
  time?: number;
}
export default function useSessionTimer({
  tokenExpiresAt,
  time = 1000,
}: UseSessionTimerProps) {
  const [timeLeft, setTimeLeft] = useState<number>(getTimeleft(tokenExpiresAt));
  const interval = useRef<any>();

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      const t = getTimeleft(tokenExpiresAt);
      setTimeLeft(t);
    }, time);
    return () => {
      clearInterval(interval.current);
    };
  }, [tokenExpiresAt, time]);

  return timeLeft;
}

