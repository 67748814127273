//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  ProgramVariantItem,
  getProgramVariantService,
} from "./programVariantApi";

export default function useProgramVariantList() {
  const params = useParams<"program_id">();
  const scope = { "programs": params.program_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "program-variants/"
      : ""
  }`
  const service = getProgramVariantService({ "programs": params.program_id });

  return { ...service, params, scope, editUrl };
}

