//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getBookingOptionService } from "./bookingOptionApi";

export default function useBookingOptionList() {
  const params = useParams<
    | "booking_id"
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "program_variant_id"
    | "partner_id"
    | "partner_group_id"
    | "program_option_id"
    | "option_group_id"
  >();
  const scope = {
    bookings: params.booking_id,
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
    options: params.program_option_id,
    "option-groups": params.option_group_id,
  };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "booking-options/"
      : ""
  }`;
  const service = getBookingOptionService({
    bookings: params.booking_id,
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
    options: params.program_option_id,
    "option-groups": params.option_group_id,
  });

  return { ...service, params, scope, editUrl };
}

