import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Box } from "@mui/material";
import {
  NavigationTab,
  PageHeader,
  Spacer,
  TabNavigation,
  useSelectedTab,
} from "common";
import I18n from "i18n";
import { Outlet } from "react-router-dom";
import useRoleForm from "./useRoleForm";

export default function RoleTabs() {
  const form = useRoleForm({});
  const selectedTab = useSelectedTab({ pattern: "roles/:role_id" });
  //const welcomeEmailSent = parseInt(form.item.welcome_email_sent) > 0;
  const title = form.item.name;
  return (
    <Box>
      <PageHeader
        backUrl={".."}
        title={
          form.isCreate ? I18n.t("Role.addTitle") : I18n.t("Role.editTitle")
        }
        subtitle={form.isCreate ? undefined : title}
        loading={form.loading}
        avatar={<AdminPanelSettingsIcon />}
        //subtitle={form.isCreate ? undefined : getClientAddress(form.item)}
      ></PageHeader>
      {!form.isCreate && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginLeft: -3,
            marginRight: -3,
          }}
        >
          <TabNavigation value={selectedTab || ""}>
            <NavigationTab label={I18n.t("App.general")} value="" replace />
            <NavigationTab
              label={I18n.t("Permission.title")}
              value="permissions"
              replace
            />
          </TabNavigation>
        </Box>
      )}
      <Spacer h={3} />
      <Outlet context={form} />
    </Box>
  );
}

