import api from "app/api";
import { BoatItem } from "features/boats/boatApi";
import { LIMIT_TYPES } from "./scheduleConstants";

export interface ScheduleEvent {
  start_times: string[];
  days: number[];
}

export type ScheduleLimit = {
  variant_ids: number[];
  number_of_places: number;
  number_of_online_places: number;
  places_tolerance: number;
  name?: string; //New field required when type === LIMIT_TYPES.CUSTOM
  program_id?: number; //New field required when type === LIMIT_TYPES.PROGRAM
  type: LIMIT_TYPES; //New field required
};

export type ScheduleVariant = {
  variant_id: number;
  adult_price: number;
  kid_price: number;
};

export type ScheduleOption = {
  option_id: number;
  price: number;
  limit?: number;
};

export interface ScheduleItem {
  id: number;
  name: string;
  boat_id: number;
  boat?: BoatItem;
  duration: number;
  date_from: string;
  date_to: string;
  program_ids: number[];
  events: ScheduleEvent[];
  limits: ScheduleLimit[];
  variants: ScheduleVariant[];
  options: ScheduleOption[];
  is_modified?: boolean;
  has_event?: any;
}

export function getScheduleService(scope?: any) {
  return api.getService<ScheduleItem>("schedules", {
    scope,
    //params goes here
  });
}

