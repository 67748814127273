//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  BoatItem,
  getBoatService,
} from "./boatApi";

export default function useBoatList() {
  const params = useParams();
  const scope = undefined;
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "boats/"
      : ""
  }`
  const service = getBoatService();

  return { ...service, params, scope, editUrl };
}

