import {
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
  Tooltip,
} from "@mui/material";
import { cloneElement, ReactElement } from "react";
import { Link } from "react-router-dom";

export interface NavigationMenuItemProps
  extends Omit<MenuItemProps, "component"> {
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
  value?: any;
  label?: string;
  download?: string;
  icon?: ReactElement;
  textProps?: ListItemTextProps;
  absolute?: boolean;
  minimized?: boolean;
  iconProps?: ListItemIconProps;
}
export default function NavigationMenuItem({
  reloadDocument,
  replace,
  state,
  value,
  label,
  download,
  icon,
  textProps,
  iconProps,
  absolute,
  minimized,
  ...props
}: NavigationMenuItemProps) {
  const menuItemProps = {
    ...props,
    sx: {
      "& a": {
        flex: 1,
        display: "flex",
        textDecoration: "none",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
      },
      ...props.sx,
    },
  };
  if (value || value === "") {
    return (
      //@ts-ignore
      <MenuItem {...menuItemProps}>
        <Tooltip
          title={label}
          disableHoverListener={!minimized}
          placement="right"
        >
          <Link
            replace={replace}
            state={state}
            reloadDocument={reloadDocument}
            to={absolute ? `/${value}` : value}
            style={{}}
            className="MuiLink-root"
          >
            {!!icon && (
              <ListItemIcon
                {...iconProps}
                sx={{
                  minWidth: 0,
                  mr: minimized ? "auto" : 0,
                  justifyContent: "center",
                  ...iconProps?.sx,
                }}
              >
                {cloneElement(icon, { fontSize: "small", ...icon.props })}
              </ListItemIcon>
            )}
            <ListItemText
              {...textProps}
              sx={{
                display: minimized ? "none" : "block",
                whiteSpace: "normal",
                ...textProps?.sx,
              }}
            >
              {label}
            </ListItemText>
          </Link>
        </Tooltip>
      </MenuItem>
    );
  }
  if (download) {
    return (
      //@ts-ignore
      <MenuItem {...menuItemProps}>
        <Tooltip
          title={label}
          disableHoverListener={!minimized}
          placement="right"
        >
          <a href={download} download>
            {!!icon && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: minimized ? "auto" : 0,
                  justifyContent: "center",
                  ...iconProps?.sx,
                }}
              >
                {cloneElement(icon, { fontSize: "small", ...icon.props })}
              </ListItemIcon>
            )}
            <ListItemText
              {...textProps}
              sx={{
                display: minimized ? "none" : "block",
                whiteSpace: "normal",
                ...textProps?.sx,
              }}
            >
              {label}
            </ListItemText>
          </a>
        </Tooltip>
      </MenuItem>
    );
  }
  return (
    <Tooltip title={label} disableHoverListener={!minimized} placement="right">
      <MenuItem {...menuItemProps}>
        {!!icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: minimized ? "auto" : 0,
              justifyContent: "center",
              ...iconProps?.sx,
            }}
          >
            {cloneElement(icon, { fontSize: "small", ...icon.props })}
          </ListItemIcon>
        )}
        <ListItemText
          {...textProps}
          sx={{
            display: minimized ? "none" : "block",
            whiteSpace: "normal",
            ...textProps?.sx,
          }}
        >
          {label}
        </ListItemText>
      </MenuItem>
    </Tooltip>
  );
}

