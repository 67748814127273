import { resetStatus } from "./identitySlice";

const identityMiddleware =
  (store: any) => (next: any) => async (action: any) => {
    const nextAction = next(action);

    //Clear login loading state on page reload
    if (action.type === "persist/REHYDRATE") {
      if (store.getState().identity.status !== "idle") {
        store.dispatch(resetStatus());
      }
    }

    return nextAction;
  };

export default identityMiddleware;

