import DataGridBase, { DataGridBaseBaseProps } from "./DataGridBase";
import { GridToolbarItem, UseDataGridProps } from "./types";
import useDataGrid from "./useDataGrid";

export interface DataGridProps extends UseDataGridProps, DataGridBaseBaseProps {
  toolbar?: {
    items: GridToolbarItem[];
    renderCreate?: Function;
    renderTrash?: Function;
    renderRefresh?: Function;
    renderSearch?: Function;
    renderFilter?: Function;
    renderFilters?: Function;
    renderTitle?: Function;
  };
}

export default function DataGrid(props: DataGridProps) {
  const props2 = useDataGrid(props);

  return <DataGridBase {...props} {...props2} />;
}

