import { useRef, useState } from "react";

export enum UploadFileTypes {
  DocumentsAndImages = ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/png, jpg, image/jpg, jpeg, image/jpeg, .pdf, application/pdf",
  Jpeg = "jpg, image/jpg, jpeg, image/jpeg",
  M4a = "m4a, audio/x-m4a",
  //Aac = "aac, audio/x-hx-aac-adts",
  Aac = ".aac, audio/x-hx-aac-adts",
  ExcelCsv = ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
}

export interface UseUploadButtonProps {
  onChange: (files: FileList | null) => any;
  multiple?: boolean;
  accept?: string;
}

export default function useUploadButton({
  onChange,
  multiple,
  accept,
}: UseUploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  function resetInput() {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  function getFileInput() {
    return (
      <input
        multiple={multiple}
        ref={fileInputRef}
        onChange={async evt => {
          let promise = onChange(evt.target.files);
          if (!!promise && typeof promise.then === "function") {
            setLoading(true);
            promise.finally(() => {
              setLoading(false);
              resetInput();
            });
          } else {
            resetInput();
          }
        }}
        type="file"
        hidden
        accept={accept}
      />
    );
  }

  return {
    loading,
    fileInput: getFileInput(),
  };
}

