import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { GridColumn, GridState } from "./types";

export interface DataGridVisibleColumnsProps {
  handleToggleColumn: (column: string) => void;
  state: GridState;
  columns: GridColumn[];
}

export default function DataGridVisibleColumns({
  handleToggleColumn,
  state,
  columns,
}: DataGridVisibleColumnsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Oszlop láthatóság">
        <IconButton
          //onClick={() => handleToggleColumn("name")}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {columns
          .filter(c => c.hideable !== false && c.hide !== true)
          .map((column, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleToggleColumn(column.field)}
              >
                <ListItemText>{column.headerName || column.field}</ListItemText>
                {!!state.hidden_columns.find(
                  field => field === column.field
                ) ? (
                  <VisibilityOffIcon sx={{ color: "action.disabled" }} />
                ) : (
                  <VisibilityIcon sx={{ color: "action.active" }} />
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}

