import { DialogProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export type DialogParams = { open: boolean; onClose?: DialogProps["onClose"] };
export type UseDialogReturnProps<T = any> = [
  //"create" | T | undefined,
  T | undefined,
  (value?: T) => void,
  DialogParams
];
export interface UseDialogProps<T = any> {
  value?: T;
}
export default function useDialog<T = any>(
  props?: UseDialogProps<T>
): UseDialogReturnProps<T> {
  const [value, setValue] = useState<T | undefined>(props?.value);
  const [open, setOpen] = useState<boolean>(!!props?.value);

  useEffect(() => {
    if (!!value !== open) {
      if (typeof value === "boolean") {
        setOpen(!!value);
      } else {
        setTimeout(() => {
          setOpen(!!value);
        }, 200);
      }
      /*setTimeout(() => {
        setOpen(!!value);
      }, 200);*/
    }
  }, [value]);

  return [
    value,
    (value?: T) => setValue(value),
    {
      open: open,
      onClose: () => {
        setOpen(false);
        setTimeout(() => {
          setValue(undefined);
        }, 400);
      },
    },
  ];
}

export interface UseRouteDialogProps {
  paramName: string;
}
export function useRouteDialog({ paramName }: UseRouteDialogProps) {
  const params: any = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  function goBack() {
    const backUrl = location.pathname.replace(
      new RegExp("/" + params[paramName] + "$"),
      ""
    );

    navigate(backUrl, { replace: true });
  }
  return [
    params[paramName],
    (value: any) => (value ? navigate(value) : goBack()),
    {
      open: !!params[paramName],
      onClose: goBack,
    },
  ] as [string, Function, { open: boolean; onClose?: DialogProps["onClose"] }];
}

