import { BaseInputProps } from "common/components/Inputs/types";
import { FakeEvent } from "common/types";
import { BookingOptionItem } from "features/booking-options/bookingOptionApi";
import { BookingItem } from "features/bookings/bookingApi";
import { OptionGroupItem } from "features/option-groups/optionGroupApi";
import { OptionItem } from "features/options/optionApi";
type Group = {
  items: OptionItem[];
} & OptionGroupItem;

export interface BookingOptionSelectorProps
  extends BaseInputProps<BookingOptionItem[]> {
  options?: Group[];
  booking: BookingItem;
  onChange?: (event: FakeEvent<BookingOptionItem[]>) => void;
}
export type BookingOptionValidatorProps = {
  booking: BookingItem;
  options?: Group[];
  //value?: BookingOptionItem[];
};
export default class BookingOptionValidator {
  constructor({ booking, options }: BookingOptionValidatorProps) {
    this.booking = booking;
    this.options = options;
    //@ts-ignore
    this.value = booking.booking_options;
    this.setup();
  }
  booking: BookingItem;
  options?: Group[];
  value?: BookingOptionItem[];
  number_of_guests!: number;
  number_of_guests_with_kids!: number;

  setup() {
    this.number_of_guests =
      (this.booking.number_of_adults || 0) + (this.booking.number_of_kids || 0);
    this.number_of_guests_with_kids =
      this.number_of_guests + (this.booking.number_of_babies || 0);
  }

  getGroupOptionCount(group: Group): number {
    let count = 0;
    this.value?.forEach(option => {
      if (option.option?.option_group_id === group.id) {
        count += option.qty;
      }
    });
    return count;
  }
  validateGroup(group: Group): "more" | "less" | "ok" | null {
    if (group.type === "guest") {
      let count = this.getGroupOptionCount(group);
      if (count > this.number_of_guests) {
        return "more";
      }
      if (count < this.number_of_guests && group.required) {
        return "less";
      }
    }
    if (!group.required) {
      return null;
    }
    if (group.type === "booking") {
      //TODO: Ennek itt nincs sok értelme ( egy kötelező pipa lenne az eredménye)
    }
    return "ok";
  }
  validateOptions() {
    let errors: any = {};
    this.options?.forEach(group => {
      let validationResult = this.validateGroup(group);
      if (/*validationResult === "less" ||*/ validationResult === "more") {
        errors[group.id] = validationResult;
      }
    });
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return null;
  }
}

