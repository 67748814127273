import api from "app/api";
import { Options } from "common";
import { DbFile } from "common/types";
export interface UserItem {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  password_hash: string;
  login_at: string;
  file: DbFile;
  file_id: number;
  settings: any;
  active: boolean;
  role_ids: number[];
}

export type UserOptions = Options<"role" | "status">;

export function getUserService(scope?: any) {
  return api.getService<UserItem, UserItem, UserOptions>("users", {
    scope,
    //params goes here
  });
}
