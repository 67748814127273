import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Box } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PersonIcon from "@mui/icons-material/Person";import I18n from "i18n";
import {
  Button,
  NavigationTab,
  PageHeaderBreadcrumbs,
  Spacer,
  TabNavigation,
  useSelectedTab,
  Toolbar
} from "common";
import { Fragment } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import useAccess from "features/profile/useAccess";
import useUserForm, {
  UseUserFormProps,
} from "./useUserForm";


export default function UserTabs() {
  const context = useOutletContext<any>();
  const parents = context?.parents;
  const form = useUserForm({});
  const { hasPermissions } = useAccess();
  const selectedTab = useSelectedTab({ pattern: "users/:user_id" });
  const { item } = form;
  const title = item.name;
  const subtitle = item.email;
 
  function Header({ isParent }: { isParent?: boolean; }) { return (<PageHeaderBreadcrumbs
  parents={isParent ? undefined : parents}
  avatar={<PersonIcon />}  backUrl={parents ? undefined : ".."}
  title={form.isCreate ? I18n.t("User.addTitle") : title}
  loading={form.loading}
  subtitle={subtitle}
/>)};

  if (false) {
    return (
      <Outlet
        context={{
          ...form,
          parents: parents ? [...parents, <Header isParent />] : [<Header isParent />],
        }}
      />
    );
  }

  return (
    <Box>
      <Header />
      {!form.isCreate && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginLeft: -3,
            marginRight: -3,
          }}
        >
          <TabNavigation value={selectedTab || ""}>
            <NavigationTab
              label={I18n.t("User.titleSingular")}
              value=""
              replace
              icon={<PersonIcon />}            />
                  {hasPermissions("permission.view") && (<NavigationTab
        icon={<VerifiedUserIcon />}
      label={I18n.t("Permission.title")}
      value="permissions"
      replace
    />)}
          </TabNavigation>
        </Box>
      )}
      <Spacer h={3} />
      <Outlet context={form} />
    </Box>
  );
}

