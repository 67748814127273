import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Table } from "../TableEditor/TableEditor";

export interface TableSelectorProps {
  value: string[] | null;
  reserved: string[] | null;
  onChange: (selectedTables: string[] | null) => void;
  tables: Table[];
}

const TableSelector: React.FC<TableSelectorProps> = ({
  value = [],
  reserved = [],
  onChange,
  tables = [],
}) => {
  const originalValue = useRef(value);
  const toggleTableSelection = (tableName: string) => {
    let updatedSelection;
    if (value?.find(t => String(t) === String(tableName))) {
      updatedSelection = value.filter(
        name => String(name) !== String(tableName)
      );
    } else {
      updatedSelection = [...(value || []), tableName];
    }
    onChange(updatedSelection);
  };

  return (
    <Box
      sx={{
        position: "relative",
        //width: "100%",
        height: "500px",
        border: theme => `1px solid ${theme.palette.divider}`,
        backgroundColor: theme => theme.palette.background.sheet,
        backgroundImage: theme =>
          `linear-gradient(to right,  ${theme.palette.divider} 1px, transparent 1px),linear-gradient(to bottom,  ${theme.palette.divider} 1px, transparent 1px)`,
        backgroundSize: `15px 15px`,
        //overflow: "hidden",
        overflowX: "auto",
      }}
    >
      {tables.map(table => {
        const isTable = table.type === "table";
        const isBookable =
          (!reserved?.find(t => String(t) === String(table.name)) ||
            originalValue.current?.find(
              t => String(t) === String(table.name)
            )) &&
          table.type === "table";

        const selected = !!value?.find(t => String(t) === String(table.name));
        return (
          <Box
            key={table.id}
            sx={{
              position: "absolute",
              left: table.x - 1,
              top: table.y - 1,
              width: table.width - 4,
              height: table.height - 4,
              backgroundColor: theme =>
                selected ? theme.palette.info.dark : table.color,
              border: "2px solid",
              borderColor: theme =>
                selected ? theme.palette.info.main : table.color,
              color: theme =>
                theme.palette.getContrastText(
                  selected ? theme.palette.info.dark : table.color
                ),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: isBookable ? "pointer" : "not-allowed",
              opacity: isBookable || !isTable ? 1 : 0.3,
              fontWeight: 700,
            }}
            onClick={
              isBookable ? () => toggleTableSelection(table.name) : undefined
            }
          >
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: 0,
                left: 2,
                fontWeight: 700,
                color: theme =>
                  theme.palette.getContrastText(
                    selected ? theme.palette.info.dark : table.color
                  ),
                opacity: 0.7,
                //zIndex: -1,
              }}
            >
              {table.max_person}
            </Typography>
            {table.name}
          </Box>
        );
      })}
    </Box>
  );
};

export default TableSelector;
